import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Spinner, ContentTop, CampaignCard, Filter, FilterRow} from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getCampaigns, updateFilter, clearFilter } from './CropValidationIndexActions';
import { FilterFields } from '../../../interfaces/interfaces';
import { getDepartments } from '../../../reducers/datatypes/DatatypesActions';

const mapStateToProps = (state: RootState) => {
	return {
		campaign_index_state: state.campaignIndex,
		datatypes_state: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	getCampaigns,
	updateFilter,
	clearFilter,
	getDepartments,
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showAddModal: boolean,
	showFilter: boolean,
	showCreate: boolean,
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showAddModal: false,
			showFilter: false,
			showCreate: false,
        };
	}

	componentDidMount() {
		this.props.getCampaigns();
		this.props.getDepartments();
	}

	renderFilter() {
		if (this.state.showFilter === true) {
			return (
				<Filter
					filterFields={FilterFields.CAMPAIGN}
					filter={this.props.campaign_index_state.filter}
					onClose={() => this.setState({ showFilter: false })}
					onClear={() => {
						this.props.clearFilter()
						this.setState({ showFilter: false })
						this.props.getCampaigns()
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter)
						this.setState({ showFilter: false })
						this.props.getCampaigns()
				}}/>)
			}
	}

	renderContentTop() {
		const { t } = this.props;
		const { campaigns, filter } = this.props.campaign_index_state;

		return (
			<ContentTop>
				<article>
					<div>
						<h2>{ t('campaigns') }</h2>
					</div>
				</article>
				<FilterRow
					filter={ filter }
					results={ campaigns.length || 0 }
					pluralizeStrings={[t('result_found'), t('results_found')]}
					onFilterClicked={() => this.setState({ showFilter: true })}
				/>
			</ContentTop>
		)
	}

	renderCampaigns() {
		const { campaigns } = this.props.campaign_index_state;
		return (
			campaigns.map(campaign => (<CampaignCard key={campaign.id} campaign={campaign} crops={true}/>))
		)
	}

	render () {
		const { t } = this.props;
		const { loading } = this.props.campaign_index_state;

		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		return (
			<>
				{this.renderFilter()}
				{this.renderContentTop()}
				<section id="campaigns">
					{this.renderCampaigns()}
				</section>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
