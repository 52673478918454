import React from 'react';
import { Link } from "react-router-dom";
import { User } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface UserCardProps {
    user: User
}

type StoreCardPropsSum = UserCardProps & WithTranslation;

class UserCardComponent extends React.Component<StoreCardPropsSum> {

    cardLink(user: User) {
        if (user.id === undefined) {
            return '#';
        }
        return `/users/${user.id}`;
    }

    checkDisabled(status: string) {
        if (status !== 'Passed') {
            return 'disabled';
        }
        return '';
    }

    render () {

        const { user } = this.props;
     
        return (
            <li>
                <Link to={this.cardLink(user)} className="cursor-hand">
                    <h3>{ user.name } { user.last_name }</h3>
                </Link>
            </li>
        );
    };
}

const UserCard = withTranslation()(UserCardComponent);
export { UserCard };
