import {
    UPDATE_SUPPORTS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    SupportIndexActions,
    AppThunk,
    APPEND_SUPPORTS,
    UPDATE_STORES,
    UPDATE_FLOORS,
} from './ActionTypes';
import { Filter } from '../../../interfaces/interfaces';
import { get_floors, get_stores, get_supports } from '../../../api';
import { FilterFloorRequest, FilterStoreRequest } from '../../../api/interfaces';

export const getSupports = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as SupportIndexActions);

        const filter = getState().supportIndex.filter;
      
        filter.search=filter.search_support;
        let supports = await get_supports({
            ...filter,
            store_nav_ids: filter.store ? [filter.store] : [],
            textil_types: filter.textileTypesList,
            support_types: filter.supportTypesList,
            status_primark: filter.support_status,
            key: lastKey
        });

        dispatch({
            type:  lastKey ? APPEND_SUPPORTS : UPDATE_SUPPORTS,
            payload: supports
        } as SupportIndexActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as SupportIndexActions);
    }
}

export const updateFilter = (value: Filter): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as SupportIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as SupportIndexActions);
    }
}

export const getStores = (): AppThunk => {
    return async dispatch => {
        const stores = await get_stores({ page_size: 0 } as FilterStoreRequest);
        const sortedStores = stores?.sort((n1,n2) => {
            if (n1.name > n2.name) {
                return 1;
            }
        
            if (n1.name < n2.name) {
                return -1;
            }
        
            return 0;
        });

        dispatch({
            type:  UPDATE_STORES,
            payload: sortedStores.map(store => ({ value: store.id, name: `${store.customer_shop_no}: ${store.name}` }))
        } as SupportIndexActions);
    }
}

export const getFloors = (): AppThunk => {
    return async dispatch => {
        const floors = await get_floors({ page_size: 0 } as FilterFloorRequest);

        dispatch({
            type:  UPDATE_FLOORS,
            payload: floors
        } as SupportIndexActions);
    }
}