import { get_active_campaign, one_store_validation, get_gallery_visuals, get_campaigns } from '../../../api';
import { Filter, Support, SupportStatusPrimark } from '../../../interfaces/interfaces';
import {
    UPDATE_LOADING,
    UPDATE_STORE,
    UPDATE_ACTIVE_CAMPAIGN,
    UPDATE_GALLERY_VISIBLE_CAMPAIGN_VISUALS,
    UPDATE_FILTER,
    UPDATE_CAMPAIGNS,
    UPDATE_KENDU_PROCESSING,
    AppThunk,
    SELECT_SUPPORT,
} from './ActionTypes';

export const getData = (id: string, support_id?: string): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        });

        let store = await one_store_validation(id);
     
        
        const kendu_processing_supports=store?.supports?.find(support => support.status_primark === SupportStatusPrimark.KENDU_PROCESSING);
       
        let supports_not_kendu_processing = store?.supports?.filter(support => support.status_primark !== SupportStatusPrimark.KENDU_PROCESSING);
        let new_store={...store,supports: supports_not_kendu_processing};
        
    
        
        dispatch({
            type: UPDATE_STORE,
            payload: new_store
        });
        if(kendu_processing_supports){
            dispatch({
                type: UPDATE_KENDU_PROCESSING,
                payload: true
            }); 
        }else{
            dispatch({
                type: UPDATE_KENDU_PROCESSING,
                payload: false
            }); 
        }
        const selected_support = new_store?.supports?.find(support => support.id === support_id) || new_store?.supports?.[0] || null;
        dispatch({
            type: SELECT_SUPPORT,
            payload: selected_support
        }); 

        const active_campaign = await get_active_campaign();
        dispatch({
            type: UPDATE_ACTIVE_CAMPAIGN,
            payload: active_campaign
        }); 
        
        dispatch({
            type: UPDATE_LOADING,
            payload: false
        });

        const visuals = await get_gallery_visuals();
        dispatch({
            type: UPDATE_GALLERY_VISIBLE_CAMPAIGN_VISUALS,
            payload: visuals
        }); 
    }
}

export const selectSupport = (support: Support) => {
    return {
        type: SELECT_SUPPORT,
        payload: support
    }
}

export const updateFilter = (value: Filter): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        });
    }
}

export const getCampaigns = (): AppThunk => {
    return async (dispatch) => {
        const campaigns = await get_campaigns({galleryVisible:true, is_gallery: true});
        dispatch({
            type: UPDATE_CAMPAIGNS,
            payload: campaigns
        });
    }
}
