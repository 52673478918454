import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseButton, Notification, SupportCard } from '.';
import { Support, ViewSupportStyle, Campaign } from '../interfaces/interfaces';

export interface SupportsWithVisualProps {
    onClose: () => void,
    supports: Support[] | undefined,
    campaign?: Campaign | undefined
}

type SupportsWithVisualPropsSum = SupportsWithVisualProps & WithTranslation;
class SupportsWithVisualComponent extends React.Component<SupportsWithVisualPropsSum> {

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll');
    }

    render () {
        const { t, supports, campaign } = this.props;
        return(
            <section id="support-with-visual" className="cover-blur /*active*/ active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('image_placed_in_suports')}</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content pb-40">
                        <fieldset>
                            <Notification title={t('changing_this_image_will_cost_you')} subtitle={"1200 €"} />
                            <ul className="accordion">
                                <li className="open">                                          
                                    <section className="campaign-content">                                        
                                        {(supports || []).map((support: Support) =>
                                            <SupportCard key={support.id} support={support} campaign={campaign} viewStyle={ ViewSupportStyle.POPUP }/>
                                        )}
                                    </section>
                                </li>
                            </ul>
                        </fieldset>
                    </section>
                </section>
            </section>
        )
    };
}

const SupportsWithVisual= withTranslation()(SupportsWithVisualComponent);


export { SupportsWithVisual};
