import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Filter as FilterIcon } from 'react-feather';
import { pluralize } from '../util/text';
import { SecondaryButton } from '.';
import { SecondaryButtonStyle, UserFilter as FilterInterface } from "../interfaces/interfaces";

export interface UserFilterRowProps {
    filter: FilterInterface,
    results?: number,
    pluralizeStrings: string[],
    totalResults?: number,
    onFilterClicked: () => void,
}

type UserFilterRowPropsSum = UserFilterRowProps & WithTranslation;

class UserFilterRowComponent extends React.Component<UserFilterRowPropsSum> {

    is_filtered(filter: any) : boolean {
        return Object.values(filter).some(item => item && item !== null && item !== '');
    }

    render () {
        const { t, filter, results, pluralizeStrings, onFilterClicked, totalResults } = this.props;

        var numberComponent = undefined
        if (results && results === totalResults) {
            numberComponent = <p>{pluralize(results, pluralizeStrings)}</p>
        } else if (results && totalResults && results !== totalResults) {
            numberComponent =  <p>{ results + " / " + pluralize(totalResults, pluralizeStrings)}</p>
        } else {
            numberComponent = <p>{pluralize(results!, pluralizeStrings)}</p>
        }

        return (
            <article>
                <div className="resultDetails">
                    { numberComponent }
                </div>
                <div className="filters">
                    <SecondaryButton onClick={() => onFilterClicked()} style={this.is_filtered(filter) ? SecondaryButtonStyle.YELLOW : SecondaryButtonStyle.PRIMARY}>
                        <FilterIcon />
                        <span>{t('filter')}</span>
                    </SecondaryButton>
                </div>
            </article>
        );
    }

}

const UserFilterRow = withTranslation()(UserFilterRowComponent);
export { UserFilterRow };
