import { Store, Campaign, VisualPack } from "../interfaces/interfaces";
import { ApiClient } from "./ApiClient";

export class AuthError extends Error {
  constructor() {
    super("Auth error");
  }
}

export class StoreNotFoundError extends Error {
  constructor() {
    super("Store not found");
  }
}

export class StoreApi {
  constructor(private readonly client: ApiClient) {}

  async getStore(id: string): Promise<Store | null> {
    return this.client.request({
      url: `/stores/with_supports/${id}`,
      method: "GET",
    });
  }

  async getActiveCampaign(): Promise<Campaign | null> {
    return this.client.request({
      url: "/campaigns/find_active_campaign/",
      method: "GET",
    });
  }

  async getVisualPacks(storeId: string): Promise<VisualPack[]> {
    return this.client.request({
      url: `/visualpack/${storeId}`,
      method: "GET",
    });
  }
}
