import {
    UPDATE_LOADING,
    UPDATE_LINES,
    CLEAN_LINES,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_STORES,
    UPDATE_TOTAL_LINES,
  
    ProcessingIndexActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { ProcessingLine,Filter,SelectOption } from "../../../interfaces/interfaces";

export interface ProcessingIndexState {
    lines: ProcessingLine[]|undefined,
    loading: boolean,
    filter: Filter,
    stores: SelectOption[],
    totalLines:ProcessingLine[]|undefined,
}

const INITIAL_STATE: ProcessingIndexState = {
    lines: undefined,
    loading: false,
    filter: new Filter(),
    stores: [],
    totalLines:undefined
};

const reducer: Reducer<ProcessingIndexState, ProcessingIndexActions> = (state = INITIAL_STATE, action: ProcessingIndexActions) => {
    switch(action.type) {
        case UPDATE_LINES:
            return { ...state, lines: action.payload};  
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case CLEAN_LINES:
            return { ...state, lines: undefined};  
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new Filter() };
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case UPDATE_TOTAL_LINES:
            return { ...state, totalLines: action.payload };
        default:
            return state;
    }
}

export default reducer;
