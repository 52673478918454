import {
    UPDATE_CAMPAIGNS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    CropValidationIndexActions,
    AppThunk,
} from './ActionTypes';
import { CampaignStatusPrimark, Filter } from '../../../interfaces/interfaces';
import { get_campaigns } from '../../../api';
import { FilterCampaignRequest } from '../../../api/interfaces';


export const getCampaigns = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CropValidationIndexActions);

        const campaigns = await get_campaigns({
            ...getState().campaignIndex.filter,
            title: getState().campaignIndex.filter.search,
            status_primark: CampaignStatusPrimark.RATIO_CROP_VALIDATING,
            key: lastKey,
        } as FilterCampaignRequest);

        dispatch({
            type:  UPDATE_CAMPAIGNS,
            payload: campaigns
        } as CropValidationIndexActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CropValidationIndexActions);
    }
}

export const updateFilter = (value: Filter): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as CropValidationIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as CropValidationIndexActions);
    }
}