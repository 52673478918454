import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Accordion, InputText, PrimaryButton, CloseButton } from '.';
import { Visual } from '../interfaces/interfaces';

export interface AddImageProps {
    onClose: () => void,
    onSubmit: () => void,
}

type AddImagePropsSum = AddImageProps & WithTranslation;

interface State {
	selectedImage: Visual | null;
}

class AddImageComponent extends React.Component<AddImagePropsSum, State> {

    constructor(props: AddImagePropsSum) {
		super(props);
		this.state = {
			selectedImage: null,
		};
	}

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    render () {
        const { t } = this.props;
        const { selectedImage } = this.state;

        return (
            <section id="store-add-validating-image" className="cover-blur active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('add_support') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content pb-50">
                        <fieldset>
                            <div className="form-row">
                                <article className="form-item">
                                    <InputText id="search" search={ true } className="field field--search" placeholder={ t('name_status_placeholder')} value={''} onChange={(text) => null} />
                                </article>
                            </div>
                        </fieldset>
                        <ul className="accordion">
                            <Accordion
                                title="Campaign 1"
                                defaultOpen={ true }
                                visuals={[
                                    { image: '/img/borrar/michael-dam.jpg', name: 'image 1', campaign_id:"25", key: "1", id: "1" , priority: 0 , section:'1', thumbnail: '/img/borrar/michael-dam.jpg', adaptation: false },
                                    { image: '/img/borrar/generica.jpg', name: 'image 2', campaign_id:"25", key: "2", id: "2" , priority: 0 , section:'1', thumbnail: '/img/borrar/generica.jpg', adaptation: false },
                                    { image: 'https://images.unsplash.com/photo-1511317559916-56d5ddb62563?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=693&q=802', name: 'image 3', campaign_id:"25", key: "3", id: "3" , priority: 0 , section:'1', thumbnail: 'https://images.unsplash.com/photo-1511317559916-56d5ddb62563?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=693&q=802', adaptation: false },
                                ]}
                                selectedImage={selectedImage}
                                onSelect={(visual: Visual) => this.setState({ selectedImage: visual })}
                            />
                            <Accordion
                                title="Campaign 2"
                                selectedImage={selectedImage}
                                visuals={[
                                    { image: '/img/borrar/img-grid.png', name: 'image 4', campaign_id:"25", key: "4", id: "4" , priority: 0 , section:'1', thumbnail: '/img/borrar/img-grid.png', adaptation: false },
                                ]}
                                onSelect={(visual: Visual) => this.setState({ selectedImage: visual })}
                            />
                            <Accordion
                                title="Campaign 3"
                                selectedImage={selectedImage}
                                visuals={[
                                    { image: '/img/borrar/campaign_card4.jpg', name: 'image 5', campaign_id:"25", key: "4", id: "4" , priority: 0 , section:'1', thumbnail: '/img/borrar/campaign_card4.jpg', adaptation: false },
                                ]}
                                onSelect={(visual: Visual) => this.setState({ selectedImage: visual })}
                            />
                        </ul>
                    </section>
                    <section className="popup-actions">
                        <PrimaryButton onClick={() => this.props.onSubmit()}>{ t('add_support') }</PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const AddImage = withTranslation()(AddImageComponent);
export { AddImage };
