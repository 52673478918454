import { approve_store_email,one_store } from '../../../api';
import { Store } from '../../../interfaces/interfaces';
import {
   UPDATE_APPROVE,
    UPDATE_LOADING,
    UPDATE_STORE,
    CLEAN_STORE,
    ApproveStoreActions,
    AppThunk,
} from './ActionTypes';



export const approveStoreEmail = (store_id: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ApproveStoreActions);
       
        const result = await approve_store_email(store_id);
        dispatch({
            type: UPDATE_APPROVE,
            payload: result
        } as ApproveStoreActions);
        dispatch({
            type: CLEAN_STORE,
            
        } as ApproveStoreActions);
        const store: Store = await one_store(store_id, {});
        dispatch({
            type: UPDATE_STORE,
            payload: store
        } as ApproveStoreActions);
       
        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ApproveStoreActions);
    }
}
