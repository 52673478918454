import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputText } from '.';

export interface FormTextProps {
    id: string,
    label?: string,
    value?: string,
    disabled?: boolean,
    helpText?: string,
    className?: string,
    error?: boolean,
    maxLength?: number|null,
    onChange?: (text: string) => void
}

type FormTextPropsSum = FormTextProps & WithTranslation;

class FormTextComponent extends React.Component<FormTextPropsSum> {
    
    render () {
        const { id, label, value, disabled, helpText, className, onChange,error,maxLength } = this.props;
       
        return (
            <article className="form-item">
            {error?<label style={{'color':'red'}} htmlFor={ id }>{ label }</label>:<label htmlFor={ id }>{ label }</label>}
                
                <InputText id={ id } className={`field field--text ${className ? className : ''}`} value={value} disabled={disabled} helpText={helpText} onChange={(text) => onChange ? onChange(text) : null}  maxLength={maxLength}/>
            </article>
        );
    };
}

const FormText = withTranslation()(FormTextComponent);
export { FormText };
