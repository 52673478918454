import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SelectOption, User, PrimaryButtonStyle } from "../interfaces/interfaces";
import { FormText, CloseButton, PrimaryButton, FormSelectSearchable, InputSelectMultiple } from '.';
import { is_empty_string } from '../util/form';
import { Role } from '../util/jwt';

export interface EditProfileProps {
    user?: User,
    roles?: SelectOption[],
    stores?: SelectOption[],
    user_new_edit?: string,
    onClose: () => void,
    onSubmit: (user: User) => void,
    onDelete: (user: User) => void
}

interface State {
    user: User | undefined,
    user_name_error: string,
    user_last_name_error: string,
    user_email_error: string,
    user_role_error: string,
    user_phone_error: string,
    stores_has_change:boolean,
    initial_user:User |undefined
}

type EditProfilePropsSum = EditProfileProps & WithTranslation;

class EditProfileComponent extends React.Component<EditProfilePropsSum, State> {

    constructor(props: EditProfilePropsSum) {
        super(props);

        this.state = {
            user: {...props.user} as User,
            user_name_error: '',
            user_last_name_error: '',
            user_email_error: '',
            user_role_error: '',
            user_phone_error: '',
            stores_has_change:false,
            initial_user:{...props.user} as User
        };
    }

    componentDidMount() {
        document.body.classList.add('stopScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    async onSubmit() {
        const { user, stores_has_change,initial_user } = this.state;
        
        if (await this.isValid()) {

            if(this.props.user_new_edit==='CREATE'){
                const userData = {...user, password: Math.random().toString(36).slice(-8), hash_change_password: Math.random().toString(36).slice(-8)} as User
                this.props.onSubmit(userData as User);
            }else{
                if(!stores_has_change && (user?.role==='store-manager' || user?.role==='country-manager')){
                    const userData = {...user,stores:initial_user?.stores} as User
                    this.props.onSubmit(userData as User);
                }else{
                    const userData = {...user} as User
                    this.props.onSubmit(userData as User);
                }
                
            }
            
        }
    }

    async onDelete() {
        const { user } = this.state;
        if (user !== undefined) {
            this.props.onDelete(user);
        }
    
    }

    async isValid() {
        const { t } = this.props;
        const { name, last_name, email, role, phone } = this.state.user!;
        let isValid = true;


        this.setState({
            user_name_error: '',
            user_last_name_error: '',
            user_email_error: '',
            user_role_error: '',
            user_phone_error: '',
        });

        if (is_empty_string(name)) {
            isValid = false;
            this.setState({ user_name_error:t('user_name_required')});
        } 
        if (is_empty_string(last_name)) {
            isValid = false;
            this.setState({ user_last_name_error:t('user_last_name_required')});
        } 
        if (is_empty_string(email)) {
            isValid = false;
            this.setState({ user_email_error:t('user_email_required')});
        } 
        if (is_empty_string(role)) {
            isValid = false;
            this.setState({ user_role_error:t('user_role_required')});
        } 
        if (is_empty_string(phone)) {
            isValid = false;
            this.setState({ user_phone_error:t('user_phone_required')});
        } 
        return isValid
    }

    renderStores() {
        const { user } = this.state
        const { t, stores } = this.props
       
        const selectedStores: string[] = []
        user?.stores?.forEach(element => {
            var store = stores?.find(elem => elem.value.toString() === element)
            if (store !== undefined) selectedStores.push(store.value)
        });

        if (user?.role === Role.STORE_MANAGER || user?.role === Role.COUNTRY_MANAGER) {
            return (
                <div className="form-row">
                    <InputSelectMultiple 
                        id="stores" 
                        selected={selectedStores} 
                        options={stores!} 
                        onChange={(options) => {
                           this.setState({user: {...user,stores:options.map(option=>{
                               return option.value
                           })}})
                           this.setState({stores_has_change:true})
                        }}
                        placeholder={ t('stores') } 
                        helpText={ "" }
                        disabled={ false }/>
                    </div> 
            )
        }
    }

    renderRemove() {
        const { t } = this.props;
        const { user } = this.state;
        if (user && user.id !== undefined )
        return (
            <PrimaryButton onClick={() => this.onDelete()} style={PrimaryButtonStyle.RED_LINK}>
                { t('remove') }
            </PrimaryButton>
        )
    }

    render () {
        const { t, roles } = this.props;
        const { user } = this.state;
       
        return (
            <section id="popUp-edit-store" className="cover-blur active date-range-space-between">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ this.props.user_new_edit==='CREATE'? t('New user') : t('Edit user') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content formlines-container">
                        <fieldset className="formlines-container margin-top-24">
                            <div className="form-row">
                                <legend>{ t('User Info') }</legend>
                            </div>
                            <div className="form-row col-d-1-1">
                                <FormText id="name" label={ t('name') } value={ user?.name } onChange={(text) => this.setState({ user: { ...user, name: text } as User })} helpText={this.state.user_name_error} />
                                <FormText id="last-name" label={ t('last_name') } value={ user?.last_name } onChange={(text) => this.setState({ user: { ...user, last_name: text } as User }) } helpText={this.state.user_last_name_error}/>
                            </div>
                            
                            <div className="form-row">
                                <FormSelectSearchable options={roles} id="role" label={ t('role') } value={ user?.role } onChange={(value, label) => this.setState({ user: { ...user, role: value, stores: [] } as User})} helpText={this.state.user_role_error} />
                            </div> 

                            {this.renderStores()}

                        </fieldset>
                        <fieldset className="formlines-container">
                            <div className="form-row">
                                <legend>{ t('Contact Info') }</legend>
                            </div>
                            <div className="form-row col-d-1-1">
                                <FormText id="email" label={ t('email') } value={ user?.email } onChange={(text) => this.setState({ user: { ...user, email: text } as User })} helpText={this.state.user_email_error}/>
                                <FormText id="phone" label={ t('phone') } value={ user?.phone } onChange={(text) => this.setState({ user: { ...user, phone: text } as User })} helpText={this.state.user_phone_error}/>
                            </div>
                        </fieldset>
                    </section>
                    <section className="popup-actions">
                        { this.renderRemove() }
                        <PrimaryButton onClick={() => this.onSubmit()}>{ user && user.id !== undefined ? t('Edit') : t('Create')}</PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const EditProfile = withTranslation()(EditProfileComponent);
export { EditProfile };