import { edit_user, get_profile } from '../api';
import { User } from '../interfaces/interfaces';
import {
    UPDATE_TOKEN,
    GET_USER,
    UPDATE_USER,
    AuthActions,
    AppThunk
} from './AuthTypes';

export const updateToken = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_TOKEN,
            payload: value
        } as AuthActions);
    }
}


export const getUser = (): AppThunk => {
    return async dispatch => {
        const user = await get_profile();
        dispatch({
            type: GET_USER,
            payload: user
        } as AuthActions);
    }
}

export const updateUser = (user: User): AppThunk => {
    return async dispatch => {
        const editted_user = await edit_user(user);
        dispatch({
            type: UPDATE_USER,
            payload: editted_user
        } as AuthActions);
    }
}