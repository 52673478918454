import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { RouteComponentProps, Link } from "react-router-dom";
import { Role } from '../../../util/jwt';
import { RoleGuard } from '../../../components/RoleGuard';
import {
	Spinner,
	ContentTop,
	BackArrow,
	SecondaryButton,
	PrimaryButton,
	EditSupport,
	Filter,
	VisualSupportCard,
	ImagePreview,
	ConfirmationPopUp,
	SupportImageCard
} from '../../../components';
import { Icon } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getSupport, updateFilter,updateLoading } from './SupportDetailActions';
import { Floor, FilterFields, PrimaryButtonStyle, SecondaryButtonSize, Visual, Support } from '../../../interfaces/interfaces';
import { Filter as FilterInterface } from "../../../interfaces/interfaces";
import moment from 'moment';
import { getTextileTypes, getSupportTypes, getSupportStatuses, getDepartments,getProductionLanguages} from '../../../reducers/datatypes/DatatypesActions';
import { edit_support, delete_support } from '../../../api';

const mapStateToProps = (state: RootState) => {
	return {
		support_detail_state: state.supportDetail,
		data_types_state: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	getSupport,
	updateFilter,
	getTextileTypes,
	getSupportTypes,
	getSupportStatuses,
	getDepartments,
	getProductionLanguages,
	updateLoading
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

const dateFormat = "YYYY-M-D H:m";
const region = "es";
const past_filter = (visual: Visual) => moment(visual.end_date,dateFormat,region).isBefore(moment());
/* const present_filter = (visual: Visual) => moment(visual.start_date,dateFormat,region).isBefore(moment()) && moment(visual.end_date,dateFormat,"es").isAfter(moment());
const future_filter = (visual: Visual) => moment(visual.start_date,dateFormat,region).isAfter(moment()); */

interface State {
	showConfirmation: boolean,
	supportToDelete: Support | null,
	showFilter: boolean,
	showEdit: boolean,
	showFloorplans: boolean,
	previewVisualIndex: number | null
	previewVisuals: Visual[] | [],
	previewVisual: Visual | null,
	editingFloorplan: Floor | null,
	previewSupport:string |null
}

class Detail extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showConfirmation: false,
			supportToDelete: null,
			showFilter: false,
			showEdit: false,
			showFloorplans: false,
			editingFloorplan: null,
			previewVisualIndex: null,
			previewVisuals: [],
			previewVisual: null,
			previewSupport:null,
		};
	}

	componentDidMount() {
		this.props.getSupport(this.props.match.params.id);
		this.props.getTextileTypes();
		this.props.getSupportTypes();
		this.props.getSupportStatuses();
		this.props.getDepartments();
		this.props.getProductionLanguages();
		
	}

	renderEditPopUp() {
		const { support, floors } = this.props.support_detail_state;
		const { showEdit } = this.state;
		const { textile_types, support_types,map_disposition_types, departments, production_languages } = this.props.data_types_state;
		
		if (showEdit) {
			return (
				<EditSupport
					editing={ true }
					textile_types={textile_types}
					support_types={support_types}
					map_disposition_types={map_disposition_types}
					support={ support }
					floors={ floors }
					sections={ departments }
					production_languages={production_languages}
					onDelete={(support:Support) => {
						this.setState({ showConfirmation: true, supportToDelete: support })
					}}
					onClose={() => this.setState({ showEdit: !showEdit })}
					onSubmit={(support: Support) => {
						edit_support(support).then(() => {
							this.setState({ showEdit: false })
							this.props.getSupport(this.props.match.params.id);
						}).catch(err => console.error);
						}
					}
				/>
			);
		}
	}

	renderDeleteConfirmationPopup() {
		const { t } = this.props
		const { showConfirmation } = this.state
		if (showConfirmation)
		return (
			<ConfirmationPopUp
				onClose={() => this.setState({ showConfirmation: false, supportToDelete: null }) }
				onSubmit={() => {
					
					const { supportToDelete } = this.state
					delete_support(supportToDelete!).then(() => {
						this.setState({ showConfirmation: false, supportToDelete: null })
						this.props.history.push('/frames')
					}).catch(err => console.error);
				}}
				title={t('delete_support')}
				change_text={t('delete_support_change_text')}
			/>
		);
	}
	
	renderPreviewVisualPopUp() {
		const { previewVisual } = this.state;
		if (previewVisual) {
			return (
				<ImagePreview visual={ previewVisual } onClose={() => this.setState({ previewVisual: null }) }/>
			);
		}
	}

	renderSupportFilterPopUp() {
		const { filter } = this.props.support_detail_state;
		const { showFilter } = this.state;
		const { updateFilter } = this.props;
		if (showFilter) {
			return (
				<Filter
					filter={ filter }
					filterFields={FilterFields.SUPPORT}
					onClose={() => {
						this.setState({ showFilter: !showFilter });
					}}
					onClear={() => {
						updateFilter({});
						this.setState({ showFilter: !showFilter });
					}}
					onSubmit={(filter: FilterInterface) => {
						updateFilter(filter);
						this.setState({ showFilter: !showFilter });
					}}
				/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;
		const { showEdit } = this.state;
		const { support } = this.props.support_detail_state;
		return (
			<ContentTop>
				<article className="detail-top">
					<div className="detail-top--title">
						<BackArrow onClick={() => this.props.history.goBack()} />
						<div className="title-detail">
							<h2>{ support?.no_soporte_cliente }</h2>
						</div>
					</div>
					<div className="detail-top--actions">
					<RoleGuard roles={[Role.ADMIN,Role.HEAD_VM]}>
						<SecondaryButton onClick={() => this.setState({ showEdit: !showEdit })} size={SecondaryButtonSize.MEDIUM}>
							{ t('edit') }
						</SecondaryButton>
						</RoleGuard>
						<Link to={`../stores/${support?.store_id}?support=${support?.id}&floor_id=${support?.floor_id}`} className="cursor-hand element">
							<PrimaryButton onClick={() => null} style={PrimaryButtonStyle.OUTLINED}>{ t('see_on_map') }</PrimaryButton>
						</Link>
					</div>
				</article>
			</ContentTop>)
	}

	renderSupportInfo() {
		const { t } = this.props;
		const { support, floors } = this.props.support_detail_state;

		const { departments,production_languages } = this.props.data_types_state;
		const department = departments.find(dep => dep.value === support?.section);
		
	
		const language=production_languages.find(lan=>lan.value.toString()===support?.production_language);
		let support_status='';
		if(support?.status_primark!==undefined){
			support_status=t(support?.status_primark.toLowerCase());
		}
		return (
			<section className="support-detail">
				<div className="title-content-grid">
					<ul className="col_r2_d4">
						<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
							<li><span>{ t('frame_navision_code') }</span> { support?.id }</li>
						</RoleGuard>
						<li><span>{ t('size') }</span> { support?.width } X { support?.height } mm</li>
						<li><span>{ t('type') }</span> { support?.support_type } </li>
						<li><span>{ t('print_material') }</span> { support?.textil_type } </li>
						<li><span>{ t('language') }</span> { language!==undefined? language?.name:support?.production_language }</li>
						<li><span>{ t('section') }</span> { department !== undefined ? department.name : support?.section }</li>
						<li><span>{ t('priority') }</span> { support?.priority }</li>
						<li><span>{ t('store_name') }</span> { support?.store?.name }</li>
						<li><span>{ t('store_code') }</span> { support?.store?.customer_shop_no }</li>
						<li><span>{ t('status') }</span> { support_status }</li>
						<li><span>{ t('floor') }</span> { floors.find(floor => floor.entry_no === support?.floor_id)?.name }</li>
					</ul>
				</div>
        	</section>
		);
	}

	renderVisualsBlock() {
		const { t } = this.props;
		const { support } = this.props.support_detail_state;
		if (support && support.visuals && support.visuals.length > 0) {
			return (
				<section className="vertical-timeline">
					<section className="time-items">
						{ this.renderVisuals('past', t('past'), past_filter) }
						{this.renderSupport(support?.current_image_name, support?.current_image,'present', t('present'))}
						{this.renderSupport(support?.new_image_name, support?.new_image,'future', t('future'))}
					</section>
				</section>
			);
		}
	}
	renderSupport(image_name:string,image_url:string,article: string, title: string){
		const { support } = this.props.support_detail_state;
		if (support) {
			
			
				return (
					<article className={article}>
						<h3>{title}</h3>
						<ul>
							<SupportImageCard 
								image_url={image_url}
								image_name={image_name}
								onVisualClick={() => this.setState({ previewSupport: image_url})}
							/>									
						</ul>
					</article>
				)
			
		}
	}

	renderSupportImagePreview(){
		const {t}=this.props;
		const{previewSupport}=this.state;
		if(previewSupport){
			return (
				<section id="gallery-visual" className="cover-blur /*active*/ active">
					<article className="hot-image">
							<button onClick={ ()=>this.setState({ previewSupport: null }) }>
								<Icon type="close-icon"/>
								<span>{ t('close_popup') }</span>
							</button>
						<img alt="" src={previewSupport } />
					</article>
				</section>
			)
		}

	}

	renderVisuals(article: string, title: string, filter: ((visual: Visual) => boolean)) {
		const { support } = this.props.support_detail_state;

		if (support && support.visuals) {
			const visuals = support.visuals.filter(filter);		
			if (visuals.length > 0) {
				return (
					<article className={article}>
						<h3>{title}</h3>
						<ul>
							{
								visuals.map(v => {
									return <VisualSupportCard 
										key={`${v.campaign_id}-${v.id}`}
										visual={v} 
										start_date={v.start_date?.split("T")[0]}
										end_date={v.end_date?.split("T")[0]}
										onVisualClick={() => this.setState({ previewVisual: { image: v.thumbnail } as Visual })}
									/>									
								})
							}
						</ul>
					</article>
				)
			}
		}
	}

	render () {
		const { t } = this.props;
		const { loading } = this.props.support_detail_state;

		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		return (
			<>
				{this.renderPreviewVisualPopUp()}
				{this.renderEditPopUp()}
				{this.renderDeleteConfirmationPopup()}
				{this.renderSupportFilterPopUp()}
				{this.renderContentTop()}
				{this.renderSupportInfo()}
				{this.renderVisualsBlock()} 
				{this.renderSupportImagePreview()}
			</>
		);
	}
}

export default withTranslation()(connector(Detail));
