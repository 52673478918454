import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseButton } from './CloseButton';
import { FormText, PrimaryButton, FormNumber } from '.';
import { SelectOption, Support, PrimaryButtonStyle, Floor} from "../interfaces/interfaces";
import { FormSelect } from './';
import { is_empty_string } from '../util/form';
import { InputSelectSearchable } from './InputSelectSearchable';
import { RoleGuard } from './RoleGuard';
import { Role } from '../util/jwt';
import { check_available_support_code } from '../api';

export interface EditSupportProps {
    editing?: boolean,
    floor_edit?: boolean,
    support?: Support,
    onClose: () => void,
    onSubmit: (support: Support) => void,
    onDelete: (support: Support) => void,
    textile_types?: SelectOption[],
    support_types?: SelectOption[],
    sections?: SelectOption[],
    stores?: SelectOption[],
    floors?: Floor[],
    map_disposition_types?: SelectOption[],
    production_languages?: SelectOption[]
}

interface State {
    support: Support | undefined,
    support_id_error: string,
    support_width_error: string,
    support_height_error: string,
    support_textil_type_error: string,
    support_section_error: string,
    support_support_type_error: string,
    support_priority_error: string,
    support_store_error: string,
}

type EditSupportPropsSum = EditSupportProps & WithTranslation;

class EditSupportComponent extends React.Component<EditSupportPropsSum, State> {

    constructor(props: EditSupportPropsSum) {
        super(props);
        this.state = {
            support: {map_disposition: "right", ...props.support } as Support,
            support_id_error: '',
            support_width_error: '',
            support_height_error: '',
            support_textil_type_error: '',
            support_section_error: '',
            support_support_type_error: '',
            support_priority_error: '',
            support_store_error: '',
        };
    }

    componentDidMount() {
        document.body.classList.add('stopScroll');

    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    async onSubmit() {
        const { support } = this.state;

        if (await this.isValid()) {
            this.props.onSubmit(support as Support);
        }
    }

    async isValid() {
        const { t, stores } = this.props;
        const { width, height, textil_type, section, support_type, priority, store_id, no_soporte_cliente } = this.state.support!;
        let isValid = true;

        this.setState({
            support_id_error: '',
            support_width_error: '',
            support_height_error: '',
            support_textil_type_error: '',
            support_section_error: '',
            support_support_type_error: '',
            support_priority_error: '',
            support_store_error: '',
        });

        if (is_empty_string(no_soporte_cliente)) {
            isValid = false;
            this.setState({ support_id_error:t('support_id_required')});
		} else if (no_soporte_cliente !== this.props.support?.no_soporte_cliente) {
            const used = await check_available_support_code(no_soporte_cliente)
            if (!used) {
                isValid = false;
                this.setState({ support_id_error:t('support_number_not_available')});
            }
        }

        if (!width) {
            isValid = false;
            this.setState({ support_width_error:t('support_width_required')});
		}

        if (!height) {
            isValid = false;
            this.setState({ support_height_error:t('support_height_required')});
		}

        if (is_empty_string(textil_type)) {
            isValid = false;
            this.setState({ support_textil_type_error:t('support_textil_type_required')});
		}

        if (is_empty_string(section)) {
            isValid = false;
            this.setState({ support_section_error:t('support_section_required')});
		}
        if (is_empty_string(support_type)) {
            isValid = false;
            this.setState({ support_support_type_error:t('support_support_type_required')});
		}

        if (!priority) {
            isValid = false;
            this.setState({ support_priority_error:t('support_priority_required')});
		}

        if( stores && !store_id){
            isValid = false;
            this.setState({ support_store_error:t('support_store_required')});
        }
        

        return isValid
    }

    renderMapDisposition() {
        const { t } = this.props;
        const  map_disposition_types  = [
			{ name: 'Right', value: 'right' },
			{ name: 'Left', value: 'left' },
			{ name: 'Top', value: 'top' },
			{ name: 'Bottom', value: 'bottom' },
		];

        return(
            <>
                <div className="form-row">
                    <legend>{ t('map_position') }</legend>
                </div>
                <div className="map-disposition col-d-4x1">
                    {map_disposition_types?.map(map_disposition_type =>
                        <article key={map_disposition_type.name} className={`cursor-hand ${this.state.support?.map_disposition === map_disposition_type.value? "map-item selected": "map-item"}`} onClick={(event) => this.setState({ support: { ...this.state.support, map_disposition: map_disposition_type.value } as Support })}>
                            <button className="support-icon support-icon--left">
                                <strong className={`dis dis--${map_disposition_type.value} fill-primary`} id={map_disposition_type.value} >
                                    <span>000</span>
                                </strong>
                            </button>
                        </article>
                    )}
                </div>
            </>
        );
    }

    async onDelete() {
        const { support } = this.props;
        if (support !== undefined) {
            this.props.onDelete(support)
        }
    
    }

    renderRemove() {
        const { t, editing } = this.props;
        if (editing)
        return (
            <PrimaryButton onClick={() => this.onDelete()} style={PrimaryButtonStyle.RED_LINK}>
                { t('remove') }
            </PrimaryButton>
        )
    }

    render () {
        const { t, support_types, textile_types, sections, stores, floors, editing, floor_edit } = this.props;
        const { support } = this.state;
       
        const floorOptions = floors?.filter(floor => floor.store_no.toString() === support?.store_id?.toString()).map(floor => ({ value: floor.entry_no.toString(), name: floor.name }));

        return (
            <section id="popUp-edit-suport" className="cover-blur edit-floorplan-popup active date-range-space-between">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ !support?.id ? t('new_support') : t('edit_support') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>

                    <section className="popup-content formlines-container">

                        
                       
                            <fieldset>
                                <div className="form-row">
                                    <FormText id="id" label={ t('id') } value={support?.no_soporte_cliente} onChange={(text) => {
                                         if (text !== "" && text !== this.props.support?.no_soporte_cliente) check_available_support_code(text).then((used) => {
                                            if (!used) {
                                                this.setState({ support_id_error:t('support_number_not_available')});
                                            } else {
                                                this.setState({ support_id_error: '' });
                                            }
                                         })
                                         this.setState({ support: {...support, no_soporte_cliente: text} as Support })
                                    }} helpText={this.state.support_id_error}/>
                                </div>
                            </fieldset>
                       
                       
                            <fieldset>
                                <div className="form-row col-d-1-1">
                                    <FormNumber id="width" label={ t('width') } value={support?.width} unitClass="mm" onChange={(text) => this.setState({ support: { ...support, width: parseInt(text) } as Support })} helpText={this.state.support_width_error}/>
                                    <FormNumber id="height" label={ t('height') } value={support?.height} unitClass="mm" onChange={(text) => this.setState({ support: { ...support, height: parseInt(text) } as Support })} helpText={this.state.support_height_error} />
                                </div>
                            </fieldset>
                        

                      
                            <fieldset>
                                {this.renderMapDisposition()}
                            </fieldset>                        
                       
                            <fieldset>
                                <div className="form-row col-d-1-1">
                                    <FormSelect id="textile_type" label={ t('textile_type') } options={ textile_types } value={ support?.textil_type } onChange={(value, label) => this.setState( { support: {...support, textil_type: String(label)} as Support } )} helpText={this.state.support_textil_type_error}/>
                                    <FormSelect id="support_type" label={ t('support_type') } options={ support_types } value={ support?.support_type } onChange={(value) => this.setState( { support: {...support, support_type: String(value)} as Support } )} helpText={this.state.support_support_type_error}/>
                                </div>
                            </fieldset>
                        { stores && !editing && 
                            <fieldset className="options-in-top">
                                <div className="form-row col-d-1-1">
                                    <InputSelectSearchable id="store_id" label={ t('store') } options={ stores } value={ support?.store_id } onChange={(value) => this.setState( { support: {...support, store_id: value, floor_id: undefined} as Support } )} helpText={this.state.support_store_error} />
                                    <InputSelectSearchable id="floor" label={ t('floor') } options={floorOptions} value={support?.floor_id?.toString()} onChange={(value) => this.setState({ support: { ...support, floor_id: Number(value ) } as Support })} />
                                </div>
                            </fieldset>
                        }   
                 
                        { (editing || floor_edit) && 
                            <fieldset className="options-in-top">
                                <div className="form-row">
                                    <InputSelectSearchable id="floor" label={ t('floor') } options={floorOptions} value={support?.floor_id?.toString()} onChange={(value) => this.setState({ support: { ...support, floor_id: Number(value ) } as Support })} />
                                </div>
                            </fieldset>
                        }  
                        
                       
                            <fieldset>
                                <div className="form-row section-and-priority">
                                    <legend>{ t('section_and_priority')}</legend>
                                </div>
                                <div className="form-row col-d-1-1">
                                    <FormSelect id="section" label={ t('section') } options={ sections } value={ support?.section } onChange={(value, label) => this.setState( { support: {...support, section: String(value)} as Support })} helpText={this.state.support_section_error}/>
                                    <FormText id="priority" label={ t('priority') } value={support?.priority} onChange={(text) => this.setState({ support: {...support, priority: text} as Support })} helpText={this.state.support_priority_error}/>
                                </div>
                            </fieldset>
                             
                    </section>
                    <section className="popup-actions">
                    {editing&&
                        <RoleGuard roles={[Role.ADMIN]}>
                        { this.renderRemove() }
                        </RoleGuard> 
                    }
                        
                        <PrimaryButton onClick={() => this.onSubmit()} style={PrimaryButtonStyle.FILLED}>{ t('save') }</PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const EditSupport = withTranslation()(EditSupportComponent);
export { EditSupport };
