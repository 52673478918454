export interface Campaign {
    status_primark: CampaignStatusPrimark;
    id: string;
    title: string;
    start_date?: string;
    end_date?: string;
    image: string;
    status : CampaignStatus;
    budget: number;
    sections?: Section[];
    visuals?: Visual[];
    galleryVisible: boolean;
    departments: string[];
    to_sqm: number;
    to_units: number;
    to_artwork: number;
    to_shipping: number;
    to_canvas: number;
    to_campaign: number;
	num_supports: number;
	m2: number;
	is_gallery: boolean;
    notifications: boolean;
}

export interface Visual {
  key: string;
  id: string;
  image: string;
  thumbnail: string;
  name: string;
  tags?: string[];
  ready?: boolean;
  campaign_id: string;
  chiliAssetId?: string;
  priority: number;
  section: string;
  date?: string;
  end_date?: string;
  start_date?: string;
  adaptation: boolean;
  section_name?: string;
}

export interface Store {
  id: string;
  key: string;
  name: string;
  name2: string;
  customer_no: string;
  direccion: string;
  direccion2: string;
  ciudad: string;
  telefono: string;
  pais: string;
  provincia: string;
  codigo_postal: string;
  email: string;
  home_page: string;
  customer_shop_no: string;
  es_centro_distribucion: boolean;
  pisos: number;
  grupo_tienda: string;
  tipo_tienda: string;
  idioma: string;
  floors: Floor[];
  sections: Section[];
  storeManagerName: string;
  storeManagerEmail: string;
  storeManagerPhone: string;
  status: StoreStatus;
  installed: boolean;
  commercial_group_id: string;
  supports?: Support[];
  campaigns?: Campaign[];
  current_campaign?: Campaign;
  no_tienda_spk?: string;
  status_primark: StoreStatusPrimark;
  tracking_number?: string;
  new_trend?: string;
  store_lines?: CampaignVisualHardware[];
  kp_support?: boolean;
  kp_support_n?: number;
  store_ready_admin: boolean;
}

export interface Activity {
  id: string;
  activity_type: string;
  campaign_num: string;
  message_type: string;
  message: string;
  created_at: string;
}

export interface ActivityTypes {
  name: string;
}

export interface Language {
  key: string;
  id: string;
  name: string;
}

export enum StoreStatus {
  PENDING_SIGN_OFF = "Pending sign off",
}

export interface Floor {
  key?: string;
  entry_no: number;
  name: string;
  map_url: string;
  store_no: number;
  floor_order: number;
  width?: number;
  ratio?: number;
  height?: number;
}

export interface Section {
  customer_no?: string;
  id: string;
  description: string;
  visuals: Visual[];
  supports?: Support[];
  status?: string;
  max_support_number?: number;
}

export interface Support {
  key: string;
  id: string;
  name: string;
  customer_id: string;
  store_id: string;
  width: number;
  height: number;
  depth: number;
  comments: string;
  image_width: number;
  image_height: number;
  image_comments: string;
  section: string;
  support_type: string;
  color: string;
  priority: string;
  material_id: string;
  textil_type: string;
  textil_type_id: string;
  reference: string;
  no_soporte_cliente: string;
  size: string;
  commercial_group_id?: string;
  production_language: string;
  campaigns?: Campaign[];
  visuals?: Visual[];
  store?: Store;
  visual_url?: string;
  doble_cara?: string;
  no_tienda_spk?: string;
  map_disposition: "left" | "top" | "right" | "bottom";
  map_x: number;
  map_y: number;
  status: SupportStatus;
  current_image: string;
  current_image_name: string;
  new_image: string;
  new_image_name: string;
  floor_id?: number;
  floor?: Floor;
  status_primark: SupportStatusPrimark;
  clon?: boolean;
  sopadre?: number;
  campaign?: string;
  childrens?: Support[];
  store_lines?: [];
}

export enum CampaignStatus {
  DRAFT = "Draf",
  LAUNCH = "Launch",
  QUOTE = "Quote",
  APPROVED = "Approved",
  LIVE = "Live",
  END = "End",
}

export enum CampaignStatusPrimark {
  DRAFT = "DRAFT",
  KENDU_PROCESSING = "KENDU_PROCESSING",
  RATIOS_CROPPING = "RATIOS_CROPPING",
  RATIO_CROP_VALIDATING = "RATIO_CROP_VALIDATING",
  RATIO_CROP_CONFIRMED = "RATIO_CROP_CONFIRMED",
  PRIORITY_CONFIRMED = "PRIORITY_CONFIRMED",
  CROPPING = "CROPPING",
  SALESPOINT_VALIDATING = "SALESPOINT_VALIDATING",
  LIVE = "LIVE",
  END_PASSED = "END_PASSED",
}

export enum StoreStatusPrimark {
  N_A = "N_A",
  PENDING_APPROVAL_SM = "PENDING_APPROVAL_SM",
  PENDING_SIGN_OFF_CM = "PENDING_SIGN_OFF_CM",
  SIGNED_OFF = "SIGNED_OFF",
  IN_PRODUCTION = "IN_PRODUCTION",
  SHIPPED = "SHIPPED",
  INSTALLED = "INSTALLED",
}

export enum SupportStatus {
  FINISHED = "finished",
  IN_CAMPAIGN = "in_campaign",
  PENDING_APPROVAL = "pending_approval",
  APPROVED = "approved",
  WAITING_FOR_CHANGE = "waiting_for_change",
  NOT_IN_CAMPAIGN = "not_in_campaign",
  PENDING_SIGN_OFF = "Pending sign off",
}

export enum SupportStatusPrimark {
  BLANK = "_blank_",
  N_A = "N_A",
  NOT_IN_CAMPAIGN = "NOT_IN_CAMPAIGN",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED_BY_SM = "APPROVED_BY_SM",
  PENDING_APPROVAL_CM = "PENDING_APPROVAL_CM",
  APPROVED_BY_CM = "APPROVED_BY_CM",
  INSTALLED = "INSTALLED",
  KENDU_PROCESSING = "KENDU_PROCESSING",
}

export enum VisualPreviewSizes {
  O = "Original",
  S = "500x3000",
  M = "680x3000",
  L = "1225x810",
  L2 = "1160x1500",
  XL = "2000x2000",
}

export class Filter {
  search?: string;
  storeManager?: string;
  country_managers?: any[];
  country?: string;
  countries?: string[];
  department?: string;
  campaign?: string;
  language?: string;
  textileType?: string;
  supportType?: string;
  floor?: string;
  priority?: string;
  start_date?: number;
  end_date?: number;
  campaignStatuses?: string[];
  width?: number;
  height?: number;
  section?: string;
  store?: string;
  textileTypesList?: string[];
  supportTypesList?: string[];
  support_status?: string;
  support_statuses?: string[];
  address?: string;
  customer_shop_no?: string;
  primark_statuses?: string[];
  kendu_processing?: string[];
  check_directory?: boolean | false;
  search_support?: string;
}

export class UserFilter {
  name?: string;
  last_name?: string;
  stores?: string[];
  roles?: string[];
}

export class ActivityFilter {
  from?: string;
  to?: string;
  activity_type?: string;
  page?: number;
}

export interface SelectOption {
  value: string;
  name: string;
}

export enum PrimaryButtonSize {
  SMALL = "btn-s",
  MEDIUM = "btn-m",
  BIG = "btn-xl",
}
export enum PrimaryButtonStyle {
  FILLED = "btn-base  fill-primary",
  TRANSPARENT = "btn-base  fill-transparent",
  OUTLINED = "btn-outline outline-primary",
  RED_LINK = "text-link link-red link-m",
}
export enum SecondaryButtonSize {
  SMALL = "link-s",
  MEDIUM = "link-m",
  BIG = "link-xl",
}
export enum SecondaryButtonStyle {
  PRIMARY = "link-primary",
  RED = "link-red",
  YELLOW = "link-yellow",
}

export enum FilterFields {
  CAMPAIGN = "search date status",
  STORE = "search store_manager country address customer_shop_no primark_status country_managers",
  DASHBOARD_STORE = "search store_manager country address customer_shop_no primark_status country_managers",
  SUPPORT = "search_support store floor width height section textile_type priority support_type textileTypesList supportTypesList support_status",
  STORE_SUPPORT = "search_support floor width height section textile_type priority support_type support_status_multiple",
  GALLERY = "search status",
  KENDU_PROCESSING = " search_support  store section textile_type priority support_type kendu_processing processing_directory date width height languages",
}

export enum PillColorStyle {
  GREY = "grey-pill",
}

export enum InfoBoxStyle {
  BLACK = "",
  YELLOW = "yellow",
  PRIMARY = "primary",
  BLUE = "blue",
  GREEN = "green",
  RED = "red",
}

export enum ViewCampaignStyle {
  GRID = "grid",
  LIST = "list",
}

export enum ViewSupportStyle {
  INDEX = "index",
  POPUP = "popup",
  LIST = "list",
}

export interface EstimatedPriceChange {
  name: string;
  price: number;
}

export interface VisualArea {
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface PointOfInterest {
  x: number;
  y: number;
}

export interface Country {
  key: string;
  id: string;
  name: string;
}

export interface DataType {
  key: string;
  type: string;
  customer_no: string;
  id: string;
  description: string;
  commercial_group: string;
  width: number;
  height: number;
  product_id: string;
  section_campaign: string;
  campaign_id: string;
  store_id: string;
}

export interface User {
  id: number;
  email: string;
  password: string;
  hash_change_password: string;
  customer: string;
  commercial_group: string;
  role: string;
  region: string;
  position: string;
  language: string;
  name: string;
  last_name: string;
  phone: string;
  photo: string;
  stores?: string[];
}

export interface SpecialRequestRequest {
  comment: string;
  special_request_image?: string;
  directory?: string;
  directory2?: string;
}

export interface CampaignVisualHardware {
  key: string;
  id: string;
  customer_no: string;
  shop_id: string;
  campaign_id: string;
  visual_id: string;
  hardware_id: string;
  quantity: string;
  unit_price: string;
  amount: string;
  has_assignation_error: boolean;
  shipping_price: string;
  country: string;
  group_id: string;
  product_id: string;
  observations: string;
  support_width: string;
  support_height: string;
  adaptation: boolean;
  special_request: boolean;
  directory: string;
  directory2: Directory | null;
  comment: string;
  user_rol: string;
  special_request_image: string;
  sr_date: string;
  nombresoporte: string;
  auto_descarga: boolean;
  descargado: boolean;
}

export interface Directory {
  floors: string;
  highlight_floor: string;
  language: string;
  returns_exchanges: string;
  rrss_wifi: string;
  click_collect: string;
  click_collect_floor: string;
}

export interface ProcessingLine {
  line: CampaignVisualHardware;
  support: Support;
  store: Store;
}

export type VisualPack = {
  img: string;
  campaign: string;
  url: string;
};
