import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { CloseButton } from "./CloseButton";
import { FormText, PrimaryButton } from ".";
import {
  SelectOption,
  Support,
  PrimaryButtonStyle,
  
} from "../interfaces/interfaces";
import { FormSelect } from ".";
import { is_empty_string } from '../util/form';

export interface EditSupportKenduProcessingProps {
  
  support?: Support;
  onClose: () => void;
  onSubmit: (support: Support) => void;
 
 
  sections?: SelectOption[];
 
}

interface State {
  support: Support | undefined;
  support_id_error: string;
  support_width_error: string;
  support_height_error: string;
  support_textil_type_error: string;
  support_section_error: string;
  support_support_type_error: string;
  support_priority_error: string;
  support_store_error: string;
}

type EditSupportKenduProcessingPropsSum = EditSupportKenduProcessingProps &
  WithTranslation;

class EditSupportKenduProcessingComponent extends React.Component<
  EditSupportKenduProcessingPropsSum,
  State
> {
  constructor(props: EditSupportKenduProcessingPropsSum) {
    super(props);
    this.state = {
      support: { map_disposition: "right", ...props.support } as Support,
      support_id_error: "",
      support_width_error: "",
      support_height_error: "",
      support_textil_type_error: "",
      support_section_error: "",
      support_support_type_error: "",
      support_priority_error: "",
      support_store_error: "",
    };
  }

  componentDidMount() {
    document.body.classList.add("stopScroll");
  }

  componentWillUnmount() {
    document.body.classList.remove("stopScroll");
  }

  async isValid() {
    const { t } = this.props;
    const {  section, priority } = this.state.support!;
    let isValid = true;

    this.setState({
        support_id_error: '',
        support_width_error: '',
        support_height_error: '',
        support_textil_type_error: '',
        support_section_error: '',
        support_support_type_error: '',
        support_priority_error: '',
        support_store_error: '',
    });

   

    if (is_empty_string(section)) {
        isValid = false;
        this.setState({ support_section_error:t('support_section_required')});
    }
 

    if (!priority) {
        isValid = false;
        this.setState({ support_priority_error:t('support_priority_required')});
    }

  
    

    return isValid
}

  async onSubmit() {
    const { support } = this.state;

    if (await this.isValid()) {
        this.props.onSubmit(support as Support);
    }
  }

  render() {
    const {
      t,
    
      sections,
     
    } = this.props;
    const { support } = this.state;

   
    return (
      <section
        id="popUp-edit-suport"
        className="cover-blur edit-floorplan-popup active date-range-space-between"
      >
        <section className="popup">
          <section className="popup-top">
            <h2>{ t("edit_support")}</h2>
            <CloseButton onClick={this.props.onClose} />
          </section>

          <section className="popup-content formlines-container">
            

          

           

            
            

            <fieldset>
              <div className="form-row section-and-priority">
                <legend>{t("section_and_priority")}</legend>
              </div>
              <div className="form-row col-d-1-1">
                <FormSelect
                  id="section"
                  label={t("section")}
                  options={sections}
                  value={support?.section}
                  onChange={(value, label) =>
                    this.setState({
                      support: {
                        ...support,
                        section: String(value),
                      } as Support,
                    })
                  }
                  helpText={this.state.support_section_error}
                />
                <FormText
                  id="priority"
                  label={t("priority")}
                  value={support?.priority}
                  onChange={(text) =>
                    this.setState({
                      support: { ...support, priority: text } as Support,
                    })
                  }
                  helpText={this.state.support_priority_error}
                />
              </div>
            </fieldset>
          </section>
          <section className="popup-actions">
           

            <PrimaryButton
              onClick={() => this.onSubmit()}
              style={PrimaryButtonStyle.FILLED}
            >
              {t("save")}
            </PrimaryButton>
          </section>
        </section>
      </section>
    );
  }
}

const EditSupportKenduProcessing = withTranslation()(EditSupportKenduProcessingComponent);
export { EditSupportKenduProcessing };
