import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { User, UserFilter, Store } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_USERS = 'update_users';
export const UPDATE_STORES = 'update_stores';
export const UPDATE_FILTER = 'update_filter_user';
export const CLEAR_FILTER = 'clear_filter_user';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateUsersAction {
    type: typeof UPDATE_USERS,
    payload: User[]
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: UserFilter
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export type UserIndexActions =
UpdateLoadingAction |
UpdateUsersAction |
UpdateFilterAction |
UpdateStoresAction |
ClearFilterAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
