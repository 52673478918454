import React from 'react';
import { Document, Page, Text, View, StyleSheet, Svg, Image as PDFImage, G, Rect } from '@react-pdf/renderer';
import { Campaign, Floor, Store, Support, SupportStatusPrimark } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getIndicatorColor, getSupports, labelContainerProps, labelTextProps, supportIndicatorProps } from '../util/floorplan';

export interface VisualFocusAreaProps {
    store: Store,
    floors:Floor[]
    currentCampaign?: Campaign | null
}
// const PAGE_HEIGHT = 595;
const PAGE_WIDTH = 842;
const FLOORPLAN_MARGIN = 0;
const REDIMENSION=0.6;
const styles = StyleSheet.create({
  frontPage: {
    flexDirection: 'column',
    backgroundColor: '#0ca4cf',
  },
  // Page 1
  coverSection: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    textAlign: 'center',
    color:"white",
  },
  mainTitle:{
    marginTop: 75,
    fontSize:"65",
    fontWeight: "bold",
  },
  mainSubitle:{
    margin: 20,
    fontSize:"35",
    fontWeight: "semibold",
  },
  logo:{
    marginTop: 40,
    marginLeft: "auto",
    marginRight: "auto",
    width: PAGE_WIDTH/3,
    objectFit: 'scale-down',
  },
  footer:{
    width: "100%",
    textAlign: "center",
    position:"absolute",
    bottom: 10,
  },
  // Floorplan
  mapText:{
    fontSize: 8,
    boxSizing: 'inherit',
    fontStyle: 'normal',
    fontWeight: "semibold",
    letterSpacing: 0,
  },
  
  floorName:{
    paddingTop:5
  },
  // Table
  table:{
    marginTop:15,
  },
  headerRow: {
    flexDirection: "row", 
    flexWrap: "wrap",
    backgroundColor:"#66c4de"
  },
  headerText:{
    fontWeight: "heavy",
    marginLeft:"auto",
    marginRight:"auto",
    fontSize:"12",
    width:"95",
    padding:"2",
  },
  headerTextSmall:{
    fontWeight: "heavy",
    marginLeft:"auto",
    marginRight:"auto",
    fontSize:"12",
    width:"70",
    padding:"2",
  },
  row: {
    flexDirection: "row", 
    flexWrap: "wrap",
    borderBottom: "1px solid #b6bbc3",
  },
  tableText:{
    marginLeft:"auto",
    marginRight:"auto",
    fontSize:"10",
    width:"95",
    padding:"2",
  },
  tableTextSmall:{
    marginLeft:"auto",
    marginRight:"auto",
    fontSize:"10",
    width:"70",
    padding:"2",
  },
  // Support images
  supportBlock:{
    flexDirection: "row", 
    flexWrap: "wrap",
  },
  supportItem:{
    padding: "5px 0",
    width: "21%",
    height:"270",
    marginTop:15  ,
    marginBottom:0,
    marginRight:15,
    marginLeft:15
  },
  supportImage:{
    height:"180",
    objectFit: 'scale-down',
    // border: '5px solid #dfdfdf',
  },
  supportInfo:{
    padding: "5px 0",
    textAlign: "center",
    fontSize: "14",
    fontWeight: "bold",
  },
  secondaryInfoBlock:{
    fontSize: "10",
    textAlign: "center",
  },
  supportDimensions:{
    padding: "5px 0",
    textAlign: "center",
    fontSize: "12",
    color: "#6a737d",
  },
  // General
  section: {
    margin: 10,
    marginBottom: 10,
    padding: 10,
    flexGrow: 1
  },
  page:{
    margin: 10,
  }
});

type StoreVisualPackToPDFCPropsSum = VisualFocusAreaProps & WithTranslation;
class StoreVisualPackToPDFComponent extends React.Component<StoreVisualPackToPDFCPropsSum> {

  getSectionName(section_cod: string, store: Store) {
   
    const section = store.sections.find((s) => s.id === section_cod);
    return section ? section.description : "";
  }

  parseSupports(supports: Support[], floorName: string){
    const { t, currentCampaign,store } = this.props;
    var supportArray: any[] = [];
    const keys = [t('point'), t('floor'), t('section'), t('priority'),t('format'), t('size'), t('support_status'), t('campaign_status')];


    supports.map((support) => {
     
      return supportArray.push({
        point: support.no_soporte_cliente,
        floor: floorName,
        section: this.getSectionName(support.section,store),
        priority: support.priority,
        format: support.support_type,
        size: support.width + " x " + support.height + " mm",
        support_status: support.status_primark,
        campaign_status: (currentCampaign && !([SupportStatusPrimark.N_A,SupportStatusPrimark.NOT_IN_CAMPAIGN]).includes(support.status_primark) )?currentCampaign.status_primark:' - '
      });
    });

    return({
      supportArray: supportArray,
      keys: keys
    });
  }

  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  }

  renderFloor(floor: Floor, floor_key: number){

    const supports = getSupports(floor.entry_no, this.props.store);

    return(
      <React.Fragment key={"main"+floor_key}>
        {/* Floorplan */}
        {this.renderFloorPlan(supports,floor, floor_key)}
        {this.renderSupportTable(supports, floor.name)}
        {this.renderSupportImages(supports, floor_key)}
      </React.Fragment>
      );
  }

  renderFloorPlan(supports: Support[], floor: Floor, floor_key: number ){
    const{floors} =this.props;
   
   
    let new_height=floors[floor_key]?.height;
    if(new_height!==undefined){
      new_height=new_height*REDIMENSION;
    }
    let floorplanStyleN=StyleSheet.create({
      floorplanImage: {
        margin: FLOORPLAN_MARGIN,
        flexGrow: 1,
        objectFit: 'scale-down',
        height: new_height,
        position: 'absolute',
        zIndex:-1
      },
      floorplan: {
        margin: FLOORPLAN_MARGIN,
        flexGrow: 1,
        objectFit: 'scale-down',
        height: floors[floor_key].height,
        width: 800,
        position: 'absolute',
        zIndex:-1
      },
    });
    if(!floor.width) floor.width = 700*REDIMENSION;
    if(!floor.ratio) floor.ratio = 1;
    floor.width=800;
    var floorplanStyle = floorplanStyleN.floorplan;
    floorplanStyle.width = floor.width * floor.ratio;
    
    return (<Page size="A4" orientation="landscape" key={"page-"+floor_key} style={styles.page}>
        <View key={"view-"+floor_key} style={styles.section}>
          <Text style={styles.floorName}>{ floor.name }</Text>
          <PDFImage src={ floor.map_url } style={floorplanStyleN.floorplanImage}  />  
          <Svg style={floorplanStyle}>
            {supports.map((support,index) => 
              this.renderSupport(support,index,floor.ratio!)
            )}
          </Svg>
        </View>
      </Page>);
  }

  renderSupport(support: Support,index:number, ratio:number){
    const x = support.map_x * 1;
    const y = support.map_y * 1;

    return(<G key={index} transform={`translate(${x*REDIMENSION}, ${y*REDIMENSION})`} >
        <Rect rx="7" fill="#001114" {...labelContainerProps(support,5,0,10,6,20,3)}/>
        <Text style={styles.mapText} {...labelTextProps(support,5,0,10,6,20,3)} fill="#fff" dominantBaseline="central" textAnchor="middle" >{support.no_soporte_cliente}</Text>
        <Rect rx="4" fill={getIndicatorColor(support)} stroke="white" strokeWidth={2} {...supportIndicatorProps(support,5,0,10,6,20,3)}/>
    </G>);
  }

  renderSupportTable(supports: Support[], floorName: string){
    const { t } = this.props;
    const {supportArray, keys} = this.parseSupports(supports,floorName);
    return(
      <Page size="A4" orientation="landscape" key={"support-table-page-"+floorName} style={styles.page}>
        <View key={"support-table-"+floorName} style={styles.section}>
          <Text style={styles.floorName}>{ t('supports_list') +' '+ floorName }</Text>
          <View key={`support-table-container-${floorName}`} style={styles.table}>
            <View key={`support-table-keys-${floorName}`} style={styles.headerRow} wrap>
              {keys.map((key) =>(
                  <Text key={key} style={((['priority', 'point']).includes(key))?styles.headerTextSmall:styles.headerText}>{key}</Text>
                ))}
            </View>
            {supportArray.map((support) =>(
              <View key={`support-table-support-${support.point}`} style={styles.row} wrap={false}>
                {Object.values(support).map((el: any, key) => 
                    <Text key={`support-table-support-${support.point}-${el}`} style={(([0, 3]).includes(key))?styles.tableTextSmall:styles.tableText}>{el}</Text>)
                }
              </View>
            ))}
          </View>
        </View>
      </Page>);
  }

  renderSupportImages(supports: Support[],floor_key: number){
    const { t,store } = this.props;

    return(
      <Page size="A4" orientation="landscape" key={"renderSupportImages-"+floor_key}>
        <View key={"supportBlock-"+floor_key} style={styles.supportBlock}>
          {supports.map((support) => 
            <View key={"supportItem-"+support.no_soporte_cliente} style={styles.supportItem} wrap={false}>
              <Text style={styles.supportInfo}>{ support.status_primark }</Text>
              <PDFImage src={ (support.new_image)?support.new_image:((support.new_image)?support.new_image:'/img/corporative/placeholder.png')} style={styles.supportImage}></PDFImage>
              <Text style={styles.supportDimensions}>{ support.width + " x " + support.height + " mm" }</Text>
              <Text style={styles.supportInfo}>{ support.no_soporte_cliente }</Text>
              <View style={styles.secondaryInfoBlock} key={"support-secondary-info-"+support.no_soporte_cliente} wrap={false}>
                <Text >{ support.name }</Text>
                <Text >{ t("Priority") + ": " + support.priority }</Text>
                <Text >{ support.support_type + " - " +  support.width + " x " + support.height + " mm"  }</Text>
                <Text>{this.getSectionName(support.section,store)}</Text>
                {
                  (support.current_image && !support.new_image)?(
                    <Text >{ t('Point is Unchanged. Showing previous Picture') }</Text>
                  ):(<></>)
                }
              </View>
            </View>
          )}
        </View>
      </Page>
    );
  }

  render()
  {
    const { store, t,currentCampaign } = this.props;
    return(
      <Document key="PDF">
        <Page size="A4" orientation="landscape" style={styles.frontPage}> 
          <View key="PDF-cover" style={styles.coverSection}>
            <PDFImage src={ '/img/corporative/primark-white.png' } style={styles.logo} />
            <Text style={styles.mainTitle}>{store.name}</Text>
            <Text style={styles.mainSubitle}>{t("Visual pack for ") + currentCampaign?.title}</Text>
            <Text style={styles.footer}>{this.getCurrentDate(" / ")}</Text>
          </View>
        </Page>
        {
          store?.floors.map((floor, floor_key) => 
            this.renderFloor(floor, floor_key)
          )
        }
      </Document>
    );
  }
}
const StoreVisualPackToPDF = withTranslation()(StoreVisualPackToPDFComponent);
export { StoreVisualPackToPDF };
