import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { InputText } from '../../../components';
import { updatePassword, updatePasswordError, updatePasswordRepeat, updatePasswordRepeatError, updateUser, updateHashError } from './PasswordResetActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getUser } from '../../../reducers/AuthActions';
import { find_by_hash, reset_user_password, reset_user_password_hash } from '../../../api';
import cookies, { COOKIE_USER_TOKEN } from '../../../util/cookies';
import { Alert } from '@material-ui/lab';

const mapStateToProps = (state: RootState) => {
	return {
		security_password_reset_state: state.securityPasswordReset,
		auth: state.auth,
	}
}

const connector = connect(mapStateToProps, { getUser, updatePassword, updatePasswordError, updatePasswordRepeat, updatePasswordRepeatError, updateUser, updateHashError });

interface ParamTypes {
	hash: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Settings extends React.Component<Props> {
	
	async save() {
		const { t } = this.props;
		let hash='';
		if(this.props.match.params.hash!==undefined){
			hash = this.props.match.params.hash.split("&creating=true")[0];
		}
		
		const { password, passwordRepeat, user } = this.props.security_password_reset_state;
		let hasError = false;
		if (!password) {
			hasError = true;
			this.props.updatePasswordError(t('password_is_required'));
		} else {
			this.props.updatePasswordError('');
		}
		if (!passwordRepeat) {
			hasError = true;
			this.props.updatePasswordRepeatError(t('password_is_required'));
		} else if (password !== passwordRepeat) {
			hasError = true;
			this.props.updatePasswordRepeatError(t('passwords_must_be_same'));
		} else {
			this.props.updatePasswordRepeatError('');
		}
		
		if (!hasError && user !== null) {
			if(hash && hash !== ''){
				await reset_user_password_hash(password, user);
			}else{
				await reset_user_password(password, user);
			}
			cookies.remove(COOKIE_USER_TOKEN);
			window.location.href ="/";
		}
	}

	async renderUser() {
		const { t } = this.props;

		var user = null;
		let hash='';
		if(this.props.match.params.hash!==undefined){
			hash = this.props.match.params.hash.split("&creating=true")[0];
		}
		
		if(hash && hash !== ''){
			// Obtener el usuario mediante el hash
			user = await find_by_hash(hash);
		}else{
			await this.props.getUser();
			user = this.props.auth.user;
		}
		this.props.updateUser(user);
		
		if(user === null || user === ''){
			this.props.updateHashError(t('expired_password_reset_link'));
		}
    }

	render () {
		const { t } = this.props;
		const { token }  = this.props.auth;
		const { userUpdated, user, hashError } = this.props.security_password_reset_state;
		let isCreating=false;
		if(this.props.match.params.hash!==undefined){
			isCreating = this.props.match.params.hash.includes("&creating=true")
		}
	

		if(!userUpdated){
			this.renderUser();
		}

		return(
			<section id="reset-pass-box">
				<Link to="/">
					<h1 className="text-center">
						<img src="../../img/corporative/primark.svg" alt="PRIMARK" />
					</h1>
				</Link>

				<article id="content">
					<h2 className="mb-24">{isCreating ? t('create_password') : t('reset_password')}</h2>
					{(hashError === '')?(
						<form>
							<fieldset className="formlines-container">
								<div className="form-row">
									<article className="form-item">
										<label htmlFor="email">{t('email')}</label>
										<InputText
											id="email"
											type="text"
											value={(user)?user.email:''}
											disabled={(token !== undefined && token !== null)?true:false}
										/>
									</article>
								</div>
								<div className="form-row">
									<article className="form-item">
										<label htmlFor="password">{t('password')}</label>
										<InputText
											type="password"
											id="password"
											value={this.props.security_password_reset_state.password}
											onChange={(event) => this.props.updatePassword(event)}
											helpText={this.props.security_password_reset_state.passwordError}
										/>
									</article>
								</div>
								<div className="form-row">
									<article className="form-item">
										<label htmlFor="password-repeat">{t('repeat_password')}</label>
										<InputText
											type="password"
											id="password-repeat"
											value={this.props.security_password_reset_state.passwordRepeat}
											onChange={(event) => this.props.updatePasswordRepeat(event)}
											helpText={this.props.security_password_reset_state.passwordRepeatError}
										/>
									</article>
								</div>
							</fieldset>
							
							<article className="login-actions">
								<button type="button" onClick={() => this.save()} className="btn-base fill-primary btn-xl w-100 mb-24">{isCreating ? t('create') : t('reset')}</button>
								<p>
									<Link to="/">
										{t('go_to_login')}	
									</Link>
								</p>
							</article>
						</form>
					):(
						<>
						<article className="form-row">
							<Alert severity="error">{hashError}</Alert>
						</article>
						<article className="login-actions">
							<p>
								<Link to="/">
									{t('go_to_login')}
								</Link>
							</p>
						</article>
						</>
					)}
				</article>
			</section>
		);
	}
}

export default withTranslation()(connector(Settings));
