import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SecondaryButtonSize, SecondaryButtonStyle } from '../interfaces/interfaces';

export interface SecondaryButtonProps {
    onClick?: () => void,
    cssClass?: string,
    style?: SecondaryButtonStyle,
    size?: SecondaryButtonSize,
    disabled?: boolean
}


type SecondaryButtonPropsSum = SecondaryButtonProps & WithTranslation;

class SecondaryButtonComponent extends React.Component<SecondaryButtonPropsSum> {


    render () {
        const { size, style, cssClass } = this.props

        return (
            <button disabled={this.props.disabled} className={`text-link ${style} ${size} ${cssClass}`} onClick={this.props.onClick}>
                {this.props.children}
            </button>
        );
    };
}

const SecondaryButton = withTranslation()(SecondaryButtonComponent);

SecondaryButton.defaultProps ={ 
    style: SecondaryButtonStyle.PRIMARY,
    size: SecondaryButtonSize.MEDIUM }
    
export { SecondaryButton };
