import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseButton,Icon } from '.';


export interface TrackingProps {
    trackingNumber: string|undefined,
    
    onClose: () => void,
    
}

interface State {
    showCopy:boolean
}



type TrackingPropsSum = TrackingProps & WithTranslation;

class TrackingCardComponent extends React.Component<TrackingPropsSum,State> {
    constructor(props: TrackingPropsSum) {
        super(props);

        this.state = {
            showCopy:false
        };
    }

    onCopyClick(trackingNumber:any){
        let anyNavigator: any
        anyNavigator = window.navigator
        anyNavigator.clipboard.writeText(trackingNumber)
        this.setState({showCopy:true})
 
    }

    render () {
        const {trackingNumber}=this.props;
        const {showCopy}=this.state;
        const { t } = this.props;
        return (
            <section id="tracking-card" className="cover-blur active">
            <section className="popup">
                <section className="popup-top">
                    <h2>{t('tracking_number')}</h2>
                    <CloseButton onClick={this.props.onClose} />
                   
                </section>
                <section className="popup-content pb-50">
                {showCopy&&<p className="message message--info message--show">{t('copy_clipboard')}</p>}
                
                {/* TODO Desarrollo -> Hacer que el botón copiar copie el códio y muestre durante 2s el mensaje de 'Copiado a tu portapapeles', luego desaparece*/}
                    <div className="tracking-row">
                        <p className="headline-500--regular">{trackingNumber}</p>  
                        {trackingNumber!==undefined&& <button onClick={()=>this.onCopyClick(trackingNumber)}><Icon type="copy" /></button>}                  
                        
                    </div>
                    
                    <table>
                    <tbody>
                        
                            <tr key={1}>
                                <td className="text-left"><img src="/img/icons/uk.png" id="img_uk" alt="imagen"/></td>
                                <td className="text-left"><p>UK</p></td>
                                <td className="text-right"><a href="https://www.parcelforce.com/" target="_blank" rel="noopener noreferrer"><Icon type="external-link" /></a></td>
                            </tr>
                            <tr key={2}>
                                <td className="text-left"><img src="/img/icons/europe.png" id="img_europe" alt="imagen"/></td>
                                <td className="text-left"><p>Europe</p></td>
                                <td className="text-right"><a href="https://www.fedex.com/" target="_blank" rel="noopener noreferrer"><Icon type="external-link" /></a></td>
                            </tr>
                            <tr key={3}>
                                <td className="text-left"><img src="/img/icons/spain.png" id="img_spain" alt="imagen"/></td>
                                <td className="text-left"><p>Spain {'&'} Portugal</p></td>
                                <td className="text-right"><a href="https://www.gls-spain.es/" target="_blank" rel="noopener noreferrer"><Icon type="external-link" /></a></td>
                            </tr>
                        
                    </tbody>
                    
                    </table>
    
                    
                </section>
                
            </section>
        </section>
        );
    };
}

const TrackingCard = withTranslation()(TrackingCardComponent);
export { TrackingCard };
