import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface IconProps {
    type: 'store' | 'back-arrow' | 'support' | 'validation' | 'close-icon' | 'user' | 'dashboard' | 'check-square' | 'package' | 'kendu-processing' | 'chevron-right' | 'image' | 'copy' | 'external-link' | 'check-icon' | 'x-icon' | 'monitor-icon' | 'refresh-icon' | 'arrow-right' | 'arrow-left' | 'gallery-alert'
}

type IconPropsSum = IconProps & WithTranslation;

class IconComponent extends React.Component<IconPropsSum> {

    renderStoreIcon() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23 12H1L3 6H21L23 12Z" stroke="#001114" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                <path d="M19 2H5C3.9 2 3 2.9 3 4V6H21V4C21 2.9 20.1 2 19 2Z" stroke="#001114" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                <path d="M3 12V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V12" stroke="#001114" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                <path d="M13 22V16C13 15.4 13.4 15 14 15H16C16.6 15 17 15.4 17 16V22" stroke="#001114" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
            </svg>
        );
    }

    renderBackArrowIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left">
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
        );
    }

    renderSupportIcon() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.89 1.45015L20.89 5.45015C21.5696 5.78788 21.9996 6.48121 22 7.24015V16.7702C21.9996 17.5291 21.5696 18.2224 20.89 18.5602L12.89 22.5602C12.3266 22.8421 11.6634 22.8421 11.1 22.5602L3.09999 18.5602C2.42098 18.218 1.9947 17.5205 1.99999 16.7601V7.24015C2.00039 6.48121 2.43033 5.78788 3.10999 5.45015L11.11 1.45015C11.6706 1.17156 12.3294 1.17156 12.89 1.45015Z" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2.32001 6.16016L12 11.0002L21.68 6.16016" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 22.76V11" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    renderCloseIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        );
    }

    renderUserIcon() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 21V19C20 16.7909 18.2091 15 16 15H8C5.79086 15 4 16.7909 4 19V21" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    renderCheckSquareIcon() {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 8.25L8.25 10.5L16.5 2.25" stroke="#959DA5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M15 9V14.25C15 15.0784 14.3284 15.75 13.5 15.75H3C2.17157 15.75 1.5 15.0784 1.5 14.25V3.75C1.5 2.92157 2.17157 2.25 3 2.25H11.25" stroke="#959DA5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    renderPackageIcon() {
        return (
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.66754 2.08737L15.6675 5.08737C16.1773 5.34067 16.4997 5.86066 16.5 6.42987V13.5774C16.4997 14.1466 16.1773 14.6666 15.6675 14.9199L9.66754 17.9199C9.24501 18.1313 8.74756 18.1313 8.32504 17.9199L2.32504 14.9199C1.81578 14.6633 1.49607 14.1401 1.50004 13.5699V6.42987C1.50034 5.86066 1.82279 5.34067 2.33254 5.08737L8.33254 2.08737C8.75301 1.87843 9.24706 1.87843 9.66754 2.08737Z" stroke="#959DA5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.73999 5.62012L8.99999 9.25012L16.26 5.62012" stroke="#959DA5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 18.07V9.25" stroke="#959DA5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.25 3.625L12.75 7.375" stroke="#959DA5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    renderDashboardIcon() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 9L12 2L21 9V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V9Z" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 22V16C9 14.8954 9.89543 14 11 14H13C14.1046 14 15 14.8954 15 16V22" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    renderKenduProcessingIcon() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2V6" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 18V22" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.92993 4.92969L7.75993 7.75969" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.24 16.2402L19.07 19.0702" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2 12H6" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M18 12H22" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.92993 19.0702L7.75993 16.2402" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.24 7.75969L19.07 4.92969" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    renderValidationIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" width="24" viewBox="0 0 24 24">
                <title>crop check</title>
                <g className="nc-icon-wrapper">
                    <path className="st0" d="M8 11l3 3L22 3" fill="none" stroke="#6A737D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path className="st1" d="M2 6V5c0-1.1.9-2 2-2h1" fill="none" stroke="#6A737D" strokeWidth="2" strokeLinecap="round"></path>
                    <path className="st1" d="M5 21H4c-1.1 0-2-.9-2-2v-1" fill="none" stroke="#6A737D" strokeWidth="2" strokeLinecap="round"></path>
                    <path className="st1" d="M20 18v1c0 1.1-.9 2-2 2h-1" fill="none" stroke="#6A737D" strokeWidth="2" strokeLinecap="round"></path>
                    <path className="st1" d="M2 10v4" fill="none" stroke="#6A737D" strokeWidth="2" strokeLinecap="round"></path>
                    <path className="st1" d="M13 3H9" fill="none" stroke="#6A737D" strokeWidth="2" strokeLinecap="round"></path>
                    <path className="st1" d="M9 21h4" fill="none" stroke="#6A737D" strokeWidth="2" strokeLinecap="round"></path>
                    <path className="st1" d="M20 12v2" fill="none" stroke="#6A737D" strokeWidth="2" strokeLinecap="round"></path>
                </g>
            </svg>
        );
    }
    
    renderChevRightIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className="arrow-symbol-mktg" width="24" height="24" viewBox="0 0 16 16" fill="none">
                <path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path>
                <path className="octicon-chevrow-stem" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path>
            </svg>
        );
    }
    renderImageIcon() {
        return (
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.25 18.25C16.25 17.1454 17.1454 16.25 18.25 16.25H111.75C112.855 16.25 113.75 17.1454 113.75 18.25V111.75C113.75 112.855 112.855 113.75 111.75 113.75H18.25C17.1454 113.75 16.25 112.855 16.25 111.75V18.25Z" stroke="#DEE0E3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M46.0415 54.167C50.5288 54.167 54.1665 50.5293 54.1665 46.042C54.1665 41.5547 50.5288 37.917 46.0415 37.917C41.5542 37.917 37.9165 41.5547 37.9165 46.042C37.9165 50.5293 41.5542 54.167 46.0415 54.167Z" stroke="#DEE0E3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M113.75 81.2503L86.6668 54.167L27.0835 113.75" stroke="#DEE0E3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }
    renderCopyIcon(){
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 11C9 9.89543 9.89543 9 11 9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H11C9.89543 22 9 21.1046 9 20V11Z" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 15H4C2.89543 15 2 14.1046 2 13V4C2 2.89543 2.89543 2 4 2H13C14.1046 2 15 2.89543 15 4V5" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
    }

    renderExternalLink(){
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 13V19C18 20.1046 17.1046 21 16 21H5C3.89543 21 3 20.1046 3 19V8C3 6.89543 3.89543 6 5 6H11" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 3H21V9" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 14L21 3" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        )
    }

    renderCheckIcon(){
        return(
            <svg width="24" height="24" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 1L6 12L1 7" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        )
    }

    renderXIcon(){
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 6L18 18" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
    }

    renderMonitorIcon(){
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V15C22 16.1046 21.1046 17 20 17H4C2.89543 17 2 16.1046 2 15V5Z" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 21H16" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 17V21" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        );
    }

    renderRefreshIcon(){
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 4V10H17" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 20V14H7" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.56734 8.66641C2.3831 9.18705 2.65581 9.75848 3.17646 9.94272C3.69711 10.127 4.26853 9.85425 4.45277 9.3336L2.56734 8.66641ZM18.3601 5.64L17.6528 6.34693C17.6602 6.35432 17.6677 6.3616 17.6753 6.36875L18.3601 5.64ZM22.3153 10.7288C22.7178 11.1069 23.3506 11.0873 23.7288 10.6848C24.107 10.2823 24.0873 9.64944 23.6848 9.27125L22.3153 10.7288ZM1.68482 13.2712C1.28234 12.8931 0.649484 12.9127 0.271293 13.3152C-0.106899 13.7177 -0.0872101 14.3506 0.315269 14.7288L1.68482 13.2712ZM5.64005 18.36L6.34733 17.6531C6.33994 17.6457 6.33244 17.6384 6.32482 17.6312L5.64005 18.36ZM21.4328 15.3336C21.617 14.813 21.3443 14.2415 20.8236 14.0573C20.303 13.873 19.7316 14.1458 19.5473 14.6664L21.4328 15.3336ZM4.45277 9.3336C5.36911 6.7441 7.5499 4.80579 10.229 4.19961L9.78766 2.24891C6.43875 3.00665 3.71276 5.42953 2.56734 8.66641L4.45277 9.3336ZM10.229 4.19961C12.9082 3.59342 15.7109 4.40413 17.6528 6.34693L19.0673 4.93307C16.6401 2.50457 13.1366 1.49118 9.78766 2.24891L10.229 4.19961ZM17.6753 6.36875L22.3153 10.7288L23.6848 9.27125L19.0448 4.91125L17.6753 6.36875ZM0.315269 14.7288L4.95527 19.0888L6.32482 17.6312L1.68482 13.2712L0.315269 14.7288ZM4.93276 19.0669C7.36005 21.4954 10.8635 22.5088 14.2124 21.7511L13.7711 19.8004C11.0919 20.4066 8.28916 19.5959 6.34733 17.6531L4.93276 19.0669ZM14.2124 21.7511C17.5614 20.9934 20.2873 18.5705 21.4328 15.3336L19.5473 14.6664C18.631 17.2559 16.4502 19.1942 13.7711 19.8004L14.2124 21.7511Z" fill="#001114"/>
            </svg>

        )
    }

    rnderArrowRight(){
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 12H20" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14 6L20 12L14 18" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
    }

    renderArrowLeft(){
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 12H4" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 18L4 12L10 6" stroke="#001114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        )
    }

    renderGalleryAlert(){
        return(
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.71758 3.64491L1.36508 14.2499C1.09856 14.7115 1.09697 15.2798 1.3609 15.7428C1.62483 16.2058 2.11464 16.4941 2.64758 16.4999H15.3526C15.8855 16.4941 16.3753 16.2058 16.6393 15.7428C16.9032 15.2798 16.9016 14.7115 16.6351 14.2499L10.2826 3.64491C10.0107 3.19662 9.52439 2.92285 9.00008 2.92285C8.47576 2.92285 7.9895 3.19662 7.71758 3.64491Z" stroke="#FFD33D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 7.5V10.5" stroke="#FFD33D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="9" cy="13.5" r="0.75" fill="#FFD33D"/>
            </svg>
            
        )
    }

    

    renderSwitch = () => {
        const { type } = this.props;

        switch (type) {
            case ('image'): return this.renderImageIcon();
            case ('store'): return this.renderStoreIcon();
            case ('back-arrow'): return this.renderBackArrowIcon();
            case ('support'): return this.renderSupportIcon();
            case ('kendu-processing'): return this.renderKenduProcessingIcon();
            case ('validation'): return this.renderValidationIcon();
            case ('user'): return this.renderUserIcon();
            case ('dashboard'): return this.renderDashboardIcon();
            case ('check-square'): return this.renderCheckSquareIcon();
            case ('package'): return this.renderPackageIcon();
            case ('close-icon'): return this.renderCloseIcon();
            case ('chevron-right'): return this.renderChevRightIcon();
            case ('copy'): return this.renderCopyIcon();
            case ('external-link'): return this.renderExternalLink();
            case ('check-icon'): return this.renderCheckIcon();
            case ('x-icon'): return this.renderXIcon();
            case ('monitor-icon'): return this.renderMonitorIcon();
            case ('refresh-icon'): return this.renderRefreshIcon();
            case ('arrow-right'): return this.rnderArrowRight();
             case ('arrow-left'): return this.renderArrowLeft();
             case ('gallery-alert'): return this.renderGalleryAlert();
            default: return;
    }  }




    render () {
        return(<> { this.renderSwitch() }</>)
    };
}

const Icon = withTranslation()(IconComponent);
export { Icon };
