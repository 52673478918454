import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface ContentProps {
}

type ContentPropsSum = ContentProps & WithTranslation;

class ContentComponent extends React.Component<ContentPropsSum> {

    render () {
        return (
            <section className="content">
                {this.props.children}
            </section>
        );
    };
}

const Content = withTranslation()(ContentComponent);
export { Content };
