import { one_campaign } from '../../../api';
import {
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    StoreValidateCropsActions,
    AppThunk,
} from './ActionTypes';

export const getData = (id: string): AppThunk => {
    return async (dispatch) => {

        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as StoreValidateCropsActions);

        const campaign = await one_campaign(id);

        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: campaign
        } as StoreValidateCropsActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as StoreValidateCropsActions);
    }
}
