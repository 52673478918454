import {
    UPDATE_STORES,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_TOTAL_STORES,
    StoreIndexActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Store, Filter } from "../../../interfaces/interfaces";

export interface StoreIndexState {
    stores: Store[],
    totalStores: Store[],
    loading: boolean,
    filter: Filter,
}

const INITIAL_STATE: StoreIndexState = {
    stores: [],
    totalStores: [],
    loading: false,
    filter: new Filter(),
};

const reducer: Reducer<StoreIndexState, StoreIndexActions> = (state = INITIAL_STATE, action: StoreIndexActions) => {
    switch(action.type) {
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new Filter() }
        case UPDATE_TOTAL_STORES:
            return { ...state, totalStores: action.payload }
        default:
            return state;
    }
}

export default reducer;