import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SelectOption, Visual } from '../interfaces/interfaces';

export interface AccordionProps {
    title: string,
    defaultOpen?: boolean,
    selectedImage: Visual | null,
    visuals: Visual[],
    key?: number,
    campaigns?: SelectOption[],
    onSelect: (visual: Visual) => void,
    search?: string,
    sections?: SelectOption[]
}

type AccordionPropsSum = AccordionProps & WithTranslation;

interface State {
	open: boolean;
}

class AccordionComponent extends React.Component<AccordionPropsSum, State> {

    constructor(props: AccordionPropsSum) {
		super(props);
		this.state = {
			open: this.props.defaultOpen || false,
		};
	}

    status() {
        const { open } = this.state;
        return open ? 'open' : 'close';
    }

    selectedClass(visual: Visual) {
        const { selectedImage } = this.props;
        if (visual.image === selectedImage?.image) {
            return 'selected';
        }
    }

    getCampaignTitle(visual: Visual ){
        const { title, campaigns } = this.props;

        if(title === '' && campaigns !== undefined){
            const campaign = campaigns.find( obj => obj.value === visual.campaign_id );
            if(campaign !== undefined){
                return campaign.name;
            }
        }
        return title;   
    }

    getSectionName(section: string) {
        return this.props.sections?.find(s => s.value === section)?.name;
    }

     ordenarPorSectionPriority = (a:Visual, b:Visual) => {
        // Primero, ordenar por section_name
        const comparacionSection = (a.section_name ?? '').localeCompare(b.section_name ?? '');
      
        // Si section_name es el mismo, entonces ordenar por priority
        if (comparacionSection === 0) {
          return a.priority - b.priority;
        }
      
        return comparacionSection;
      };

    renderVisual(visual: Visual, index: number){        
        const { search, t } = this.props;
        const campaign_title = this.getCampaignTitle(visual);

        if(search !== undefined){
            if(!((visual.name).indexOf(search) >= 0 || campaign_title.indexOf(search) >= 0) ){
                return( <></>);
            }
        }
        return(
            <article key={ index } className={`${true ? 'img-inside' : 'img-less'} ${this.selectedClass(visual)}`} onClick={() => this.props.onSelect(visual)}>
                {true &&
                    <div className="campaign-image">
                        <img src={ visual.thumbnail } alt="" />
                    </div>
                }
                <div className="campaign-info">
                    <div>
                        <h4>{ visual.name }</h4>
                        <p style={{ color: '#888' }}>{ campaign_title }</p>
                        <p>{visual.section_name} | {t('priority')} {visual.priority}</p>
                    </div>
                </div>
            </article>
        );     
    }

    render () {
        const { open } = this.state;
        const { visuals, key, title } = this.props;
        let visual_array=visuals;
        let visuals_order: Visual[] = [];
        visual_array.map((visual, index) => {
            visual.section_name = this.getSectionName(visual.section);
            visuals_order.push(visual);
            return visual; // Add the return statement
        });

        visuals_order=visuals_order.slice().sort(this.ordenarPorSectionPriority);
        return (
            <li key={ key } className={this.status()}>
                {(title !== '')?(
                    <h3 className="cursor-hand" onClick={() => this.setState({ open: !open })}>{ title }</h3>
                ):(<></>)}
                {open &&
                    <section className="campaign-content">
                        {visuals_order.map((visual, index) =>
                            this.renderVisual(visual, index)
                        )}
                    </section>
                }
            </li>
        );
    };
}

const Accordion = withTranslation()(AccordionComponent);
export { Accordion };
