import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputSelect } from '.';
import { SelectOption } from '../interfaces/interfaces';

export interface FormSelectProps {
    id: string,
    cssClass?: string,
    label?: string,
    value?: string,
    options?: SelectOption[];
    disabled?: boolean,
    helpText?: string,
    onChange?: (value?: string, label?: string) => void,
    error?:boolean
}

type FormSelectPropsSum = FormSelectProps & WithTranslation;

class FormSelectComponent extends React.Component<FormSelectPropsSum> {

    render () {
        const { id, label, value, disabled, helpText, options, onChange, cssClass, error } = this.props;
        return (
            <article className={`form-item ${cssClass}`}>
                {error?<label style={{'color':'red'}} htmlFor={ id }>{ label }</label>:<label htmlFor={ id }>{ label }</label>}
                <InputSelect id={ id } value={value} disabled={disabled} helpText={helpText} options={ options } onChange={(value, label) => onChange ? onChange(value, label) : null} />
            </article>
        );
    };
}

const FormSelect = withTranslation()(FormSelectComponent);
export { FormSelect };
