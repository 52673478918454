import {
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SUPPORTS_WITH_VISUAL,
    GalleryDetailActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Campaign, Support } from "../../../interfaces/interfaces";

export interface StoreDetailState {
    campaign: Campaign | undefined,
    loading: boolean,
    supports: Support[] | undefined,
}

const INITIAL_STATE: StoreDetailState = {
    campaign: undefined,
    loading: false,
    supports: undefined
};

const reducer: Reducer<StoreDetailState, GalleryDetailActions> = (state = INITIAL_STATE, action: GalleryDetailActions) => {
    switch(action.type) {
        case UPDATE_CAMPAIGN:
            return { ...state, campaign: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_SUPPORTS_WITH_VISUAL:
            return { ...state, supports: action.payload };
        default:
            return state;
    }
}

export default reducer;