import { useState, useEffect } from "react";
import { VisualPack } from "../interfaces/interfaces";
import { StoreApi } from "../api/StoreApi";
import { AuthenticatedClient, AxiosClient } from "../api/ApiClient";
import { get_user_token } from "../util/cookies";

export function useVisualPacksPopup(storeId: string) {
  const [loading, setLoading] = useState(true);
  const [visualPacks, setVisualPacks] = useState<VisualPack[]>([]);

  useEffect(() => {
    const storeApi = new StoreApi(
      new AuthenticatedClient(
        new AxiosClient(process.env.REACT_APP_API_URL || ""),
        get_user_token(),
      ),
    );

    (async () => {
      const visualPacks = await storeApi.getVisualPacks(storeId);

      setVisualPacks(visualPacks);
      setLoading(false);
    })();
  }, [storeId]);

  return { loading, visualPacks };
}
