import React from "react";
import useVisualPack from "./VisualPack.hook";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { StoreVisualPackToPDF } from "../../../components/StoreVisualPackToPDFComponent";
import { PrimaryButton, Spinner } from "../../../components";
import { PrimaryButtonStyle } from "../../../interfaces/interfaces";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";

export function VisualPackk() {
  const { t } = useTranslation();
  const { showRenderPdf, store, floors, campaign, error } = useVisualPack();

  const renderButton = () => {
    if (error) {
      return <Redirect to="/" />;
    }
    if (showRenderPdf && store) {
      return (
        <PDFDownloadLink
          document={
            <StoreVisualPackToPDF
              store={store}
              floors={floors}
              currentCampaign={campaign}
            />
          }
        >
          {({ loading }) =>
            loading ? (
              <Spinner message={t("loading")} />
            ) : (
              <PrimaryButton style={PrimaryButtonStyle.OUTLINED}>
                {t("print_pdf")}
              </PrimaryButton>
            )
          }
        </PDFDownloadLink>
      );
    }
    return <Spinner message={t("loading")} />;
  };

  return <div>{renderButton()}</div>;
}
