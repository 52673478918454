import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Store } from "../../../interfaces/interfaces";
export const UPDATE_LOADING = 'update_loading';
export const CLEAN_STORE='clean_store';
export const UPDATE_APPROVE = 'update_approve';
export const UPDATE_STORE='update_store';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}



export interface AproveStoreAction {
    type: typeof UPDATE_APPROVE,
    payload: number[]
}
export interface UpdateStoresAction {
    type: typeof UPDATE_STORE,
    payload: Store
}
export interface CleanStore {
    type: typeof CLEAN_STORE,
}

export type ApproveStoreActions =
UpdateLoadingAction |AproveStoreAction|UpdateStoresAction|CleanStore


export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
