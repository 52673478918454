import moment from "moment";
import { Campaign, CampaignStatusPrimark } from "../interfaces/interfaces";

export const is_blocked = (campaign: Campaign | null) : boolean => {
    if (campaign?.status_primark !== CampaignStatusPrimark.DRAFT) {
        return true;
    }
    return false;
}

export const start_end_date = (campaign: Campaign | null | undefined) : string => {
    if (!campaign) {
        return '';
    }
    return moment(campaign?.start_date).format('LL') + ' › ' + moment(campaign?.end_date).format('LL');
}

export const estimated_end_date = (campaign: Campaign | null | undefined) : string => {
    if (!campaign) {
        return '';
    }
    return moment(campaign?.end_date).format('LL');
}

