import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface InputTextProps {
    id: string,
    search?: boolean,
    type?: string,
    label?: string,
    value?: string,
    disabled?: boolean,
    hasError?: boolean,
    helpText?: string,
    className?: string,
    placeholder?: string,
    maxLength?:number|null,
    onChange?: (text: string) => void
}

type InputTextPropsSum = InputTextProps & WithTranslation;

class InputTextComponent extends React.Component<InputTextPropsSum> {

    render () {
        const { id, search, type, label, value, disabled, helpText, className, placeholder, onChange, maxLength } = this.props;
        if (search) {
            return (
                <TextField
                    id={ id }
                    type={ type || 'text' }
                    label={ label }
                    value={ value }
                    onChange={(event) => onChange ? onChange(event.target.value) : null}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ className: className }}
                    fullWidth
                    disabled={ disabled }
                    error={ !!helpText }
                    helperText={ helpText }
                    placeholder={ placeholder }
                />
            );
        }
        return (
            <TextField
                id={ id }
                type={ type || 'text' }
                label={ label }
                value={ value }
                onChange={(event) => onChange ? onChange(event.target.value) : null}
                variant="outlined"
                inputProps={{ className: className, maxLength:maxLength }}
                fullWidth
                disabled={ disabled }
                InputLabelProps={{ shrink: (value !== "" && value!== null && value!== undefined)?true:false }}
                error={ !!helpText }
                helperText={ helpText }
                placeholder={ placeholder }
            />
        );
    };
}

const InputText = withTranslation()(InputTextComponent);
export { InputText };
