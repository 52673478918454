import { get_active_campaign, processing, processing_total_lines } from '../../../api';
import { UPDATE_LOADING, AppThunk, UPDATE_ACTIVE_CAMPAIGN, UPDATE_LINES, UPDATE_FILTER, CLEAR_FILTER,UPDATE_STORES, UPDATE_TOTAL_LINES, ProcessingIndexActions, CLEAN_LINES } from './ActionTypes';
import { Filter } from '../../../interfaces/interfaces';
import {  get_stores } from '../../../api';
import {  FilterStoreRequest } from '../../../api/interfaces';
export const getActiveCampaign = (): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_LOADING,
			payload: true
        } as ProcessingIndexActions);
        

		const active_campaign = await get_active_campaign();

		dispatch({
			type: UPDATE_ACTIVE_CAMPAIGN,
			payload: active_campaign
		});

		dispatch({
			type: UPDATE_LOADING,
			payload: false
		} as ProcessingIndexActions);
	}
}

export const cleanLines=():AppThunk=>{
    return async (dispatch)=>{
        dispatch({
            type: CLEAN_LINES
        } as ProcessingIndexActions);
    }
}

export const getLines = (): AppThunk => {
    return async (dispatch,getState) => {
        dispatch({
            type: CLEAN_LINES
        } as ProcessingIndexActions);
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ProcessingIndexActions);
        const filter = getState().processingIndex.filter;
      
        let lines = await processing(filter);
        lines = lines.filter((item:any) => {
            if (item.line && item.line.comment) {
                return !item.line.comment.includes('resize-') && !item.line.comment.includes('change-');
              }
              return true;
          });
       
        
       
        const areAllValuesUndefined = Object.values(filter).every(val => val === undefined);
        let totalLines=[];
        if (areAllValuesUndefined){
            totalLines=lines;
        }else{
            totalLines=await processing_total_lines();
        }

        totalLines = totalLines.filter((item:any) => {
            if (item.line && item.line.comment) {
                return !item.line.comment.includes('resize-') && !item.line.comment.includes('change-');
              }
              return true;
          });

  
        dispatch({
            type: UPDATE_TOTAL_LINES,
            payload: totalLines
        } as ProcessingIndexActions);
        dispatch({
            type: UPDATE_LINES,
            payload: lines
        } as ProcessingIndexActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ProcessingIndexActions);
    }
}



export const updateFilter = (value: Filter): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as ProcessingIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as ProcessingIndexActions);
    }
}



export const getStores = (): AppThunk => {
    return async dispatch => {
        const stores = await get_stores({ page_size: 0 } as FilterStoreRequest);
        const sortedStores = stores?.sort((n1,n2) => {
            if (n1.name > n2.name) {
                return 1;
            }
        
            if (n1.name < n2.name) {
                return -1;
            }
        
            return 0;
        });

        dispatch({
            type:  UPDATE_STORES,
            payload: sortedStores.map(store => ({ value: store.id, name: `${store.customer_shop_no}: ${store.name}` }))
        } as ProcessingIndexActions);
    }
}


