import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign, Section } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_CAMPAIGN = 'update_selected_campaign';
export const UPDATE_SECTION = 'update_selected_campaign_section';


export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateCampaignAction {
    type: typeof UPDATE_CAMPAIGN,
    payload: Campaign
}

export interface UpdateSectionAction {
    type: typeof UPDATE_SECTION,
    payload: Section
}

export type CampaignDetailActions =
UpdateLoadingAction |
UpdateCampaignAction |
UpdateSectionAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
