import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserFilter as FilterInterface, SelectOption, PrimaryButtonStyle } from "../interfaces/interfaces";
import { CloseButton, PrimaryButton, FormText, InputSelectMultiple, InputCheckBox } from '.';

export interface UserFilterProps {
    filter: FilterInterface,
    stores?: SelectOption[],
    roles?: SelectOption[],
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterInterface) => void
}

interface State {
    filter: FilterInterface,
}

type FilterPropsSum = UserFilterProps & WithTranslation;

class FilterComponent extends React.Component<FilterPropsSum, State> {

    constructor(props: FilterPropsSum) {
        super(props);

        this.state = {
            filter: props.filter,
        };
    }

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    onSubmit() {
        const { filter } = this.state;
        this.props.onSubmit(filter);
    }

    renderName() {
        const { t } = this.props;
        const { filter } = this.state;
        return (
            <div className="form-row">
                <FormText id="address" label={ t('name') } value={filter.name} onChange={(text) => this.setState({ filter: { ...filter, name: text } })} />
            </div>
        );
    }

    renderLastName() {
        const { t } = this.props;
        const { filter } = this.state;
        return (
            <div className="form-row">
                <FormText id="address" label={ t('last_name') } value={filter.last_name} onChange={(text) => this.setState({ filter: { ...filter, last_name: text } })} />
            </div>
        );
    }

    renderRoles() {
        const { t, roles } = this.props;
        const { filter } = this.state;

        return (
            <fieldset>
                <div className="form-row check-group-title">
                    <legend>{ t('roles') }</legend>
                </div>
                <div className="check-group-row col-2">
                    {roles?.map(role =>
                        <InputCheckBox
                            key={role.value}
                            name={ role.name }
                            id={role.name+"_role"}
                            checked={ (filter.roles || []).includes(role.value) }
                            checkBoxChange={(checked) => {
                                if (checked) {
                                    this.setState({ filter: { ...filter, roles: [...filter.roles || [], role.value] } });
                                } else {
                                    this.setState({ filter: { ...filter, roles: (filter.roles || []).filter(element => element !== role.value) } });
                                }
                            }}
                        />
                    )}
                </div>
            </fieldset>
        );
        
    }


    renderStores() {
        const { t, stores } = this.props
        const { filter } = this.state;

        return (
            
            <div className="form-row">
                <div className="form-row check-group-title">
                    <legend>{ t('stores') }</legend>
                </div>
                <InputSelectMultiple 
                    id="stores" 
                    selected={filter.stores || []} 
                    options={stores!} 
                    onChange={(options) => this.setState({ filter: { ...filter, stores: options.map(option => {
                            return option.value
                        }
                    )}})}
                    placeholder={ t('stores') } 
                    helpText={ "" }
                    disabled={ false }/>
                </div> 
        )
        
    }


    render () {
        const { t } = this.props;
        return (
            <section id="popUp-advanced-filter" className="cover-blur advanced-filter-popup active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('advanced_filter') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content formlines-container">
                
                    <fieldset className="advanced-filter-datapicker">
                        {this.renderName()}
                        {this.renderLastName()}
                        {this.renderRoles()}
                        {this.renderStores()}
                    </fieldset>
        
                    </section>
                    <section className="popup-actions">
                        <PrimaryButton onClick={this.props.onClear} style={PrimaryButtonStyle.OUTLINED}>
                            { t('clear_filters') }
                        </PrimaryButton>
                        <PrimaryButton onClick={() => this.onSubmit()} style={PrimaryButtonStyle.FILLED}>
                            { t('apply_filter') }
                        </PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const UserFilter = withTranslation()(FilterComponent);
export { UserFilter };
