import {
    UPDATE_USERS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_STORES,
    UserIndexActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { User, UserFilter, Store } from "../../../interfaces/interfaces";

export interface UserIndexState {
    users: User[],
    stores: Store[],
    loading: boolean,
    filter: UserFilter,
}

const INITIAL_STATE: UserIndexState = {
    users: [],
    stores: [],
    loading: false,
    filter: new UserFilter(),
};

const reducer: Reducer<UserIndexState, UserIndexActions> = (state = INITIAL_STATE, action: UserIndexActions) => {
    switch(action.type) {
        case UPDATE_USERS:
            return { ...state, users: action.payload };
        case UPDATE_STORES:
            return { ...state, stores: action.payload }
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new UserFilter() }
        default:
            return state;
    }
}

export default reducer;