import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Store, Campaign, Floor, SupportStatusPrimark } from "../../../interfaces/interfaces";
import { StoreApi } from "../../../api/StoreApi";
import { AuthenticatedClient, AxiosClient } from "../../../api/ApiClient";
import { FloorMeasure } from "./floor.measure";

export default function useVisualPack() {
  const { store_id, token } = useParams();
  const [showRenderPdf, setShowRenderPdf] = useState(false);
  const [store, setStore] = useState<Store | null>(null);
  const [storeWithFloors, setStoreWithFloors] = useState<Store | null>(null);
  const [floors, setFloors] = useState<Floor[]>([]);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const removeNotInCampaignSupports = (store: Store|null) => {
    if (!store) {
      return store;
    }
    return { ...store, sections: store.sections.map((section) => {
      return {
        ...section,
        supports: section.supports?.filter(support => support.status_primark !== SupportStatusPrimark.NOT_IN_CAMPAIGN ) || []
      }
    })};
  }

  useEffect(() => {
    const storeApi = new StoreApi(
      new AuthenticatedClient(
        new AxiosClient(process.env.REACT_APP_API_URL || ""),
        token,
      ),
    );

    (async () => {
      const activeCampaign = await storeApi.getActiveCampaign();
      const store = await storeApi.getStore(store_id);

      setCampaign(activeCampaign);
      setStore(removeNotInCampaignSupports(store));
    })().catch((error) => {
      setError(error);
    });
  }, [store_id, token]);

  useEffect(() => {
    if (store) {
      const measureFloor = new FloorMeasure(800);

      Promise.all(
        store.floors.map((floor) => measureFloor.measure(floor)),
      ).then((floors) => {
        setFloors(floors);
        setStoreWithFloors({ ...store, floors });

        setShowRenderPdf(true);
      });
    }
  }, [store]);

  return {
    showRenderPdf,
    store: storeWithFloors,
    floors,
    campaign,
    error,
  };
}
