import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SectionContainerProps {}

type SectionContainerPropsSum = SectionContainerProps & WithTranslation;

class SectionContainerComponent extends React.Component<SectionContainerPropsSum> {

    render () {
        return (
            <section className="work-section">
                {this.props.children}
            </section>
        );
    };
}

const SectionContainer = withTranslation()(SectionContainerComponent);
export { SectionContainer };
