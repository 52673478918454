import { get_active_campaign, get_stores, install } from '../../../api';
import { UPDATE_LOADING, AppThunk, DashboardIndexActions, UPDATE_ACTIVE_CAMPAIGN, UPDATE_STORES, UPDATE_FILTER, CLEAR_FILTER, UPDATE_STORE_STATS, UPDATE_TOTAL_STORES } from './ActionTypes';
import { FilterStoreRequest } from '../../../api/interfaces';
import { Filter, StoreStatusPrimark } from '../../../interfaces/interfaces';

export const getActiveCampaign = (): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_LOADING,
			payload: true
		} as DashboardIndexActions);

		const active_campaign = await get_active_campaign();

		dispatch({
			type: UPDATE_ACTIVE_CAMPAIGN,
			payload: active_campaign
		});

		dispatch({
			type: UPDATE_LOADING,
			payload: false
		} as DashboardIndexActions);
	}
}

export const getStores = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as DashboardIndexActions);

        const stores = await get_stores({
            ...getState().dashboardIndex.filter,
            name: getState().dashboardIndex.filter.search,
            page_size: 0,
            key: lastKey
		} as FilterStoreRequest);
		dispatch({
            type: UPDATE_STORES,
            payload: stores
		} as DashboardIndexActions);
		const totalStores = await get_stores(
			{
				page_size: 0,
				key: lastKey
			} as FilterStoreRequest
		);
		dispatch({
            type: UPDATE_TOTAL_STORES,
            payload: totalStores
        } as DashboardIndexActions);
		
        

		const in_campaign_statutes = [
			StoreStatusPrimark.PENDING_APPROVAL_SM,
			StoreStatusPrimark.PENDING_SIGN_OFF_CM,
			StoreStatusPrimark.SIGNED_OFF,
			StoreStatusPrimark.IN_PRODUCTION,
			StoreStatusPrimark.SHIPPED,
			StoreStatusPrimark.INSTALLED
		];

		const approved_statuses = [
			StoreStatusPrimark.SIGNED_OFF,
			StoreStatusPrimark.IN_PRODUCTION,
			StoreStatusPrimark.SHIPPED,
			StoreStatusPrimark.INSTALLED
		];
		const total_stores = stores.filter(store => in_campaign_statutes.includes(store.status_primark)).length;
		const installed_stores = stores.filter(store => store.status_primark === StoreStatusPrimark.INSTALLED).length;
		const approved_stores = stores.filter(store => approved_statuses.includes(store.status_primark)).length;

		dispatch({
			type: UPDATE_STORE_STATS,
			payload: { total_stores, installed_stores, approved_stores }
		});

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as DashboardIndexActions);
    }
}

export const updateFilter = (value: Filter): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as DashboardIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as DashboardIndexActions);
    }
}

export const setInstalled = (store_id: string): AppThunk => {
	return async dispatch => {
		dispatch({
			type: UPDATE_LOADING,
			payload: true
		} as DashboardIndexActions);

		await install(store_id);

		dispatch({
			type: UPDATE_LOADING,
			payload: false
		} as DashboardIndexActions);
	}
}