import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { AuthError } from "./StoreApi";

export type ApiRequest = AxiosRequestConfig;

export interface ApiClient {
  request<T>(config: ApiRequest): Promise<T>;
}

export class AxiosClient implements ApiClient {
  constructor(private readonly baseUrl: string) {}

  async request<T>(config: ApiRequest) {
    return axios
      .request<T>({ ...config, baseURL: this.baseUrl })
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          throw new AuthError();
        }
        throw error;
      });
  }
}

export class AuthenticatedClient implements ApiClient {
  constructor(
    private readonly client: ApiClient,
    private readonly token: string,
  ) {}

  async request<T>(config: ApiRequest) {
    return this.client.request<T>({
      ...config,
      headers: { Authorization: `Bearer ${this.token}` },
    });
  }
}
