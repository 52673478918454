import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface InputNumberProps {
    id: string,
    type?: string,
    label?: string,
    value?: number,
    disabled?: boolean,
    hasError?: boolean,
    helpText?: string,
    className?: string,
    placeholder?: string,
    unitClass?: string,
    onChange?: (text: string) => void
}

type InputNumberPropsSum = InputNumberProps & WithTranslation;

class InputNumberComponent extends React.Component<InputNumberPropsSum> {

    render () {
        const { id, label, value, disabled, helpText, className, placeholder, unitClass, onChange } = this.props;
        return (
            <TextField
                id={ id }
                type="number"
                label={ label }
                value={ value }
                onChange={(event) => onChange ? onChange(event.target.value) : null}
                variant="outlined"
                inputProps={{ className: className }}
                fullWidth
                disabled={ disabled }
                error={ !!helpText }
                helperText={ helpText }
                placeholder={ placeholder }
                className={ unitClass }
            />
        );
    };
}

const InputNumber = withTranslation()(InputNumberComponent);
export { InputNumber };
