import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputTextArea } from '.';

export interface FormTextAreaProps {
    id: string,
    label?: string,
    value?: string,
    disabled?: boolean,
    helpText?: string,
    className?: string,
    onChange?: (text: string) => void,
    limit?: number,
    error?: boolean
}

type FormTextAreaPropsSum = FormTextAreaProps & WithTranslation;

class FormTextAreaComponent extends React.Component<FormTextAreaPropsSum> {

	renderLimit() {
		const { limit, value } = this.props;

		if (limit) {
			return <span style={{color: '#888', fontSize: '0.8rem'}}>{value?.length}/{limit}</span>;
		}
	}

    render () {
        const { id, label, value, disabled, helpText, className, limit, onChange,error } = this.props;
        return (
            <article className="form-item">
              {error?<label style={{'color':'red'}} htmlFor={ id }>{ label }</label>:<label htmlFor={ id }>{ label }</label>}
                <InputTextArea id={ id } className={`field field--textarea ${className ? className : ''}`} maxLength={limit} value={value} disabled={disabled} helpText={helpText} onChange={(text) => onChange ? onChange(text) : null} />
				{this.renderLimit()}
            </article>
        );
    };
}

const FormTextArea = withTranslation()(FormTextAreaComponent);
export { FormTextArea };
