import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Accordion, PrimaryButton, SecondaryButton, CloseButton } from '.';
import { get_gallery_visuals } from '../api';
import { Visual, Filter as FilterInterface, SelectOption, Support } from '../interfaces/interfaces';
import { ConfirmationPopUp } from './ConfirmationPopUp';
import { InputSelectSearchable } from './InputSelectSearchable';
import { RoleGuard } from './RoleGuard';
import { Role } from '../util/jwt';

export interface ChangeImageProps {
    onClose: () => void,
    onSubmit: (selectedImage: Visual | null) => void,
    onSpecialRequest: () => void,
    visuals: Visual[],
    filter: FilterInterface,
    sections: SelectOption[],
    campaigns: SelectOption[],
    selectedImage: Visual | null,
    selectedSupport: Support | null
}

type ChangeImagePropsSum = ChangeImageProps & WithTranslation;

interface State {
	selectedImage: Visual | null;
    filter: FilterInterface;
	showConfirmationPopUp: boolean;
    visuals: Visual[];
}

class ChangeImageComponent extends React.Component<ChangeImagePropsSum, State> {

    constructor(props: ChangeImagePropsSum) {
		super(props);
		this.state = {
			selectedImage: this.props.selectedImage,
            filter: props.filter,
            visuals: this.props.visuals,
			showConfirmationPopUp: false,
		};
	}

    componentDidMount() {
        document.body.classList.add('stopScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    async filterSubmit(type: string, text: string | undefined) {
        const { filter } = this.state;
        var tempFilter = filter;
        var execFilter = true;

        switch(type){
            case "campaign": tempFilter.campaign = text; break;
            case "section": tempFilter.section = text; break;
            case "search": tempFilter.search = text; execFilter = false; break;
            default: break;
        }

        this.setState({ filter: tempFilter }); 
        if(execFilter)
            get_gallery_visuals(filter.campaign, filter.section, filter.search).then(visuals => 
                this.setState({visuals: visuals})
            );
    }

    renderFilter() {
        const { t, sections, campaigns } = this.props;
        const { filter } = this.state;

        return (
            <div className="add-support-header">                
                <fieldset className="formlines-container">
                    <div className="form-row col-d-1-1">
                        <InputSelectSearchable id="campaign" placeholder={ t('all_campaigns') } options={ ([...campaigns, {name: t('all_campaigns'), value: '0' }]).sort(function(a, b) { return a.value.localeCompare(b.value);}) } value={filter.campaign} onChange={(text) => { this.filterSubmit('campaign', text) }}  />                        
                        <InputSelectSearchable id="section" placeholder={ t('all_sections') } options={([...sections, {name: t('all_sections'), value: '0' }]).sort(function(a, b) {return a.value.localeCompare(b.value);}) }  value={filter.section} onChange={(value) => {  this.filterSubmit('section', value) }} />
                    </div>
                </fieldset>

                <fieldset>
                    <div className="form-row">
                        <article className="form-item">
                            <input id="search" name="search" className="field field--search" type="text" placeholder={t('name_status_placeholder')} value={filter.search} onChange={(e) => { this.filterSubmit('search', e.target.value ) }} />
                        </article>
                    </div>
                </fieldset>
            </div>
        );
    }

    renderVisuals(){
        const { selectedImage, visuals, filter } = this.state;
        
        return(<fieldset>
            <ul className="accordion">
                <Accordion
                    title={ '' }
                    visuals={ visuals }
                    selectedImage={selectedImage}
                    onSelect={(visual: Visual) => this.setState({ selectedImage: visual })}
                    defaultOpen = { true }
                    campaigns={ this.props.campaigns }
                    search={ filter.search }
                    sections={this.props.sections}
                />
            </ul>
        </fieldset>);
    }

	renderSectionChangeConfirmation(){
		const { t, selectedSupport, sections } = this.props;
        const { selectedImage, showConfirmationPopUp } = this.state;

        if(selectedImage && selectedSupport)
        {
            const support_section = sections.find(section => section.value === selectedSupport.section);
            const section_image = sections.find(section => section.value === selectedImage.section);
            
            var change_text = t("Support_changing_section");
            if(support_section && section_image )
                change_text = t("Support changing from department {{section_support}} to department {{section_image}}", { section_support: support_section.name, section_image: section_image.name } );
    
            if(showConfirmationPopUp){
                return(
                    <ConfirmationPopUp 
                        onClose={() => this.setState({ showConfirmationPopUp: false })}
                        onSubmit={() => {
                            this.props.onSubmit(selectedImage);
                            this.setState({ showConfirmationPopUp: false });
                        }}
                        title={t("confirm_support_section_change")}
                        change_text={change_text}
                    />
                );
            }
        }
	}

    checkSections(){
		const { selectedSupport } = this.props;
        const { selectedImage } = this.state;
        
        if(selectedSupport?.section !== selectedImage?.section){
            this.setState({showConfirmationPopUp:true});
        }else{
            this.props.onSubmit(selectedImage);
            this.setState({ showConfirmationPopUp: false });
        }
    }

    render () {
        const { t } = this.props;

        return (
            <section id="store-change-validating-image" className="cover-blur active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('change_image') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content pb-50">
                    <div className="text-center mt-16">
							<RoleGuard roles={[Role.STORE_MANAGER, Role.COUNTRY_MANAGER, Role.HEAD_VM, Role.ADMIN, Role.BACKDOOR]}>
								<SecondaryButton onClick={() => this.props.onSpecialRequest()}>{ t('Special request / ad hoc request') }</SecondaryButton>
							</RoleGuard>
                        </div>
                        {this.renderFilter()}
                        {this.renderVisuals()}
                        
                    </section>
                    <section className="popup-actions">
                        <PrimaryButton onClick={() => this.checkSections()}>{ t('change_image') }</PrimaryButton>
                    </section>
                </section>
				{this.renderSectionChangeConfirmation()}
            </section>
        );
    };
}

const ChangeImage = withTranslation()(ChangeImageComponent);
export { ChangeImage };
