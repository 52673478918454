import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Activity, ActivityFilter,ActivityTypes } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_FILTER = 'update_filter_activity';
export const UPDATE_ACTIVITIES = 'update_activities';

export const CLEAR_FILTER = 'clear_filter_activity';
export const UPDATE_ACTIVITY_TYPES = 'update_activity_types';
export const UPDATE_PAGE='update_page';
export const UPDATE_TOTAL_PAGES='update_total_pages';
export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: ActivityFilter
}

export interface UpdateActivitiesTypesAction {
    type: typeof UPDATE_ACTIVITY_TYPES,
    payload: ActivityTypes[]
}

export interface UpdateActivitiesAction {
    type: typeof UPDATE_ACTIVITIES,
    payload: Activity[]
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export interface UpdatePageAction{
    type:typeof UPDATE_PAGE,
    payload:number
}

export interface UpdateTotalPageAction{
    type:typeof UPDATE_TOTAL_PAGES,
    payload:number
}
export type ActivityIndexActions =
UpdateLoadingAction |
UpdateFilterAction |
UpdateActivitiesAction |
UpdateActivitiesTypesAction |
UpdatePageAction |
UpdateTotalPageAction |
ClearFilterAction
;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
