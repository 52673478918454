import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import { BackArrow, ContentTop, Floorplan, PrimaryButton, ImagePreview, ChangeImage, SpecialRequest, EstimatedPricesChange, AddImage, DepartmentChange, Spinner, Notification } from '../../../components';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Visual, PrimaryButtonStyle, EstimatedPriceChange, SupportStatusPrimark, StoreStatusPrimark, SpecialRequestRequest } from '../../../interfaces/interfaces';
import { getData, selectSupport, getCampaigns } from './ValidateImagesActions';
// import { renderPrice } from '../../../util/price';
import { start_end_date } from '../../../util/campaign';
import { StatusGuard } from '../../../components/StatusGuard';
import { RoleGuard } from '../../../components/RoleGuard';
import { Role } from '../../../util/jwt';
import { add_support_to_campaign, approve_store_sm, assign_visual_to_support, store_sign_off, validate_support_image, special_request } from '../../../api';
import { ConfirmationPopUp } from '../../../components/ConfirmationPopUp';
import { getDepartments } from '../../../reducers/datatypes/DatatypesActions';
import { getProductionLanguages,getAllLanguages} from '../../../reducers/datatypes/DatatypesActions';
const mapStateToProps = (state: RootState) => {
	return {
		store_validate_images_state: state.storeValidateImages,
		datatypes_state: state.datatypes,
		data_types_state:state.datatypes
		
	}
}

const connector = connect(mapStateToProps, {
	getData,
	selectSupport,
	getCampaigns,
	getDepartments,
	getProductionLanguages,

	getAllLanguages
});

interface ParamTypes {
	store_id: string,
	support_id?: string,
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	previewImage: Visual | null;
	showChange: boolean;
	showSpecialRequest: boolean;
	showEstimated: boolean;
	showAddSupport: boolean;
	showDepartmentChange: boolean;
	showConfirmationPopUp: boolean;
	loadingFromPop: boolean;
}

class ValidateImages extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			previewImage: null,
			showChange: false,
			showSpecialRequest: false, // Si pones a true, puedes ver el popUp de Special Request
			showEstimated: false,
			showAddSupport: false,
			showDepartmentChange: false,
			showConfirmationPopUp: false,
			loadingFromPop: false,
		};
	}

	componentDidMount() {
		const { store_id, support_id } = this.props.match.params;
		this.props.getData(store_id, support_id);
		this.props.getCampaigns();
		this.props.getDepartments();
		this.props.getProductionLanguages();
		this.props.getAllLanguages();
	}

	renderViewImageModal() {
		const { previewImage } = this.state;
		if (previewImage) {
			return (
				<ImagePreview visual={ previewImage } onClose={() => this.setState({ previewImage: null }) }/>
			);
		}
	}

	renderEstimatedModal() {
		const { showEstimated } = this.state;
		if (showEstimated) {
			const estimatedPricesChange : EstimatedPriceChange[] = [
				{name: 'Total SQM', price: 10000},
				{name: 'Total UNITS', price: 6000},
				{name: 'Total ARTWORK', price: 100},
				{name: 'Total SHIPPING', price: 1},
				{name: 'Total CANVAS', price: 200},
				{name: 'Total CAMPAIGN', price: 2000},
			]
			
			return (
				<EstimatedPricesChange
					onClose={() => this.setState({ showEstimated: false })} 
					estimatedPricesChange={estimatedPricesChange} />
			);
		}
	}

	renderAddSupportModal() {
		const { showAddSupport } = this.state;
		if (showAddSupport) {
			return (
				<AddImage
					onSubmit={() => this.setState({ showDepartmentChange: true , showAddSupport: false })}
					onClose={() => this.setState({ showAddSupport: false })}
				/>
			);
		}
	}

	renderDepartmentChangetModal() {
		const { showDepartmentChange } = this.state;
		if (showDepartmentChange) {
			return (
				<DepartmentChange
					onSubmit={() => this.setState({ showDepartmentChange: false })}
					onClose={() => this.setState({ showDepartmentChange: false })}
					onDelete={() => this.setState({ showDepartmentChange: false })}
				/>
			);
		}
	}

	renderChangeModal() {
		const { showChange } = this.state;
		const { store_id } = this.props.match.params;
		const { selected_support, visuals, filter, campaigns, store } = this.props.store_validate_images_state;

		var selectedImage = null;
		if(selected_support){
			selectedImage = (this.props.store_validate_images_state.visuals).find(visual => visual.image === selected_support?.new_image);
		}

		if(selectedImage === undefined) selectedImage = null;

		const get_visuals = (campaign_id?: string) => {
			if (campaign_id) {
				return visuals.filter(visual => visual.campaign_id === campaign_id);
			}
			return visuals;
		}

		if (showChange) {
			return (
				<ChangeImage
					onSubmit={(selectedImage: Visual | null) => {
						this.setState({ loadingFromPop: true });
						if( selectedImage && selected_support !== null ){
							(assign_visual_to_support(selectedImage.id, selected_support.id)).then(() => {
								this.setState({ showChange: false });
								this.props.getData(store_id, selected_support.id);
								this.setState({ loadingFromPop: false });
							});
						}
					}}
					onClose={() => this.setState({ showChange: false })}
					onSpecialRequest={() => this.setState({ showChange: false, showSpecialRequest: true })}
					visuals={get_visuals(store?.current_campaign?.id)}
					filter={{ ...filter, campaign: store?.current_campaign?.id }}
					sections={ this.props.datatypes_state.departments }
					campaigns={campaigns.map(campaign => ({
						name: campaign.title,
						value: campaign.id
					  })) 
					}
					selectedImage={ selectedImage }
					selectedSupport={ selected_support }
				/>
			);
		}
	}

	renderSpecialRequestModal() {
		const { showSpecialRequest } = this.state;
		const { selected_support, store } = this.props.store_validate_images_state;
		const { store_id } = this.props.match.params;
		const current_campaign = store?.current_campaign;
		const { production_languages } = this.props.data_types_state;
		
		if (showSpecialRequest && current_campaign && selected_support) {
			return (
				<SpecialRequest 
					onSubmit={(specialRequest: SpecialRequestRequest) => {
						this.setState({ showSpecialRequest: false });
						this.setState({ loadingFromPop: true })

						special_request(current_campaign.id, selected_support.id, specialRequest).then(() => {
							this.props.getData(store_id, selected_support.id);
							this.setState({ loadingFromPop: false });
						});
					}}
					floors_options={store?.floors}
					production_languages={production_languages}
					onClose={() => this.setState({ showSpecialRequest: false })}
				/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;
		const { store,kendu_processing  } = this.props.store_validate_images_state;
		
		var statusString = ""
        if (store?.current_campaign?.status_primark) {
            statusString= store?.current_campaign?.status_primark.toLowerCase()
            if (statusString === "kendu_processing") { // KENDU_PROCESSING => RATIO_PROCESSING
                statusString = t("ratio_processing")
            } else {
                statusString = t(statusString)
            }
		}
		

		let pending_cm_without_image=store?.supports?.filter((support)=>support.status_primark===SupportStatusPrimark.PENDING_APPROVAL_CM && support.new_image==null).length;
		
		return (
			<ContentTop>
				<article className="detail-top">
					<div className="detail-top--title">
						<BackArrow onClick={() => this.props.history.push(`/stores/${this.props.match.params.store_id}`)} />
						<div className="title-detail">
							<h2>{ store?.current_campaign?.title } <span className="state-info-box">{ statusString }</span></h2>
							<p>{ start_end_date(store?.current_campaign) }</p>
						</div>
					</div>
					{store?.supports?.length===0&&
					<div className="detail-top--title">
						<section className={`pending-notification pending-notification--red`}>
            				<article className="pending-info">
                    			<h2>{ t('store_no_frames_validating') }</h2>
                			</article>
            			</section>
					</div>}
					
					<div className="detail-top--actions">
						<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.PENDING_APPROVAL_SM]}>
							<RoleGuard roles={[Role.STORE_MANAGER,Role.ADMIN]}>
								{store?.current_campaign?.status_primark.toLowerCase() === "salespoint_validating" ? 						
									<PrimaryButton  disabled={store?.supports?.some(support => [SupportStatusPrimark.PENDING_APPROVAL, SupportStatusPrimark.KENDU_PROCESSING].includes(support.status_primark)) || kendu_processing===true} 
									onClick={() => this.setState({ showConfirmationPopUp: true }) }
									aria_label={store?.supports?.some(support => [SupportStatusPrimark.KENDU_PROCESSING].includes(support.status_primark)) || kendu_processing===true? t('kendu_is_processing_frames'):''}
									data_ballon_pos={!store?.supports?.some(support => [SupportStatusPrimark.PENDING_APPROVAL].includes(support.status_primark)) && kendu_processing===true? 'up':''}
									>{ t('approve store') }</PrimaryButton>
									: null}							
							</RoleGuard>
						</StatusGuard>

						<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.PENDING_SIGN_OFF_CM]}>
							<RoleGuard roles={[Role.COUNTRY_MANAGER, Role.HEAD_VM, Role.ADMIN]}>
							<PrimaryButton disabled={kendu_processing === true ||(pending_cm_without_image!==undefined && pending_cm_without_image>0)} onClick={() => this.setState({ showConfirmationPopUp: true })}>
							  {t('sign off store')}
							</PrimaryButton>
							</RoleGuard>
						</StatusGuard>
					</div>
				</article>
			</ContentTop>
		);
	}
	

	renderNotifications() {
		const { t } = this.props;
		const { store } = this.props.store_validate_images_state;

		return (<>
			{store?.supports?.some(support => support.status_primark === SupportStatusPrimark.KENDU_PROCESSING) && <Notification title={t('kendu_proccessing_notification')} />}
		</>);
	}

	selectedSupportIndex() {
		const { store, selected_support } = this.props.store_validate_images_state;
		return store?.supports?.findIndex(support => support.id === selected_support?.id) || 0;
	}

	clickNextImage() {
		const { store, selected_support } = this.props.store_validate_images_state;

		if (store && store.supports && selected_support) {
			const index = store.supports.indexOf(selected_support); 
			this.props.selectSupport(store.supports[index + 1]);
		}
	}

	clickPreviousImage() {
		const { store, selected_support } = this.props.store_validate_images_state;

		if (store && store.supports && selected_support) {
			const index = store.supports.indexOf(selected_support); 
			this.props.selectSupport(store.supports[index - 1]);
		}
	}

	validateImage() {
		const { store, selected_support } = this.props.store_validate_images_state;
		
		
 			if (store && selected_support) {
				validate_support_image(selected_support.id).then((result) => {
					if (result && store.supports) {
						const index = store.supports.indexOf(selected_support); 
						if ( index === (store?.supports?.length || 1) - 1 	){ 
							this.props.getData(store.id, selected_support.id);
						}else{
						const next_support = store.supports[index + 1]
						this.props.getData(store.id, next_support.id);

						}
					}
				});
			} 
		
		
	}

	renderStatusChangeConfirmationPop(){
		const { t } = this.props;
		const { store } = this.props.store_validate_images_state;
		const { showConfirmationPopUp } = this.state;
		var change_text = t("store_passing_to_the_next_state");
		
		if(store){
			const next_status_index = (Object.values(StoreStatusPrimark)).indexOf(store.status_primark) + 1;
			
			if(next_status_index in Object.values(StoreStatusPrimark))
			{
				let fromStatus=t(store.status_primark.toString().toLowerCase());
				let newStatus = t(Object.values(StoreStatusPrimark)[next_status_index].toString().toLowerCase());

				change_text = t("Store_passing_from_state_{{statusFrom}}_to_state_{{statusTo}}", {
					statusFrom: fromStatus as string,
					statusTo: newStatus,
				  })
			}

			if((store.status_primark as string) === StoreStatusPrimark.PENDING_SIGN_OFF_CM){
				change_text += " " + t("this_will_validate_all_supports_automatically");
			}
		}

		if(showConfirmationPopUp){
			return(
				<ConfirmationPopUp 
					onClose={() => this.setState({ showConfirmationPopUp: false })}
					onSubmit={() => {
						this.setState({ showConfirmationPopUp: false })
						this.setState({ loadingFromPop: true })
						this.changeStatus()
					}}
					title={t("confirm_store_status_change")}
					change_text={change_text}
				/>
			);
		}
	}

	changeStatus(){
		const { store } = this.props.store_validate_images_state;
		if(store){
			if(store.status_primark === StoreStatusPrimark.PENDING_APPROVAL_SM){
				this.approveStore();
			}else if(store.status_primark === StoreStatusPrimark.PENDING_SIGN_OFF_CM){
				this.signOffStore();
			}
		}
	}

	approveStore() {
		const { store } = this.props.store_validate_images_state;

		if (store) {
			approve_store_sm(store.id).then((result) => {
				if (result) {
					this.setState({ loadingFromPop: false })
					this.props.getData(store.id);
				}
			});
		}
	}

	signOffStore(){
		const { store } = this.props.store_validate_images_state;
		if (store) {
			store_sign_off(store.id).then((result) => {
				if (result) {
					this.setState({ loadingFromPop: false })
					this.props.getData(store.id);
				}
			});
		}
	}

	renderBody() {
		const { t } = this.props;
		const { store, selected_support } = this.props.store_validate_images_state;
		const index = this.selectedSupportIndex();
		const is_last = index === (store?.supports?.length || 1) - 1;
		const is_first = index === 0;
		
		return (
			<section id="validation-content">
				<section className="work-section">
					<section className="work-header">
						<h4>{selected_support?.no_soporte_cliente}</h4>
						<div className="section-actions">
							<p>{ index + 1 }/{ store?.supports?.length }</p>
							<ul>
								<li>
									<button disabled={is_first} onClick={() => this.clickPreviousImage()}><ChevronLeft /></button>
								</li>
								<li>
									<button disabled={is_last} onClick={() => this.clickNextImage()}><ChevronRight /></button>
								</li>
							</ul>
						</div>
					</section>

					<section className="new">
						<h5>{ t('New image on campaign') }</h5>
						<article className="card-validation">
							<div className="card-image cursor-hand image-covered" onClick={() => this.setState({ previewImage: { image: selected_support?.new_image || '' } as Visual })}>
								<img alt="" src={ selected_support?.new_image }/>
							</div>
							<div className="card-content">
								<h3>{selected_support?.new_image_name}</h3>
							</div>
						</article>
					</section>

					<section className="validation-actions mb-16">
						<StatusGuard status={selected_support?.status_primark} statuses={[SupportStatusPrimark.PENDING_APPROVAL, SupportStatusPrimark.APPROVED_BY_SM]}>
							<RoleGuard roles={[Role.STORE_MANAGER,Role.ADMIN]}>
							<PrimaryButton onClick={() => this.setState({ showChange: true })} style={ PrimaryButtonStyle.OUTLINED }>{ t('change') }</PrimaryButton>
							</RoleGuard>
						</StatusGuard>
						<StatusGuard status={selected_support?.status_primark} statuses={[SupportStatusPrimark.PENDING_APPROVAL_CM]}>
							<RoleGuard roles={[Role.COUNTRY_MANAGER, Role.HEAD_VM,Role.ADMIN]}>
							<PrimaryButton onClick={() => this.setState({ showChange: true })} style={ PrimaryButtonStyle.OUTLINED }>{ t('change') }</PrimaryButton>
							</RoleGuard>
						</StatusGuard>
						<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.PENDING_APPROVAL_SM]}>
							<RoleGuard roles={[Role.STORE_MANAGER,Role.ADMIN]}>
								<PrimaryButton disabled={selected_support?.status_primark !== SupportStatusPrimark.PENDING_APPROVAL || selected_support?.new_image=== undefined} onClick={() => this.validateImage()}>{ t('validate') }</PrimaryButton>
							</RoleGuard>
						</StatusGuard>
					</section>

					<section className="current">
						<h5>{ t('Actual image on store') }</h5>
						<article className="card-validation">
							<div className="card-image cursor-hand image-covered" onClick={() => this.setState({ previewImage: { image: selected_support?.current_image || '' } as Visual })}>
								<img alt="" src={ selected_support?.current_image }/>
							</div>
							<div className="card-content">
								<h3>{ selected_support?.current_image_name }</h3>
							</div>
						</article>
					</section>
				</section>

				<Floorplan floor={store?.floors.find(floor => floor.entry_no === selected_support?.floor_id)} 
							opennedSupport={selected_support!} 
							supports={store?.supports?.filter(support => support.floor_id === selected_support?.floor_id)} 
							editMode={false}
							isValidate={true}
							onAddCampaignClick={(support) => {
								add_support_to_campaign(support.id).then(() => {
									if (store) {
										this.props.getData(store.id,support.id);
									}
								});
							}}
							
							onValidateClick={(support) => {
								if(support.new_image!==undefined){
									validate_support_image(support.id).then(() => {
										if (store) {
											this.props.getData(store.id,support.id);
										}
									});	
								}
								
							}}
							selectedSupport={selected_support}
							onSupportSelected={()=>{}}
							store={store}
							sections={ this.props.datatypes_state.departments }
				/>
			</section>
		);
	}

	render () {
		const { t } = this.props;
		const { loadingFromPop } = this.state;
		const { loading } = this.props.store_validate_images_state;

		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		if(loadingFromPop){
			return (
				<Spinner message={ t('change_image_loading') } />
			);
		}

		return (
			<>
				{this.renderEstimatedModal()}
				{this.renderViewImageModal()}
				{this.renderChangeModal()}
				{this.renderAddSupportModal()}
				{this.renderDepartmentChangetModal()}
				{this.renderSpecialRequestModal()}
				{this.renderContentTop()}
				{this.renderNotifications()}
				{this.renderBody()}
				{this.renderStatusChangeConfirmationPop()}
			</>
		);
	}
}

export default withTranslation()(connector(ValidateImages));
