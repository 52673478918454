import { Country, SelectOption, User } from "../../interfaces/interfaces";

export const UPDATE_COUNTRIES = 'update_countries';
export const UPDATE_SUPPORT_TYPES = 'update_support_types';
export const UPDATE_TEXTIL_TYPES = 'update_textile_types';
export const UPDATE_SUPPORT_STATUSES = 'update_support_statuses';
export const UPDATE_MAP_DISPOSITION_TYPES = 'update_map_disposition_types';
export const UPDATE_DEPARTMENTS = 'update_departments';
export const UPDATE_PRODUCTION_LANGUAGES = 'update_production_languages';
export const UPDATE_LANGUAGES = 'update_languages';
export const UPDATE_COUNTRY_MANAGERS = 'update_country_managers';

type ActionType = typeof UPDATE_SUPPORT_TYPES | typeof UPDATE_TEXTIL_TYPES | typeof UPDATE_SUPPORT_STATUSES | typeof UPDATE_MAP_DISPOSITION_TYPES | typeof UPDATE_DEPARTMENTS| typeof UPDATE_PRODUCTION_LANGUAGES | typeof UPDATE_LANGUAGES | typeof UPDATE_COUNTRY_MANAGERS;

export interface UpdateDatatypesAction {
    type: ActionType,
    payload: SelectOption[]
}

export interface UpdateCountriesAction {
    type: typeof UPDATE_COUNTRIES,
    payload: Country[]
}

export interface UpdateLanguagesAction {
    type: typeof UPDATE_LANGUAGES,
    payload: SelectOption[]
}

export interface UpdateCountryManagersAction {
    type: typeof UPDATE_COUNTRY_MANAGERS,
    payload: User[]
}

export type DatatypesActions = UpdateDatatypesAction | UpdateCountriesAction | UpdateLanguagesAction | UpdateCountryManagersAction;