import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_REPEAT = 'UPDATE_PASSWORD_REPEAT';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const UPDATE_SIGN_UP_ERROR = 'UPDATE_SIGN_UP_ERROR';

export interface UpdateUserAction {
    type: typeof UPDATE_USER,
    payload: string
}

export interface UpdateUserErrorAction {
    type: typeof UPDATE_USER_ERROR,
    payload: string
}

export interface UpdatePasswordAction {
    type: typeof UPDATE_PASSWORD,
    payload: string
}

export interface UpdatePasswordRepeatAction {
    type: typeof UPDATE_PASSWORD_REPEAT,
    payload: string
}

export interface UpdatePasswordErrorAction {
    type: typeof UPDATE_PASSWORD_ERROR,
    payload: string
}

export interface UpdateSignUpErrorAction {
    type: typeof UPDATE_SIGN_UP_ERROR,
    payload: string
}

export type SetingsActions =
UpdateUserAction |
UpdateUserErrorAction |
UpdatePasswordAction |
UpdatePasswordRepeatAction |
UpdatePasswordErrorAction |
UpdateSignUpErrorAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>