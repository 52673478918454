import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PillColorStyle } from '../interfaces/interfaces';

export interface PillProps {
    color?: PillColorStyle,
    onClick?: () => void,
}

type PillPropsSum = PillProps & WithTranslation;

class PillComponent extends React.Component<PillPropsSum> {

    render () {
        const { color, onClick } = this.props;
        return (
            <button className={`${color} ${onClick ? 'hover-pill' : ''}`} onClick={onClick}>
                {this.props.children}
            </button>
        );
    };
}

const Pill = withTranslation()(PillComponent);

Pill.defaultProps = {
    color: PillColorStyle.GREY,
}
export { Pill };
