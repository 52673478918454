import React from 'react';
import Dropzone, { IDropzoneProps, IExtra, IFileWithMeta, StatusValue, getFilesFromEvent } from 'react-dropzone-uploader';
import { Plus } from 'react-feather';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface DropzoneProps {
	onUploadCompleted?: () => void
	onUploadStarted?: () => void
	url: string
	token: string
	section: string
	max_files?: number
}

type Props = DropzoneProps & WithTranslation;

interface State {
	globalDropzoneProps: IDropzoneProps;
	max_files_disabled: boolean;
}

class DropzoneComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            globalDropzoneProps: {
                ...Dropzone.defaultProps,
				styles: {
					dropzone: { borderRadius:0, borderWidth: 0, borderStyle: 'dashed', borderColor: '#d5d9e4', overflow: 'hidden', width: '100%', height: '100%' },
					dropzoneReject: { borderColor: 'red', backgroundColor: 'red' },
					dropzoneActive: { borderColor: '#00a3da', backgroundColor: '#00a3da'},
					inputLabel: { color: '#00a3da', fontFamily: 'Inter', fontSize: '1.6rem' },
					inputLabelWithFiles: { display: 'none' },
					input: { display: 'none' }
				},
				accept: "application/pdf, image/*",
				multiple: true,
				minSizeBytes: 0,
				maxSizeBytes: 1024 * 1024 * 150,
				maxFiles: this.props.max_files ? Math.min(this.props.max_files,10) : 10,
				autoUpload: true,
				inputWithFilesContent: null,
				submitButtonDisabled:true,
				canCancel : false,
				canRemove: false
			},
			max_files_disabled: false
        }
	}

	getUploadParams(file : Blob) { 
		const { url , token } = this.props;

		const body = new FormData()
		body.append('image', file)

		return { 
			url,
			body, 
			headers: {
				Authorization: `Bearer ${token}` 
			}
		} 
	}
	
	handleChangeStatus(file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) {
		if (status === 'uploading') {
			this.props.onUploadStarted?.();
		}
		if (status === 'done') {
			const allFilesUploaded = allFiles.every(file => file.meta.status === 'done');

			if (allFilesUploaded){
				this.props.onUploadCompleted?.();
			}
		}
	}

	inputContent(files: IFileWithMeta[], extra: IExtra) {
		const { t } = this.props;
		const { maxFiles } = this.state.globalDropzoneProps;

		if (extra.dragged.length > maxFiles) {
			return this.renderInputContent('red',t('max_files_allowed'))
		}
		if (this.state.max_files_disabled) {
			return this.renderInputContent('red',t('max_files_allowed'))
		}
		if (extra.reject) {
			return this.renderInputContent('#959DA5', t("only_file_type_allowed"))
		}
		return this.renderInputContent('#959DA5',t("start_dragging_or_attaching_the_images"))
	}

	renderInputContent(color: string, text: string ){
		return (
				<div key="1"> {/* TODO CHRISTIAN: la preview de imagenes al subir: ( empequeñecer las imagenes) y alinearla con la barra de progreso (clases dzu-previewContainer , dzu-previewImage ) */}
                    <article className="drag-and-drop-content draggable-center" > {/* TODO CHRISTIAN: esta capa tiene que ocupar todo el tamaño de la capa del padre para poder hacer drag and drop bien, si pongo width y height al 100% luego hay que centrar el texto, poner tambien el cursor bonito) y el simbolo "+" en gris*/}
                        <div className="drag-and-drop-element"> 
                            <Plus />
                        </div>
                        <div className="drag-and-drop-element">
							<p style={{color: color}}>{ text }</p>
                        </div>
                    </article>
				</div>
		)
	}

	getFilesFromEvent(event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>): Promise<File[]> {
		const { maxFiles } = this.state.globalDropzoneProps;

		return new Promise((resolve, reject) => {
			const files = getFilesFromEvent(event);

			if (files.length > maxFiles) {
				this.setState({ max_files_disabled: true });

				resolve([]);

				setTimeout(() => { this.setState({ max_files_disabled: false }) }, 1500);
			} else {
				resolve(files as File[]);
			}
		})
	}


    render () {
		const { globalDropzoneProps } = this.state;
		const { section } = this.props;

        return (
			<article className="drag-and-drop-module">
				<div className="drag-and-drop-dashed">
					<Dropzone
						key= { section }
						{...globalDropzoneProps}
						getUploadParams={({file}) => this.getUploadParams(file)}
						disabled={(files, extra) => this.state.max_files_disabled || extra.dragged.length > globalDropzoneProps.maxFiles }
						onChangeStatus={(file, status, allFiles) => this.handleChangeStatus(file, status, allFiles)}
						inputContent={(files, extra) => this.inputContent(files, extra) }
						getFilesFromEvent={event => this.getFilesFromEvent(event)}
					 />
				</div>
			</article>
        );
    };
}


const DropzoneWrapper = withTranslation()(DropzoneComponent);
export { DropzoneWrapper };