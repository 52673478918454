import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Filter as FilterIcon } from 'react-feather';
import { SecondaryButton } from '.';
import { SecondaryButtonStyle, ActivityFilter as FilterInterface } from "../interfaces/interfaces";

export interface ActivityFilterRowProps {
    filter: FilterInterface,
    pluralizeStrings: string[],
    onFilterClicked: () => void,
}

type ActivityFilterRowPropsSum = ActivityFilterRowProps & WithTranslation;

class ActivityFilterRowComponent extends React.Component<ActivityFilterRowPropsSum> {

    is_filtered(filter: any): boolean {
        return Object.keys(filter).some(key => {
          if (key === 'page') {
            return false; // Excluir la propiedad 'page'
          }
          return filter[key] && filter[key] !== null && filter[key] !== '';
        });
      }

    render () {
        const { t, filter, onFilterClicked,  } = this.props;

       

        return (
            <article>
                
                <div className="filters">
                    <SecondaryButton onClick={() => onFilterClicked()} style={this.is_filtered(filter) ? SecondaryButtonStyle.YELLOW : SecondaryButtonStyle.PRIMARY}>
                        <FilterIcon />
                        <span>{t('filter')}</span>
                    </SecondaryButton>
                </div>
            </article>
        );
    }

}

const ActivityFilterRow = withTranslation()(ActivityFilterRowComponent);
export { ActivityFilterRow };
