import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Plus, Lock, ZoomIn, ZoomOut, Unlock } from 'react-feather';
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import { Floor, SelectOption, Store, Support, SupportStatusPrimark } from '../interfaces/interfaces';
import { SvgSupport } from './SvgSupport';
import { applyToPoint, fromObject, inverse } from 'transformation-matrix';
import { SupportInfoWindow } from './SupportInfoWindow';
import { RoleGuard } from './RoleGuard';
import { Role } from '../util/jwt';

export interface FloorplanProps {
    floor: Floor | undefined,
    addSupportClicked?: (centerX: number, centerY: number) => void,
    onSupportsMove?: (supports: Support[]) => void,
    onValidateClick?: (support: Support) => void,
    onAddCampaignClick?: (support: Support) => void,
    onEditSupportClicked?: (support: Support, supports: Support[]) => void,
    onSupportSelected: (support: Support|null)=>void,
    supports?: Support[],
    opennedSupport?: Support,
    editMode: boolean,
    store?: Store | null,
    sections?: SelectOption[],
    initialSupport?: string | null,
    initialFloor?: string | null,
    role?: Role,
    selectedSupport?: Support | null,
    isValidate: boolean
}

type FloorplanPropsSum = FloorplanProps & WithTranslation;

interface State {
    imageWidth: number,
    imageHeight: number,
    componentWidth: number,
    editing: boolean,
    selectedSupport: Support|null,
    changedSupports: Support[],
    zoom?: number,
}

const MAX_ZOOM = 1.5;

class FloorplanComponent extends React.Component<FloorplanPropsSum, State> {

    private panZoomRef: any;
    private panContainerRef: any;

    constructor(props: FloorplanPropsSum) {
        super(props);

        this.panZoomRef = React.createRef();
        this.panContainerRef = React.createRef();

        this.state = {
            imageWidth: 0,
            imageHeight: 0,
            componentWidth: 0,
            editing: false,
            selectedSupport: null,
            changedSupports: []
        };
    }

    componentDidMount() {
        this.updateComponentWidth();
        window.addEventListener('resize', this.updateComponentWidth);
        const { initialSupport, supports } = this.props
        if (initialSupport) {
            const support = supports?.find(support => support.id === initialSupport)
            if (support !== undefined) {
                this.props.onSupportSelected(support);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateComponentWidth);
    }

    updateComponentWidth = () => {
        this.setState({ componentWidth: this.panContainerRef.current.clientWidth - 100 });
    }

    getImageWidth() {
        const { imageWidth } = this.state;
        return imageWidth || 1000;
    }

    getImageHeight() {
        const { imageHeight } = this.state;
        return imageHeight || 500;
    }

    handleImageLoaded(image: string) {
        var img = new Image();
        img.src = image;
        img.onload = () => {
            let new_height=(800*img.height)/img.width;
            this.setState({
                imageWidth: 800,
                imageHeight: new_height
            });
            this.panZoomRef.current.fitToViewer('center', 'center');
        };
    }

    renderEditionTools(){
        if(this.props.editMode){
            return (
                <ul>
                    <RoleGuard not={true} roles={[Role.STORE_MANAGER]}>
                        <li>
                            <button onClick={() => {
                                const centerPoint = this.getSVGPoint(this.state.componentWidth / 2, 600 / 2);
                                this.props.addSupportClicked?.(centerPoint.x, centerPoint.y);
                            }}><Plus /></button>
                        </li>
                    </RoleGuard>
                    <li>
                        <button style={{ backgroundColor: this.state.editing ? '#00B4D9' : '#001114'}} onClick={() => {
                            if (this.state.editing) {
                                this.props.onSupportsMove?.(this.state.changedSupports);
                            }
                            this.setState({ editing: !this.state.editing })
                        }}>{this.state.editing ? <Unlock /> : <Lock /> }</button>
                    </li>
                </ul>
            );
        }else{
            return (
                <></>
            );
        }
    }

    renderToolbar() {
        return (
            <section className="context-menu">
                {this.renderEditionTools()}
                <ul>
                    <li>
                        <button onClick={() => this.panZoomRef.current.zoomOnViewerCenter(1.2)}><ZoomIn /></button>
                    </li>
                    <li>
                        <button onClick={() => this.panZoomRef.current.zoomOnViewerCenter(0.8)}><ZoomOut /></button>
                    </li>
                </ul>
            </section>
        );
    }

    getSVGPoint(viewerX: number, viewerY: number) {
        let matrix = fromObject(this.panZoomRef.current.Viewer.getValue());
      
        let inverseMatrix = inverse(matrix);
        return applyToPoint(inverseMatrix, {x: viewerX, y: viewerY});
    }

    onSupportClick = (support: Support, {x, y}: {x: number, y: number}) => {
        const {selectedSupport}=this.props;
        if (this.props.editMode) {
            if (support.id === selectedSupport?.id) {
                this.props.onSupportSelected(null);
            } else {
                this.props.onSupportSelected(support);
                
            }
        }
    }

    onSupportMove = (changedSupport: Support, x: number, y: number) => {
        const supports = () => {
            if (this.state.changedSupports.some(support => support.id === changedSupport.id)) {
                return this.state.changedSupports.map(support => {
                    if (support.id === changedSupport.id) {
                        return { ...changedSupport, map_x: x, map_y: y };
                    }
                    return support;
                });
            } else {
                return [...this.state.changedSupports, { ...changedSupport, map_x: x, map_y: y }];
            }
        };

        this.setState({
            changedSupports: supports()
        })
    }

    render () {
        const { floor, opennedSupport, sections, role,selectedSupport } = this.props;
        const { componentWidth, editing, zoom } = this.state;
        const selectedSupportToOpen = opennedSupport || selectedSupport;

        return (
            <section className="floor-plan">
                <article id="plan" ref={ this.panContainerRef }>
                    <UncontrolledReactSVGPanZoom
                        ref={ this.panZoomRef }
                        width={ componentWidth }
                        height={ 600 }
                        customToolbar={() => this.renderToolbar()}
                        background="white"
                        detectWheel={ false }
                        detectAutoPan={ false }
                        customMiniature={() => null}
                        onClick={() => this.props.onSupportSelected(null)}
                        onZoom={(e: any) => this.setState({ zoom: e.a})}
                        tool="auto">
                        <svg width={ this.getImageWidth() } height={ this.getImageHeight() }>
                            <image xlinkHref={ floor?.map_url } onLoad={() => this.handleImageLoaded(floor?.map_url || '')} />
                            {this.props.supports?.map((support) =>
                                <SvgSupport 
                                    support={support} 
                                    key={support.id} 
                                    draggable={editing} 
                                    disabled={( support.status_primark === SupportStatusPrimark.NOT_IN_CAMPAIGN && ( selectedSupportToOpen === null || selectedSupportToOpen?.id !== support.id ) ) } 
                                    selected={ selectedSupportToOpen !== null && selectedSupportToOpen?.id === support?.id }
                                    zoom={zoom && zoom > MAX_ZOOM ? zoom * 1/MAX_ZOOM : 1}
                                    onSupportClick={this.onSupportClick} 
                                    onSupportMove={this.onSupportMove} />)}
                        </svg>
                    </UncontrolledReactSVGPanZoom>
                                    
                    { selectedSupportToOpen && <SupportInfoWindow floor_name={floor?.name} store={this.props.store} support={selectedSupportToOpen} onValidateClick={this.props.onValidateClick} onAddCampaignClick={this.props.onAddCampaignClick} onEditClick={this.props.onEditSupportClicked} changedSupports={this.state.changedSupports} editing={editing} sections={sections} role={role} isValidate={this.props.isValidate}/> }
                </article>
            </section>
        );
    };
}

const Floorplan = withTranslation()(FloorplanComponent);
export { Floorplan };