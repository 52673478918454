import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Spinner, ContentTop, StoreCard, PrimaryButton, Filter, EditStore, FilterRow } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getStores, updateFilter, clearFilter } from './StoreIndexActions';
import { getAllLanguages, getCountries, getProductionLanguages, getCountryManagers } from '../../../reducers/datatypes/DatatypesActions';
import { FilterFields, Store } from '../../../interfaces/interfaces';
import { country_map } from '../../../util/select_maps';
import { create_store } from '../../../api';
import { Role } from '../../../util/jwt';
import { RoleGuard } from '../../../components/RoleGuard';


const mapStateToProps = (state: RootState) => {
	return {
		store_index_state: state.storeIndex,
		data_types_state: state.datatypes,
	}
}

const connector = connect(mapStateToProps, {
	getStores,
	updateFilter,
	clearFilter,
	getCountries,
	getProductionLanguages,
	getAllLanguages,
	getCountryManagers
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showFilter: boolean,
	showCreate: boolean,
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showFilter: false,
			showCreate: false
		};
	}

	componentDidMount() {
		this.props.getStores();
		this.props.getCountries();
		this.props.getProductionLanguages();
		this.props.getAllLanguages();
		this.props.getCountryManagers();
	}

	renderFilter() {
		const { showFilter } = this.state;
		const { filter } = this.props.store_index_state;
		const { countries, country_managers } = this.props.data_types_state;


		if (showFilter) {
			return (
				<Filter
					filterFields={ FilterFields.STORE }
					filter={ filter }
					countryManagers = { country_managers }
					countries={ countries.map(country_map) }
					onClose={() => this.setState({ showFilter: !showFilter })}
					onClear={() => {
						this.props.clearFilter();
						this.props.getStores();
						this.setState({ showFilter: !showFilter })
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.getStores();
						this.setState({ showFilter: !showFilter });
					}}
				/>
			);
		}
	}

	renderCreate() {
		
		const { showCreate } = this.state;
		const { countries,languages } = this.props.data_types_state;

		if (showCreate) {
			return (
				<EditStore
					countries={ countries.map(country_map) }
					languages={ languages }
					onClose={() => this.setState({ showCreate: false })}
					onSubmit={(store: Store) => {
						create_store(store).then(() => {
							this.setState({ showCreate: false })
							this.props.getStores();
						}).catch(err => console.error);
					}
				}
					
				/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;
		const { showFilter, showCreate } = this.state;
		const { stores, filter, totalStores } = this.props.store_index_state;
		return (
			<ContentTop>
				<article>
					<div>
						<h2>{ t('stores') }</h2>
					</div>
					<div>
						<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
							<PrimaryButton onClick={() => this.setState({ showCreate: !showCreate })}>
								{t('create_store') }
							</PrimaryButton>
						</RoleGuard>
					</div>
				</article>
				<FilterRow
					filter={filter}
					results={stores.length || 0}
					totalResults={totalStores.length || 0}
					pluralizeStrings={[t('result_found'), t('results_found')]}
					onFilterClicked={() => this.setState({ showFilter: !showFilter })}
				/>
			</ContentTop>)
	}

	renderStores() {
		const { stores } = this.props.store_index_state;
		return (
			<ul>
				{stores.sort((a, b) => Number(a.customer_shop_no) > Number(b.customer_shop_no) ? 1 : -1).map(store => (
					<StoreCard key={store.id} store={store} />
				))}
			</ul>
		)
	}

	render () {
		const { t } = this.props;
		const { loading } = this.props.store_index_state;

		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}

		return (
			<>
				{this.renderFilter()}
				{this.renderCreate()}
				{this.renderContentTop()}
				<section id="listPills">
					{this.renderStores()}
				</section>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
