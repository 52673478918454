import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Spinner, ContentTop, PrimaryButton, UserFilter, UserFilterRow, EditProfile, ConfirmationPopUp } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getUsers, updateFilter, clearFilter, getStores } from './UserIndexActions';
import { RouteComponentProps } from 'react-router-dom';
import { User } from '../../../interfaces/interfaces';
import { Role, get_roles } from '../../../util/jwt';
import { create_user, edit_user, delete_user, request_password } from '../../../api';
import { RoleGuard } from '../../../components/RoleGuard';


const mapStateToProps = (state: RootState) => {
	return {
		user_index_state: state.userIndex,
		data_types_state: state.datatypes,
	}
}

const connector = connect(mapStateToProps, {
	getUsers,
	getStores,
	updateFilter,
	clearFilter
});

interface ParamTypes {
	id: string
}

type Props = ConnectedProps<typeof connector> & RouteComponentProps<ParamTypes> & WithTranslation;

interface State {
	user?: User,
	showFilter: boolean,
	showCreate: boolean,
	showConfirmation: boolean,
	userToDelete: User | null,
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showFilter: false,
			showCreate: false,
			user: undefined,
			showConfirmation: false,
			userToDelete: null,
		};
	}

	componentDidMount() {
		this.props.getUsers();
		this.props.getStores();
	}

	renderFilter() {
		const { showFilter } = this.state;
		const { filter } = this.props.user_index_state;
		const { stores } = this.props.user_index_state;
		const {t}=this.props;
		if (showFilter) {
			return (
				<UserFilter
					filter={ filter }
					stores={ stores.map((store) => {
						return {value: store.id, name: store.name + " - " + store.customer_shop_no}
					}) }
					roles={get_roles().map((role) => {
						return {value: role, name: t(role)}
					})}
					onClose={() => this.setState({ showFilter: !showFilter })}
					onClear={() => {
						this.props.clearFilter();
						this.props.getUsers();
						this.setState({ showFilter: !showFilter })
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.getUsers();
						this.setState({ showFilter: !showFilter });
					}}
				/>
			);
		}
	}

	sendChangePw(email: string) {
		const { t } = this.props;
        request_password(email,t('create_password_subject'),t('create_password_mail_title'),t('create_password_mail_text'),[ t('create_password') ], true)
	}

	renderCreate() {
		
		const { showCreate, user } = this.state;
		const { stores } = this.props.user_index_state;
		const {t}=this.props;
		let user_new_edit='';
		const editUser = user;
		if(editUser===undefined){
			user_new_edit='CREATE';
			
		}else{
			user_new_edit='UPDATE';
		}
		
		if (showCreate) {
			return (
				<EditProfile 
				    user={ editUser }
					roles={get_roles().map((role) => {
						return {value: role, name: t(role)}
					})}
					user_new_edit={ user_new_edit }
					stores={stores.map((store) => {
						return {value: store.id, name: store.name + " - " + store.customer_shop_no }
					})}
					onClose={() => this.setState({ showCreate: false })}
					onSubmit={(user: User) => {
						if (editUser !== undefined) {
							edit_user(user).then(() => {
								this.setState({ showCreate: false })
								this.props.getUsers();
							}).catch(err => console.error);
						} else {
							create_user(user).then(() => {
								this.setState({ showCreate: false })
								this.props.getUsers();
								this.sendChangePw(user.email)
							}).catch(err => console.error);
						}}
					}
					onDelete={(user: User) => {
						this.setState({ showConfirmation: true, userToDelete: user })
					}}				

				/>
			);
		}
	}

	renderDeleteConfirmationPopup() {
		const { t } = this.props
		const { showConfirmation } = this.state
		if (showConfirmation)
		return (
			<ConfirmationPopUp
				onClose={() => this.setState({ showConfirmation: false, userToDelete: null }) }
				onSubmit={() => {
					const { userToDelete } = this.state
					if (userToDelete !== undefined) {
						delete_user(userToDelete!).then(() => {
							this.setState({ showCreate: false, showConfirmation: false, userToDelete: null })
							this.props.getUsers();
						}).catch(err => console.error);
					}
					
				}}
				title={t('delete_user')}
				change_text={t('delete_user_change_text')}
			/>
		);
	}

	renderContentTop() {
		const { t } = this.props;
		const { showFilter, showCreate } = this.state;
		const { users, filter } = this.props.user_index_state;

		var filteredUsers: User[] = []
		if (filter !== undefined) {
			filteredUsers = users.filter(user => this.filterUser(user))
		} else {
			filteredUsers = users
		}

		return (
			<ContentTop>
				<article>
					<div>
						<h2>{ t('users') }</h2>
					</div>
					<div>
						<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
							<PrimaryButton onClick={() => this.setState({ showCreate: !showCreate, user: undefined })}>
								{t('create_user') }
							</PrimaryButton>
						</RoleGuard>
					</div>
				</article>
				<UserFilterRow
					filter={filter}
					results={filteredUsers.length || 0}
					totalResults={users.length || 0}
					pluralizeStrings={[t('result_found'), t('results_found')]}
					onFilterClicked={() => this.setState({ showFilter: !showFilter})}
				/>
			</ContentTop>)
	}

	filterUser(user: User) {
		const { filter } = this.props.user_index_state;

		var toReturn = true

		if (filter.name && !user.name.toLowerCase().includes(filter.name.toLowerCase())) toReturn = false
		if (filter.last_name && !user.last_name.toLowerCase().includes(filter.last_name.toLowerCase())) toReturn = false
		if (filter.roles && !filter.roles.includes(user.role)) toReturn = false
		if (filter.stores) {       
			var foundOne = false
			filter.stores.forEach(store => {
				if (user.stores?.includes(store.toString())) {
					foundOne = true
				}
				toReturn = toReturn && foundOne
			})
		}
		return toReturn

	}

		

	renderUsers() {
		const { showCreate } = this.state;
		const { users } = this.props.user_index_state;

		const { filter } = this.props.user_index_state;
		const {t} =this.props;
		var filteredUsers: User[] = []
		if (filter !== undefined) {
			filteredUsers = users.filter(user => this.filterUser(user))
		} else {
			filteredUsers = users
		}

		filteredUsers.sort((a,b) => a.last_name.localeCompare(b.last_name))
		
		return (
			filteredUsers.map(user => (
					<tr key={user?.id.toString()} onClick={() => this.setState({ user: user, showCreate: !showCreate })} className="cursor-hand" >
						<td>{ user.name }</td>
						<td>{ user.last_name }</td>
						<td>{ t(user.role) }</td>
						<td>{ user.email }</td>
						<td>{ user.phone }</td>
					</tr>
				))
		)
	}




	render () {
		const { t } = this.props;
		const { loading } = this.props.user_index_state;

		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}

		return (
			<>
				{this.renderFilter()}
				{this.renderCreate()}
				{this.renderDeleteConfirmationPopup()}
				{this.renderContentTop()}
				<section id="listPills">
				<table className='table table-slim'>
					<thead>
						<tr>
							<th className='w-28 text-left'>{ t('Name') }</th>
							<th className='w-18 text-left'>{ t('Last Name') }</th>
							<th className='w-18 text-left'>{ t('Role') }</th>
							<th className='w-18 text-left'>{ t('Email') }</th>
							<th className='w-18 text-left'>{ t('Phone number') }</th>
						</tr>
					</thead>
					<tbody>
					{this.renderUsers()}

					</tbody>
				</table>
				</section>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
