import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SelectOption } from '../interfaces/interfaces';
import { InputSelectSearchable } from './InputSelectSearchable';

export interface FormSelectSearchableProps {
    id: string,
    label?: string,
    value?: string,
    options?: SelectOption[];
    disabled?: boolean,
    helpText?: string,
    onChange?: (value?: string, label?: string) => void
}

type FormSelectPropsSum = FormSelectSearchableProps & WithTranslation;

class FormSelectSearchableComponent extends React.Component<FormSelectPropsSum> {

    render () {
        const { id, label, value, disabled, helpText, options, onChange } = this.props;
        return (
            <article className="form-item">
                <label htmlFor={ id }>{ label }</label>
                <InputSelectSearchable id={ id } value={value} disabled={disabled} helpText={helpText} options={ options } onChange={(value, label) => onChange ? onChange(value, label) : null} />
            </article>
        );
    };
}

const FormSelectSearchable = withTranslation()(FormSelectSearchableComponent);
export { FormSelectSearchable };
