import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Filter, Campaign } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_CAMPAIGNS = 'update_campaigns';
export const UPDATE_FILTER = 'update_filter_store';
export const CLEAR_FILTER = 'clear_filter_store';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateStoresAction {
    type: typeof UPDATE_CAMPAIGNS,
    payload: Campaign[]
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: Filter
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export type GalleryIndexActions =
UpdateLoadingAction |
UpdateStoresAction |
UpdateFilterAction |
ClearFilterAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
