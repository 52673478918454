import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseButton, PrimaryButton, SecondaryButton } from '.';
import { PrimaryButtonStyle, SecondaryButtonStyle } from '../interfaces/interfaces';

export interface DepartmentChangeProps {
    onClose: () => void,
    onSubmit: () => void,
    onDelete: () => void
}

type DepartmentChangePropsSum = DepartmentChangeProps & WithTranslation;


class DepartmentChangeComponent extends React.Component<DepartmentChangePropsSum> {

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    render () {
        const { t } = this.props;
        return (
            <section id="message-popup" className="cover-blur active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('department_change') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content">
                        <p>{ t('department_change_text') }</p>
                    </section>
                    <section className="popup-actions">
                        <SecondaryButton onClick={() => this.props.onDelete()} style={SecondaryButtonStyle.RED}>{t('delete_floorplan')}</SecondaryButton>
                        <PrimaryButton onClick={() => this.props.onClose} style={PrimaryButtonStyle.FILLED}>{t('add_support')}</PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const DepartmentChange = withTranslation()(DepartmentChangeComponent);
export { DepartmentChange };
