import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface NextPrevButtonsProps {
    breadth?: string,
    hasNext: boolean,
    hasPrev: boolean,
    onClickNext?: () => void,
    onClickPrev?: () => void,
}

type NextPrevButtonsPropsSum = NextPrevButtonsProps & WithTranslation;

class NextPrevButtonsComponent extends React.Component<NextPrevButtonsPropsSum> {

    render () {
        const { breadth, t, onClickPrev, onClickNext, hasNext , hasPrev } = this.props

        return (
                <ul className={`roulette ${breadth || ''}`}>
                    
                    <li>
                        {hasPrev &&
                            <button onClick={ onClickPrev }>
                                <ArrowLeft />
                                <span>{t('previous')}</span>
                            </button>
                        }
                    </li>
                        
                    <li>
                        {hasNext && 
                            <button onClick={ onClickNext }>
                                <ArrowRight />
                                <span>{t('next')}</span>
                            </button>
                        }
                    </li>
                </ul>
        );
    };
}

const NextPrevButtons = withTranslation()(NextPrevButtonsComponent);


export { NextPrevButtons };
