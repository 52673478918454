import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { RouteComponentProps } from "react-router-dom";
import {
	Spinner,
	ContentTop,
	BackArrow,
	SecondaryButton,
	Info,
	Tabs,
	Tab,
	SectionContainer,
	SupportCard,
	PrimaryButton,
	NotificationStore,
	Floorplan,
	EditStore,
	EditFloorplans,
	EditFloorplan,
	Filter,
	FilterRow,
	ImagePreview,
	EditSupport
} from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getStore, updateFloor, updateFilter, getUsers,cleanStore,updateSelectedSupport,updateLoading } from './StoreDetailActions';
import { Floor, SecondaryButtonSize, Visual, FilterFields, PrimaryButtonSize, PrimaryButtonStyle, Filter as FilterInterface, Support, Store, StoreStatusPrimark,  SupportStatusPrimark } from '../../../interfaces/interfaces';
import { getAllLanguages, getCountries, getTextileTypes, getSupportTypes, getSupportStatuses, getDepartments  } from '../../../reducers/datatypes/DatatypesActions';
import { country_map } from '../../../util/select_maps';
import { add_support_to_campaign, create_floor, create_support, delete_floor, edit_floor, edit_floor_order, edit_store, install,admin_approve_sm, update_supports,add_auto_download_support,remove_support_campaign,clone_support,update_store_status,cvm_move_back_store, moveUpSMStore } from '../../../api';


import { remove_decimals } from '../../../util/number';
import { Role, get_role } from '../../../util/jwt';
import { RoleGuard } from '../../../components/RoleGuard';
import { StatusGuard } from '../../../components/StatusGuard';
import { ConfirmationPopUp } from '../../../components/ConfirmationPopUp';
import { ChangeStoreStatusPopUp } from '../../../components/ChangeStoreStatusPopUp';
import { StoreVisualPackToPDF } from '../../../components/StoreVisualPackToPDFComponent';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { get_user_token } from '../../../util/cookies';
import { VisualPacksPopup } from '../../../components/VisualPacksPopup';

const mapStateToProps = (state: RootState) => {
	return {
		store_detail_state: state.storeDetail,
		data_types_state: state.datatypes,
	}
}

const connector = connect(mapStateToProps, {
	getStore,
	updateFloor,
	updateFilter,
	getCountries,
	getTextileTypes,
	getSupportTypes,
	getSupportStatuses,
	getDepartments,
	getAllLanguages,
	updateSelectedSupport,
	getUsers,
	cleanStore,
	updateLoading
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showFilter: boolean,
	showEdit: boolean,
	showCreateSupport: boolean,
	showFloorplans: boolean,
  	showVisualPacks: boolean,
  	showEditStoreStatus:boolean,
	showCvmStoreStatus: boolean,
	editingFloorplan: Floor | null,
	previewVisual: Visual | null,
	supportX: number,
	supportY: number,
	showConfirmationPopUp: boolean,
	showDuplicateConfirmationPopUp: boolean,
	showAddConfirmationPopUp: boolean,
	selectedAddSupportId: string,
	storeId: string| undefined,
	selectedSupportDuplicateId:string |null,
	selectedSupportDuplicateName:string |null,
	selectedSupportAddName:string |null,
	showAdminConfirmationPopUp:boolean,
	loadingFromPop: boolean,
	showRenderPDF: boolean,
	floors: Floor[],
	showEditSupport: boolean,
	editingSupport: Support | null,
	link_support: string | null,
	link_floor_id: string | null,
	editSupports:Support[],
	showStoreJumpPopUp: boolean
}

class Detail extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			showFilter: false,
			showEdit: false,
			showCreateSupport: false,
			showFloorplans: false,
      		showVisualPacks: false,
	  		showEditStoreStatus: false,
			showCvmStoreStatus: false,
			editingFloorplan: null,
			previewVisual: null,
			supportX: 0,
			supportY: 0,
			showConfirmationPopUp: false,
			showDuplicateConfirmationPopUp:false,
			showAddConfirmationPopUp:false,
			selectedAddSupportId: '',
			storeId: '',
			selectedSupportDuplicateId:null,
			selectedSupportDuplicateName:null,
			selectedSupportAddName:null,
			showAdminConfirmationPopUp:false,
			loadingFromPop: false,
			showRenderPDF: false,
			floors: [],
			showEditSupport: false,
			editingSupport: null,
			link_support: null,
			link_floor_id: null,
			editSupports: [],
			showStoreJumpPopUp: false
		};
	}

	componentDidMount() {
		const query = this.useQuery();
		const support = query.get('support');	
		const floor_id = query.get('floor_id')
		const token = get_user_token();
		const role = get_role(token);

		this.props.getStore(this.props.match.params.id, floor_id || undefined);
		this.props.getCountries();
		this.props.getTextileTypes();
		this.props.getSupportTypes();
		this.props.getSupportStatuses();
		this.props.getDepartments();
		this.props.getAllLanguages();

		if ([Role.ADMIN, Role.BACKDOOR].includes(role)) {
			this.props.getUsers(this.props.match.params.id);
		}

		this.setState({ link_support: support, link_floor_id: floor_id, showRenderPDF:false})
	}
	componentWillUnmount(){
		
		this.props.cleanStore()
	}
	

	useQuery = () => new URLSearchParams(this.props.location.search);

	renderFloorPlanImages(store: Store){
		var auxFloors: Floor [] = [];
		store?.floors.map((floor) => {
		
			if(floor.map_url){
				var img = new Image();
				img.src = floor.map_url;
				img.onload = () => {
					floor.width = 800;
					
					floor.height=(800*img.height)/img.width;
					auxFloors.push(floor);
					if(auxFloors.length === store?.floors.length){
						this.setState({showRenderPDF: true, floors: auxFloors});
					}
				}
			}
		});
		
	}

	renderPreviewVisualPopUp() {
		const { previewVisual } = this.state;
		if (previewVisual) {
			return (
				<ImagePreview visual={ previewVisual } onClose={() => this.setState({ previewVisual: null }) }/>
			);
		}
	}

	renderEditPopUp() {
		const { store } = this.props.store_detail_state;
		const { showEdit } = this.state;
		const { countries, languages } = this.props.data_types_state;
		if (showEdit) {
			return (
				<EditStore
					store={ store }
					countries={ countries.map(country_map) }
					languages={ languages }
					onClose={() => this.setState({ showEdit: false })}
					onSubmit={(store: Store) => {
						edit_store(store).then(() => {
							this.setState({ showEdit: false })
							this.props.getStore(this.props.match.params.id);
						}).catch(err => console.error);
					}}
				/>
			);
		}
	}

	renderFloorplansPopUp() {
		const { store } = this.props.store_detail_state;
		const { showFloorplans } = this.state;
		if (showFloorplans) {
			return (
				<EditFloorplans
					floors={ store?.floors || [] }
					store_no = { store!.id}
					onEditClicked={(floor: Floor) => this.setState({ showFloorplans: false, editingFloorplan: floor })}
					onSort={(floors) => {
						floors.forEach(floor => {
							edit_floor_order(floor).then(() => {
							}).catch(err => console.error);
							this.setState({ showEdit: false, showFloorplans: false, editingFloorplan: null })
							this.props.getStore(this.props.match.params.id);
						})
					}}
					onClose={() => this.setState({ showFloorplans: false })}
				/>
			);
		}
	}

	renderEditFloorplanPopUp() {
		const { editingFloorplan } = this.state;
		const { store } = this.props.store_detail_state;

		if (editingFloorplan) {
			const supports = this.getSupports(editingFloorplan?.entry_no);

			return (
				<EditFloorplan
					floor={ editingFloorplan }
					discardFloors = { store?.floors || []}
					onSubmit={(floor: Floor) => {
						if (floor.entry_no !== undefined){
							edit_floor(floor).then(() => {
								this.setState({ showFloorplans: true, editingFloorplan: null })
								this.props.getStore(this.props.match.params.id);
							}).catch(err => console.error);
						}else{
							create_floor(floor).then(() => {
								this.setState({ showFloorplans: true, editingFloorplan: null })
								this.props.getStore(this.props.match.params.id);
							}).catch(err => console.error);
						}
						
					}}
					onClose={() => this.setState({ showFloorplans: true, editingFloorplan: null })}
					onDelete={(id: number) => {
						delete_floor(id).then(() => {
							this.setState({ showFloorplans: true, editingFloorplan: null })
							this.props.getStore(this.props.match.params.id);
						}).catch(err => console.error);
					}}
					canDelete={supports.length === 0}
				/>
			);
		}
	}

	renderStoreFilterPopUp() {
		const { filter, store } = this.props.store_detail_state;
		const { showFilter } = this.state;
		const { textile_types, support_types, departments } = this.props.data_types_state;
		const { t, updateFilter } = this.props;
		if (showFilter) {
			return (
				<Filter
					filter={ {...filter, store: store?.id } }
					filterFields={FilterFields.STORE_SUPPORT}
					textile_types={textile_types}
					support_types={support_types}
					support_statuses={Object.values(SupportStatusPrimark).map(status => {
						return {name: t(status), value: status}
					})}
					sections={departments}
					floors={store?.floors}
					onClose={() => {
						this.setState({ showFilter: !showFilter });
					}}
					onClear={() => {
						updateFilter({});
						this.setState({ showFilter: !showFilter });
						this.props.getStore(this.props.match.params.id);
					}}
					onSubmit={(filter: FilterInterface) => {
						updateFilter(filter);
						this.setState({ showFilter: !showFilter });
						this.props.getStore(this.props.match.params.id);
					}}
				/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;
		const { showEdit } = this.state;
		const { store } = this.props.store_detail_state;

		const numberStyle = {
			marginLeft: '16px',
			paddingLeft: '8px',
			paddingRight: '8px',
			paddingTop: '2px',
			paddingBottom: '2px',
			backgroundColor: '#E9EBEC',
			color: '#444D56',
			fontSize: '16px',
			lineHeight: '20px',
			borderRadius: '6px'
		}

		return (
			<ContentTop>
				<article className="detail-top">
					<div className="detail-top--title">
						<BackArrow onClick={() => this.props.history.goBack()} />
						<div className="title-detail">
							<h2>{ store?.name } <span style={ numberStyle }>{ store?.customer_shop_no }</span></h2>
							<p>{ store?.direccion }, { store?.ciudad } ({ store?.pais })</p>
						</div>
					</div>
					<div className="detail-top--actions">
						<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.N_A]}>

							<RoleGuard roles={[Role.ADMIN]}>
								<SecondaryButton onClick={() => this.setState({ showEditStoreStatus: true })}>{t('change_store_status')}</SecondaryButton>
							</RoleGuard>
						</StatusGuard>

						<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
							<SecondaryButton onClick={() => this.setState({ showEdit: !showEdit })} size={SecondaryButtonSize.MEDIUM}>
								{ t('edit') }
							</SecondaryButton>
						</RoleGuard>

						
					</div>
				</article>
			</ContentTop>
		);
	}

	renderAdminInstallButton(){
		const { store } = this.props.store_detail_state;
	
		const { t } = this.props;
		if(store?.store_ready_admin){
			return (
				<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.PENDING_APPROVAL_SM]}>
				
					<RoleGuard roles={[Role.ADMIN]}>
						<NotificationStore status_class={store?.status_primark} title={t('posible_store_opening')} actions={[
							<PrimaryButton onClick={() =>  this.setState({ showAdminConfirmationPopUp: true })} size={PrimaryButtonSize.SMALL}>{ t('approve_store') }</PrimaryButton>
						]} />
					</RoleGuard>
				</StatusGuard>
			)
		}
		
	}

	renderStoreNotifications() {
		const { t } = this.props;
		const { store } = this.props.store_detail_state;
		
		return (<>

	
			<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.PENDING_APPROVAL_SM]}>
				 <RoleGuard roles={[Role.COUNTRY_MANAGER,Role.HEAD_VM]}>
				<NotificationStore status_class={store?.status_primark} title={t('store_pending_of_confirmation')} actions={[
					<SecondaryButton onClick={() => this.setState({ showStoreJumpPopUp:true })} >{ t('move_to_cvm') }</SecondaryButton>
				]} />
				</RoleGuard> 
				<RoleGuard roles={[Role.STORE_MANAGER,Role.ADMIN]}>
				
					<NotificationStore status_class={store?.status_primark} title={t('store_pending_of_confirmation')} actions={[
						<RoleGuard roles={[Role.ADMIN]}>
							<SecondaryButton onClick={() => this.setState({ showEditStoreStatus: true })}>{t('change_store_status')}</SecondaryButton>
						</RoleGuard>,
						<PrimaryButton onClick={() => this.props.history.push(`/stores/${this.props.match.params.id}/validate_images`)} size={PrimaryButtonSize.SMALL}>{ t('approve_frames') }</PrimaryButton>	
					]}/>
				</RoleGuard>
			</StatusGuard>

			<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.PENDING_SIGN_OFF_CM]}>
				<RoleGuard roles={[Role.STORE_MANAGER]}>
					<NotificationStore status_class={store?.status_primark} title={t('store_pending_of_sign_off')} />
				</RoleGuard>
				<RoleGuard roles={[Role.COUNTRY_MANAGER, Role.HEAD_VM,Role.ADMIN]}>
					<NotificationStore status_class={store?.status_primark} title={t('store_pending_of_sign_off')} actions={[
						<RoleGuard roles={[Role.COUNTRY_MANAGER]}>
						<SecondaryButton onClick={() => this.setState({ showCvmStoreStatus: true })}>{t('move_back_store_status')}</SecondaryButton>
					</RoleGuard>,
						<RoleGuard roles={[Role.ADMIN]}>
						<SecondaryButton onClick={() => this.setState({ showEditStoreStatus: true })}>{t('change_store_status')}</SecondaryButton>
					</RoleGuard>,
						<PrimaryButton onClick={() => this.props.history.push(`/stores/${this.props.match.params.id}/validate_images`)} size={PrimaryButtonSize.SMALL}>{ t('approve_frames') }</PrimaryButton>
					]} />
				</RoleGuard>
			</StatusGuard>

			<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.SIGNED_OFF]}>
				<NotificationStore title={t('signed_off')} status_class={store?.status_primark} actions={[
					<RoleGuard roles={[Role.ADMIN]}>
					<SecondaryButton onClick={() => this.setState({ showEditStoreStatus: true })}>{t('change_store_status')}</SecondaryButton>
				</RoleGuard>
				]}/>
			</StatusGuard>
			<StatusGuard status={store?.status_primark} statuses={[ StoreStatusPrimark.IN_PRODUCTION]}>
			
				<NotificationStore title={t('store_in_production')} status_class={store?.status_primark} actions={[
					<RoleGuard roles={[Role.ADMIN]}>
					<SecondaryButton onClick={() => this.setState({ showEditStoreStatus: true })}>{t('change_store_status')}</SecondaryButton>
				</RoleGuard>
				]}/>
			</StatusGuard>

			<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.SHIPPED]}>
				<NotificationStore status_class={store?.status_primark} title={t("order_shipped._Tracking_number_{{tN}}", { tN: (store?.tracking_number as string) }) } actions={[
					// <PrimaryButton onClick={() => {window.open(store?.tracking_number, '_blank')} } size={PrimaryButtonSize.SMALL}>{ t('track_your_order') }</PrimaryButton>	
				]} />
				<NotificationStore status_class={store?.status_primark} title={t('install_order_in_store')} actions={[
					<RoleGuard roles={[Role.ADMIN]}>
						<SecondaryButton onClick={() => this.setState({ showEditStoreStatus: true })}>{t('change_store_status')}</SecondaryButton>
					</RoleGuard>,
					<PrimaryButton onClick={() => this.setState({ showConfirmationPopUp: true }) }  size={PrimaryButtonSize.SMALL}>{ t('install') }</PrimaryButton>
				]} />
			</StatusGuard>
				{this.renderAdminInstallButton()}
			
		</>
		);
	}

	

	installStore() {
		const { store } = this.props.store_detail_state;

		if (store) {
			install(store.id).then((result) => {
				if (result) {
					this.setState({ loadingFromPop: false })
					this.props.getStore(store.id);

				}
			});
		}
	}

	renderStatusChangeConfirmationPop(){
		const { t } = this.props;
		const { store } = this.props.store_detail_state;
		const { showConfirmationPopUp } = this.state;

		var change_text = t("store_passing_to_the_next_state");
		if(store){
			const next_status_index = (Object.values(StoreStatusPrimark)).indexOf(store.status_primark) + 1;
			if(next_status_index in Object.values(StoreStatusPrimark))
			{
				change_text = t("Store passing from state {{statusFrom}} to state {{statusTo}}", { statusFrom: (store.status_primark as string), statusTo: ( (Object.values(StoreStatusPrimark))[next_status_index] ) } );
			}
		}

		if(showConfirmationPopUp){
			return(
				<ConfirmationPopUp 
					onClose={() => this.setState({ showConfirmationPopUp: false })}
					onSubmit={() => {
						this.setState({ showConfirmationPopUp: false })
						this.setState({ loadingFromPop: true })
						this.installStore()
					}}
					title={t("confirm_store_status_change")}
					change_text={change_text}
				/>
			);
		}
	}

	renderCVMStatusChangeConfirmationPopUp(){

		const { t } = this.props;
	
		const { showCvmStoreStatus } = this.state;

		if(showCvmStoreStatus){
			return(
				<ConfirmationPopUp 
					onClose={() => this.setState({ showCvmStoreStatus: false })}
					onSubmit={() => {
						this.setState({ showCvmStoreStatus: false })
						this.setState({ loadingFromPop: true })
						
						this.moveBackCvm()
						
						

						
						
					}}
					title={t("confirm_store_status_change")}
					change_text={t('store_passing_to_the_state_pending_store_manager')}
				/>
			);
		}
	}

	renderShowJumpCmPopUp(){

		const { t } = this.props;
	
		const { showStoreJumpPopUp } = this.state;

		if(showStoreJumpPopUp){
			return(
				<ConfirmationPopUp 
					onClose={() => this.setState({ showStoreJumpPopUp: false })}
					onSubmit={() => {
						this.setState({ showStoreJumpPopUp: false })
						this.setState({ loadingFromPop: true })

						this.handleMoveUpSMStore();

					}}
					title={t("confirm_store_status_change")}
					change_text={t('store_passing_to_the_state_pending_store_manager')}
				/>
			);
		}
	}

	async handleMoveUpSMStore(){
		const { store } = this.props.store_detail_state;

		if (store) {
			moveUpSMStore(store.id).then((result) => {
				this.setState({ loadingFromPop: false })
				this.props.getStore(this.props.match.params.id);
				/* this.props.history.push('/stores/'+store?.id); */
			});
		}
	}
	async moveBackCvm(){
		const { store } = this.props.store_detail_state;

		if (store) {
			cvm_move_back_store(store.id).then((result) => {
				this.setState({ loadingFromPop: false })
				this.props.getStore(this.props.match.params.id);
				
				/* this.props.history.push('/stores/'+store?.id); */
				
				
			});
		}
	}

	approveStoreSM(){
		const { store } = this.props.store_detail_state;

		if (store) {
			admin_approve_sm(store.id).then((result) => {
				this.setState({ loadingFromPop: false })
				this.props.getStore(store.id);
				/* this.props.history.push('/stores/'+store?.id); */
				
			});
		}
	}

	renderAdminStoreApproveSM(){
		const { t } = this.props;
		const { store } = this.props.store_detail_state;
		const { showAdminConfirmationPopUp } = this.state;

		var change_text = t("store_passing_to_the_next_state");
		if(store){
			const next_status_index = (Object.values(StoreStatusPrimark)).indexOf(store.status_primark) + 1;
			if(next_status_index in Object.values(StoreStatusPrimark))
			{
				change_text = t("Store passing from state {{statusFrom}} to state {{statusTo}}", { statusFrom: (store.status_primark as string), statusTo: ( (Object.values(StoreStatusPrimark))[next_status_index] ) } );
			}
		}

		if(showAdminConfirmationPopUp){
			return(
				<ConfirmationPopUp 
					onClose={() => this.setState({ showAdminConfirmationPopUp: false })}
					onSubmit={() => {
						this.setState({ showAdminConfirmationPopUp: false })
						this.setState({ loadingFromPop: true })
						this.approveStoreSM();
					}}
					title={t("confirm_store_status_change")}
					change_text={change_text}
				/>
			);
		}
	}

	renderStoreInfo() {
		const { t } = this.props;
		const { store, users } = this.props.store_detail_state;
		const { languages, countries } = this.props.data_types_state;
		
		var current_lang = undefined;
		var current_country = undefined;
		if(store){
			current_lang = languages.find(i => i.value === store.idioma);
			current_country = countries.find(i => i.id === store.pais);
		}
		
		return (
			<Info title={ t('store_info') }>
				<div className="title-content-grid">
					<div className='flex flex-column gap-16 padding-bottom-16'>

					<ul className="col_r2_d5  mb-16">
						<li><span>{ t('country') }</span> { (current_country)? current_country.name : store?.pais }</li>
						<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
							<li><span>{ t('store_navision_code') }</span> { store?.id }</li>
						</RoleGuard>
						<li><span>{ t('city') }</span> { store?.ciudad }</li>
						<li><span>{ t('address') }</span> { store?.direccion }</li>
						<li><span>{ t('post_code') }</span> { store?.codigo_postal }</li>
					</ul>
					<ul className="col_r2_d5 mb-16">
						<li><span>{ t('store_code') }</span> { store?.customer_shop_no }</li>
						<li><span>{ t('store_phone') }</span> { store?.telefono }</li>
						<li><span>{ t('language') }</span> { (current_lang)? current_lang.name:' - ' }</li> 
						<StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.SHIPPED, StoreStatusPrimark.INSTALLED]}>
							<li><span>{ t('tracking_number') }</span> { (store?.tracking_number)? store.tracking_number:' - ' }</li> 
						</StatusGuard>
					</ul>

					</div>

					<section className='grid cols-4 col-gap-16'>

					{ users?.map((user) => {  
						return (
							<article className='card card--employee flex flex-column gap-4'>
								<h4 className='flex row-space-between'>{user.name} {user.last_name}<span>{user.role === Role.COUNTRY_MANAGER ? 'CVM' : 'SM'}</span></h4>
								<ul>
									<li>{user.email}</li>
									<li>{user.phone}</li>
								</ul>
							</article>
						)						
					})}
						
					</section>
					
				</div>
			</Info>
		);
	}

	renderSubTitle() {
		const { t } = this.props;
		const { showFloorplans } = this.state;
		return (
			<section className="inner-title h-opposed">
				<h2 className="v-center">{ t('floorplan') }</h2>
				<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
				<SecondaryButton onClick={() => this.setState({ showFloorplans: !showFloorplans })}>{ t('edit_floorplan') }</SecondaryButton>
				</RoleGuard>
				{this.renderPDF()}
       			 <PrimaryButton style={PrimaryButtonStyle.TRANSPARENT} onClick={() => this.setState({ showVisualPacks: true })}>{ t('visual_pack_history') }</PrimaryButton>
			</section>
		);
	}

  renderVisualPacksPopup() {
    const { showVisualPacks } = this.state;
		const { store } = this.props.store_detail_state;

    if (store && showVisualPacks) {
      return (
        <VisualPacksPopup storeId={store.customer_shop_no} onClose={() => this.setState({ showVisualPacks: false })} />
      );
    }
  }

	renderPDF(){
		const { t } = this.props;
		var { store, activeCampaign } = this.props.store_detail_state;
		const { showRenderPDF, floors } = this.state;
		
		if(showRenderPDF && store){
			store.floors = floors;
			
			return(
				<PDFDownloadLink document={<StoreVisualPackToPDF store={store!} floors={floors} currentCampaign={ activeCampaign }/>} >
					{({loading}) => ((loading || !showRenderPDF)? <PrimaryButton disabled={true} style={PrimaryButtonStyle.OUTLINED}>{t('loading')}</PrimaryButton> :  <PrimaryButton style={PrimaryButtonStyle.OUTLINED}>{t('print_pdf')}</PrimaryButton>) }
				</PDFDownloadLink>
			);
		}else{
			
			return(
				<PrimaryButton disabled={true} style={PrimaryButtonStyle.OUTLINED}>{t('loading')}</PrimaryButton>
			);
		}
	}

	renderStoreFloors() {
		const { store, selectedFloor } = this.props.store_detail_state;

		return (
			<Tabs>
				{store?.floors.sort((a, b) => a.floor_order > b.floor_order ? 1 : -1).map((floor) =>
					<Tab key={ floor.name } text={ floor.name } active={floor?.key === selectedFloor?.key} onClick={() =>this.props.updateFloor(floor) } />
				
				)}
			</Tabs>
		);
	}

	getSupports(floor_id: number) {
		return this.props.store_detail_state.store?.sections?.reduce<Support[]>((supports, section) => [...supports, ...section.supports?.filter(support => support.floor_id === floor_id) || []], []) || [];
	}

	renderFloorplan() {
		const { selectedFloor, store, selectedSupport } = this.props.store_detail_state;
		const { link_support, link_floor_id } = this.state;
		const token = get_user_token();
		const role = get_role(token);
		

		if (selectedFloor) {
			return (
				<Floorplan role={role} initialFloor={link_floor_id} initialSupport={link_support} store={store} supports={this.getSupports(selectedFloor.entry_no)} floor={ selectedFloor } addSupportClicked={(centerX, centerY) => { this.setState({ supportX: centerX, supportY: centerY, showCreateSupport: true }) }} editMode={true} onSupportsMove={supports => {
					update_supports(supports).then(result => {
						let selected_floor=store?.floors.filter(floor => floor.entry_no===selectedFloor.entry_no);
						if (selected_floor && selected_floor.length > 0) {
							this.props.getStore(this.props.match.params.id, selected_floor[0].entry_no.toString() || undefined);
						  } else{
							this.props.getStore(this.props.match.params.id);
						  }
						
					});
				}} 
				onValidateClick={(support) => {
					this.props.history.push(`/stores/${store?.id}/validate_images/${support.id}`)
				}}
				onAddCampaignClick={(support) => {
					this.setState({ selectedAddSupportId: support.id, storeId:store?.id, selectedSupportAddName:support.no_soporte_cliente, showAddConfirmationPopUp:true })
				}}
				isValidate={false}
				onEditSupportClicked={(support,changedSupports) => {
					this.setState({
						showEditSupport: true,
						editingSupport: support,
						editSupports:changedSupports
					});
					
				}}
				selectedSupport={selectedSupport}
				onSupportSelected={(support)=>{
					this.props.updateSelectedSupport(support);
				}}
				sections={ this.props.data_types_state.departments }
				/>
			);
		}
	}

	renderCreateSupport() {
		const { showCreateSupport, supportX, supportY } = this.state;
		const { textile_types, support_types,map_disposition_types, departments} = this.props.data_types_state;
		const { selectedFloor } = this.props.store_detail_state;

		if (showCreateSupport) {
			return (
				<EditSupport					
					textile_types={textile_types}
					support_types={support_types}
					map_disposition_types={map_disposition_types}
					onClose={() => this.setState({ showCreateSupport: !showCreateSupport })}
					sections={ departments }
					onDelete={(support: Support) => {}}
					onSubmit={(support: Support) => {
						create_support({
								...support, 
								map_x: remove_decimals(supportX), 
								map_y: remove_decimals(supportY), 
								floor_id: selectedFloor?.entry_no,
								store_id:this.props.match.params.id } as Support
							).then(() => {
							this.setState({ showCreateSupport: false })
							this.props.getStore(this.props.match.params.id);
						}).catch(err => console.error);
						}
					}
				/>
			);
		}
	}

	renderEditSupport() {
		const { showEditSupport, editingSupport,editSupports } = this.state;
		const { textile_types, support_types,map_disposition_types, departments} = this.props.data_types_state;
		const { store } = this.props.store_detail_state;
		if (showEditSupport && editingSupport) {
			return (
				<EditSupport
					textile_types={textile_types}
					support_types={support_types}
					map_disposition_types={map_disposition_types}
					support={ editingSupport }
					sections={ departments }
					floor_edit={true}
					floors={store?.floors}
					onDelete={(support:Support) => {}}
					onClose={() => this.setState({ showEditSupport: !showEditSupport })}
					onSubmit={(support: Support) => {
						let merged_supports=[];
						let existe=false;
						//Mergeamos el soporte edita en los soportes movidos para que no de error de key de Navision
						//Recorrer soportes movidos
						for(const m_support of editSupports){
							//Si soporte editado también se ha movido
							if(support.id===m_support.id){
								existe=true;
								let merge_support=support;
							
								merge_support.map_x=m_support.map_x;
								merge_support.map_y=m_support.map_y;
								merged_supports.push(merge_support);
							}else{
								//Si el soporte movido no es el editado añadirlo al array de soportes
								merged_supports.push(m_support);
							}
							
						}
						//Si el soporte editado no se ha movido
						if(!existe){
							merged_supports.push(support);
						}
						
						//Actualizamos los soportes del merge
						update_supports(merged_supports).then(result => {
							this.setState({ showEditSupport: false, editingSupport: null })
							this.props.getStore(this.props.match.params.id);
						});
						
						
						
					}}
				/>
			);
		}
	}

	renderStoreFilter() {
		const { t } = this.props;
		const { showFilter } = this.state;
		const { store, filter,totalSupports } = this.props.store_detail_state;

		return (
			<ContentTop>
				<FilterRow
					filter={filter}
					totalResults={totalSupports || 0}
					results={store?.sections ? store?.sections.reduce((sum, current) => sum + current.supports!.length, 0) : 0}
					pluralizeStrings={[t('result_found'), t('results_found')]}
					onFilterClicked={() => this.setState({ showFilter: !showFilter })}
				/>
			</ContentTop>
		);
	}

	filterSupportLine(support:Support,role:string){
		const { store } = this.props.store_detail_state;
		let lines=store?.store_lines;
			if(support.status_primark!==SupportStatusPrimark.NOT_IN_CAMPAIGN && (role===Role.ADMIN || role===Role.COUNTRY_MANAGER)){
				let support_line=lines?.filter(line=>line.hardware_id===support.id);
				return support_line && support_line[0]?.id;
			}else{
				return '';
			}
	
	}

	filterSupportInstalled(support:Support,role:string){
		const { store } = this.props.store_detail_state;
		let lines=store?.store_lines;
			if(support.status_primark!==SupportStatusPrimark.NOT_IN_CAMPAIGN && role==='admin'){
				let support_line=lines?.filter(line=>line.hardware_id===support.id);
				return support_line && support_line[0]?.descargado;
			}else{
				return false;
			}
	
	}

	renderStoreSectionSupports(section:any){
		const { store } = this.props.store_detail_state;
		const { departments } = this.props.data_types_state
		const token = get_user_token();
		const role = get_role(token);
		let render_supports: Support[]=[];
		let clon_supports: Support[]=[];
		section.supports.forEach((support: Support) => {
			if (support.clon === false) {
			  render_supports.push(support);
			} else {
			  clon_supports.push(support);
			}
		  });
		clon_supports.forEach((support: Support) => {
			const parentId = support.sopadre; // ID del sopadre del support clonado
		  
			const matchingSupport = render_supports.find((renderSupport) => renderSupport.id.toString() === parentId?.toString());
		  
			if (matchingSupport) {
			 
			  if (!matchingSupport.childrens) {
				matchingSupport.childrens = [];
			  }
		  
			  const existingClone = matchingSupport.childrens.find((child) => child.id === support.id);
			  if (!existingClone) {
				matchingSupport.childrens.push(support);
			  }
			} else {
				render_supports.push(support);
			 
			
			}
		  });
		 
		
		return (
			<>
			{render_supports.map((support:Support, index:number) =>						
								<SupportCard
									key={ index }
									userRole={ role }
									storeStatus={ store?.status_primark }
									store_lines={store?.store_lines}
									departments={departments}
									support={{...support, floor: store?.floors.find(floor => floor.entry_no === support.floor_id)}}
									showValidationIcon={ true }
									onVisualClick={image => this.setState({ previewVisual: { image } as Visual })}
									onValidateClick={(click_support) => this.props.history.push(`/stores/${store?.id}/validate_images/${click_support.id}`)}
									onAddCampaignClick={(click_support) => {
											this.setState({ selectedAddSupportId: click_support.id, storeId:store?.id, selectedSupportAddName:support.no_soporte_cliente, showAddConfirmationPopUp:true })
										
									}}
									line_id={this.filterSupportLine(support,role)}
									installed={this.filterSupportInstalled(support,role)}
									installSupport={(line_id)=>{
										add_auto_download_support(line_id).then(()=>{
											this.props.history.push('/stores/'+store?.id);
											window.location.reload();
										})
									}}
								
									removeCampaignSupport={(line_id)=>{
										this.props.updateLoading();
										remove_support_campaign(line_id).then(()=>{
											this.props.history.push('/stores/'+store?.id);
											window.location.reload();
										})
									}}
								
									duplicateSupport={()=>{
										this.setState({ selectedSupportDuplicateId: support.id, selectedSupportDuplicateName:support.no_soporte_cliente, showDuplicateConfirmationPopUp:true })
										
									}}
								
								
								
								
								/>
								)}</>
		)
	}
	
	renderStoreSections() {
		const { store } = this.props.store_detail_state;
	
		return (
			(store?.sections || []).map(section =>
				<SectionContainer key={ section.description }>
					<div className="work-header">
						<h4>{ section.description }</h4>
					</div>
					{this.renderStoreSectionSupports(section)}
				</SectionContainer>
			)
		);
	}

	renderDuplicateConfirmation(){
		const { t } = this.props;
		const query = this.useQuery();
			
		const floor_id = query.get('floor_id')
		const { showDuplicateConfirmationPopUp, selectedSupportDuplicateId, selectedSupportDuplicateName } = this.state;
		if(showDuplicateConfirmationPopUp){
			let text=t('you_are_goind_to_duplicate_the_support')+selectedSupportDuplicateName;
			return(
				<ConfirmationPopUp 
					onClose={() => this.setState({ showDuplicateConfirmationPopUp: false })}
					onSubmit={() => {
						this.setState({ showDuplicateConfirmationPopUp: false })
						this.setState({ loadingFromPop: true })
						clone_support(selectedSupportDuplicateId).then((support_id)=>{
							if(support_id!==false){
								this.props.history.push('/stores/'+this.props.match.params.id+'/validate_images/'+support_id);
							}else{
								this.props.getStore(this.props.match.params.id, floor_id || undefined);
							}
							
							
						});
					}}
					title={t("generic_confirm_message")}
					change_text={text}
				/>
			);
		}
	}

	renderAddConfirmation(){
		const { t } = this.props;		
		const { showAddConfirmationPopUp, selectedAddSupportId, storeId,selectedSupportAddName } = this.state;
		if(showAddConfirmationPopUp){
			let text=t('you_are_going_to_add_the_support')+selectedSupportAddName;
			return(
				<ConfirmationPopUp 
					onClose={() => this.setState({ showAddConfirmationPopUp: false })}
					onSubmit={() => {
						this.setState({ showAddConfirmationPopUp: false })
						this.setState({ loadingFromPop: true })
										this.props.updateLoading();
											add_support_to_campaign(selectedAddSupportId).then(() => {
												this.props.history.push(`/stores/${storeId}/validate_images/${selectedAddSupportId}`)
											});
					}}
					title={t("generic_confirm_message")}
					change_text={text}
				/>
			);
		}
	}

	renderStoreChangeStatusPopUp() {
		const { store } = this.props.store_detail_state;
		const {showEditStoreStatus} = this.state;
		if(showEditStoreStatus){
			return <ChangeStoreStatusPopUp 
			onClose={() => this.setState({ showEditStoreStatus: false })}
			onSubmit={(status:string|undefined) => {
			
				this.setState({ showEditStoreStatus: false })
				this.setState({ loadingFromPop: true })

				update_store_status(store?.id, status).then(()=>{
			
					this.props.history.push('/stores/'+store?.id);
					window.location.reload();
				})
			}}
			store_status={store?.status_primark}
			/>
		}
	}

	render () {
		const { t } = this.props;
		const { loading, store } = this.props.store_detail_state;
		const { loadingFromPop, showRenderPDF } = this.state;
		
		if (loading || loadingFromPop || store===undefined) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		if(store && [StoreStatusPrimark.INSTALLED, StoreStatusPrimark.SHIPPED,StoreStatusPrimark.PENDING_APPROVAL_SM,StoreStatusPrimark.PENDING_SIGN_OFF_CM, StoreStatusPrimark.SIGNED_OFF, StoreStatusPrimark.IN_PRODUCTION].includes(store.status_primark) && !showRenderPDF && !loading && !loadingFromPop){
			this.renderFloorPlanImages(store);
		}
		
		
		return (
			<>
				{this.renderPreviewVisualPopUp()}
				{this.renderEditPopUp()}
        {this.renderVisualPacksPopup()}
				{this.renderCreateSupport()}
				{this.renderEditSupport()}
				{this.renderFloorplansPopUp()}
				{this.renderEditFloorplanPopUp()}
				{this.renderStoreFilterPopUp()}
				{this.renderContentTop()}
				{this.renderStoreNotifications()}
				{this.renderStoreInfo()}
				{this.renderSubTitle()}
				{this.renderStoreFloors()}
				{this.renderFloorplan()}
				{this.renderStoreFilter()}
				{this.renderStoreSections()}
				{this.renderStatusChangeConfirmationPop()}
				{this.renderDuplicateConfirmation()}
				{this.renderAddConfirmation()}
				{this.renderAdminStoreApproveSM()}
				{this.renderStoreChangeStatusPopUp()}
				{this.renderCVMStatusChangeConfirmationPopUp()}
				{this.renderShowJumpCmPopUp()}
			</>
		);
	}
}

export default withTranslation()(connector(Detail));
