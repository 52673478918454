import { one_campaign, supports_with_visual } from '../../../api';
import {
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SUPPORTS_WITH_VISUAL,
    GalleryDetailActions,
    AppThunk,
} from './ActionTypes';

export const getCampaign = (id: string): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as GalleryDetailActions);

        const campaign = await one_campaign(id);

        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: campaign
        } as GalleryDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as GalleryDetailActions);
    }
}

export const getSupportsWithVisual = (id: string): AppThunk => {
    return async (dispatch) => {

        const supports = await supports_with_visual(id);

        dispatch({
            type: UPDATE_SUPPORTS_WITH_VISUAL,
            payload: supports
        } as GalleryDetailActions);
    }
}