import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Support, Filter as FilterInterface, Floor } from "../../../interfaces/interfaces";

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_SUPPORT = 'update_support';
export const UPDATE_SELECTED_FLOOR = 'update_selected_floor';
export const UPDATE_FILTER = 'update_supports_filter';
export const UPDATE_FLOORS = 'update_floors';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateSupportsAction {
    type: typeof UPDATE_SUPPORT,
    payload: Support
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: FilterInterface
}

export interface UpdateFloorAction {
    type: typeof UPDATE_FLOORS,
    payload: Floor[]
}

export type SupportDetailActions =
UpdateLoadingAction |
UpdateSupportsAction |
UpdateFilterAction |
UpdateFloorAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
