import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface ContentTopProps {
}

type ContentTopPropsSum = ContentTopProps & WithTranslation;

class ContentTopComponent extends React.Component<ContentTopPropsSum> {

    render () {
        return (
            <section className="content-top">
                {this.props.children}
            </section>
        );
    };
}

const ContentTop = withTranslation()(ContentTopComponent);
export { ContentTop };
