import { one_campaign, one_section } from '../../../api';
import { Campaign, Section } from '../../../interfaces/interfaces';
import { sortVisualArrayByPriority } from '../../../util/visual';
import {
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SECTION,
    CampaignDetailActions,
    AppThunk,
} from './ActionTypes';


export const getCampaign = (id: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignDetailActions);

        let campaign = await one_campaign(id);

        //order by priority algorithm and add priority to visuals without them
        
        if ( campaign ){
            campaign = {
                ...campaign, 
                sections: campaign.sections.map( (section : Section) =>{ 
                    return {...section, visuals: sortVisualArrayByPriority( section.visuals ).map( (visual, index) =>{
                        if(!visual.priority){
                            return {...visual, priority: index + 1 }
                        }else{
                            return{...visual}
                        }
                    
                    })} 
                })
            }
        }

        dispatch({
            type:  UPDATE_CAMPAIGN,
            payload: campaign
        } as CampaignDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignDetailActions);
    }
}

export const getSection = (id: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignDetailActions);

        const section = await one_section(id);

        dispatch({
            type: UPDATE_SECTION,
            payload: section
        } as CampaignDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignDetailActions);
    }
}

export const updateTags = (visualName: string, tags: string[]): AppThunk => {
    return async (dispatch, getState) => {
        const campaign = getState().campaignDetail.campaign;
        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: { ...campaign, sections: (campaign?.sections || []).map(section => ({ ...section, visuals: section.visuals.map(visual => ({ ...visual, tags: visual.name === visualName ? tags : visual.tags })) })) }
        } as CampaignDetailActions);
    }
}


export const updateCampaign = (campaign: Campaign): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: campaign
        } as CampaignDetailActions);
    }
}