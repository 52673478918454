import {
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_REPEAT,
    UPDATE_PASSWORD_ERROR,
    UPDATE_SIGN_UP_ERROR,
    SetingsActions
} from "./ActionTypes";
import { Reducer } from 'redux';

export interface ClientDetailState {
    user: string,
    userError: string,
	password: string;   
    passwordRepeat: string,
    signUpError: string,
}

const INITIAL_STATE: ClientDetailState = {
    user: '',
    userError: '',
    password: '',
    passwordRepeat: '',
    signUpError: '',
};

const reducer: Reducer<ClientDetailState, SetingsActions> = (state = INITIAL_STATE, action: SetingsActions) => {
    switch(action.type) {
        case UPDATE_USER:
            return { ...state, user: action.payload };
        case UPDATE_USER_ERROR:
            return { ...state, userError: action.payload };
        case UPDATE_PASSWORD:
            return { ...state, password: action.payload };
        case UPDATE_PASSWORD_REPEAT:
            return { ...state, passwordRepeat: action.payload };
        case UPDATE_PASSWORD_ERROR:
            return { ...state, passwordError: action.payload };
        case UPDATE_SIGN_UP_ERROR:
            return { ...state, signUpError: action.payload };
        default:
            return state;
    }
}

export default reducer;