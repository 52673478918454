import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InfoBoxStyle } from '../interfaces/interfaces';

export interface InfoBoxProps {
    color?: InfoBoxStyle
}

type InfoBoxPropsSum = InfoBoxProps & WithTranslation;

class InfoBoxComponent extends React.Component<InfoBoxPropsSum> {

    render () {
        const { color } = this.props;
        return (
            <em className={`state-info-box ${color}`}>{this.props.children}</em>
        );
    };
}

const InfoBox = withTranslation()(InfoBoxComponent);

InfoBox.defaultProps = {
    color: InfoBoxStyle.PRIMARY,
}
export { InfoBox };
