import {
    UPDATE_SUPPORT,
    UPDATE_LOADING,
    UPDATE_FILTER,
    UPDATE_FLOORS,
    SupportDetailActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Support, Filter as FilterInterface, Floor } from "../../../interfaces/interfaces";

export interface SupportDetailState {
    support: Support | undefined,
    loading: boolean,
    filter: FilterInterface,
    floors: Floor[]
}

const INITIAL_STATE: SupportDetailState = {
    support: undefined,
    loading: false,
    filter: new FilterInterface(),
    floors: []
};

const reducer: Reducer<SupportDetailState, SupportDetailActions> = (state = INITIAL_STATE, action: SupportDetailActions) => {
    switch(action.type) {
        case UPDATE_SUPPORT:
            return { ...state, support: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case UPDATE_FLOORS:
            return { ...state, floors: action.payload }
        default:
            return state;
    }
}

export default reducer;