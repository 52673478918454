import { SelectOption } from "../interfaces/interfaces";
import React from "react";
import { InputCheckBox } from "./InputCheckBox";

export interface Props {
	name: string,
	options: SelectOption[],
	default?: SelectOption,
	onChange: (option: SelectOption) => void
}

export interface State {
	selected: SelectOption|null
}

class InputCheckBoxGroup extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			selected: props.default || null
		};
	}

	onChange(option: SelectOption, checked: boolean) {
		if (checked) {
			this.setState({ selected: option });

			this.props.onChange(option);
		}
	}

	render() {
		const { options, name } = this.props;
		const { selected } = this.state;

		return (
			<>
				<fieldset>
					<div className="form-row check-group-title">
						<label>{name}</label>
					</div>
					<div className="check-group-row col-2">
						{options.map(option =>
							<InputCheckBox
								name={option.name}
								key={option.value}
								id={`${name}${option.value}`}
								checked={option.value === selected?.value}
								checkBoxChange={(checked) => this.onChange(option, checked)}
							/>
						)}
					</div>
				</fieldset>
			</>
		);
	}
}

export { InputCheckBoxGroup };
