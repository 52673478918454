import { DataType, SelectOption } from "../interfaces/interfaces";
import { get_with_auth } from "./helper";

const datatype_select_map = (datatype: DataType): SelectOption => ({ value: datatype.id, name: datatype.description });

export const get_countries = get_with_auth(`${process.env.REACT_APP_API_URL}/countries`);
export const get_support_types = get_with_auth<DataType, SelectOption>(`${process.env.REACT_APP_API_URL}/datatypes/supports`, {}, datatype_select_map);
export const get_textile_types = get_with_auth<DataType, SelectOption>(`${process.env.REACT_APP_API_URL}/datatypes/textile_types`, {}, datatype_select_map);
export const get_campaign_statuses = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/campaign_statuses`);
export const get_store_statuses = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/store_statuses`);
export const get_support_statuses = get_with_auth<SelectOption>(`${process.env.REACT_APP_API_URL}/datatypes/support_statuses`);
export const get_sections = get_with_auth<DataType, SelectOption>(`${process.env.REACT_APP_API_URL}/datatypes/sections`, {}, datatype_select_map);
export const get_production_languages = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/production-languages`);
export const get_all_languages = get_with_auth(`${process.env.REACT_APP_API_URL}/languages/`,{});
