import {
    UPDATE_LOADING,
    ActivityIndexActions,
    AppThunk,
    UPDATE_ACTIVITY_TYPES,
    UPDATE_FILTER,
    UPDATE_ACTIVITIES,
    CLEAR_FILTER,
    UPDATE_PAGE,
    UPDATE_TOTAL_PAGES
} from './ActionTypes';
import { ActivityFilter } from '../../../interfaces/interfaces';
import { get_activity_types,get_activities,get_total_pages } from '../../../api';
import { FilterActivityRequest } from '../../../api/interfaces';

export const getActivityTypes = (): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ActivityIndexActions);
        const activity_types = await get_activity_types();
        
        dispatch({
            type: UPDATE_ACTIVITY_TYPES,
            payload: activity_types
        } as ActivityIndexActions);
        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ActivityIndexActions);
    }
}

export const getActivities = (): AppThunk => {

    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ActivityIndexActions);
        let filter=getState().activityIndex.filter;
        let page=getState().activityIndex.page;
        filter.page=page;
        
      
        const activities = await get_activities({
            ...filter
        } as FilterActivityRequest);

        dispatch({
            type: UPDATE_ACTIVITIES,
            payload: activities
        } as ActivityIndexActions);
        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ActivityIndexActions);
       
    }
}
export const updatePage = (value: number): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PAGE,
            payload: value
        } as ActivityIndexActions);
    }
}
export const getTotalPages = (): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ActivityIndexActions);
        let filter=getState().activityIndex.filter;
      
        
       
        const lines = await get_total_pages({
            ...filter
        } as FilterActivityRequest);
        dispatch({
            type: UPDATE_TOTAL_PAGES,
            payload: lines
        } as ActivityIndexActions);
        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ActivityIndexActions);
       
    }
}




export const updateFilter = (value: ActivityFilter): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as ActivityIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as ActivityIndexActions);
    }
}




