import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';

export interface UpdateEmailAction {
    type: typeof UPDATE_EMAIL,
    payload: string
}

export interface UpdateEmailErrorAction {
    type: typeof UPDATE_EMAIL_ERROR,
    payload: string
}

export interface UpdateEmailSuccessAction {
    type: typeof UPDATE_EMAIL_SUCCESS,
    payload: string
}

export type SetingsActions =
UpdateEmailAction |
UpdateEmailSuccessAction |
UpdateEmailErrorAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
