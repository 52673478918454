import { Visual } from "../interfaces/interfaces";

const visualSortByPriority = (a: Visual,b: Visual) => { 
    if (a.priority && b.priority) {
        return a.priority - b.priority;
    } else if (!a.priority && !b.priority) {
        return a.name.localeCompare(b.name);
    } else {
        return a.priority ? -1 : 1;
    }
}


export function sortVisualArrayByPriority(visualArray: Visual[]){
    const newVisualArray = visualArray ? [  ...visualArray  ] : []

    return newVisualArray.sort(visualSortByPriority)
}

export function changeVisualPriority(visuals: Visual[], visual_id:string, new_priority:number){

    visuals.sort(visualSortByPriority)
    visuals = visuals.map( (visual, index) => { return { ...visual, priority: index + 1 } })
    const old_priority = visuals.find( visual => visual.id === visual_id)?.priority

    if( old_priority && visuals ){

        if(old_priority < new_priority){
            visuals = visuals.map( visual =>{ 
                if(visual.priority){
                    if(visual.priority > old_priority && visual.priority  <= new_priority )
                    return { ...visual, priority: visual.priority - 1 }
                
                if(visual.priority === old_priority)
                    return { ...visual, priority: new_priority}
                
                
                }
                return visual
                
            })
        }

        if(old_priority > new_priority){
            visuals = visuals.map( visual =>{ 
                if(visual.priority){
                    if(visual.priority >= new_priority && visual.priority  < old_priority )
                    return { ...visual, priority: visual.priority + 1 }
                
                    if(visual.priority === old_priority)
                        return { ...visual, priority: new_priority}
                
             
                }
                return visual
            })
        }
    }

    return visuals

}