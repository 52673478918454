import {
    UPDATE_SUPPORTS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    SupportIndexActions,
    APPEND_SUPPORTS,
    UPDATE_STORES,
    UPDATE_FLOORS,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Support, Filter, SelectOption, Floor } from "../../../interfaces/interfaces";

export interface SupportsIndexState {
    supports: Support[],
    stores: SelectOption[],
    floors: Floor[],
    loading: boolean,
    filter: Filter,
}

const INITIAL_STATE: SupportsIndexState = {
    supports: [],
    stores: [],
    floors: [],
    loading: false,
    filter: new Filter(),
};

const reducer: Reducer<SupportsIndexState, SupportIndexActions> = (state = INITIAL_STATE, action: SupportIndexActions) => {
    switch(action.type) {
        case UPDATE_SUPPORTS:
            return { ...state, supports: action.payload };
        case APPEND_SUPPORTS:
            return { ...state, supports: [ ...state.supports, ...action.payload] };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new Filter() }
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case UPDATE_FLOORS:
            return { ...state, floors: action.payload };
        default:
            return state;
    }
}

export default reducer;