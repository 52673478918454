import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { Spinner, ContentTop, CampaignCard, PrimaryButton, Filter, FilterRow, EditCampaign, CloseButton, ConfirmationPopUp } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getCampaigns, updateFilter, clearFilter, setLoading } from './CampaignIndexActions';
import { Campaign, FilterFields } from '../../../interfaces/interfaces';
import { getDepartments } from '../../../reducers/datatypes/DatatypesActions';
import { create_campaign } from '../../../api';
import { Role } from '../../../util/jwt';
import { RoleGuard } from '../../../components/RoleGuard';

const mapStateToProps = (state: RootState) => {
	return {
		campaign_index_state: state.campaignIndex,
		datatypes_state: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	setLoading,
	getCampaigns,
	updateFilter,
	clearFilter,
	getDepartments,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showAddModal: boolean,
	showFilter: boolean,
	showCreate: boolean,
	showCreateNotificationPopup: boolean,
	showErrorPopUp: boolean,
	campaignToCreate: Campaign | null,
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showAddModal: false,
			showFilter: false,
			showCreate: false,
			showErrorPopUp: false,
			showCreateNotificationPopup: false,
			campaignToCreate: null
        };
	}

	componentDidMount() {
		this.props.getCampaigns();
		this.props.getDepartments();
	}

	renderFilter() {
		if (this.state.showFilter === true) {
			return (
				<Filter
					filterFields={FilterFields.CAMPAIGN}
					filter={this.props.campaign_index_state.filter}
					onClose={() => this.setState({ showFilter: false })}
					onClear={() => {
						this.props.clearFilter()
						this.setState({ showFilter: false })
						this.props.getCampaigns()
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter)
						this.setState({ showFilter: false })
						this.props.getCampaigns()
				}}/>)
			}
	}

	renderCreate() {
		const { showCreate } = this.state;
		if (showCreate) {
			return (
				<RoleGuard roles={[Role.ADMIN]}>
					<EditCampaign
						departments={ this.props.datatypes_state.departments }
						onClose={() => this.setState({ showCreate: false })}
						onSubmit={(campaign: Campaign) => {
							if(campaign.notifications === undefined){campaign.notifications=false}
							if(campaign.notifications === false){
								this.props.setLoading()
								campaign.notifications=false;
								create_campaign(campaign).then((response) => {
									if(response === undefined){
										this.setState({ showErrorPopUp: true })
									} else {
										this.props.history.push(`/campaigns/${response.id}`)
									}
									this.setState({ showCreate: false, campaignToCreate: null })
									this.props.getCampaigns();
								}).catch(err => console.error);
							}else{
								this.setState({ showCreateNotificationPopup: true, campaignToCreate: campaign })
							}
							
						}}
					/>
				</RoleGuard>
			);
		}
	}

	renderCreatingErrorNotice(){
		const { t } = this.props;
		if (this.state.showErrorPopUp === true) {
			return(
				<section id="confirmation" className="cover-blur active">
				<section className="popup">
					<section className="popup-top">
						<h2>{ t('error') }</h2>
						<CloseButton onClick={() => this.setState({ showErrorPopUp: false})} />
					</section>
					<section className="popup-content">
						<div>
							<p>{ t('error_creating_campaign_check_other_campaign_statuses') }</p>
						</div>
					</section>
					<section className="popup-actions">
						
					</section>
				</section>
			</section>
			);
		}
		
	}

	renderContentTop() {
		const { t } = this.props;
		const { campaigns, filter, totalCampaigns } = this.props.campaign_index_state;

		return (
			<ContentTop>
				<article>
					<div>
						<h2>{ t('campaigns') }</h2>
					</div>
					<div>
						<RoleGuard roles={[Role.ADMIN]}>
							<PrimaryButton onClick={() => this.setState({ showCreate: true })}>
								{ t('create_campaign') }
							</PrimaryButton>
						</RoleGuard>
					</div>
				</article>
				<FilterRow
					filter={ filter }
					results={ campaigns.length || 0 }
					totalResults={ totalCampaigns.length || 0}
					pluralizeStrings={[t('result_found'), t('results_found')]}
					onFilterClicked={() => this.setState({ showFilter: true })}
				/>
			</ContentTop>
		)
	}

	renderCampaigns() {
		const { campaigns } = this.props.campaign_index_state;
		return (
			campaigns.map(campaign => (<CampaignCard key={campaign.id} campaign={campaign}/>))
		)
	}

	renderCreateConfirmationPopup() {
		const { t } = this.props
		const { showCreateNotificationPopup } = this.state
		if (showCreateNotificationPopup)
		return (
			<ConfirmationPopUp
				onClose={() => this.setState({ showCreateNotificationPopup: false, campaignToCreate: null }) }
				onSubmit={() => {
					const { campaignToCreate } = this.state
					if (campaignToCreate !== null) {
						this.setState({ showCreateNotificationPopup: false })
						this.props.setLoading()
							create_campaign(campaignToCreate).then((response) => {
								if(response === undefined){
									this.setState({ showErrorPopUp: true })
								} else {
									this.props.history.push(`/campaigns/${response.id}`)
								}
								this.setState({ showCreate: false, campaignToCreate: null })
								this.props.getCampaigns();
							}).catch(err => console.error);
					}
					
				}}
				title={t('create_campaign')}
				change_text={t('create_campaign_notify_users_warning')}
			/>
		);
	}

	render () {
		const { t } = this.props;
		const { loading } = this.props.campaign_index_state;

		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		return (
			<>
				{this.renderFilter()}
				{this.renderCreate()}
				{this.renderCreateConfirmationPopup()}
				{this.renderContentTop()}
				{this.renderCreatingErrorNotice()}		
				<section id="campaigns">
					{this.renderCampaigns()}
				</section>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
