import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { updateUserError, updatePassword, updatePasswordError, updatePasswordRepeat, updateSignUpError, updateUser } from './SignUpActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { signup } from '../../../api';
import { is_empty_string, is_email } from "../../../util/form";
import { Alert } from '@material-ui/lab';

const mapStateToProps = (state: RootState) => {
	return {
		security_signup_state: state.securitySignUp,
	}
}

const connector = connect(mapStateToProps, {
	updateUser, 
	updateUserError, 
	updatePassword, 
	updatePasswordError, 
	updatePasswordRepeat, 
	updateSignUpError,
    signup
});

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Settings extends React.Component<Props> {

	signup() {
        const { t } = this.props;
		const { user, password } = this.props.security_signup_state;
		
		if (this.validate()) {
			// TODO: Create user
			signup(user, password).then(() => {
				
			});
			this.props.updateSignUpError(t(''));
		}else{
			this.props.updateSignUpError(t('creation_error'));
		}
	}

	validate() {
        const { t } = this.props;
		const { user, password, passwordRepeat } = this.props.security_signup_state;
		let isValid = true;

		this.props.updateUserError('');
		this.props.updatePasswordError('');

		if (!is_email(user)) {
			isValid = false;
			this.props.updateUserError(t('user_should_have_email_format'));
		}
		if (is_empty_string(user)) {
			isValid = false;
			this.props.updateUserError(t('user_is_required'));
		}
		if (is_empty_string(password)) {
			isValid = false;
			this.props.updatePasswordError(t('password_is_required'));
		}

		if(password !== passwordRepeat){
			isValid = false;
			this.props.updatePasswordError(t('passwords_dont_match'));
		}

		return isValid;
	}

	renderSignUpError() {
		if (this.props.security_signup_state.signUpError) {
			return (
				<article className="form-row">
					<Alert severity="error">{this.props.security_signup_state.signUpError}</Alert>
				</article>
			);
		}
	}

	render () {
		const { t } = this.props;
		return (
			<main id="loginContainer">
				<section id="signup-box">
					<Link to="/">
						<h1 className="text-center">
							<img src="../../img/corporative/primark.svg" alt="PRIMARK" />
						</h1>
					</Link>
					<article id="content">
						<h2 className="mb-24">{t('create_account')}</h2>
						{this.renderSignUpError()}
						<form>
							<div className="form-row">
								<article className="form-item">
									<label htmlFor="mail">{t('email')}</label>
									<input id="mail" className="field field--text" type="text" 
									placeholder="jessica.hanson@example.com" 
									onChange={(event) => this.props.updateUser(event.target.value)} />
								</article>
							</div>
							<div className="form-row">
								<article className="form-item">
									<label htmlFor="password">{t('password')}</label>
									<input id="password" className="field field--text" type="password" 
									onChange={(event) => this.props.updatePassword(event.target.value)} />
								</article>
							</div>
							<div className="form-row">
								<article className="form-item">
									<label htmlFor="password">{t('repeat_password')}</label>
									<input id="passwordRepeat" className="field field--text" type="password"  
									onChange={(event) => this.props.updatePasswordRepeat(event.target.value)} />
								</article>
							</div>
							<article className="login-actions">
								<button type="button"  onClick={() => this.signup()}  className="btn-base fill-primary btn-xl w-100 mb-24">{t('create_account')}</button>
							</article>
						</form>
					</article>
				</section>
			</main>	
		);
	}
}

export default withTranslation()(connector(Settings));
