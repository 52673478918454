import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormText, CloseButton, FormImage, PrimaryButton } from '.';
import { check_available_email, get_profile } from '../api';
import { User } from "../interfaces/interfaces";
import { is_empty_string } from '../util/form';

export interface EditUserProps {
    onClose: () => void,
    onSubmit: (user: User) => void
    user?: User | null
}

interface State {
    user_email_error: string,
    user_name_error: string,
    user_last_name_error: string,
    user: User | null,
    openEditpopUp?: boolean,
    password: string
}

type EditUserPropsSum = EditUserProps & WithTranslation;

class EditUserComponent extends React.Component<EditUserPropsSum, State> {

    constructor(props: EditUserPropsSum) {
        super(props);

        this.state = {
            user_email_error: '',
            user_name_error: '',
            user_last_name_error: '',
            user: (this.props.user)?this.props.user!:null,
            password: '********'
        };
    }

    async renderUser() {
        if(this.state.user === null || this.state.user === undefined){
            // Si se pasa un usuario "null", se tomará por defecto el actual
            this.setState( { user: await get_profile() as unknown as User } );
        }
    }

    onSubmit() {
        const { user } = this.state;
        this.props.onSubmit(user as User);
    }

    async submit() {
        const { id, email, name, last_name, photo, password } = this.state.user!;

        if (await this.isValid()) {
                this.props.onSubmit({
                    id,
                    email,
                    name,
                    last_name,
                    photo,
                    password
                } as User);
        }
    }

    async isValid() {
        const { t } = this.props;
        let isValid = true;
        this.setState({
            user_email_error: '',
        });

        if (is_empty_string(this.state.user?.email)) {
            isValid = false;
            this.setState({ user_email_error: t('user_email_required')});
		}

        const emailAvailable = await check_available_email(this.state.user!);
        if(emailAvailable === null || emailAvailable === false){
            isValid = false;
            this.setState({ user_email_error: t('email_is_not_available')});
        }

        if (is_empty_string(this.state.user?.name) ) {
            isValid = false;
            this.setState({ user_name_error: t('user_name_required')});
		}

        if (is_empty_string(this.state.user?.last_name) ) {
            isValid = false;
            this.setState({ user_last_name_error: t('user_last_name_required')});
		}

        return isValid;
    }

    resetPass(){
        this.setState({ openEditpopUp: false });
        window.location.href = "/password-reset";
    }

    render () {
        const { t} = this.props;
        const { user } = this.state;
        this.renderUser();

        return (
            <section id="user-edit" className="cover-blur /*active*/ active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('profile') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content">
                        <FormImage photo={this.state.user?.photo} onChange={(photo) => {
                            this.setState({ user: { ...user, photo: photo } as User })
                        }}/>
                        <fieldset className="formlines-container">

                            <div className="form-row col-d-1-1">
                                <FormText id="name" label={ t('name') } value={ (user)?user.name:'' } onChange={(text) => this.setState({ user: { ...user, name: text } as User })} helpText={this.state.user_name_error}/>
                                <FormText id="last_name" label={ t('last_name') } value={ (user)?user.last_name:'' }  onChange={(text) => this.setState({ user: { ...user, last_name: text } as User })} helpText={this.state.user_last_name_error}/>
                            </div>
                            
                            <div className="form-row">
                                <FormText id="email" label={ t('email') } value={ (user)?user.email:'' }  onChange={(text) => this.setState({ user: { ...user, email: text } as User })}  helpText={this.state.user_email_error}/>
                            </div>

                            <div className="form-row">
								<article className="form-item">
									<label htmlFor="password">{t('password')}</label>
                                    <input id="password" className="field field--text" type="password" value={ (user)?this.state.password:'' }  disabled onChange={() => undefined}/>
								</article>
                            </div>
                            
                            <div className="form-row col-d-1-1">
                                <span></span>
                                <button onClick={() => this.resetPass() }  className="btn-outline outline-primary btn-m"><label htmlFor="change_password">{ t('change_password') }</label></button>
                            </div>

                        </fieldset>
                    </section>  
                    <section className="popup-actions">
                        <PrimaryButton onClick={async () => await this.submit()} >{ t('save_changes') }</PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const EditUser = withTranslation()(EditUserComponent);
export { EditUser };