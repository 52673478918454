import React from 'react';
import { Link } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Globe, Edit2, LogOut } from 'react-feather';
import { ClickHandler } from '.';
import cookies, { COOKIE_USER_LANGUAGE, COOKIE_USER_NAME, COOKIE_USER_PHOTO, COOKIE_USER_TOKEN, get_user_language, get_user_name, get_user_photo } from '../util/cookies';
import { edit_language, edit_user } from '../api';
import i18n from 'i18next';
import { EditUser } from './EditUser';
import { User } from '../interfaces/interfaces';

export interface HeaderProps {
}

interface HeaderState {
    openUserMenu: boolean,
    openLanguageMenu: boolean,
    activeLanguage: string,
    openEditpopUp?: boolean
}

type HeaderPropsSum = HeaderProps & WithTranslation;

class HeaderComponent extends React.Component<HeaderPropsSum, HeaderState> {

    constructor(props: HeaderPropsSum) {
        super(props);

        this.state = {
            openUserMenu: false,
            openLanguageMenu: false,
            activeLanguage: '',
        };
    }

    componentDidMount() {
        this.setState({ activeLanguage: this.getCurrentLanguage() })
    }

    getCurrentLanguage() {
        return get_user_language();
    }

    HeaderPopLanguageMenuClass() {
        return this.state.openLanguageMenu ? 'open' : 'close';
    }

    HeaderPopUserMenuClass() {
        return this.state.openUserMenu ? 'open' : 'close';
    }

    setLanguage(value: string) {
        cookies.set(COOKIE_USER_LANGUAGE, value.toLowerCase(), { path: '/' });
        this.setState({ activeLanguage: value })
        edit_language(value.toUpperCase());
        i18n.changeLanguage(value.toLowerCase(), (err, t) => {
            if (err) return console.log('something went wrong loading', err);
        });
        this.setState({ openLanguageMenu: !this.state.openLanguageMenu });
    }

    renderLanguages(){
        const { activeLanguage } = this.state;
        const { t } = this.props; 

        const languages = { 'esp' : t('Spanish'), 'enu': t('English')}
        return(
            <ul className={`submenu ${this.HeaderPopLanguageMenuClass()}`}>
                { Object.keys(languages).map((languageKey) => 
                    <li  key={languageKey} className= {  (activeLanguage === languageKey) ? 'active' : '' } >
                        <Link onClick={ () => this.setLanguage(languageKey)}
                            to={`?lang=${languageKey}`}>{t(languages[languageKey as keyof typeof languages]) }
                        </Link>
                    </li>
                 )}
            </ul>)
    }

     renderUserEditPopUp(){
        const { openEditpopUp } = this.state;
        if(openEditpopUp){
            return (
                <EditUser       
                    onClose={() => this.setState({ openEditpopUp: false })}
                    onSubmit={(user: User) => {
                        edit_user(user).then(() => {
                            this.setState({ openEditpopUp: false });
                            cookies.set(COOKIE_USER_NAME, user.name + ' ' + user.last_name, { path: '/' });
							cookies.set(COOKIE_USER_PHOTO, user.photo, { path: '/' });
                            this.forceUpdate();
                        }).catch(err => console.error);
                    } }
                />
            );
        }
    }

    render () {
        const { t } = this.props;
        const { openLanguageMenu, openUserMenu } = this.state;
        
        return (
            <>
                <header>
                    <Link to="/"><h1><img src={'/img/corporative/primark.svg'} alt="PRIMARK"/></h1></Link>
                    <article>
                        <nav id="lang">
                            <ul>
                                <li>
                                    <ClickHandler onClickedOutside={() => this.setState({ openLanguageMenu: false })}>
                                        <div><Globe onClick={() => this.setState({ openLanguageMenu: !openLanguageMenu })} /><span>{ t('Select lang') }</span></div>
                                        {this.renderLanguages()}
                                    </ClickHandler>
                                </li>
                            </ul>
                        </nav>
                        {/* <Link to="#" className="notification active"><Bell /><span>{ t('Notifications') }</span></Link> */}
                        <nav id="profileConfig" className={ this.HeaderPopUserMenuClass() }>
                            <ul>
                                <li>
                                    <ClickHandler onClickedOutside={() => this.setState({ openUserMenu: false })}>
                                        <button className="btn-profile" onClick={() => this.setState({ openUserMenu: !openUserMenu })}>
                                            {get_user_photo() !== null && get_user_photo() !== "null" && get_user_photo() !== "" ? <img width={32} height={32} src={get_user_photo()} alt={get_user_name()} />:<img width={32} height={32} src={"/img/icons/profile_placeholder.png"} alt={get_user_name()} />}
                                            <span>{get_user_name()}</span>
                                        </button>
                                        <ul className="submenu">
                                            <li><Link to="#" onClick={() => this.setState({ openEditpopUp: true })} ><Edit2 /> { t('Edit profile') }</Link></li>
                                            <li className="logout"><a href='/' onClick={() => cookies.remove(COOKIE_USER_TOKEN)}><LogOut />{ t('Log out') }</a></li>
                                        </ul>
                                    </ClickHandler>
                                </li>
                            </ul>
                        </nav>
                    </article>
                </header>
                {this.renderUserEditPopUp()}
            </>
        );
    };
}

const Header = withTranslation()(HeaderComponent);
export { Header };
