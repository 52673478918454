import {
    UPDATE_LOADING,
    UPDATE_STORE,
    StoreValidateImagesActions,
    SELECT_SUPPORT,
    UPDATE_ACTIVE_CAMPAIGN,
    UPDATE_GALLERY_VISIBLE_CAMPAIGN_VISUALS,
    UPDATE_FILTER,
    UPDATE_CAMPAIGNS,
    UPDATE_KENDU_PROCESSING
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Campaign, Filter, Store, Support, Visual } from "../../../interfaces/interfaces";

export interface StoreValidateImagesState {
    loading: boolean,
    store: Store|null,
    selected_support: Support|null,
    active_campaign: Campaign | null,
    visuals: Visual [],
    filter: Filter,
    campaigns: Campaign[],
    kendu_processing:boolean
}

const INITIAL_STATE: StoreValidateImagesState = {
    loading: false,
    store: null,
    selected_support: null,
    active_campaign: null,
    visuals: [],
    filter: new Filter(),
    campaigns: [],
    kendu_processing:false
};

const reducer: Reducer<StoreValidateImagesState, StoreValidateImagesActions> = (state = INITIAL_STATE, action: StoreValidateImagesActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_STORE:
            return { ...state, store: action.payload };
        case SELECT_SUPPORT:
            return { ...state, selected_support: action.payload };
        case UPDATE_ACTIVE_CAMPAIGN:
            return { ...state, active_campaign: action.payload };
        case UPDATE_GALLERY_VISIBLE_CAMPAIGN_VISUALS:
            return { ...state, visuals: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case UPDATE_CAMPAIGNS:
            return { ...state, campaigns: action.payload };
        case UPDATE_KENDU_PROCESSING:
            return {...state,kendu_processing: action.payload}
        default:
            return state;
    }
}

export default reducer;