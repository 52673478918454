import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Visual, ViewCampaignStyle } from '../interfaces/interfaces';


export interface VisualSupportCardProps {
    visual: Visual,
    style?: ViewCampaignStyle,
    start_date?: string,
    end_date?: string,
    onVisualClick?: () => void,
}

type VisualSupportCardPropsSum = VisualSupportCardProps & WithTranslation;

class VisualSupportCardComponent extends React.Component<VisualSupportCardPropsSum> {

    render () {
        const { visual, onVisualClick, start_date, end_date } = this.props;
        return(
            <li>
                <div className="image__contain hover__enlarge" onClick={ onVisualClick }>
                    <img src={`${visual.thumbnail}`} alt={`${visual.name}`} className="cursor-hand" />
                </div>
                <div className="time-item-txt">
                    <div>
                        <h4>{visual.name}</h4>
                        <p>{start_date} &gt; {end_date}</p>
                    </div>
                </div>
            </li>
        );
    };
}

const VisualSupportCard = withTranslation()(VisualSupportCardComponent);
export { VisualSupportCard };