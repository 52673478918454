import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../reducers';
import { User } from '../interfaces/interfaces';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';

export interface UpdateTokenAction {
    type: typeof UPDATE_TOKEN,
    payload: string
}

export interface GetUserAction {
    type: typeof GET_USER,
    payload: User
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER,
    payload: User
}

export type AuthActions =
UpdateTokenAction | GetUserAction | UpdateUserAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
