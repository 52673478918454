import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SpinnerProps {
    message: string
}

type SpinnerPropsSum = SpinnerProps & WithTranslation;

class SpinnerComponent extends React.Component<SpinnerPropsSum> {

    render () {
        return (
            <div className="spinner-inline">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    };
}

const SpinnerInline = withTranslation()(SpinnerComponent);
export { SpinnerInline };
