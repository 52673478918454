import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign, Support } from "../../../interfaces/interfaces";

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_CAMPAIGN = 'update_campaign';
export const UPDATE_SUPPORTS_WITH_VISUAL = 'update_supports_with_visual';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateStoresAction {
    type: typeof UPDATE_CAMPAIGN,
    payload: Campaign
}

export interface UpdateSupportsWithVisualAction {
    type: typeof UPDATE_SUPPORTS_WITH_VISUAL,
    payload: Support[]
}

export type GalleryDetailActions =
UpdateLoadingAction |
UpdateStoresAction |
UpdateSupportsWithVisualAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
