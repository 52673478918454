import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Spinner, ContentTop, ActivityFilterRow,ActivityFilter } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import {getActivities,getActivityTypes,updateFilter,clearFilter,updatePage,getTotalPages} from './ActivityIndexActions';
import { Icon } from '../../../components';
import Pagination from '@material-ui/lab/Pagination';

const mapStateToProps = (state: RootState) => {
	return {
		activity_index_state: state.activityIndex,
	}
}

const connector = connect(mapStateToProps, {
	getActivities,
	getActivityTypes,
	updateFilter,
	clearFilter,
	updatePage,
	getTotalPages
});



type Props =  ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showFilter: boolean,
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showFilter:false
        };
	}

	componentDidMount() {
		this.props.getActivities();
		this.props.getActivityTypes();
		this.props.getTotalPages();
		
	}

	renderContentTop() {
		const { t } = this.props;
		const { showFilter } = this.state;
		const { filter } = this.props.activity_index_state;

		return (
			<ContentTop>
				<article>
					<div>
						<h2>{ t('activity_history') }</h2>
					</div>
					
					<div>
					<ActivityFilterRow
					filter={filter}
					pluralizeStrings={[t('result_found'), t('results_found')]}
					onFilterClicked={() => this.setState({ showFilter: !showFilter})}
				/>
				
					</div>
				</article>
				
			</ContentTop>)
	}

	renderFilter() {
		const { showFilter } = this.state;
		const { filter } = this.props.activity_index_state;
		const { activity_types } = this.props.activity_index_state;
	
		if (showFilter) {
		  return (
			<ActivityFilter
			  filter={filter}
			  activities_types_options={activity_types.map((row)=>({
				name: row.name,
				value: row.name
			  }))}
			  onClose={() => this.setState({ showFilter: !showFilter })}
			  onClear={() => {
				this.setState({ showFilter: !showFilter });
				this.props.clearFilter();
				this.props.getActivities();
			  }}
			  onSubmit={(filter) => {
				this.setState({ showFilter: !showFilter });
				this.props.updateFilter(filter);
				this.props.getActivities();
			  }}
			/>
		  );
		}
	  
		// Agregar un valor de retorno para el caso en que showFilter sea falso
		return null;
	  }
	updatePage(direction:string){
		const { page } = this.props.activity_index_state;
		let currentPage=1;
		if(direction==='up'){
			currentPage = page+1;
		}else{
			currentPage = page-1;
		   	if(currentPage<=0){
				currentPage=1;
			}
			
		}
		this.props.updatePage(currentPage);
		this.props.getActivities()
	}
	handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		// Aquí debes actualizar el estado de la página actual usando "value"
		this.props.updatePage(value);
		this.props.getActivities()
	};
	  
	renderTable() {
		const { activities,page,total_pages } = this.props.activity_index_state;
		const { t } = this.props;
		return (
			<div>
				

				<div style={{display:'flex'}}>
				<button onClick={()=>this.props.getActivities()}><Icon type="refresh-icon" /></button>
				<Pagination
                    count={total_pages}
                    variant="outlined"
                    page={page}
                    onChange={this.handleChange} // Usa el método handleChange
                />
				</div>
				
				
				<table className='table table-slim'>
					<thead>
					  <tr>
						<th className='w-28 text-left'>{t('cron_name')}</th>
						<th className='w-18 text-left'>{t('campaign_id')}</th>
						<th className='w-18 text-left'>{t('message_type')}</th>
						<th className='w-18 text-left'>{t('message')}</th>
						<th className='w-18 text-left'>{t('date')}</th>
					  </tr>
					</thead>
					<tbody>
					  {activities.map((activity) => (
						<tr key={activity.id}>
						  <td>{activity.activity_type}</td>
						  <td>{activity.campaign_num}</td>
						  <td>{activity.message_type}</td>
						  <td>{activity.message}</td>
						  <td>{activity.created_at}</td>
						</tr>
					  ))}
					</tbody>
		  			</table>
					<br/>
					<div style={{display:'flex'}}>
						<button onClick={()=>this.props.getActivities()}><Icon type="refresh-icon" /></button>
						<Pagination
                		    count={total_pages}
                		    variant="outlined"
                		    page={page}
                		    onChange={this.handleChange} // Usa el método handleChange
                		/>
					</div>
			</div>
		  
		);
	  }
	  
	 

	render () {
		const { loading } = this.props.activity_index_state;
		const { t } = this.props;
		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		
		return (
			<>
				{this.renderFilter()}
				{this.renderContentTop()}
				{this.renderTable()}
			</>
		);
	}
}

export default withTranslation()(connector(Index));
