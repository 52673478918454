import { UPDATE_LOADING, UPDATE_ACTIVE_CAMPAIGN, UPDATE_STORES, DashboardIndexActions, UPDATE_FILTER, CLEAR_FILTER, UPDATE_STORE_STATS, UPDATE_TOTAL_STORES } from "./ActionTypes";
import { Reducer } from 'redux';
import { Campaign, Store, Filter } from "../../../interfaces/interfaces";

export interface DashboardIndexState {
    loading: boolean,
	active_campaign: Campaign | null,
	stores: Store[],
	filter: Filter,
	total_stores: number,
	installed_stores: number,
	approved_stores: number,
	totalStores: Store[]
}

const INITIAL_STATE: DashboardIndexState = {
    loading: false,
	active_campaign: null,
	stores: [],
	filter: new Filter(),
	total_stores: 0,
	installed_stores: 0,
	approved_stores: 0,
	totalStores: []
};

const reducer: Reducer<DashboardIndexState, DashboardIndexActions> = (state = INITIAL_STATE, action: DashboardIndexActions) => {
    switch(action.type) {
		case UPDATE_LOADING:
			return { ...state, loading: action.payload };
		case UPDATE_ACTIVE_CAMPAIGN:
			return { ...state, active_campaign: action.payload };
		case UPDATE_STORES:
			return { ...state, stores: action.payload };
		case UPDATE_FILTER:
			return { ...state, filter: action.payload };
		case CLEAR_FILTER:
			return { ...state, filter: new Filter() };
		case UPDATE_STORE_STATS:
			return { 
				...state, 
				total_stores: action.payload.total_stores,
				installed_stores: action.payload.installed_stores,
				approved_stores: action.payload.approved_stores
			}
		case UPDATE_TOTAL_STORES:
            return { ...state, totalStores: action.payload }
        default:
            return state;
    }
}

export default reducer;
