import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormSelect, Icon } from '.';
import { Visual, ViewCampaignStyle,CampaignStatusPrimark } from '../interfaces/interfaces';
import { SecondaryButton } from '../components';
import { InputCheckBoxStatus } from './InputCheckBoxStatus';
import { set_adaptation } from '../api';
import { SpinnerInline } from './SpinnerInline';
import { RoleGuard } from './RoleGuard';
import { Role } from '../util/jwt';

export interface VisualCardProps {
    visual: Visual, 
    changePriority?: boolean,
    style?: ViewCampaignStyle,
    start_date?: string,
    end_date?: string,
    priority: number,
    campaign_status:CampaignStatusPrimark|undefined,
    max_priority?: number,
    priority_no?:boolean,
    onVisualClick?: () => void,
    onInfoClick?: () => void,
    onTagsChange?: (text: string[]) => void
    onChangePriorityClick?: (new_priority: number) => void,
    onSupportClicked?: (visual_id: string) => void
}

interface State {
	loading: boolean
}

type VisualCardPropsSum = VisualCardProps & WithTranslation;

class VisualCardComponent extends React.Component<VisualCardPropsSum, State> {

	constructor(props: VisualCardPropsSum) {
		super(props);

		this.state = {
			loading: false
		};
	}

	renderAdaptation() {

		const { t, visual,campaign_status } = this.props;
        let statuses=[CampaignStatusPrimark.LIVE,CampaignStatusPrimark.SALESPOINT_VALIDATING, CampaignStatusPrimark.END_PASSED];
        let findStatuses=statuses.find((status)=>status===campaign_status);
        let disabled=false;
        if(findStatuses){
            disabled=true;
        }
		return <InputCheckBoxStatus id={visual.id} disabled={disabled} cssClass={visual.adaptation ? 'campaigns-check flex gap-8 checked' : 'campaigns-check flex gap-8'} name={t('adaptation')} checked={visual.adaptation} checkBoxChange={(checked) => {
			this.setState({ loading: true });

			set_adaptation(visual.id, checked).then(result => {
				if (result) {
					visual.adaptation = checked;
				}

				this.setState({ loading: false });
			});
		}}/>
	}

    renderGridStyle() {
        const { visual, onVisualClick, onChangePriorityClick, max_priority, priority, changePriority } = this.props;

        return(
            <article className="card-grid-full">
                {/* <div className="card-image image-covered" style={{ backgroundImage: `url("${visual.thumbnail}")` }} onClick={ onVisualClick }></div> */}
                <div className="card-image" onClick={ onVisualClick }>
                    <img src={visual.thumbnail} alt='' />
                </div>
                <div className="card-content">
                    <h3>{ visual.name }</h3>
                        { changePriority && 
                            <FormSelect id="priority" 
                                options = { Array.apply(null, Array(max_priority)).map((value, index) => {return {value: (index+1).toString() ,name: (index+1).toString()} }) }
                                value={ priority!.toString() } 
                                onChange={(new_priority) => onChangePriorityClick!(Number(new_priority))}
                            />
                        }
                        <div className='priority-checkbox'>
                        { !changePriority && 
                            <ul className="priority-assignment">
                                <li>{ priority!.toString().padStart(2,"0") }</li>
                            </ul>
                        }
						<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR,Role.HEAD_VM]}>
							{this.renderAdaptation()}
						</RoleGuard>
                        </div>
                </div>
            </article>
        )
    }

    renderListStyle() {
        const { visual, onVisualClick, onChangePriorityClick, max_priority, priority, changePriority, t, onSupportClicked, priority_no } = this.props;

        if (onSupportClicked) {
            return (
                <article className="element">
                    <ul>
                        <li>
                            <div className="image__contain hover__enlarge" onClick={ onVisualClick }>
                                <img src={visual.thumbnail} alt='' />
                            </div>
                            <div className="header">
                                <div>
                                    <h3>{ visual.name }</h3>
                                    { !changePriority && 
                                        <ul>
                                            <li>{ `${t('priority')} ${priority}` }</li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </li>
                        <li className='campaign-checkbox flex gap-24' style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {
                                this.state.loading && <SpinnerInline message="" />
                            }
                            {
                                priority_no &&    <Icon type="gallery-alert" />
                            }
                            { changePriority && 
                                <FormSelect id="priority" 
                                    options = { Array.apply(null, Array(max_priority)).map((value, index) => {return {value: (index+1).toString() ,name: (index+1).toString()} }) }
                                    value={ priority!.toString() } 
                                    onChange={(new_priority) => onChangePriorityClick!(Number(new_priority))}
                                />
                            }
						<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR, Role.HEAD_VM]}>
							{this.renderAdaptation()}
						</RoleGuard>
                        </li>

                        <SecondaryButton onClick={() => {
                                onSupportClicked(visual.id)
                            }}>
                            <span>CSV</span>
                        </SecondaryButton>
                    </ul>
                </article>
            );
        } else {
            return (
                <article className="element">
                    <ul>
                        <li>
                            <div className="image__contain hover__enlarge" onClick={ onVisualClick }>
                                <img src={visual.thumbnail} alt='' />
                            </div>
                            <div className="header">
                                <div>
                                    <h3>{ visual.name }</h3>
                                    { !changePriority && 
                                        <ul>
                                            <li>{ `${t('priority')} ${priority}` }</li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </li>
                        <li className='campaign-checkbox flex gap-24'>
                            {
                                this.state.loading && <SpinnerInline message="" />
                            }                            
                            { changePriority && 
                                <FormSelect id="priority" 
                                    options = { Array.apply(null, Array(max_priority)).map((value, index) => {return {value: (index+1).toString() ,name: (index+1).toString()} }) }
                                    value={ priority!.toString() } 
                                    onChange={(new_priority) => onChangePriorityClick!(Number(new_priority))}
                                />
                            }
                            <RoleGuard roles={[Role.ADMIN, Role.BACKDOOR,Role.HEAD_VM]}>
                                {this.renderAdaptation()}
                            </RoleGuard>
                        </li>
                    </ul>
                </article>
            );
        }
    }

    renderSwitch = () => {
        switch (this.props.style) {
            case (ViewCampaignStyle.GRID): return this.renderGridStyle()
            case (ViewCampaignStyle.LIST): return this.renderListStyle()
            default: return this.renderGridStyle()
        }
    }

    render () {
        return(<>{ this.renderSwitch() }</>)
    };
}

const VisualCard = withTranslation()(VisualCardComponent);
export { VisualCard };
