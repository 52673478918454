import { User } from '../../../interfaces/interfaces';
import {
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_ERROR,
    UPDATE_PASSWORD_REPEAT,
    UPDATE_PASSWORD_REPEAT_ERROR,
    UPDATE_USER,
    UPDATE_HASH_ERROR,
    SetingsActions,
    AppThunk,
} from './ActionTypes';

export const updatePassword = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PASSWORD,
            payload: value
        } as SetingsActions);
    }
}

export const updatePasswordError = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PASSWORD_ERROR,
            payload: value
        } as SetingsActions);
    }
}

export const updateHashError = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_HASH_ERROR,
            payload: value
        } as SetingsActions);
    }
}

export const updateUser = (user: User | null): AppThunk => {
    return async dispatch => {
        dispatch({
                type: UPDATE_USER,
                payload: user
            } as SetingsActions);
    }
}

export const updatePasswordRepeat = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PASSWORD_REPEAT,
            payload: value
        } as SetingsActions);
    }
}

export const updatePasswordRepeatError = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PASSWORD_REPEAT_ERROR,
            payload: value
        } as SetingsActions);
    }
}