import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormText, PrimaryButton, CloseButton, FormSelect, FormSelectSearchable } from '.';
import { SelectOption, Store, PrimaryButtonStyle } from "../interfaces/interfaces";
import { is_empty_string } from '../util/form';
import { check_available_store_code } from '../api'

export interface EditStoreProps {
    store?: Store,
    countries?: SelectOption[],
    languages?: SelectOption[],
    onClose: () => void,
    onSubmit: (store: Store) => void
}

interface State {
    store: Store | undefined,
    store_name_error: string,
    store_post_code_error: string,
    store_address_error: string,
    store_country_error: string,
    store_city_error: string,
    store_manager_name_error: string,
    store_manager_email_error: string,
    store_manager_phone_error: string,
    store_language_error: string,
    store_phone_error: string,
    store_customer_shop_no_error: string,
}

type EditStorePropsSum = EditStoreProps & WithTranslation;

class EditStoreComponent extends React.Component<EditStorePropsSum, State> {

    constructor(props: EditStorePropsSum) {
        super(props);

        this.state = {
            store: {...props.store} as Store,
            store_name_error: '',
            store_post_code_error: '',
            store_address_error: '',
            store_country_error: '',
            store_city_error: '',
            store_manager_name_error: '',
            store_manager_email_error: '',
            store_manager_phone_error: '',
            store_language_error: '',
            store_phone_error: '',
            store_customer_shop_no_error: ''
        };
    }

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    async onSubmit() {
        const { store } = this.state;
        
        if (await this.isValid()) {
            this.props.onSubmit(store as Store);
        }
    }

    async isValid() {
        const { t } = this.props;
        const { telefono, idioma, name, codigo_postal, direccion, pais, ciudad, customer_shop_no } = this.state.store!;
        let isValid = true;


        this.setState({
            store_name_error: '',
            store_post_code_error: '',
            store_address_error: '',
            store_country_error: '',
            store_city_error: '',
            store_manager_name_error: '',
            store_manager_email_error: '',
            store_manager_phone_error: '',
            store_language_error: '',
            store_phone_error: '',
            store_customer_shop_no_error: ''
        });

        if (is_empty_string(customer_shop_no)) {
            isValid = false;
            this.setState({ store_customer_shop_no_error:t('store_customer_shop_no_required')});
        } else if (customer_shop_no.length !== 3) {
            isValid = false;
            this.setState({ store_customer_shop_no_error:t('store_customer_shop_no_length')});
		} else if (customer_shop_no !== this.props.store?.customer_shop_no) {
            const used = await check_available_store_code(customer_shop_no)
            if (!used) {
                isValid = false;
                this.setState({ store_customer_shop_no_error:t('store_number_not_available')});
            }
        }
        if (is_empty_string(name)) {
            isValid = false;
            this.setState({ store_name_error:t('store_name_required')});
		}
        if (is_empty_string(idioma)) {
            isValid = false;
            this.setState({ store_language_error:t('store_language_error')});
		}
        if (is_empty_string(telefono)) {
            isValid = false;
            this.setState({ store_phone_error:t('store_phone_error')});
		}
        if (is_empty_string(codigo_postal)) {
            isValid = false;
            this.setState({ store_post_code_error:t('store_post_code_required')});
		}
        if (is_empty_string(direccion)) {
            isValid = false;
            this.setState({ store_address_error:t('store_address_required')});
		}
        if (!pais) {
            isValid = false;
            this.setState({ store_country_error:t('store_state_required')});
		}
        if (is_empty_string(ciudad)) {
            isValid = false;
            this.setState({ store_city_error:t('store_city_required')});
		}

        return isValid
    }

    render () {
        const { t, countries, languages } = this.props;
        const { store } = this.state;

        const sortedCountries = countries?.sort((n1,n2) => {
            if (n1.name > n2.name) {
                return 1;
            }
        
            if (n1.name < n2.name) {
                return -1;
            }
        
            return 0;
        });

        return (
            <section id="popUp-edit-store" className="cover-blur active date-range-space-between">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ !store?.id ? t('new store') : t('edit store') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content formlines-container">
                        <fieldset>
                            <div className="form-row col-d-3-1">
                                <FormText id="name" label={ t('name') } value={ store?.name } onChange={(text) => this.setState({ store: { ...store, name: text } as Store })} helpText={this.state.store_name_error}/>
                                <FormText id="code" label={ t('code') } value={ store?.customer_shop_no } onChange={(text) => {
                                    if (text !== "" && text !== this.props.store?.customer_shop_no) check_available_store_code(text).then((used) => {
                                        if (!used) {
                                            this.setState({ store_customer_shop_no_error:t('store_number_not_available')});
                                        } else {
                                            this.setState({ store_customer_shop_no_error: '' });
                                        }
                                    })
                                    this.setState({ store: { ...store, customer_shop_no: text } as Store })
                                }} helpText={this.state.store_customer_shop_no_error} />
                            </div>
                        </fieldset>
                        <fieldset className="formlines-container">
                            <div className="form-row">
                                <legend>{ t('store_address') }</legend>
                            </div>
                            <div className="form-row col-d-3-1">
                                <FormText id="address" label={ t('address')+' '+t('(max 50 characters)') } value={ store?.direccion } onChange={(text) => this.setState({ store: { ...store, direccion: text } as Store })} helpText={this.state.store_address_error} maxLength={50}/>
                                <FormText id="post-code" label={ t('post_code') } value={ store?.codigo_postal } onChange={(text) => this.setState({ store: { ...store, codigo_postal: text } as Store })} helpText={this.state.store_post_code_error}/>
                            </div>
                            <div className="form-row col-d-1-1">
                                <FormSelectSearchable id="country" label={ t('country') } options={ sortedCountries } value={ store?.pais } onChange={(value, label) => this.setState({ store: { ...store, pais: value } as Store })} helpText={this.state.store_country_error}/>
                                <FormText id="city" label={ t('city') } value={store?.ciudad} onChange={(text) => this.setState({ store: {...store, ciudad: text} as Store })} helpText={this.state.store_city_error}/>
                            </div>
                            <div className="form-row col-d-1-1">
                                <FormSelect id="language" label={ t('language') } options={ languages } value={ store?.idioma } onChange={(value, label) => this.setState({ store: { ...store, idioma: value } as Store })} helpText={this.state.store_language_error}/>
                                <FormText id="store-manager-phone" label={ t('store_phone') } value={ store?.telefono } onChange={(text) => this.setState({ store: { ...store, telefono: text } as Store })} helpText={this.state.store_phone_error}/>
                            </div>
                        </fieldset>
                    </section>
                    <section className="popup-actions">
                        <PrimaryButton onClick={() => this.onSubmit()} style={ PrimaryButtonStyle.FILLED }>{ t('save') }</PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const EditStore = withTranslation()(EditStoreComponent);
export { EditStore };
