import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PrimaryButton, CloseButton } from '.';
import { PrimaryButtonStyle } from '../interfaces/interfaces';

export interface ModalProps {
    title: string,
    onClose: () => void,
    onCancel?: () => void,
    onSubmit?: () => void
}

interface State {
}

type ModalPropsSum = ModalProps & WithTranslation;

class ModalComponent extends React.Component<ModalPropsSum, State> {

    constructor(props: ModalPropsSum) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    render () {
        const { t, title, onClose, onCancel, onSubmit } = this.props;
        return (
            <section id="popUp-modal" className="cover-blur active date-range-space-between">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ title }</h2>
                        <CloseButton onClick={() => onClose()} />
                    </section>
                    <section className={`popup-content pb-${onCancel || onSubmit ? '111' : '40'}`}>
                        { this.props.children }
                    </section>
                    <section className="popup-actions">
                        {onCancel && <PrimaryButton onClick={() => onCancel()} style={ PrimaryButtonStyle.OUTLINED }>{ t('cancel') }</PrimaryButton>}
                        {onSubmit && <PrimaryButton onClick={() => onSubmit()}>{ t('save') }</PrimaryButton>}
                    </section>
                </section>
            </section>
        );
    };
}

const Modal = withTranslation()(ModalComponent);
export { Modal };
