import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface InputCheckBoxProps {
    cssClass?: string,
    name: string,
    id: string,
    checked: boolean,
    checkBoxChange: (value: boolean) => void,
}

type InputCheckBoxPropsSum = InputCheckBoxProps & WithTranslation;

class InputCheckBoxComponent extends React.Component<InputCheckBoxPropsSum> {

    render () {
        const { name, id, cssClass } = this.props;
        return (
            <article className={`check-group ${cssClass}`}>
                <input id={ id } type="checkbox" onChange={(event) => this.props.checkBoxChange(event.target.checked)} checked={ this.props.checked }/>
                <label htmlFor={ id }><span>{ name.toLocaleLowerCase() }</span></label>
            </article>
        );
    };
}

const InputCheckBox = withTranslation()(InputCheckBoxComponent);
export { InputCheckBox };