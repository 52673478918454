import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Image } from 'react-feather';

export interface FormImageProps {
    cssClass?: string,
    photo?: string,
	avatar?: boolean,
    onChange: (photo: string) => void,
}

type FormImagePropsSum = FormImageProps & WithTranslation;

interface State {
    file: File | null,
    show_error: boolean
}

class FormImageComponent extends React.Component<FormImagePropsSum, State> {

    constructor(props: FormImagePropsSum) {
        super(props);

        this.state = {
            file: null,
            show_error: false
        };
    }

    imageSelected(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files.item(0)!;

            if (file.size < 2097152) {
                this.setState({ file: event.target.files.item(0) });
                this.getBase64(event.target.files.item(0)!).then(result =>{
                    this.props.onChange(result as string)
                })
            } else {
                this.setState({ show_error: true });

                setTimeout(() => this.setState({ show_error: false }), 3000);
            }
		}
    }

    getBase64 = (file: File) => {
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function() {
                resolve(reader.result);
            };
        });
    }

    renderPhoto() {
        if (this.state.file) {
            return <img src={ URL.createObjectURL(this.state.file)} alt=""></img>
        }else if(this.props.photo){
            return <img src={ this.props.photo + "?v="+ Date.now() } alt=""></img>
        }
        else{
            return <Image />
        }
    }

    render () {
        const { t, avatar, cssClass } = this.props;
        const { show_error } = this.state;
        return (
            <fieldset>
                <section className={`upload-image ${cssClass}`}>
                    <article className={`image ${avatar ? 'avatar' : ''}`}>
                        {this.renderPhoto() /*TODO cuando cambia la imagen(al guardar), comprobar si cambia el thumnail al volver a abrir el modal*/}
                    </article>
                    <article className="actions">
                        <div>
                            <p className={` ${show_error ? 'error' : ''}`}>{ t('Images format .png, Max size 2MB') }</p>
                            <div>
                                <input type="file" id="file" onChange={(event) => this.imageSelected(event)} style={{display: 'none'}} accept=".png" />
                                <button className="btn-outline outline-primary btn-m"><label htmlFor="file">{ t('upload_image') }</label></button>
                            </div>
                        </div>
                    </article>
                </section>
            </fieldset>
        );
    };
}

const FormImage = withTranslation()(FormImageComponent);
export { FormImage };
