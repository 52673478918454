import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { X } from 'react-feather';

export interface CloseButtonProps {
    onClick?: () => void,
}

type CloseButtonPropsSum = CloseButtonProps & WithTranslation;

class CloseButtonComponent extends React.Component<CloseButtonPropsSum> {

    render () {
        const { t, onClick } = this.props;
        return (
            <button className="popup-close" onClick={ onClick }>
                <X />
                <span>{ t('close_popup') }</span>
            </button>
        );
    };
}

const CloseButton = withTranslation()(CloseButtonComponent);
export { CloseButton };