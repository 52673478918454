import React from 'react';
import { DateRangePicker } from 'react-dates';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
export interface InputDateProps {
    start_date?: number,
    end_date?: number,
    onChange: (start_date: number | undefined, end_date: number | undefined) => void,
    helpText?: string,
    disabled?: boolean
}

type InputDatePropsSum = InputDateProps & WithTranslation;

interface InputDateState {
    datePickerFocused: 'startDate'|'endDate'|null,
}

class InputDateComponent extends React.Component<InputDatePropsSum, InputDateState> {

    constructor(props: InputDatePropsSum) {
        super(props);

        this.state = {
            datePickerFocused: null,
        };
    }

    render () {
        const { start_date, end_date, onChange, helpText, disabled } = this.props;
        const { datePickerFocused } = this.state;
        return (
            <div className={helpText ? 'date--error' : ''}>
                <DateRangePicker
                    startDate={ start_date ? moment.unix(start_date) : null } // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={ end_date ? moment.unix(end_date) : null } // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => onChange(startDate?.unix(), endDate?.unix())} // PropTypes.func.isRequired,
                    focusedInput={ datePickerFocused } // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ datePickerFocused: focusedInput })} // PropTypes.func.isRequired,
                    isOutsideRange={() => false}
                    noBorder={ true }
                    customArrowIcon={<div style={{ width: '10px' }}></div>}
                    block={ true }
                    withPortal={ true }
                    displayFormat="DD/MM/YYYY"
                    startDatePlaceholderText="DD/MM/YYYY"
                    endDatePlaceholderText="DD/MM/YYYY"
                    hideKeyboardShortcutsPanel= {true}
                    required={true}
                    disabled={disabled}
                />
                {helpText && 
                <span className='text-error'>{helpText} </span>}
            </div>
        );
    };
}

const InputDate = withTranslation()(InputDateComponent);
export { InputDate };
