import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SecondaryButtonStyle, Visual } from '../interfaces/interfaces';
import { CloseButton, PrimaryButton, SecondaryButton } from '.';
import { ImagePreview } from './ImagePreview';
import { NextPrevButtons } from './NextPrevButtons';
import { RoleGuard } from './RoleGuard';
import { Role } from '../util/jwt';

export interface VisualPreviewProps {
    previewVisualIndex: number,
    previewVisuals: Visual[],
    onClose: () => void,
    onSubmit?: () => void,
    onDelete?: (visual: Visual) => void,
}
interface State {
	showImage : boolean,
    currentVisualIndex: number
}

type VisualPreviewPropsSum = VisualPreviewProps & WithTranslation;

class VisualPreviewComponent extends React.Component<VisualPreviewPropsSum, State> {

      constructor(props: VisualPreviewPropsSum) {
		super(props);
		this.state = {
            showImage: false ,
            currentVisualIndex: props.previewVisualIndex
        };
	}

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    isLast(){
        const { previewVisuals } = this.props
        const { currentVisualIndex }= this.state

        return  currentVisualIndex+1 >= previewVisuals.length
    }

    isFirst(){
        const { currentVisualIndex }= this.state

        return  currentVisualIndex <= 0
    }

    renderVisualPreview(){
        const { t, previewVisuals, onClose, onSubmit, onDelete } = this.props
        const { currentVisualIndex }= this.state
        return(
            <section id="campaign-visual" className="cover-blur /*active*/ active">
                <section className="popup">
                <NextPrevButtons 
                        hasNext= { !this.isLast() }
                        hasPrev= { !this.isFirst() }
                        onClickNext={() => this.setState({ currentVisualIndex: ( !this.isLast() ? currentVisualIndex+1 : previewVisuals.length-1  ) })} 
                        onClickPrev={() => this.setState({ currentVisualIndex: ( !this.isFirst() ? currentVisualIndex-1 : 0  ) })} 
                    />
                    <section className="popup-top">
                        <h2>{ previewVisuals[currentVisualIndex].name }</h2>
                        <CloseButton onClick={ onClose }/>
                    </section>
                    <section className="popup-content pb-40">
                        <article className="image-space">
                            <img src={ previewVisuals[currentVisualIndex].thumbnail } alt="" height="100%"/>
                        </article>
                    </section>

                    { ( onSubmit || onDelete) && 
                    <section className="popup-actions">
                        <RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
                            { onDelete && <SecondaryButton onClick={() => onDelete(previewVisuals[currentVisualIndex]) } style={SecondaryButtonStyle.RED}>{ t('delete') }</SecondaryButton> }
                        </RoleGuard>
                        { onSubmit && <PrimaryButton onClick={() => onSubmit() } >{ t('save') }</PrimaryButton> }
                    </section>
                    }
                </section>
            </section>
        )
    }

    renderImagePreview(){
        const { previewVisuals } = this.props
        const { currentVisualIndex }= this.state

        return (
            <ImagePreview visual={previewVisuals[currentVisualIndex]} onClose={ () => this.setState({ showImage: false })} />
        )
    }

    render () {
        const { showImage } = this.state;
        if (showImage) {
            return this.renderImagePreview()
        }
        return this.renderVisualPreview()
    };
}

const VisualPreview = withTranslation()(VisualPreviewComponent);


export { VisualPreview };
