import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { updateEmail, updateEmailError, UpdateEmailSuccess } from './PasswordRequestActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { request_password } from '../../../api';

const mapStateToProps = (state: RootState) => {
	return {
		security_password_request_state: state.securityPasswordRequest
	}
}

const connector = connect(mapStateToProps, { updateEmail, updateEmailError, UpdateEmailSuccess});

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Settings extends React.Component<Props> {

	componentDidMount() {
		this.props.UpdateEmailSuccess('');
		this.props.updateEmailError('');
	}

	send() {
		const { t } = this.props;
		const { email } = this.props.security_password_request_state;
		let hasError = false;
		
		if (!email) {
			hasError = true;
			this.props.updateEmailError(t('email_is_required'));
		} else {
			this.props.updateEmailError('');
		}
		
		if (!hasError) {
			// Comprobar que el email está en la base de datos
			request_password(email,t('reset_password_mail_subject'),t('reset_password_mail_title'),t('reset_password_mail_text'),[ t('change_pass') ]).then((emailSent) => {
				if(emailSent){
					this.props.UpdateEmailSuccess(t('email_sent'));
					this.props.updateEmailError(t(''));
				}else{
					this.props.UpdateEmailSuccess(t(''));
					this.props.updateEmailError(t('email_not_found'));
				}
			});
		}
	}

	renderRequestError() {
		if (this.props.security_password_request_state.emailError) {
			return (
				<article className="form-row">
					<Alert severity="error">{this.props.security_password_request_state.emailError}</Alert>
				</article>
			);
		}
	}


	renderRequestOK() {
		if (this.props.security_password_request_state.emailSuccess) {
			return (
				<article className="form-row">
					<Alert severity="success">{this.props.security_password_request_state.emailSuccess}</Alert>
				</article>
			);
		}
	}


	render () {
		const { t } = this.props;
		return (
			< >
				<section id="login-box">
					<Link to="/">
						<h1 className="text-center">
							<img src="../../img/corporative/primark.svg" alt="PRIMARK" />
						</h1>
					</Link>

					<article id="content">
						<div className="heading">
							<h2 >{t('remember_password')}</h2>
							<p>{t('remember_password_text')}</p>
						</div>
						<form className="pt-24">
							{this.renderRequestError()}	
							{this.renderRequestOK()}
							<div className="form-row">
								<article className="form-item">
									<label htmlFor="mail">{t('email')}</label>
									<input id="mail" className="field field--text" type="text" 
									onChange={(event) => this.props.updateEmail(event.target.value)} />
								</article>
							</div>
							<article className="login-actions">
								<button type="button" onClick={() => this.send()} className="btn-base fill-primary btn-xl w-100 mb-24">{t('remember')}</button>
								<p>
									<Link to="/">
										{t('return_to_login')}	
									</Link>
								</p>
							</article>
						</form>
					</article>

				</section>
			</>		
		);
	}
}

export default withTranslation()(connector(Settings));
