import React from 'react';
import Select, { createFilter, StylesConfig } from 'react-select';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SelectOption } from '../interfaces/interfaces';

export interface InputSelectProps {
    id: string,
    label?: string,
    placeholder?: string,
    value?: string,
    disabled?: boolean,
    hasError?: boolean,
    options?: SelectOption[];
    helpText?: string;
    emptyValue?: boolean,
    onChange?: (value?: string, label?: string) => void
}

type InputSelectPropsSum = InputSelectProps & WithTranslation;

class InputSelectSearchableComponent extends React.Component<InputSelectPropsSum> {

    render () {
        const { id, label, value, options, helpText, t, onChange, placeholder } = this.props;

        const customStyles: StylesConfig<{value: string, label: string}, false> = {
            control: (provided, state) => ({
                border: state.isFocused ? '2px solid #00B4D9 !important' : '1px solid #DEE0E3 !important',
                borderRadius: '8px !important',
                minHeight: '40px',
                padding: '6px',
                marginTop: '12px',
                "&:hover": {
                    border: state.isFocused ? '2px solid #00B4D9 !important' : '1px solid #DEE0E3 !important'
                }
            })
        }

        const defaultValue = options?.find(option => option.value === value);
        return (
            <article className="form-item">
                { (label)?(
                    <label htmlFor={ id }>{ label }</label>
                ):(<></>) }
            <Select
                inputId={id}
                className="basic-single"
                classNamePrefix="select"
                placeholder={(label)?label:placeholder}
                value={(defaultValue && { value: defaultValue?.value, label: defaultValue?.name }) || null }
                options={options?.sort((a, b) => a.name.localeCompare(b.name)).map(option => ({ value: option.value, label: option.name }))}
                onChange={newValue => onChange?.(newValue?.value, newValue?.label || '')}
                escapeClearsValue
                isSearchable={ true }
                filterOption={createFilter({ ignoreCase: true, ignoreAccents: true, trim: true, matchFrom: 'any' })}
                isClearable={true}
                components={{ DropdownIndicator: null, IndicatorSeparator: null,
                IndicatorsContainer: () => null }}
                styles={ customStyles }
            />
            {helpText && t(helpText) }
            </article>
        );
    };
}

const InputSelectSearchable = withTranslation()(InputSelectSearchableComponent);
export { InputSelectSearchable };
