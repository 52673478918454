import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Spinner, ContentTop, Filter, FilterRow, GalleryCard } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getCampaigns, updateFilter, clearFilter } from './GalleryIndexActions';
import { Campaign, FilterFields } from '../../../interfaces/interfaces';
import { edit_campaign_gallery_visible } from '../../../api';

const mapStateToProps = (state: RootState) => {
	return {
		gallery_index_state: state.galleryIndex,
		data_types_state: state.datatypes,
	}
}

const connector = connect(mapStateToProps, {
	getCampaigns,
	updateFilter,
	clearFilter,
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showFilter: boolean,
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showFilter: false,
		};
	}

	componentDidMount() {
		this.props.getCampaigns();
	}

	renderFilter() {
		const { showFilter } = this.state;
		const { filter } = this.props.gallery_index_state;
		if (showFilter) {
			return (
				<Filter
					filterFields={ FilterFields.GALLERY }
					filter={ filter }
					onClose={() => this.setState({ showFilter: !showFilter })}
					onClear={() => {
						this.props.clearFilter();
						this.props.getCampaigns();
						this.setState({ showFilter: !showFilter })
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.getCampaigns();
						this.setState({ showFilter: !showFilter });
					}}
				/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;
		const { showFilter } = this.state;
		const { campaigns, filter } = this.props.gallery_index_state;
		return (
			<ContentTop>
				<article>
					<div>
						<h2>{ t('gallery') }</h2>
					</div>
				</article>
				<FilterRow
					filter={filter}
					results={campaigns.length || 0}
					pluralizeStrings={[t('result_found'), t('results_found')]}
					onFilterClicked={() => this.setState({ showFilter: !showFilter })}
				/>
			</ContentTop>)
	}

	renderGallery() {
		const { campaigns } = this.props.gallery_index_state;

		return (
			<ul>
				{campaigns.map(campaign => (
					<GalleryCard 
						key={campaign.id} 
						campaign={campaign}
						onChange={(campaign: Campaign) => {
							edit_campaign_gallery_visible(campaign).then(() => {
								//this.props.getCampaigns();
							}).catch(err => console.error);
						}} 
						/>
				))}
			</ul>
		)
	}

	render () {
		const { t } = this.props;
		const { loading } = this.props.gallery_index_state;

		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		return (
			<>
				{this.renderFilter()}
				{this.renderContentTop()}
				<section id="gallery-pills">
					{this.renderGallery()}
				</section>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
