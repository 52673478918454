import {
    UPDATE_LOADING,
    UPDATE_DATA,
    UPDATE_VISUAL,
    UPDATE_STORE_DEPARTMENTS,
    UPDATE_PRIORITY,
    UPDATE_SELECTED_DEPARTMENT,
    ProcessingDetailsActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { ProcessingLine, SelectOption, Visual } from "../../../interfaces/interfaces";

export interface ProcessingDetailsState {
    data: ProcessingLine | undefined
    visual: Visual | undefined
    loading: boolean,
    store_departments:SelectOption[],
    selected_department:string;
    priority:string;
}

const INITIAL_STATE: ProcessingDetailsState = {
    data: undefined,
    visual: undefined,
    loading: false,
    selected_department:'',
    priority:'',
    store_departments:[]
};

const reducer: Reducer<ProcessingDetailsState, ProcessingDetailsActions> = (state = INITIAL_STATE, action: ProcessingDetailsActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };  
        case UPDATE_DATA:
            return { ...state, data: action.payload };
        case UPDATE_VISUAL:
            return { ...state, visual: action.payload };
        case UPDATE_STORE_DEPARTMENTS:
            return {...state,departments:action.payload}
        case UPDATE_PRIORITY:
            return {...state, priority: action.payload};
        case UPDATE_SELECTED_DEPARTMENT:
            return {...state, selected_department: action.payload}
        default:
            return state;
    }
}

export default reducer;
