import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';



export interface SupportImageCardProps {
    image_url: String,
    image_name:String,
    onVisualClick?: () => void,
}

type SupportImageCardPropsSum = SupportImageCardProps & WithTranslation;

class SupportImageCardComponent extends React.Component<SupportImageCardPropsSum> {

    render () {
        const { image_url, onVisualClick,image_name  } = this.props;
        return(
            <li>
                <div className="image__contain hover__enlarge" onClick={ onVisualClick }>
                    <img src={`${image_url}`} alt={`${image_name}`} className="cursor-hand" />
                </div>
                <div className="time-item-txt">
                    <div>
                        <h4>{image_name}</h4>
                        
                    </div>
                </div>
            </li>
        );
    };
}
const SupportImageCard = withTranslation()(SupportImageCardComponent);
export { SupportImageCard };