import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Support, Filter, SelectOption, Floor } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_SUPPORTS = 'update_supports';
export const APPEND_SUPPORTS = 'append_supports';
export const UPDATE_FILTER = 'update_filter_support';
export const CLEAR_FILTER = 'clear_filter_support';
export const UPDATE_STORES = 'update_stores';
export const UPDATE_FLOORS = 'update_floors';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateSupportsAction {
    type: typeof UPDATE_SUPPORTS,
    payload: Support[]
}

export interface AppendSupportsAction {
    type: typeof APPEND_SUPPORTS,
    payload: Support[]
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: Filter
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: SelectOption[]
}

export interface UpdateFloorsAction {
    type: typeof UPDATE_FLOORS,
    payload: Floor[]
}

export type SupportIndexActions =
UpdateLoadingAction |
UpdateSupportsAction |
AppendSupportsAction |
UpdateFilterAction |
ClearFilterAction |
UpdateStoresAction |
UpdateFloorsAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
