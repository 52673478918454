import React from 'react';
import { Link, NavLink } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Menu, ArrowLeft, Zap, Image } from 'react-feather';
import { Icon } from '.';
import { Role } from '../util/jwt';
import { RoleGuard } from './RoleGuard';

export interface NavProps {}

interface NavState {
    open: boolean
}

type NavPropsSum = NavProps & WithTranslation;

class NavComponent extends React.Component<NavPropsSum, NavState> {

    constructor(props: NavPropsSum) {
        super(props);

        this.state = {
            open: false
        };
    }

    componentDidMount() {
        if (this.state.open) {
            document.body.classList.add('menuOpen')
            document.body.classList.remove('menuClose')
        } else {
            document.body.classList.add('menuClose')
            document.body.classList.remove('menuOpen')
        }
    }

    buttonMenuCollapse() {
        this.setState({
            open: !this.state.open
        }, () => {
            if (this.state.open) {
                document.body.classList.add('menuOpen')
                document.body.classList.remove('menuClose')
            } else {
                document.body.classList.add('menuClose')
                document.body.classList.remove('menuOpen')
            }
        });
    }

    navSliderClass() {
        return this.state.open ? 'open' : 'close';
    }

    navSliderIconClass(upside: Boolean) {
        return !upside ? this.state.open ? '' : 'openIcon' : this.state.open ? 'openIcon' : ''
    }

    render () {
        const { t } = this.props;
        return (
            <nav className={`navigation-menu ${this.navSliderClass()}`}>
                <ul>
                    <li className="menu">
                        <Link to="#" onClick={() => this.buttonMenuCollapse()}>
                            <b>
                                <div>
                                    <em className={`hamburgerMenu ${this.navSliderIconClass(false)}`}><Menu /></em>  
                                    <em className={`arrowLeft ${this.navSliderIconClass(true)}`}><ArrowLeft /></em>
                                    <span>{t('close_menu')}</span>
                                </div>
                            </b>
                        </Link>
                    </li>
                    <RoleGuard roles={[Role.KENDU_DESIGN, Role.ADMIN, Role.BACKDOOR]}>
                        <li className="kendu-processing">
                            <NavLink to="/kendu_processing" activeClassName="active" aria-label={t('kendu_processing') } data-balloon-pos="right" data-balloon-nofocus>
                                <div>
                                    <Icon type="kendu-processing" />
                                </div>
                                <span>{t('kendu_processing') }</span>
                            </NavLink>
                        </li>
                    </RoleGuard>
					<li>
						<NavLink to="/dashboard" activeClassName="active" aria-label={t('Dashboard') } data-balloon-pos="right" data-balloon-nofocus>
							<div>
								<Icon type="dashboard" />
							</div>
							<span>{t('Dashboard') }</span>
						</NavLink>
					</li>
                    <RoleGuard roles={[Role.MARKETING, Role.ADMIN, Role.BACKDOOR]}>
                        <li className="crops-validation">
                            <NavLink to="/crops_validation" activeClassName="active" aria-label={t('crops_validation') } data-balloon-pos="right" data-balloon-nofocus>
                                <div>
                                    <Icon type="validation" />
                                </div>
                                <span>{t('crops_validation') }</span>
                            </NavLink>
                        </li>
                    </RoleGuard>
                    <RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
                        <li>
                            <NavLink to="/users" activeClassName="active" aria-label={t('Users') } data-balloon-pos="right" data-balloon-nofocus>
                                <div>
                                    <Icon type="user" />
                                </div>
                                <span>{t('Users') }</span>
                            </NavLink>
                        </li>
                    </RoleGuard>
                    <RoleGuard roles={[Role.MARKETING, Role.ADMIN, Role.BACKDOOR, Role.HEAD_VM]}>
                        <li>
                            <NavLink to="/campaigns" activeClassName="active" aria-label={t('campaigns') } data-balloon-pos="right" data-balloon-nofocus>
                                <div>
                                    <Zap />
                                </div>
                                <span>{t('campaigns') }</span>
                            </NavLink>
                        </li>
                    </RoleGuard>
                    <RoleGuard roles={[Role.HEAD_VM, Role.COUNTRY_MANAGER, Role.STORE_MANAGER, Role.ADMIN, Role.BACKDOOR]}>
                        <li>
                            <NavLink to="/stores" activeClassName="active" aria-label={t('stores') } data-balloon-pos="right" data-balloon-nofocus>
                                <div>
                                    <Icon type="store" />
                                </div>
                                <span>{t('stores') }</span>
                            </NavLink>
                        </li>
                    </RoleGuard>
                    <RoleGuard roles={[Role.STORE_MANAGER, Role.COUNTRY_MANAGER, Role.HEAD_VM, Role.ADMIN, Role.BACKDOOR]}>
                        <li>
                            <NavLink to="/frames" activeClassName="active" aria-label={t('frames') } data-balloon-pos="right" data-balloon-nofocus>
                                <div>
                                    <Icon type="support" />
                                </div>
                                <span>{t('frames') }</span>
                            </NavLink>
                        </li>
                    </RoleGuard>
                    <RoleGuard roles={[Role.ADMIN, Role.BACKDOOR, Role.KENDU_DESIGN]}>
                        <li>
                            <NavLink to="/gallery" activeClassName="active" aria-label={t('gallery') } data-balloon-pos="right" data-balloon-nofocus>
                                <div>
                                    <Image />
                                </div>
                                <span>{t('gallery') }</span>
                            </NavLink>
                        </li>
                    </RoleGuard>

                    <RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
                        <li>
                            <NavLink to="/activity" activeClassName="active" aria-label={t('Activity') } data-balloon-pos="right" data-balloon-nofocus>
                                <div>
                                <Icon type="monitor-icon" />
                                </div>
                                <span>{t('Activity') }</span>
                            </NavLink>
                        </li>
                    </RoleGuard>
                    <li>
                        
                    </li>
                </ul>
            </nav>
        );
    };
}

const Nav = withTranslation()(NavComponent);
export { Nav };
