import {
    UPDATE_STORE,
    UPDATE_LOADING,
    UPDATE_SELECTED_FLOOR,
    UPDATE_FILTER,
    UPDATE_ACTIVE_CAMPAIGN,
    UPDATE_STORE_USERS,
    CLEAN_STORE,
    StoreDetailActions,
    UPDATE_SUPPORT,
    UPDATE_TOTAL_SUPPORT
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Floor, Store, Filter as FilterInterface, Campaign, User, Support } from "../../../interfaces/interfaces";


export interface StoreDetailState {
    store: Store | undefined,
    loading: boolean,
    filter: FilterInterface
    selectedFloor: Floor | null,
    activeCampaign: Campaign | null,
    users: User[] | null,
    selectedSupport: Support | null,
    totalSupports:any
}

const INITIAL_STATE: StoreDetailState = {
    store: undefined,
    loading: false,
    filter: new FilterInterface(),
    selectedFloor: null,
    activeCampaign: null,
    users: null,
    selectedSupport: null,
    totalSupports:null,
};

const reducer: Reducer<StoreDetailState, StoreDetailActions> = (state = INITIAL_STATE, action: StoreDetailActions) => {
    switch(action.type) {
        case UPDATE_STORE:
            return { ...state, store: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_SELECTED_FLOOR:
            return { ...state, selectedFloor: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case UPDATE_ACTIVE_CAMPAIGN:
            return { ...state, activeCampaign: action.payload }
        case UPDATE_STORE_USERS:
            return { ...state, users: action.payload}
        case CLEAN_STORE:
            return {...state,store:undefined, selectedFloor:null}
        case UPDATE_SUPPORT:
            return {...state, selectedSupport: action.payload}
        case UPDATE_TOTAL_SUPPORT:
            return {...state,totalSupports: action.payload}
        default:
            return state;
    }
}

export default reducer;