import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { get_user_token } from '../util/cookies';
import { get_role, Role } from '../util/jwt';

export interface AclProps {
    roles: Role[],
}

type Props = AclProps & RouteProps;

class AclRoute extends React.Component<Props> {

    redirect(role: Role) {
        switch(role) {
            case Role.MARKETING:
            case Role.HEAD_VM:
            case Role.ADMIN:
            case Role.BACKDOOR:
                return <Redirect to='/campaigns' />
            case Role.COUNTRY_MANAGER:
            case Role.STORE_MANAGER:
                return <Redirect to='/stores' />
        }
    }

    render () {
        const { roles } = this.props;
        const role = get_role(get_user_token());

        if (!roles.includes(role)) {
            return this.redirect(role);
        }

        return <Route {...this.props} />
    };
}

export { AclRoute };