import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'react-feather';

export interface InfoProps {
    title: string
}

interface InfoState {
    open: boolean
}

type InfoPropsSum = InfoProps & WithTranslation;

class InfoComponent extends React.Component<InfoPropsSum, InfoState> {

    constructor(props: InfoPropsSum) {
        super(props);

        this.state = {
            open: false
        };
    }

    render () {
        const { open } = this.state;
        return (
            <section id="store-detail-close" className={`store-info ${open ? 'open' : 'close'}`}>
                <article>
                    <button className="more-info-toggle">
                        <h2>{ this.props.title }</h2>
                        <div className="v-center">
                            {open && <ChevronUp onClick={() => this.setState({open: false})} />}
                            {!open && <ChevronDown onClick={() => this.setState({open: true})} />}
                        </div>
                    </button>
                    {open && this.props.children}
                </article>
            </section>
        );
    };
}

const Info = withTranslation()(InfoComponent);
export { Info };
