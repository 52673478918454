import {
    UPDATE_APPROVE,
    UPDATE_LOADING,
    UPDATE_STORE,
    CLEAN_STORE,
   ApproveStoreActions
} from "./ActionTypes";
import { Reducer } from 'redux';
import {  Store } from "../../../interfaces/interfaces";


export interface StoreApproveState {
    checks:number[],
    loading: boolean,
    store:Store | undefined,
    
}

const INITIAL_STATE: StoreApproveState = {
    checks: [],
    loading: false,
    store:undefined
    
};

const reducer: Reducer<StoreApproveState, ApproveStoreActions> = (state = INITIAL_STATE, action: ApproveStoreActions) => {
    switch(action.type) {
        case UPDATE_APPROVE:
            return { ...state, checks: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_STORE:
            return { ...state, store: action.payload };
        case CLEAN_STORE:
            return {...state,store:undefined}
        default:
            return state;
    }
}

export default reducer;