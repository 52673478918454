import React from 'react';
import { Link } from "react-router-dom";
import { Campaign } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Folder } from 'react-feather';
import { Switch } from '.';
import { RoleGuard } from './RoleGuard';
import { Role } from '../util/jwt';
import { start_end_date } from '../util/campaign';

export interface GalleryCardProps {
    campaign: Campaign
    onChange: (campaign: Campaign) => void
}

interface State {
    active: boolean,
}

type GalleryCardPropsSum = GalleryCardProps & WithTranslation;

class GalleryCardComponent extends React.Component<GalleryCardPropsSum, State> {

    constructor(props: GalleryCardPropsSum) {
        super(props);
        this.state = {
            active: this.props.campaign.galleryVisible
        };
    }

    cardLink(campaign: Campaign) {
        if (campaign.id === undefined) {
            return '#';
        }
        return `/gallery/${campaign.id}`;
    }

    getStatusClass() {
        if (this.state.active) {
            return 'available';
        }
        return 'unavailable';
    }

    render () {

        const { campaign, onChange } = this.props;
        const { active } = this.state

        return (
            <li className={`gallery-card gallery-campaign gallery-campaign--${this.getStatusClass()}`}>
                <Link to={this.cardLink(campaign)}>
                    <div>
                        <em><Folder /></em>
                        <span>
                            <h3>{ campaign.title }</h3>
                            <ul>
                                <li>{ start_end_date(this.props.campaign) }</li>
                                <li>{ (campaign.sections?.map(section => {return section.description}))?.toString() }</li>
                            </ul>
                        </span>
                    </div>
                </Link>
                <div>
                    <RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
                    <Switch id={ campaign.id } active={ active } onChange={(value) => {
                            this.setState({active: value})
                            onChange({...campaign, galleryVisible: value} as Campaign) }
                        }
                    />
                    </RoleGuard>
                </div>
            </li>
        );
    };
}

const GalleryCard = withTranslation()(GalleryCardComponent);
export { GalleryCard };
