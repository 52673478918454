import {
    UPDATE_LOADING,
    UPDATE_CAMPAIGN,
    StoreValidateCropsActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Campaign } from "../../../interfaces/interfaces";

export interface StoreValidateCropsState {
    loading: boolean,
    campaign: Campaign | null,
}

const INITIAL_STATE: StoreValidateCropsState = {
    loading: false,
    campaign: null,
};

const reducer: Reducer<StoreValidateCropsState, StoreValidateCropsActions> = (state = INITIAL_STATE, action: StoreValidateCropsActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_CAMPAIGN:
            return { ...state, campaign: action.payload };
        default:
            return state;
    }
}

export default reducer;