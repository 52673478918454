import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SwitchProps {
    id: string,
    active: boolean,
    onChange: (value: boolean) => void,
}

type SwitchPropsSum = SwitchProps & WithTranslation;

class SwitchComponent extends React.Component<SwitchPropsSum> {

    render () {
        const { id, active, onChange } = this.props;

        return (
            <article className="switch">
                <input id={`switch-${id}`} type="checkbox" className="switch-input" checked={ active } onChange={(event) =>onChange(event.target.checked)} />
                <label htmlFor={`switch-${id}`} className="switch-label">Switch</label>
            </article>
        );
    };
}

const Switch = withTranslation()(SwitchComponent);
export { Switch };
