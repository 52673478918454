import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PointOfInterest, Visual, VisualArea, VisualPreviewSizes } from '../interfaces/interfaces';
import { CloseButton, PrimaryButton, Spinner } from '.';
import { Rnd } from 'react-rnd';
import { setPreviewVariables, getPreview, getSubjectInfo } from '../api';
import { two_decimals } from '../util/number';


export interface VisualFocusAreaProps {
    previewVisual: Visual,
    onClose: () => void,
    generateRatios: () => void,
}
interface State {
    currentVisualResolution: string,
    visualArea: VisualArea
    pointOfInterest: PointOfInterest
    imageDimensions:{width: number, height: number}
    imageInfoSaved: boolean
    chiliPreview: string
    loadingPreview: boolean
    savingData: boolean
}



type VisualFocusAreaPropsSum = VisualFocusAreaProps & WithTranslation;

class VisualFocusAreaComponent extends React.Component<VisualFocusAreaPropsSum, State> {

    container: React.RefObject<HTMLDivElement> = React.createRef();
    baseState: Readonly<State>;
    rnd: Rnd | null = null

    constructor(props: VisualFocusAreaPropsSum) {
      super(props);
        this.state = {
            currentVisualResolution: VisualPreviewSizes.O,
            imageDimensions:{width: 0, height: 0},
            visualArea: {x: 0, y: 0, width: 0, height: 0} ,
            pointOfInterest: {x: 0, y: 0} ,
            imageInfoSaved: false,
            chiliPreview: '',
            loadingPreview: false,
            savingData: false,
        };
        this.baseState = this.state

	  }

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }
    
    async updateImageDimensions() {
        const subjectInfo = await getSubjectInfo( this.props.previewVisual)

		this.setState({
			imageDimensions: {
				width: this.container.current?.offsetWidth || 0,
				height: this.container.current?.offsetHeight || 0,
			},
            pointOfInterest: {
                x: (subjectInfo.pointOfInterest.x - subjectInfo.subject.x) * (this.container.current?.offsetWidth || 0),
                y: (subjectInfo.pointOfInterest.y - subjectInfo.subject.y) * (this.container.current?.offsetHeight || 0),
            },
            visualArea: {
                x: subjectInfo.subject.x * (this.container.current?.offsetWidth || 0),
                y: subjectInfo.subject.y * (this.container.current?.offsetHeight || 0),
                width: subjectInfo.subject.width * (this.container.current?.offsetWidth || 0),
                height: subjectInfo.subject.height * (this.container.current?.offsetHeight || 0)
            }  
		});
	}

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    async previewSize(size: VisualPreviewSizes){
        const { previewVisual } = this.props

        this.setState({ currentVisualResolution: size}) 

        switch (size){
            case VisualPreviewSizes.O: 
                this.setState(this.baseState)
                break
            default:
                this.setState({ loadingPreview: true})

                //get Width & height and re-escale (10 times smaller) to optimize chili preview
                const documentWidth = Number(size.split('x')[0]) / 10
                const documentHeight = Number(size.split('x')[1]) / 10
                const documentVariables = { width : documentWidth, height : documentHeight}
                const previewVariables = { documentVariables, chiliAssetId: previewVisual.chiliAssetId! }
                getPreview( previewVariables ).then(( response ) => 
                    this.setState({ 
                        loadingPreview: false,
                        chiliPreview: 'data:image/jpg;base64,'+response
                    }
                ))
                break
        }
        
    }

    async savePOI(){
        const { previewVisual } = this.props

        this.setState({ savingData: true})

        const previewVariables = {...this.pixelsToPercentage(), chiliAssetId: previewVisual.chiliAssetId! }
                setPreviewVariables( previewVariables ).then(( response ) => 
                    this.setState({ 
                        savingData: false,
                        imageInfoSaved : true
                    }
                ))
        

    }

    pixelsToPercentage(): {visualAreaInPercentage: VisualArea, pointOfInterestInPercentage: PointOfInterest }{
        const { imageDimensions, visualArea, pointOfInterest } = this.state

        const imageWidth = imageDimensions.width
        const imageHeight = imageDimensions.height

        //dimensions are in normalizated percentage  ( 0-1 )
        //visual Area
        const visualAreaXPercentage  = two_decimals(visualArea.x / imageWidth)
        const visualAreaYPercentage  = two_decimals(visualArea.y  / imageHeight)
        const visualAreaWidthPercentage  = two_decimals(visualArea.width  / imageWidth)
        const visualAreaHeightPercentage  = two_decimals(visualArea.height / imageHeight)

        const visualAreaInPercentage = { 
            x: visualAreaXPercentage,
            y: visualAreaYPercentage,
            width: visualAreaWidthPercentage,
            height: visualAreaHeightPercentage
        }

        //point of interest
        const pointOfInterestXPercentage  = two_decimals((pointOfInterest.x + visualArea.x) / imageWidth)
        const pointOfInterestYPercentage  = two_decimals((pointOfInterest.y + visualArea.y) / imageHeight)

        const pointOfInterestInPercentage = { 
            x: pointOfInterestXPercentage,
            y: pointOfInterestYPercentage,
        }


        return  {visualAreaInPercentage , pointOfInterestInPercentage}
    }

    renderActions(){
        const { imageDimensions, imageInfoSaved, savingData } = this.state
        const { t, generateRatios } = this.props

        if(!imageInfoSaved && !savingData && imageDimensions.width !== 0 && imageDimensions.height !== 0){
            return(
                <PrimaryButton onClick={() => this.savePOI() }>{ t('save') }</PrimaryButton>
            )
        }else if(savingData){
            return(
                <button className="btn-base btn-base--loading fill-primary btn-m">
                    <span>{ t('saving') }</span>
                </button>
            )
        }else if(imageInfoSaved){
            return(
                <PrimaryButton onClick={ generateRatios }>{ t('generate_ratios') }</PrimaryButton>
            )
        }else{
            return(
                <button className="btn-base btn-base--loading fill-primary btn-m">
                    <span>{ t('saving') }</span>
                </button>
            )
        }
    }

    render () {
        const { t, previewVisual, onClose } = this.props
        const { currentVisualResolution, imageDimensions, imageInfoSaved, chiliPreview, visualArea, pointOfInterest, loadingPreview, savingData } = this.state

        return (
            <section id="campaign-crop-visual" className="cover-blur /*active*/ active">
                <section className="popup popup--breadth">
                    <section className="popup-top">
                        <h2>{ previewVisual.name }</h2>
                        <CloseButton onClick={ onClose }/>
                    </section>
                    <section id="focus-image-popup-content" className="popup-content pb-40">
                        <fieldset>
                            <div className="form-row focus-point-container">
                                <article className={`image-space ${currentVisualResolution}`} style={{padding:0, justifyContent:"normal", alignItems:"normal", overflow: 'hidden'}} >
                                        <div ref={this.container} className="content-image" style={{overflow: 'hidden'}}>
                                        {loadingPreview
                                            ? <Spinner message={ t('loading') } />
                                            : <img src={  chiliPreview ? chiliPreview : previewVisual.thumbnail } alt=""  onLoad={ () => this.updateImageDimensions()} /> 
                                        }
                                        
                                        {(imageDimensions.width !== 0 && imageDimensions.height !== 0) &&  currentVisualResolution === VisualPreviewSizes.O &&
                                            <Rnd
                                                default={{
                                                    x: visualArea.x ,
                                                    y: visualArea.y ,
                                                    width: visualArea.width ,
                                                    height: visualArea.height 
                                                    }}
                                                minWidth={12}
                                                minHeight={12}
                                                bounds="parent"
                                                onDrag={e => {e.stopPropagation()}}
                                                onDragStop={(e, d) => { this.setState({ visualArea: {...this.state.visualArea, x: d.x, y: d.y},
                                                                                        imageInfoSaved : false }) }}
                                                onResizeStop={(e, d, ref, delta, position) => { 
                                                    this.rnd?.updatePosition({x: ref.clientWidth/2, y: ref.clientHeight/2})
                                                    this.setState({ visualArea: {
                                                                        x: position.x, 
                                                                        y: position.y,
                                                                        width: parseInt(ref.style.width), 
                                                                        height: parseInt(ref.style.height)},
                                                                        pointOfInterest: {...this.state.pointOfInterest, x: ref.clientWidth/2, y: ref.clientHeight/2},
                                                                        imageInfoSaved : false
                                                    }) }}
                                                style={{ border: 2, borderStyle: "dotted", boxShadow: "0 0 0 9999em rgb(0 0 0 / 50%)"}}
                                                
                                                resizeHandleClasses = {{
                                                    bottomLeft: 'react-draggable drag-dot drag-dot--bl',
                                                    bottomRight: 'react-draggable drag-dot drag-dot--br',
                                                    topLeft: 'react-draggable drag-dot drag-dot--tl',
                                                    topRight: 'react-draggable drag-dot drag-dot--tr'
                                                }}
                                                >
                                                <div className="interest-area" />
                                                <Rnd
                                                    ref={c => { this.rnd = c; }}
                                                    default={{
                                                    x: pointOfInterest.x ,
                                                    y: pointOfInterest.y ,
                                                    width: 0,
                                                    height: 0
                                                    }}
                                                    enableResizing={false}
                                                    bounds="parent"
                                                    onDrag={e => {e.stopPropagation()}}
                                                    onDragStart={e => {e.stopPropagation()}}
                                                    onDragStop={(e, d) => { this.setState({ 
                                                                                pointOfInterest: {...this.state.visualArea, x: d.x, y: d.y},
                                                                                imageInfoSaved : false })
                                                                            e.stopPropagation() }}
                                                    style={{ border:1, borderStyle:'dashed', }}
                                                >
                                                    <div className="focus-point" />
                                                </Rnd>
                                            </Rnd>
                                        }
                                    </div>
                                </article>
                                <article>
                                    <h4>{ t('Format') }</h4>
                                    <ul>
                                        {Object.values(VisualPreviewSizes).map(size =>
                                            <li key={size} 
                                                onClick={() => (imageInfoSaved && !loadingPreview && !savingData) ? this.previewSize(size) : undefined} 
                                                className={ (currentVisualResolution === size) ? 'active' : (imageInfoSaved && !loadingPreview && !savingData) ? '' : 'size-disabled' }
                                            >
                                                {size}
                                            </li>
                                        )}
                                    </ul>
                                </article>
                            </div>
                        </fieldset>
                    </section>
                    <section className="popup-actions">
                        { this.renderActions() }
                    </section>
                </section>
            </section>
        )
    };
}

const VisualFocusArea = withTranslation()(VisualFocusAreaComponent);
export { VisualFocusArea };