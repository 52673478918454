import { get_active_campaign, one_store, get_users } from '../../../api';
import { Floor, Store, StoreStatusPrimark, Support, SupportStatusPrimark } from '../../../interfaces/interfaces';
import {
    UPDATE_STORE,
    UPDATE_LOADING,
    UPDATE_SELECTED_FLOOR,
    UPDATE_FILTER,
    UPDATE_ACTIVE_CAMPAIGN,
    UPDATE_STORE_USERS,
    CLEAN_STORE,
    UPDATE_SUPPORT,
    UPDATE_TOTAL_SUPPORT,
    StoreDetailActions,
    AppThunk,
} from './ActionTypes';
import { Filter as FilterInterface } from "../../../interfaces/interfaces";
import { FilterUserRequest } from '../../../api/interfaces';


export const getStore = (id: string, initial_floor?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as StoreDetailActions);
       
        dispatch({
            type: CLEAN_STORE,
          
        } as StoreDetailActions);

        const filter = getState().storeDetail.filter;
        
        filter.search=filter.search_support;
        const store: Store = await one_store(id, {
            ...filter,
            textil_types: filter.textileTypesList,
            support_types: filter.supportTypesList
        });
       let totalSupports=0;
       store?.sections?.forEach(section => {
           totalSupports=totalSupports+Number(section.supports?.length);
        })
      
        dispatch({
            type: UPDATE_TOTAL_SUPPORT,
            payload:totalSupports
          
        } as StoreDetailActions);
        if (filter.support_statuses && filter.support_statuses.length > 0) {
            store.sections.forEach(section => {
                section.supports = section.supports?.filter(sup => filter.support_statuses?.includes(sup.status_primark))
            })
            store.sections = store.sections.filter(sec => sec.supports?.length !== 0)
        }
        let store_ready_admin=checkStoreAdminReady(store)
        store.store_ready_admin=store_ready_admin;

        dispatch({
            type: UPDATE_STORE,
            payload: store
        } as StoreDetailActions);

        if (store && store.floors[0]) {
            const floor = store.floors.find(floor => floor.entry_no.toString() === initial_floor)
            if (floor) {
                dispatch(updateFloor(floor));
            } else {
                dispatch(updateFloor(store.floors[0]));
            }
        }

        const activeCampaign = await get_active_campaign();
        dispatch({
            type: UPDATE_ACTIVE_CAMPAIGN,
            payload: activeCampaign
        }); 

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as StoreDetailActions);
    }
}

export const checkStoreAdminReady=(store:Store)=>{
    let check=false;
    if(store?.status_primark===StoreStatusPrimark.PENDING_APPROVAL_SM){
        let supports_with_img=0;
        let supports_whithout_img=0;
        let supports_kendu_processing=0;
        store?.sections?.forEach(section => {
            const kendu_p = section.supports ? section.supports.filter(support => support.status_primark === SupportStatusPrimark.KENDU_PROCESSING).length: 0; // Valor predeterminado en caso de que section.supports sea nulo o indefinido
            supports_kendu_processing = supports_kendu_processing + kendu_p;
            const pending_approval_with_image = section.supports ? section.supports.filter(support => support.status_primark === SupportStatusPrimark.PENDING_APPROVAL && support.new_image!==undefined).length: 0; // Valor predeterminado en caso de que section.supports sea nulo o indefinido
            supports_with_img=supports_with_img+pending_approval_with_image;
            const pending_approval_with_no_image = section.supports ? section.supports.filter(support => support.status_primark === SupportStatusPrimark.PENDING_APPROVAL && support.new_image===undefined).length: 0; // Valor predeterminado en caso de que section.supports sea nulo o indefinido
            supports_whithout_img=supports_whithout_img+pending_approval_with_no_image;
          
         });

        if(supports_with_img>0 && supports_whithout_img===0 && supports_kendu_processing===0){
           check=true;
        }
    }
    return check;
}


export const updateLoading = (): AppThunk => {
	return async (dispatch) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as StoreDetailActions);
	}
}

export const getUsers = (id: string): AppThunk => {
	return async (dispatch) => {
        const users = await get_users({} as FilterUserRequest);
        const filteredUsers = users.filter(user => user.stores?.includes(id))

        dispatch({
            type: UPDATE_STORE_USERS,
            payload: filteredUsers
        })
	}
}

export const cleanStore = (): AppThunk => {
	return async (dispatch) => {
        dispatch({
            type: CLEAN_STORE,
            
        })
	}
}

export const updateFloor = (floor: Floor): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_SUPPORT,
            payload: null
        } as StoreDetailActions);
        dispatch({
            type: UPDATE_SELECTED_FLOOR,
            payload: floor
        } as StoreDetailActions);
    }
}

export const updateFilter = (filter: FilterInterface): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: filter
        } as StoreDetailActions);
    }
}



export const updateSelectedSupport=(support?: Support|null): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_SUPPORT,
            payload: support
        } as StoreDetailActions);

      
    }
}
