import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface NotificationProps {
    title: string,
    subtitle?: string,
    actions?: React.ReactNode[]
}

type NotificationPropsSum = NotificationProps & WithTranslation;

class NotificationComponent extends React.Component<NotificationPropsSum> {

    render () {
        const { title, subtitle } = this.props;
        return (
            <section className="pending-notification">
                <article className="pending-info">
                    <h2>{ title }</h2>
                    <h2>{ subtitle }</h2>
                </article>
                <article className="pending-action">
                    <ul>
                        {this.props.actions?.map((action, key) => <li key={key}>{action}</li>)}
                    </ul>
                </article>
            </section>
        );
    };
}

const Notification = withTranslation()(NotificationComponent);
export { Notification };
