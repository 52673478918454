import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface TabsProps {
}

type TabsPropsSum = TabsProps & WithTranslation;

class TabsComponent extends React.Component<TabsPropsSum> {

    render () {
        return (
            <nav className="tab">
                <ul>
                    {this.props.children}
                </ul>
            </nav>
        );
    };
}

const Tabs = withTranslation()(TabsComponent);
export { Tabs };
