import { AppThunk } from '../../reducers';
import {
    get_countries,
    get_support_types,
    get_textile_types,
    get_support_statuses,
    get_sections,
    get_production_languages,
    get_all_languages,
    get_country_managers
} from '../../api';
import {
    DatatypesActions,
    UPDATE_COUNTRIES,
    UPDATE_SUPPORT_TYPES,
    UPDATE_TEXTIL_TYPES,
    UPDATE_SUPPORT_STATUSES,
    UPDATE_DEPARTMENTS,
    UPDATE_PRODUCTION_LANGUAGES,
    UPDATE_LANGUAGES,
    UPDATE_COUNTRY_MANAGERS
} from './DatatypesTypes';

export const getProductionLanguages = (): AppThunk => {
    return async dispatch => {

        const production_languages = await get_production_languages();
       
        dispatch({
            type: UPDATE_PRODUCTION_LANGUAGES,
            payload: production_languages.map((production_language: any) => ({ value: production_language.entry_no , name: production_language.description }))
        } as DatatypesActions);
    }
}

export const getCountryManagers = (): AppThunk => {
    return async dispatch => {
        const country_managers = await get_country_managers();

        dispatch({
            type: UPDATE_COUNTRY_MANAGERS,
            payload: country_managers
        } as DatatypesActions);
    }
}

export const getAllLanguages = (): AppThunk => {
    return async dispatch => {

        const languages = await get_all_languages();

        dispatch({
            type: UPDATE_LANGUAGES,
            payload: languages.map((language: any) => ({ value: language.id , name: language.name }))
        } as DatatypesActions);
    }
}

export const getCountries = (): AppThunk => {
    return async dispatch => {

        const countries = await get_countries();

        dispatch({
            type: UPDATE_COUNTRIES,
            payload: countries
        } as DatatypesActions);
    }
}

export const getSupportTypes  = (): AppThunk => {
    return async dispatch => {

        let support_types = await get_support_types();
        
        dispatch({
            type: UPDATE_SUPPORT_TYPES,
            payload: support_types
        } as DatatypesActions);
    }
}

export const getTextileTypes = (): AppThunk => {
    return async dispatch => {

        const textile_types = await get_textile_types();
        
        dispatch({
            type: UPDATE_TEXTIL_TYPES,
            payload: textile_types
        } as DatatypesActions);
    }
}

export const getSupportStatuses = (): AppThunk => {
    return async dispatch => {

        const support_statuses = await get_support_statuses();
        
        dispatch({
            type: UPDATE_SUPPORT_STATUSES,
            payload: support_statuses
        } as DatatypesActions);
    }
}

export const getDepartments = (): AppThunk => {
    return async dispatch => {

        const departments = await get_sections();
        
        dispatch({
            type: UPDATE_DEPARTMENTS,
            payload: departments
        } as DatatypesActions);
    }
}