import React from 'react';
import { get_user_token } from '../util/cookies';
import { get_role, Role } from '../util/jwt';

export interface Props {
    roles: Role[],
    not?: boolean,
    children?: React.ReactNode
}

class RoleGuard extends React.Component<Props> {
    
    isValidRole() {
        const { roles, not } = this.props;
        const token = get_user_token();
        const role = get_role(token);

        return not ? !roles.includes(role) : roles.includes(role);
    }

    render () {
        const token = get_user_token();

        if (token && this.isValidRole()) {
            return this.props.children;
        }

        return null;
    }
}

export { RoleGuard };