import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { CloseButton } from "./CloseButton";
import { PrimaryButton } from "./PrimaryButton";
import { StoreStatusPrimark } from "../interfaces/interfaces";
import { FormSelect } from "./FormSelect";
export interface ChangeStoreStatusPopUpProps {
  onClose: () => void;
  onSubmit: (store_status: string|undefined) => void
  store_status: StoreStatusPrimark | undefined;
}

interface State {
    store_status: string|undefined,
}
type ChangeStoreStatusPopUpPropsSum = ChangeStoreStatusPopUpProps &
  WithTranslation;

class ChangeStoreStatusPopUpComponent extends React.Component<ChangeStoreStatusPopUpPropsSum,State> {

    constructor(props: ChangeStoreStatusPopUpPropsSum) {
        super(props);

        this.state = {
            store_status: props.store_status,
        };
    }
  componentDidMount() {
    document.body.classList.add("stopScroll");
  }

  componentWillUnmount() {
    document.body.classList.remove("stopScroll");
  }

  onSubmit() {
    const { store_status } = this.state;
    this.props.onSubmit(store_status);
}

  render() {
    const { t } = this.props;
    const{store_status}=this.state
    return (
      <section id="confirmation" className="cover-blur /*active*/ active">
        <section className="popup">
          <section className="popup-top">
            <h2 style={{ wordBreak: "keep-all" }}>
              {t("change_store_status")}
            </h2>
            <CloseButton onClick={this.props.onClose} />
          </section>
          <section className="popup-content">
            <div>
              <FormSelect
                id="store_status"
                label={t("store_status")}
                options={Object.values(StoreStatusPrimark).map((status) => {
                  if (status === "N_A") {
                    return { name: "Not in Campagin (N/A)", value: status };
                  }
                  return { name: t(status.toLowerCase()), value: status };
                })}
                value={store_status}
                onChange={(status) => {
                    this.setState({store_status: status})
                }}
              />
              
            </div>
          </section>
          <section className="popup-actions">
            <PrimaryButton onClick={() => this.onSubmit()}>{t("confirm")}</PrimaryButton>
          </section>
        </section>
      </section>
    );
  }
}

const ChangeStoreStatusPopUp = withTranslation()(
  ChangeStoreStatusPopUpComponent
);
export { ChangeStoreStatusPopUp };
