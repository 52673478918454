import {
    UPDATE_EMAIL,
    UPDATE_EMAIL_ERROR,
    UPDATE_EMAIL_SUCCESS,
    SetingsActions,
    AppThunk
} from './ActionTypes';

export const updateEmail = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_EMAIL,
            payload: value
        } as SetingsActions);
    }
}

export const updateEmailError = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_EMAIL_ERROR,
            payload: value
        } as SetingsActions);
    }
}

export const UpdateEmailSuccess = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_EMAIL_SUCCESS,
            payload: value
        } as SetingsActions);
    }
}
