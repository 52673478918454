import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface TabProps {
    text: string,
    bubleText?: string,
    active: boolean,
    onClick: () => void,
}

type TabPropsSum = TabProps & WithTranslation;

class TabComponent extends React.Component<TabPropsSum> {

    render () {
        const { text, bubleText, active, onClick } = this.props;
        return (
            <li className={`${active ? 'active' : ''} cursor-hand`}>
                <button onClick={onClick}>{ text }
                    {bubleText && <> <span>{bubleText}</span></>}
                </button>
            </li>
        );
    };
}

const Tab = withTranslation()(TabComponent);
export { Tab };
