import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { CloseButton } from "./CloseButton";
import { PrimaryButton } from "./PrimaryButton";

export interface ConfirmationPopUpProps {
    onClose: () => void,
    onSubmit: (...args : any[]) => void,
    title: string,
    change_text?: string
    warningText?: string
}

type ConfirmationPopUpPropsSum = ConfirmationPopUpProps & WithTranslation;

class ConfirmationPopUpComponent extends React.Component<ConfirmationPopUpPropsSum> {

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    render () {
        const { t, title, onSubmit, change_text, warningText } = this.props;
        
        return (
            <section id="confirmation" className="cover-blur /*active*/ active">
                <section className="popup">
                    <section className="popup-top">
                        <h2 style={{wordBreak: "keep-all"}}>{ title }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content">
                        <div>
                            <p>{ change_text }</p>
                            <p>{ t("are_you_sure_you_want_to_proceed_?") }</p>
                            { warningText && <><br/><p>{ warningText }</p></>}

                        </div>
                    </section>
                    <section className="popup-actions">
                        <PrimaryButton onClick={ onSubmit } >{ t('confirm') }</PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const ConfirmationPopUp = withTranslation()(ConfirmationPopUpComponent);
export { ConfirmationPopUp };
