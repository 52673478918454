import React, { Component } from 'react';
import '../css/radialchart.css';

interface State {
	setStrokeLength: boolean
}

interface Props {
	radius?: number,
	strokeWidth?: number,
	progress: number,
	dimension?: number
}

class RadialChart extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			setStrokeLength: false
		};
	}

	componentDidMount() {
		// For initial animation
		setTimeout(() => {
			this.setState({ setStrokeLength: true });
		});
	}

	getClassNames(strokeLength: number) {
		const classes = ['radial-chart'];

		if (strokeLength === 0) {
			classes.push('no-progress');
		}

		return classes.join(' ');
	}

	getColor() {
		const { progress } = this.props;

		if (progress <= 10) {
			return '#D73A49';
		}
		if (progress <= 50) {
			return '#DBAB09';
		}

		return '#34D058';
	}

	render() {
		const { setStrokeLength } = this.state;
		const {
			radius = 25,
			progress,
			strokeWidth = 6,
			dimension = 70,
		} = this.props;
		const circleRadius = Math.min(radius, 85);
		const circumference = 2 * 3.14 * circleRadius;
		const strokeLength = setStrokeLength ? circumference / 100 * progress : 0;
		const color = this.getColor();

		return (
			<div className={this.getClassNames(strokeLength)} >
				<svg viewBox={`0 0 ${dimension} ${dimension}`} width={dimension} height={dimension}>
					<circle
						className="radial-chart-total"
						stroke="#E9EBEC"
						strokeWidth={strokeWidth}
						fill="none"
						cx={dimension/2}
						cy={dimension/2}
						r={circleRadius}
					/>
					<circle
						className="radial-chart-progress"
						stroke={color}
						strokeWidth={strokeWidth}
						strokeDasharray={`${strokeLength},${circumference}`}
						fill="none"
						cx={dimension/2}
						cy={dimension/2}
						r={circleRadius}
					/>
					<text 
						className="radial-chart-label"
						x={dimension/2} 
						y={dimension/2} 
						text-anchor="middle" 
						dominant-baseline="central" 
						fill={color}>
						{progress.toFixed(0)}%
					</text>
				</svg>
			</div>
		);
	}
}

export { RadialChart };

