import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Icon } from '.';

export interface BackArrowProps {
    onClick: () => void
}

type BackArrowPropsSum = BackArrowProps & WithTranslation;

class BackArrowComponent extends React.Component<BackArrowPropsSum> {

    render () {
        const { onClick } = this.props;
        return (
            <div className="btn-back v-center">
                <button className='center' onClick={() => onClick()}>
                    <Icon type="back-arrow" />
                </button>
            </div>
        );
    };
}

const BackArrow = withTranslation()(BackArrowComponent);
export { BackArrow };
