import {
    IncidentsIndexActions,
} from "./ActionTypes";
import { Reducer } from 'redux';

export interface IncidentsIndexState {
    loading: boolean,
}

const INITIAL_STATE: IncidentsIndexState = {
    loading: false,
};

const reducer: Reducer<IncidentsIndexState, IncidentsIndexActions> = (state = INITIAL_STATE, action: IncidentsIndexActions) => {
    switch(action.type) {
        default:
            return state;
    }
}

export default reducer;