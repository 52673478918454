import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PrimaryButtonSize, PrimaryButtonStyle } from '../interfaces/interfaces';

export interface PrimaryButtonProps {
    onClick?: () => void,
    style?: PrimaryButtonStyle,
    size?: PrimaryButtonSize,
    disabled?: boolean,
    aria_label?: string;
    data_ballon_pos?:string;
}


type PrimaryButtonPropsSum = PrimaryButtonProps & WithTranslation;

class PrimaryButtonComponent extends React.Component<PrimaryButtonPropsSum> {


    render () {
        const { size, style, aria_label,data_ballon_pos } = this.props
        if(data_ballon_pos!==undefined && data_ballon_pos!==''){
            return (
                <button disabled={this.props.disabled} className={`${style} ${size}`} onClick={this.props.onClick} aria-label={aria_label} data-balloon-pos={data_ballon_pos} >
                    {this.props.children}
                </button>
            );
        }
        return (
            <button disabled={this.props.disabled} className={`${style} ${size}`} onClick={this.props.onClick}  >
                {this.props.children}
            </button>
        );
    };
}

const PrimaryButton = withTranslation()(PrimaryButtonComponent);

PrimaryButton.defaultProps = {
    style: PrimaryButtonStyle.FILLED,
    size: PrimaryButtonSize.MEDIUM
}
export { PrimaryButton };
