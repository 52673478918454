import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseButton } from '.';
import { Floor } from "../interfaces/interfaces";
import { Edit2, Plus } from 'react-feather';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Role } from '../util/jwt';
import { RoleGuard } from './RoleGuard';

export interface EditFloorplansProps {
    floors: Floor[],
    store_no: string,
    onEditClicked: (floor: Floor) => void,
    onSort: (orderedFloors: Floor[]) => void,
    onClose: () => void,
}

type EditFloorplansPropsSum = EditFloorplansProps & WithTranslation;

interface State {
    floorsOrdered: Floor[]
}

const SortableItem = SortableElement(({children}: any) => <li>{ children }</li>);
const SortableList = SortableContainer(({children}: any) => <ul className="floorplan-list">{ children }</ul>);

class EditFloorplansComponent extends React.Component<EditFloorplansPropsSum, State> {

    constructor(props: EditFloorplansPropsSum) {
        super(props);

        this.state = {
            floorsOrdered: props.floors
        };
    }

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    onSortEnd = (event: { oldIndex: number; newIndex: number; }) => {
        const { floorsOrdered } = this.state;

        var orderedFloors = arrayMove(floorsOrdered, event.oldIndex, event.newIndex)
        orderedFloors.forEach((floor, index) => floor.floor_order = index)
        this.setState({
            floorsOrdered: orderedFloors
        });

        this.props.onSort(orderedFloors)
    }

    render () {
        const { t, onEditClicked, onClose, store_no, floors } = this.props;
        const { floorsOrdered } = this.state;
        return (
            <section id="popUp-edit-foor-plans" className="cover-blur edit-floorplan-popup active date-range-space-between">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('edit_floorplans') }</h2>
                        <CloseButton onClick={onClose} />
                    </section>
                    <section className="popup-content">
                        <RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
                            <SortableList onSortEnd={this.onSortEnd} distance={3}>
                                {floorsOrdered.map((floor: any, index: any) => (
                                    <SortableItem key={`item-${ index }`} index={ index } disabled={false} style={{zIndex: 999999}}>
                                        <strong>{ floor.name }</strong>
                                            <span className="link cursor-hand" onClick={() => onEditClicked(floor)}><Edit2 /></span>
                                    </SortableItem>
                                ))}
                            </SortableList>
                        </RoleGuard>
                        <RoleGuard not roles={[Role.ADMIN, Role.BACKDOOR]}>
                            <ul className="floorplan-list">
                                {floorsOrdered.map((floor: any, index: any) => (
                                    <li>
                                        {floor.name}
                                    </li>
                                ))}
                            </ul>
                        </RoleGuard>
                        <ul className="floorplan-list">
                            <RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
                                <li className="add" onClick={() => onEditClicked({ store_no: Number(store_no), floor_order: floors.length+1  } as Floor)}>{}
                                    <Plus />
                                    <b>{ t('add_floor') }</b>
                                </li>
                            </RoleGuard>
                        </ul>
                    </section>
                </section>
            </section>
        );
    };
}

const EditFloorplans = withTranslation()(EditFloorplansComponent);
export { EditFloorplans };
