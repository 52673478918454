import { one_support, one_store } from '../../../api';
import {
    UPDATE_SUPPORT,
    UPDATE_LOADING,
    UPDATE_FILTER,
    SupportDetailActions,
    AppThunk,
    UPDATE_FLOORS,
} from './ActionTypes';
import { Filter as FilterInterface } from "../../../interfaces/interfaces";

export const getSupport = (id: string): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as SupportDetailActions);

        const support = await one_support(id);

        dispatch({
            type: UPDATE_SUPPORT,
            payload: support
        } as SupportDetailActions);

        const store = await one_store(support.store_id, {})
        
        dispatch({
            type: UPDATE_FLOORS,
            payload: store.floors
        } as SupportDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as SupportDetailActions);
    }
}
export const updateLoading = (): AppThunk => {
	return async (dispatch) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as SupportDetailActions);
	}
}
export const updateFilter = (filter: FilterInterface): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: filter
        } as SupportDetailActions);
    }
}