import React from 'react';
import { Edit, Plus } from 'react-feather';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SupportStatusPrimark, Support, StoreStatusPrimark, Store, SelectOption } from '../interfaces/interfaces';
import { Role } from '../util/jwt';
import { RoleGuard } from './RoleGuard';
import { StatusGuard } from './StatusGuard';

interface SupportInfoWindowProps {
    support: Support,
    sections?: SelectOption[],
    onValidateClick?: (support: Support) => void,
    onAddCampaignClick?: (support: Support) => void,
    onEditClick?: (support: Support,changedSupports:Support[]) => void,
    store?: Store | null
    editing: boolean,
    changedSupports?: Support[]|undefined,
    role?: Role,
    isValidate:boolean,
    floor_name?:string
}

type Props = SupportInfoWindowProps & WithTranslation;

class SupportInfoWindowComponent extends React.Component<Props> {
    getWindowClassName() {
        switch(this.props.support.status_primark) {
            case SupportStatusPrimark.APPROVED_BY_CM: return 'status-approved-cm';
            case SupportStatusPrimark.APPROVED_BY_SM: return 'status-approved-sm';
            case SupportStatusPrimark.NOT_IN_CAMPAIGN: return 'status-notincampaign';
            case SupportStatusPrimark.PENDING_APPROVAL: return 'status-pending-approval';
            case SupportStatusPrimark.PENDING_APPROVAL_CM: return 'status-pending-approval-cm';
            case SupportStatusPrimark.KENDU_PROCESSING: return 'status-processing';
            default: return '';
        }
    }

    renderValidateButton() {
        const { store, support, editing, role } = this.props
        
        if (editing && role === Role.ADMIN) {
            return <button style={{ textIndent: '0', backgroundImage: 'none'}} onClick={() => this.props.onEditClick?.(support,this.props.changedSupports!)}><Edit style={{ width: '100%', height: '100%'}} /></button> 
        }

        if (role === Role.STORE_MANAGER && !(store?.status_primark === StoreStatusPrimark.N_A || store?.status_primark === StoreStatusPrimark.PENDING_APPROVAL_SM)) {
            return null;
        }

        if (role === Role.COUNTRY_MANAGER && !(store?.status_primark === StoreStatusPrimark.N_A || store?.status_primark === StoreStatusPrimark.PENDING_APPROVAL_SM || store?.status_primark === StoreStatusPrimark.PENDING_SIGN_OFF_CM)) {
            return null;
        }

        if (store?.status_primark === StoreStatusPrimark.IN_PRODUCTION || store?.status_primark  === StoreStatusPrimark.SHIPPED || store?.status_primark  === StoreStatusPrimark.INSTALLED) {
            return null;
        }
    

      
        return(
            <>
            <StatusGuard status={store?.status_primark} statuses={[StoreStatusPrimark.PENDING_APPROVAL_SM]}>
                <RoleGuard roles={[Role.STORE_MANAGER, Role.ADMIN, Role.COUNTRY_MANAGER]}>
                    <StatusGuard status={support.status_primark} statuses={[SupportStatusPrimark.PENDING_APPROVAL]}>
                    {this.props.isValidate && support.new_image!==undefined &&<button onClick={() => this.props.onValidateClick?.(support)} disabled={false}>approve</button>}
                    {this.props.isValidate===false && <button onClick={() => this.props.onValidateClick?.(support)} disabled={false}>approve</button>}
                        
                    </StatusGuard>
                    
                </RoleGuard>
                
            </StatusGuard>
           
            <StatusGuard status={ support.status_primark } statuses={[SupportStatusPrimark.NOT_IN_CAMPAIGN]}>
                 <button style={{backgroundImage: 'url("/img/icons/plus.svg")'}} onClick={() => this.props.onAddCampaignClick?.( support )}><Plus /></button>
            </StatusGuard>
            </>
        );
    }

    getSupportImage() {
        const { status_primark, current_image, new_image } = this.props.support;

        if (status_primark === SupportStatusPrimark.NOT_IN_CAMPAIGN) {
            return current_image;
        }

        return new_image;
    }

    getSupportSection() {
        const { support, sections } = this.props;

        return sections?.find(section => section.value === support.section)?.name || support.section;
    }

    render () {
        const { support, t,floor_name } = this.props;
   
        return (
            <article className={`tooltip-support-status ${this.getWindowClassName()}`}>
                {this.renderValidateButton()}
                <div>
                    <img src={this.getSupportImage()} alt="" />
                </div>
                <div>
                    <h4>{support.no_soporte_cliente}</h4>
                    <h5>{t(support.status_primark.toLowerCase())}</h5>
                    <ul className="sizes">
                        <li>{support.support_type}</li>
                        <li>{support.width}x{support.height}</li>
                        <li>{support.textil_type}</li>
                        <li>{floor_name}</li>
                    </ul>
                    <ul className="priorities">
                        <li>{this.getSupportSection()}</li>
                        <li>{t('priority')} {support.priority}</li>
                    </ul>
                </div>
            </article>
        );
    };
}

const SupportInfoWindow = withTranslation()(SupportInfoWindowComponent);
export { SupportInfoWindow };
