import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Icon } from '.';
import { Visual } from '../interfaces/interfaces';

export interface ImagePreviewProps {
    visual: Visual,
    onClose: () => void,
}

type ImagePreviewPropsSum = ImagePreviewProps & WithTranslation;

class ImagePreviewComponent extends React.Component<ImagePreviewPropsSum> {

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    render () {
        const { t, visual, onClose } = this.props

        return (
            <section id="gallery-visual" className="cover-blur /*active*/ active">
                <article className="hot-image">
                    <button onClick={ onClose }>
                        <Icon type="close-icon"/>
                        <span>{ t('close_popup') }</span>
                    </button>
                    <img alt="" src={ visual.image } />
                </article>
            </section>
        );
    };
}

const ImagePreview = withTranslation()(ImagePreviewComponent);


export { ImagePreview };
