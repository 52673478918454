import moment from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormText, CloseButton, FormImage, InputDate, InputSelectMultiple, PrimaryButton } from '.';
import { Campaign, SelectOption, CampaignStatusPrimark } from "../interfaces/interfaces";
import { get_user_token } from '../util/cookies';
import { is_empty_string } from '../util/form';
import { get_role, Role } from '../util/jwt';
import { StatusGuard } from './StatusGuard';
export interface EditCampaignProps {
    campaign?: Campaign,
    departments: SelectOption[],
    onClose: () => void,
    onSubmit: (campaign: Campaign) => void
}

interface State {
    campaign: Campaign | undefined,
    campaign_name_error: string,
    campaign_date_error: string,
    campaign_departments_error: string,
}

type EditCampaignPropsSum = EditCampaignProps & WithTranslation;

class EditCampaignComponent extends React.Component<EditCampaignPropsSum, State> {

    constructor(props: EditCampaignPropsSum) {
        super(props);

        this.state = {
            campaign: {...props.campaign, image: '', departments : this.props.campaign?.sections?.map((section)=> section.id)} as Campaign,
            campaign_name_error: '',
            campaign_date_error: '',
            campaign_departments_error: '',
        };
    }

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    onSubmit() {
        const { campaign } = this.state;
        this.props.onSubmit(campaign as Campaign);
    }

    submit() {
        const { id, title, start_date, end_date, image, departments, notifications } = this.state.campaign!;

        if (this.isValid()) {
                this.props.onSubmit({
                    id,
                    title,
                    start_date,
                    end_date,
                    image,
                    departments,
                    notifications
                } as Campaign);
        }
    }

    isValid() {
        const { t } = this.props;
        const { title, start_date, end_date, departments } = this.state.campaign!;
        let isValid = true;

        this.setState({
            campaign_name_error: '',
            campaign_date_error: '',
            campaign_departments_error: '',
        });

        if (is_empty_string(title)) {
            isValid = false;
            this.setState({ campaign_name_error:t('campaign_name_required')});
		}

        if (is_empty_string(start_date) || is_empty_string(end_date)) {
            isValid = false;
            this.setState({ campaign_date_error:t('campaign_date_required')});
		}

        if (!departments || departments.length === 0) {
            isValid = false;
            this.setState({ campaign_departments_error:t('campaign_deparments_required')});
		}
        return isValid
    }

    isNew() {
        return !this.props.campaign;
    }

    render () {
        const { t, departments } = this.props;
        const { campaign } = this.state;
        var departmentsDisabled = false;
        if(campaign){
            if(![Role.ADMIN].includes(get_role(get_user_token())) && ![Role.MARKETING].includes(get_role(get_user_token()))){
                departmentsDisabled = true;
            }else if(campaign.status_primark && campaign?.status_primark !== CampaignStatusPrimark.DRAFT){
                departmentsDisabled = true;
            }
        }
        
        return (
            <section id="campaign-edit" className="cover-blur active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ this.isNew() ? t('new_campaign') : t('edit_campaign') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content">
                        <StatusGuard status={campaign?.status_primark??CampaignStatusPrimark.DRAFT} statuses={[CampaignStatusPrimark.DRAFT]}>
                            <FormImage photo={this.props.campaign?.image} onChange={(image) => this.setState({ campaign: { ...campaign, image: image } as Campaign })}/>
                        </StatusGuard>
                        <fieldset className="formlines-container">
                            <StatusGuard status={campaign?.status_primark??CampaignStatusPrimark.DRAFT} statuses={[CampaignStatusPrimark.DRAFT]}>
                                <div className="form-row">
                                    <FormText id="name" label={ t('name') } value={ campaign?.title } onChange={(text) => this.setState({ campaign: { ...campaign, title: text } as Campaign })}  helpText={this.state.campaign_name_error}/>
                                </div>
                                <div className="form-row">
                                    <span className="col-d-1-1">
                                        <article className="form-item">
                                            <label>{ t('start_date') }</label>
                                        </article>
                                        <article className="form-item">
                                            <label>{ t('end_date') }</label>
                                        </article>
                                    </span>
                                    <InputDate 
                                        start_date={campaign?.start_date ? moment(campaign?.start_date).unix() : undefined} 
                                        end_date={campaign?.end_date ? moment(campaign?.end_date).unix() : undefined} 
                                        onChange={(start_date, end_date) => 
                                            this.setState({ 
                                                campaign: { 
                                                    ...campaign, 
                                                    start_date: start_date ? moment.unix(start_date).format('YYYY-MM-DD') : '', 
                                                    end_date: end_date ? moment.unix(end_date).format('YYYY-MM-DD') : '' 
                                                } as Campaign }
                                            )}
                                        helpText={this.state.campaign_date_error}
                                        disabled={![Role.ADMIN].includes(get_role(get_user_token())) && ![Role.MARKETING].includes(get_role(get_user_token()))}

                                        />
                                </div>
                                <div className="form-row">
                                    <article className="form-item">
                                        <label>{ t('departments') }</label>
                                        <InputSelectMultiple 
                                            id="departments" 
                                            selected={campaign?.sections?.map((section)=> section.id) || []} 
                                            options={departments} 
                                            onChange={(options) => this.setState({ campaign: { ...campaign, departments: options.map(option => option.value)} as Campaign})} 
                                            placeholder={ t('select_departments') } 
                                            helpText={this.state.campaign_departments_error}
                                            disabled={ departmentsDisabled }/>
                                    </article>
                                </div>
                            </StatusGuard>
                            <div className="form-row">
                                <article className={`check-group campaigns-check flex gap-8 ${campaign?.notifications ? 'checked' : ''}`}>
                                    <input id={ 'email_notifications' } type="checkbox" onChange={(event) =>   this.setState({ campaign: { ...campaign, notifications: event.target.checked} as Campaign})} checked={ campaign?.notifications !== undefined ? campaign.notifications : false }/>
                                    <label htmlFor={ 'email_notifications' }>{ t('active_notifications') }</label>
                                </article>
                            </div>
                        </fieldset>
                    </section>
                    <section className="popup-actions">
                        <PrimaryButton onClick={() => this.submit()} >{ this.isNew() ? t('create') : t('save') }</PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const EditCampaign = withTranslation()(EditCampaignComponent);
export { EditCampaign };
