import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Visual, VisualPreviewSizes } from '../interfaces/interfaces';

export interface CroppedVisualCardProps {
    visual: Visual
    croppedSize : VisualPreviewSizes
    onClick: (url: string) => void,
    updated?: boolean,
}

type CroppedVisualCardPropsSum = CroppedVisualCardProps & WithTranslation;

class CroppedVisualCardComponent extends React.Component<CroppedVisualCardPropsSum> {


    renderSizeText(){
        const { croppedSize } = this.props;

        if (croppedSize === VisualPreviewSizes.O){
            return 'Master'
        }
        const documentWidth = croppedSize.split('x')[0]
        const documentHeight = croppedSize.split('x')[1]

        return `${documentWidth} x ${documentHeight}`
    }

    imageURL(){
        const { visual,croppedSize, updated } = this.props;

        if (croppedSize === VisualPreviewSizes.O){
            return visual.image
        }

        const documentWidth = Number(croppedSize.split('x')[0]) / 10
        const documentHeight = Number(croppedSize.split('x')[1]) / 10 

        return `${process.env.REACT_APP_IMAGES_URL}/campañas/${visual.campaign_id}/ratios/${visual.section}/${visual.id}_${documentWidth}_${documentHeight}.jpg?${ updated ? Date.now() : '' }`
    }

    render () {

        return (
            <article className="card-cropped-image">
                <div className="card-image">
                    <img className="cursor-hand" src={this.imageURL()}  alt="" onClick={ () => this.props.onClick(this.imageURL()) } />   
                </div>  
                <div className="card-image-detail">
                    <p>{ this.renderSizeText() }</p>
                </div>
            </article>
        );
    };
}

const CroppedVisualCard = withTranslation()(CroppedVisualCardComponent);
export { CroppedVisualCard };
