import React from 'react';
import './App.css';
import './css/main.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
import { Nav, Header, Content } from './components';
import CampaignIndex from './screens/campaign/index/Index';
import CampaignDetail from './screens/campaign/detail/Detail';
import StoreIndex from './screens/store/index/Index';
import StoreDetail from './screens/store/detail/Detail';
import StoreApprove from './screens/store/approve/Approve';
import StoreValidateImages from './screens/store/validate_images/ValidateImages';
import StoreValidateCrops from './screens/cropValidation/validate_crops/ValidateCrops';
import SupportIndex from './screens/frame/index/Index';
import CropValidationIndex from './screens/cropValidation/index/Index';
import SupportDetail from './screens/frame/detail/Detail';
import GalleryIndex from './screens/gallery/index/Index';
import GalleryDetail from './screens/gallery/detail/Detail';
import DashboardIndex from './screens/dashboard/index/Index';
import KenduProcessing from './screens/kenduProcessing/index/Index';
import ProcessingDetail from './screens/kenduProcessing/detail/Detail';
import UsersIndex from './screens/users/index/Index';
import ActivityIndex from './screens/activity/index/Index';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { RootState } from './reducers';
import { connect, ConnectedProps } from 'react-redux';
import { updateToken } from './reducers/AuthActions';
import cookies, { COOKIE_ON_LOGIN, COOKIE_USER_TOKEN, get_user_token } from './util/cookies';
import Login from './screens/security/login/Login';
import SignUp from './screens/security/sign-up/SignUp';
import PasswordRequest from './screens/security/password-request/PasswordRequest';
import PasswordReset from './screens/security/password-reset/PasswordReset';
import { Helmet } from 'react-helmet';
import { Role } from './util/jwt';
import { AclRoute } from './components/AclRoute';
import { VisualPackk } from './screens/store/visual_pack/VisualPack';

const mapStateToProps = (state: RootState) => {
    return {
        auth_state: state.auth
    }
}

const connector = connect(mapStateToProps, { updateToken })

const ALLOWED_PATHS = ['/password-request', '/password-reset', '/password-reset-email', '/sign-up', '/stores/visual-pack'];

class App extends React.Component<ConnectedProps<typeof connector>> {

    script: HTMLScriptElement;

    constructor(props: ConnectedProps<typeof connector>) {
        super(props);

        this.checkAuthorization();
        this.script = document.createElement("script");
        this.script.setAttribute('data-jsd-embedded', '');
        this.script.setAttribute('data-key', '77cce53e-dc16-49f5-ba20-a7a051eb278b');
        this.script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
        this.script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
    }

    checkAuthorization(){
        const token = get_user_token();
        this.props.updateToken(token);
        let needsLogin = true;
        
        if( window.location.pathname === '/'){
            needsLogin = false;
        }else{
            ALLOWED_PATHS.forEach(substring => {
               if((window.location.pathname).indexOf(substring) !== -1) {
                    needsLogin = false;
               }
            })
        }
        const notAllowed = !token && needsLogin;
        if(notAllowed){
            cookies.set(COOKIE_ON_LOGIN, window.location.pathname, { path: '/' });
            window.location.href = "/";
        }
    }

    componentDidMount() {
        const token = get_user_token();
        this.props.updateToken(token);
        
        cookies.addChangeListener((options) => {
            if (options.name === COOKIE_USER_TOKEN) {
                this.props.updateToken(options.value as string);
            }
        });
    }

    clearScript() {
        if (document.body.contains(this.script)) {
            document.body.removeChild(this.script);

            let iframe = document.getElementById('jsd-widget');

            while (iframe !== null) {
                document.body.removeChild(iframe);
                iframe = document.getElementById('jsd-widget');
            }
        }
    }

    appendScript() {
        document.body.appendChild(this.script);

        setTimeout(() => {
            var DOMContentLoaded_event = document.createEvent("Event")
            DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true)
            window.document.dispatchEvent(DOMContentLoaded_event)
        }, 500);
    }

    render() {
        // this.clearScript();
        const { token } = this.props.auth_state;
        const userItems = (token !== null && !token) || ALLOWED_PATHS.includes(window.location.pathname);

        return (
            <Router>
                {userItems  &&
                    <main className="body-login">
                        <Helmet>
                            <body className="body-login" />
                        </Helmet>
                        <Content>
                            <main id="loginContainer">
                                <Switch>
                                    <Route exact path="/stores/visual-pack/:store_id/:token" component={VisualPackk} />
                                    <Route path="/login" exact component={Login}/>
                                    <Route path="/password-request" exact component={PasswordRequest}/>
                                    <Route path="/password-reset" component={PasswordReset}/>
                                    <Route path="/password-reset-email/:hash" component={PasswordReset}/>
                                    <Route path="/sign-up" exact component={SignUp}/>
                                    <Route path="/" component={Login}/>
                                </Switch>
                            </main>
                        </Content>
                    </main>
                }
                {!userItems && 
                    <>
                        <Nav />
                        <main className="main-content">
                            <Header />
                            <Content>
                                <Switch>
                                    <Route path="/dashboard" exact component={DashboardIndex} />
                                    <AclRoute path="/kendu_processing" exact roles={[Role.KENDU_DESIGN, Role.ADMIN, Role.BACKDOOR]} component={KenduProcessing} />
                                    <AclRoute path="/processing_detail/:campaign_id/:support_id" exact roles={[Role.KENDU_DESIGN, Role.ADMIN, Role.BACKDOOR]} component={ProcessingDetail} />
                                    <AclRoute path="/campaigns" exact roles={[Role.MARKETING, Role.ADMIN, Role.BACKDOOR,Role.HEAD_VM]} component={CampaignIndex} />
                                    <AclRoute path="/campaigns/:id" exact roles={[Role.MARKETING, Role.ADMIN, Role.BACKDOOR,Role.HEAD_VM]} component={CampaignDetail} />
                                    <AclRoute path="/stores" exact roles={[Role.STORE_MANAGER, Role.COUNTRY_MANAGER, Role.HEAD_VM, Role.ADMIN, Role.BACKDOOR]} component={StoreIndex} />
                                    <AclRoute path="/stores/approve/:store_id?" exact roles={[Role.COUNTRY_MANAGER]} component={StoreApprove} />

                                    <AclRoute path="/stores/:id" exact roles={[Role.STORE_MANAGER, Role.COUNTRY_MANAGER, Role.HEAD_VM, Role.ADMIN, Role.BACKDOOR]} component={StoreDetail} />
                                    <AclRoute path="/stores/:store_id/validate_images/:support_id?" roles={[Role.MARKETING, Role.COUNTRY_MANAGER, Role.HEAD_VM, Role.STORE_MANAGER, Role.ADMIN, Role.BACKDOOR]} component={StoreValidateImages} />
                                    <AclRoute path="/frames" exact roles={[Role.STORE_MANAGER, Role.COUNTRY_MANAGER, Role.HEAD_VM, Role.ADMIN, Role.BACKDOOR]} component={SupportIndex} />
                                    <AclRoute path="/frames/:id" exact roles={[Role.STORE_MANAGER, Role.COUNTRY_MANAGER, Role.HEAD_VM, Role.ADMIN, Role.BACKDOOR]} component={SupportDetail} />
                                    <AclRoute path="/gallery" exact roles={[Role.ADMIN, Role.BACKDOOR,Role.KENDU_DESIGN]} component={GalleryIndex} />
                                    <AclRoute path="/gallery/:id" roles={[ Role.ADMIN, Role.BACKDOOR,Role.KENDU_DESIGN]} exact component={GalleryDetail} />
                                    <AclRoute path="/crops_validation" exact roles={[Role.MARKETING, Role.ADMIN, Role.BACKDOOR]} component={CropValidationIndex} />
                                    <AclRoute path="/crops_validation/:campaign_id" exact roles={[Role.MARKETING, Role.ADMIN, Role.BACKDOOR]} component={StoreValidateCrops} />
                                    <AclRoute path="/activity" exact roles={[Role.ADMIN, Role.BACKDOOR]} component={ActivityIndex} />
                                    <AclRoute path="/users" exact roles={[Role.ADMIN, Role.BACKDOOR]} component={UsersIndex} />
                                    <Route exact path="/">
										<Redirect to="/dashboard" />
									</Route>
                                </Switch>
                            </Content>
                        </main>
                    { this.appendScript() }
                    </>
                }
            </Router>
        );
    }
}

export default connector(App);
