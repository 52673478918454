import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Floor, PrimaryButtonStyle, SecondaryButtonStyle, SelectOption } from "../interfaces/interfaces";
import { FormImage, PrimaryButton, SecondaryButton, CloseButton, FormSelect } from '.';
import { Role } from '../util/jwt';
import { RoleGuard } from './RoleGuard';

export interface EditFloorplanProps {
    floor: Floor,
    discardFloors: Floor[],
    onSubmit: (floor: Floor) => void,
    onClose: () => void,
    onDelete: (id: number) => void,
    canDelete?: boolean
}

type EditFloorplanPropsSum = EditFloorplanProps & WithTranslation;

interface State {
    floor: Floor
}

class EditFloorplanComponent extends React.Component<EditFloorplanPropsSum, State> {

    constructor(props: EditFloorplanPropsSum) {
        super(props);

        this.state = {
            floor: { ...props.floor , map_url: ''}
        };
    }
    floorNames: SelectOption[] = [
        {name: 'L-3', value: 'L-3'},
        {name: 'L-2', value: 'L-2'},
        {name: 'L-1', value: 'L-1'},
        {name: 'L0', value: 'L0'},
        {name: 'L1', value: 'L1'},
        {name: 'L2', value: 'L2'},
        {name: 'L3', value: 'L3'},
        {name: 'L4', value: 'L4'},
        {name: 'L5', value: 'L5'},
        {name: 'L6', value: 'L6'},
        {name: 'L7', value: 'L7'},
    ]

    componentDidMount() {
        document.body.classList.add('stopScroll')

        if(!this.props.floor.entry_no){
            this.setState({floor:{ ...this.props.floor, name: 'L0'}})
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    isNew() {
        const { floor } = this.props;
        return floor.entry_no === undefined;
    }

    onSubmit() {
        const { floor } = this.state;
        this.props.onSubmit(floor);
    }
    submit() {
        const { entry_no, name, map_url, store_no, floor_order } = this.state.floor;


        if (this.isValid()) {
                this.props.onSubmit({
                    entry_no,
                    name,
                    map_url,
                    store_no,
                    floor_order
                });
        }
    }

    isValid() {
        return true
    }

    render () {
        const { t, onClose, onDelete, discardFloors } = this.props;
        const { floor } = this.state;
        return (
            <section id="popUp-edit-foor-plan" className="cover-blur new-floor active date-range-space-between">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ this.isNew() ? t('add_floorplan') : t('edit_floorplan') }</h2>
                        <CloseButton onClick={onClose} />
                    </section>
                    <section className="popup-content">
                        <fieldset className="formlines-container">
                            <div>
                                <FormSelect id="name" label={ t('name') } options={ this.floorNames.filter( floor => !discardFloors.find( discardFloor => floor.name === discardFloor.name) || floor.name === this.props.floor.name ) } value={ floor.name } onChange={(value: any) => this.setState( { floor: {...floor, name: String(value)} as Floor } )} />
                            </div>
                            <div>
                                <FormImage photo={this.props.floor.map_url} onChange={(image) => this.setState({ floor: { ...floor, map_url: image } as Floor })}/>
                            </div>
                        </fieldset>
                    </section>
                    <section className="popup-actions">
                        {!this.isNew() &&
                            <RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
                                <SecondaryButton disabled={!this.props.canDelete} onClick={() => onDelete(floor.entry_no)} style={SecondaryButtonStyle.RED}>
                                    { t('delete') }
                                </SecondaryButton>
                            </RoleGuard>
                        }
                        <PrimaryButton onClick={() => this.onSubmit()} style={PrimaryButtonStyle.FILLED}>
                            { this.isNew() ? t('create') : t('save') }
                        </PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const EditFloorplan = withTranslation()(EditFloorplanComponent);
export { EditFloorplan };
