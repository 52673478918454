import { Floor } from "../../../interfaces/interfaces";

export class FloorMeasure {
  constructor(private readonly width: number) {}

  measure(floor: Floor) {
    return new Promise<Floor>((resolve, reject) => {
      const img = new Image();
      img.src = floor.map_url;
      img.onload = () => {
        resolve({
          ...floor,
          width: this.width,
          height: (this.width * img.height) / img.width,
        });
      };
      img.onerror = () => reject();
    });
  }
}
