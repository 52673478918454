import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SpinnerProps {
    message: string
}

type SpinnerPropsSum = SpinnerProps & WithTranslation;

class SpinnerComponent extends React.Component<SpinnerPropsSum> {

    render () {
        return (
            <div className="loader-content">
                <div className="text-center">
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <p className="text-2--semibold">{this.props.message}</p>                    
                </div>
            </div>
        );
    };
}

const Spinner = withTranslation()(SpinnerComponent);
export { Spinner };
