import Cookies from 'universal-cookie';

export const COOKIE_USER_NAME = 'primark-kendu-user-name';
export const COOKIE_USER_LANGUAGE = 'primark-kendu-user-language';
export const COOKIE_USER_TOKEN = 'primark-kendu-user-token';
export const COOKIE_USER_PHOTO = 'primark-kendu-user-photo';
export const COOKIE_ON_LOGIN = 'primark-kendu-on-login';

const cookies = new Cookies();

export const get_user_name = (): string => {
    return cookies.get(COOKIE_USER_NAME);
}

export const get_user_photo = (): string => {
    return cookies.get(COOKIE_USER_PHOTO);
}

export const get_user_token = (): string => {
    return cookies.get(COOKIE_USER_TOKEN);
}

export const get_user_language = (): string => {
    return cookies.get(COOKIE_USER_LANGUAGE);
}

export const get_on_login = (): string => {
    return cookies.get(COOKIE_ON_LOGIN);
}

export default cookies;