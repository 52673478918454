import {
    UPDATE_USERS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_STORES,
    UserIndexActions,
    AppThunk,
} from './ActionTypes';
import { UserFilter } from '../../../interfaces/interfaces';
import { get_users, get_stores } from '../../../api';
import { FilterUserRequest, FilterStoreRequest } from '../../../api/interfaces';

export const getUsers = (): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as UserIndexActions);

        const users = await get_users({
            ...getState().userIndex.filter
        } as FilterUserRequest);
        dispatch({
            type: UPDATE_USERS,
            payload: users
        } as UserIndexActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as UserIndexActions);
    }
}

export const getStores = (): AppThunk => {
    return async (dispatch) => {
        const stores = await get_stores({page_size: 0} as FilterStoreRequest);
        dispatch({
            type: UPDATE_STORES,
            payload: stores
        } as UserIndexActions);
    }
}

export const updateFilter = (value: UserFilter): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as UserIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as UserIndexActions);
    }
}