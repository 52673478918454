import {
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_REPEAT,
    UPDATE_PASSWORD_ERROR,
    UPDATE_SIGN_UP_ERROR,
    SetingsActions,
    AppThunk
} from './ActionTypes';


export const updateUser = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_USER,
            payload: value
        } as SetingsActions);
    }
}

export const updateUserError = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_USER_ERROR,
            payload: value
        } as SetingsActions);
    }
}

export const updatePassword = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PASSWORD,
            payload: value
        } as SetingsActions);
    }
}

export const updatePasswordError = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PASSWORD_ERROR,
            payload: value
        } as SetingsActions);
    }
}

export const updatePasswordRepeat = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
                type: UPDATE_PASSWORD_REPEAT,
                payload: value
            } as SetingsActions);
    }
}

export const updateSignUpError = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SIGN_UP_ERROR,
            payload: value
        } as SetingsActions);
    }
}