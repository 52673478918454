import React from 'react';
import Select from '@material-ui/core/Select';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SelectOption } from '../interfaces/interfaces';
import { FormControl, MenuItem, FormHelperText } from '@material-ui/core';

export interface InputSelectProps {
    id: string,
    label?: string,
    value?: string,
    disabled?: boolean,
    hasError?: boolean,
    options?: SelectOption[];
    helpText?: string;
    emptyValue?: boolean,
    onChange?: (value?: string, label?: string) => void
}

type InputSelectPropsSum = InputSelectProps & WithTranslation;

class InputSelectComponent extends React.Component<InputSelectPropsSum> {

    render () {
        const { id, label, value, disabled, options, emptyValue, helpText, onChange } = this.props;
        const optionsList = emptyValue ? [{ value: '', name: 'Vacío' }, ...options || []] : [...options || []];
        return (
            <FormControl variant="outlined" style={{ margin: 1, minWidth: 120 }} fullWidth error={!!helpText}>
                <Select
                    id={ id }
                    label={ label }
                    value={ value || '' }
                    onChange={(e) => onChange?.(e.target.value as string, optionsList?.find(option => option.value === e.target.value)?.name)}
                    variant="outlined"
                    disabled={ disabled }
                >
                    {(optionsList||[]).map(
                        option =>
                            <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                    )}
                </Select>
                <FormHelperText>{helpText}</FormHelperText>
            </FormControl>
        );
    };
}

const InputSelect = withTranslation()(InputSelectComponent);
export { InputSelect };
