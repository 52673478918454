import {

    UPDATE_LOADING,
    UPDATE_ACTIVITIES,
    UPDATE_ACTIVITY_TYPES,
    UPDATE_FILTER,
    ActivityIndexActions,
    CLEAR_FILTER,
    UPDATE_PAGE,
    UPDATE_TOTAL_PAGES,
} from "./ActionTypes";
import { Reducer } from 'redux';
import {  ActivityFilter,Activity, ActivityTypes } from "../../../interfaces/interfaces";

export interface ActityIndexState {
    activities: Activity[],
    activity_types:ActivityTypes[],
    loading: boolean,
    filter: ActivityFilter,
    page: number;
    total_pages:number;
    
}

const INITIAL_STATE: ActityIndexState = {
    activity_types:[],
    activities: [],
    loading: false,
    filter: new ActivityFilter(),
    page:1,
    total_pages:1,
   
};

const reducer: Reducer<ActityIndexState, ActivityIndexActions> = (state = INITIAL_STATE, action: ActivityIndexActions) => {
    switch(action.type) {
   
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_ACTIVITIES:
            return { ...state, activities: action.payload };
        case UPDATE_ACTIVITY_TYPES:
            return { ...state, activity_types: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new ActivityFilter() };
        case UPDATE_PAGE:
                return { ...state,page:action.payload };
        case UPDATE_TOTAL_PAGES:
            return { ...state, total_pages: action.payload };
        default:
            return state;
    }
}

export default reducer;