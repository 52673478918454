import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Filter as FilterIcon } from 'react-feather';
import { pluralize } from '../util/text';
import { SecondaryButton } from '.';
import { SecondaryButtonStyle, Filter as FilterInterface } from "../interfaces/interfaces";

export interface FilterRowProps {
    cssClass?: string,
    filter: FilterInterface,
    results?: number,
    totalResults?: number,
    pluralizeStrings: string[],
    onFilterClicked: () => void,
}

type FilterRowPropsSum = FilterRowProps & WithTranslation;

class FilterRowComponent extends React.Component<FilterRowPropsSum> {

    is_filtered(filter: any) : boolean {
        return Object.values(filter).some(item => item && item !== null && item !== '');
    }

    render () {
        const { t, filter, results, totalResults, pluralizeStrings, cssClass, onFilterClicked } = this.props;

        var numberComponent = undefined
        if (results && results === totalResults) {
            numberComponent = <p>{ results + " / " + pluralize(totalResults, pluralizeStrings)}</p>
        } else if (results && totalResults && results !== totalResults) {
            numberComponent =  <p>{ results + " / " + pluralize(totalResults, pluralizeStrings)}</p>
        }

        return (
            <article className={cssClass}>
                <div className="resultDetails">
                    {numberComponent}
                </div>
                <div className="filters">
                    <SecondaryButton onClick={() => onFilterClicked()} cssClass={'flex col-center gap-4'} style={this.is_filtered(filter) ? SecondaryButtonStyle.YELLOW : SecondaryButtonStyle.PRIMARY}>
                        <FilterIcon />
                        <span>{t('filter')}</span>
                    </SecondaryButton>
                </div>
            </article>
        );
    }

}

const FilterRow = withTranslation()(FilterRowComponent);
export { FilterRow };
