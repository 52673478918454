import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { RouteComponentProps } from "react-router-dom";
import { Spinner, ContentTop, BackArrow, PrimaryButton, SectionContainer, VisualCard, SupportsWithVisual, ConfirmationPopUp } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getCampaign, getSupportsWithVisual } from './GalleryDetailActions';
import { Visual, ViewCampaignStyle, CampaignStatusPrimark } from '../../../interfaces/interfaces';
import { VisualPreview } from '../../../components/VisualPreview';
import { start_end_date } from '../../../util/campaign';
import { DropzoneWrapper } from '../../../components/DropzoneWrapper';
import { get_user_token } from '../../../util/cookies';
import { delete_visual, download_csv,update_visual_priority } from '../../../api';
import {  sortVisualArrayByPriority } from '../../../util/visual';

const mapStateToProps = (state: RootState) => {
	return {
		gallery_detail_state: state.galleryDetail,
	}
}

const connector = connect(mapStateToProps, {
	getCampaign,
	getSupportsWithVisual,
	
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	previewVisual: Visual | null,
	previewVisualIndex: number | null,
	previewVisuals: Visual[] | [],
	supportsWithImage: boolean,
	viewStyle: ViewCampaignStyle,
	uploading: boolean,
	showConfirmation: boolean,
	visualToDelete: Visual | null,
	showLoading: boolean,
}

class Detail extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			previewVisual: null,
			previewVisualIndex: null,
			previewVisuals: [],
			supportsWithImage: false,
			viewStyle: ViewCampaignStyle.LIST,
			uploading: false,
			showConfirmation: false,
			visualToDelete: null,
			showLoading: false,
		};
	}

	componentDidMount() {
		this.updateData()
	}

	updateData() {
		this.props.getCampaign(this.props.match.params.id);
	}

	renderVisualPreview() {
		const { previewVisualIndex, previewVisuals } = this.state;
		const { campaign } = this.props.gallery_detail_state;

		if (previewVisualIndex !== null) {
			if (campaign?.status_primark !== CampaignStatusPrimark.LIVE && campaign?.status_primark !== CampaignStatusPrimark.SALESPOINT_VALIDATING) {
				return (
					<VisualPreview
						previewVisualIndex={previewVisualIndex}
						previewVisuals={previewVisuals}
						onClose={() => this.setState({ previewVisualIndex: null, previewVisuals: [] })}
						onDelete={(v) => {
							this.setState({ showConfirmation: true, visualToDelete: v })
						}
					}
					/>
				);
			} else {
				return (
					<VisualPreview
						previewVisualIndex={previewVisualIndex}
						previewVisuals={previewVisuals}
						onClose={() => this.setState({ previewVisualIndex: null, previewVisuals: [] })}
					/>
				);
			}
		}
	}

	renderVisualSupportsWithImage() {
		const { supportsWithImage } = this.state;
		if (supportsWithImage) {
			return (
				<SupportsWithVisual
					onClose={() => this.setState({ supportsWithImage: false })} 
					supports={this.props.gallery_detail_state.supports}
					campaign={this.props.gallery_detail_state.campaign}
				/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;
		const { campaign } = this.props.gallery_detail_state;
		return (
			<ContentTop>
				<article className="detail-top">
					<div className="detail-top--title">
						<BackArrow onClick={() => this.props.history.goBack()} />
						<div className="title-detail">
							<h2>{ campaign?.title }<span className="state-info-box">{ campaign != null ? t(campaign.status_primark.toLowerCase()) : "" }</span></h2>
							<p>{ start_end_date(campaign) }</p>
						</div>
					</div>
					<div className="detail-top--actions">
						<PrimaryButton onClick={() => null}>{ t('save formats') }</PrimaryButton>
					</div>
				</article>
			</ContentTop>
		);
	}

	renderSubtitle() {
		const { t } = this.props;

		return (
			<section className="inner-title h-opposed">
				<h2 className="v-center">{ t('images_of_departments') }</h2>
			</section>
		);
	}

	onImagesUploaded() {
		setTimeout(() => {
			this.updateData()
		}, 1000);
	}

	changePriority(section_id: string, visual_id:string, new_priority:number){
		const {campaign}=this.props.gallery_detail_state;
		update_visual_priority(campaign?.id,section_id, visual_id, new_priority).then(resp => {
			this.updateData()
		})

    }

	renderStoreSections() {
		const { t } = this.props;
		const { campaign } = this.props.gallery_detail_state;
		const { viewStyle, showLoading } = this.state;

		if (!showLoading) {
			return (
				(campaign?.sections || []).map(section =>
					<SectionContainer key={ section.description }>
						<section className="work-header">
							<h4>{ section.description }</h4>
						</section>
						<section className={`section-${ viewStyle }`}>
							{sortVisualArrayByPriority(section.visuals || []).map((visual, index, sectionVisuals) =>
								<VisualCard
									key={visual.name}
									visual={visual}
									style={ viewStyle }
									campaign_status={campaign?.status_primark}
									onVisualClick={() => this.setState({ previewVisualIndex: index, previewVisuals: sectionVisuals })}
									onInfoClick={() => {
										this.setState({ supportsWithImage: true }); 
										this.props.getSupportsWithVisual(visual.id);
									}} 
									priority={ visual.priority || index+1}
									priority_no={!visual.priority}
									onSupportClicked={(visual_id) => {
										this.setState({showLoading: true})
										download_csv(campaign!.id, visual_id).then(data => {
											
											const file = new File([data], 'support_data.csv', { type: 'text/csv'})
										
	
											const link = document.createElement('a')
											const url = URL.createObjectURL(file)
										  
											link.href = url
											link.download = file.name
											document.body.appendChild(link)
											link.click()
										  
											document.body.removeChild(link)
											window.URL.revokeObjectURL(url)
											this.setState({showLoading: false})
										})
									}}
									changePriority= { campaign?.status_primark===CampaignStatusPrimark.END_PASSED || (campaign?.title==='KENDU INTERNO' && campaign?.status_primark===CampaignStatusPrimark.DRAFT)?true:false }	
									max_priority={ section.max_support_number }
									onChangePriorityClick={(new_priority) => this.changePriority(section.id, visual.id, new_priority) }
								/>
							)}
							<DropzoneWrapper
								key={ section.description }
								section={ section.description}
								url={`${process.env.REACT_APP_API_URL}/campaigns/upload_images/${campaign?.id}/${section.id}`}
								token={get_user_token()}
								onUploadStarted={() => this.setState({ uploading: true })}
								onUploadCompleted={() => {
									this.onImagesUploaded()
									this.setState({ uploading: false })
								}}
							/>
						</section>
					</SectionContainer>
				)
			);
		} else {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		
	}

	renderDeleteConfirmationPopup() {
		const { t } = this.props
		const { showConfirmation } = this.state
		if (showConfirmation)
		return (
			<ConfirmationPopUp
				onClose={() => this.setState({ showConfirmation: false, visualToDelete: null }) }
				onSubmit={() => {
					const { visualToDelete } = this.state
					if (visualToDelete !== null) {
						this.props.getCampaign(this.props.match.params.id);
						delete_visual(visualToDelete.id, visualToDelete.campaign_id).then(resp => {
							this.setState({ previewVisual: null, showConfirmation: false, visualToDelete: null, supportsWithImage: false, previewVisualIndex: null })
						})
					}
					
				}}
				title={t('delete_visual')}
				change_text={t('delete_visual_change_text')}
			/>
		);
	}

	render () {
		const { t } = this.props;
		const { loading } = this.props.gallery_detail_state;

		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		return (
			<>
				{this.renderVisualPreview()}
				{this.renderContentTop()}
				{this.renderSubtitle()}
				{this.renderStoreSections()}
				{this.renderVisualSupportsWithImage()}
				{this.renderDeleteConfirmationPopup()}
			</>
		);
	}
}

export default withTranslation()(connector(Detail));
