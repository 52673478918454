import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import { BackArrow, ContentTop, ImagePreview, PrimaryButton, Spinner} from '../../../components';
import { Campaign, CampaignStatusPrimark, PrimaryButtonSize, PrimaryButtonStyle, Visual, VisualPreviewSizes } from '../../../interfaces/interfaces';
import { getData } from './ValidateCropsActions';
import { start_end_date } from '../../../util/campaign';
import { CroppedVisualCard } from '../../../components/CroppedVisualCard';
import { VisualFocusArea } from '../../../components/VisualFocusArea';
import { generateRatiosByVisual, update_status } from '../../../api';
import { StatusGuard } from '../../../components/StatusGuard';
import { RoleGuard } from '../../../components/RoleGuard';
import { Role } from '../../../util/jwt';
import { ConfirmationPopUp } from '../../../components/ConfirmationPopUp';
import { getDepartments } from '../../../reducers/datatypes/DatatypesActions';


const mapStateToProps = (state: RootState) => {
	return {
		store_validate_crops_state: state.StoreValidateCrops,
		data_types_state: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	getData,
	getDepartments,
});

interface ParamTypes {
	campaign_id: string,
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	previewImage: Visual | null;
	previewImagePOI: Visual | null;
	loadingVisuals : Visual[];
	updatedVisuals : Visual[];
	showConfirmationPopUp: boolean;
	loadingFromPop: boolean;
}

class ValidateCrops extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			previewImage: null,
			previewImagePOI: null,
			loadingVisuals: [],
			updatedVisuals: [],
			showConfirmationPopUp: false,
			loadingFromPop: false
		};
	}

	componentDidMount() {
		this.props.getData(this.props.match.params.campaign_id);
		this.props.getDepartments();
	}

	renderViewImageModal() {
		const { previewImage, updatedVisuals } = this.state;

		const updated = updatedVisuals.some( updatedVisual => updatedVisual === previewImage)

		if (previewImage) {
			return (
				<ImagePreview 
				visual={ updated ? {...previewImage!, image: `${previewImage?.image}?${Date.now()}`} : previewImage } 
				onClose={() => this.setState({ previewImage: null }) }
				/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;
		const { campaign } = this.props.store_validate_crops_state;
		return (
			<ContentTop>
				<article className="detail-top">
					<div className="detail-top--title">
						<BackArrow onClick={() => this.props.history.push(`/crops_validation`)} />
						<div className="title-detail">
							<h2>{ campaign?.title } <span className="state-info-box">{ campaign?.status_primark }</span></h2>
							<p>{ start_end_date(campaign) }</p>
						</div>
					</div>
					<div className="detail-top--actions">
						<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.RATIO_CROP_VALIDATING]}>
							<RoleGuard roles={[Role.MARKETING, Role.ADMIN, Role.BACKDOOR]}>
							<PrimaryButton onClick={() => this.setState({ showConfirmationPopUp : true})} >{ t('confirm_all_crops') }</PrimaryButton>
							</RoleGuard>
						</StatusGuard>
					</div>
				</article>
			</ContentTop>
		);
	}


	renderStatusChangeConfirmationPop(campaign: Campaign | null){
		const { t } = this.props;
		const { showConfirmationPopUp } = this.state;

		var change_text = t("campaing_passing_to_the_next_state");
		if(campaign){
			const next_status_index = (Object.values(CampaignStatusPrimark)).indexOf(campaign.status_primark) + 1;

			let fromStatus = t(campaign.status_primark.toString().toLowerCase());
			let newStatus = t(Object.values(CampaignStatusPrimark)[next_status_index].toString().toLowerCase());

			if(next_status_index in Object.values(CampaignStatusPrimark))
			{
				change_text = t("Campain_passing_from_state_{{statusFrom}}_to_state_{{statusTo}}", { statusFrom: (fromStatus as string), statusTo: newStatus } );

				
			}

			if(showConfirmationPopUp){
				return(
					<ConfirmationPopUp 
						onClose={() => this.setState({ showConfirmationPopUp: false })}
						onSubmit={() => {
							this.setState({ loadingFromPop: true })
							this.changeStatus(campaign)
						}}
						title={t("confirm_campaign_status_change")}
						change_text={change_text}
					/>
				);
			}
		}
	}

	changeStatus(campaign: Campaign) {
		if(campaign){
			update_status(campaign).then(() => {
				this.setState({ showConfirmationPopUp: false });
				this.setState({ loadingFromPop: false });
				this.props.getData(this.props.match.params.campaign_id);
			}).catch(err => console.error);
		}
    }

	renderPOI(){
		const { previewImagePOI, loadingVisuals, updatedVisuals } = this.state;
		const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

		if (previewImagePOI){
			return(
				<VisualFocusArea
					previewVisual={previewImagePOI}
					onClose={() => this.setState({ previewImagePOI: null })} 
					generateRatios={() => {
						this.setState({ loadingVisuals: [...loadingVisuals, previewImagePOI], previewImagePOI: null})
						generateRatiosByVisual(previewImagePOI).then(async () => {
							await delay(3000);
							console.log("generate ratios finished good?")
							const updatedLoadingVisuals = loadingVisuals.splice( loadingVisuals.findIndex(loadingVisual => loadingVisual === previewImagePOI) ,1)
							this.setState({ loadingVisuals: updatedLoadingVisuals, updatedVisuals : [...updatedVisuals, previewImagePOI] })
						}).catch(err => console.error);
					}}				
					/>
				)
		}
	}

	renderBody() {
		const { t } = this.props;
        const { campaign } = this.props.store_validate_crops_state;
		const { loadingVisuals, updatedVisuals, loadingFromPop } = this.state     
		const {departments} = this.props.data_types_state  

		if (loadingFromPop) {
			return (
				<Spinner message={ t('loading') } />
			);
		}

		return (
            campaign?.sections?.map(section =>{
				return section.visuals.map(visual => {

					const department = departments?.find(department => department.value === visual.section)

					return (
						<section id="content" key={visual.id}>
							<section className="crops-validation-content">
								<section className="work-section">
									<section className="crops-validation-header">
										<h4>{visual.name}</h4>
										<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.RATIO_CROP_VALIDATING]}>
											<RoleGuard roles={[Role.MARKETING, Role.ADMIN, Role.BACKDOOR]}>
											<PrimaryButton style={PrimaryButtonStyle.OUTLINED} size={PrimaryButtonSize.SMALL} onClick={() => this.setState({ previewImagePOI: visual })} >{ t('Edit_POI')}</PrimaryButton>
											</RoleGuard>
										</StatusGuard>
										
									</section>

									<h2 className="crops-validation-header">{ department? department.name : visual.section}</h2>
				
									<section className="crops-validation-grid">
										
										<CroppedVisualCard 
											visual={visual} 
											croppedSize={VisualPreviewSizes.O}	
											onClick={(url) => this.setState({ previewImage: { image: url } as unknown as Visual })}
										/>

										{loadingVisuals.find(loadingVisual => loadingVisual === visual) 
											? <article className="card-cropped-image">
												<div className="card-image">
													<Spinner message={ t('generating_ratios')}> </Spinner>  
												</div>  
												<div className="card-image-detail">
													
												</div>
											</article>
										
										: <>
											<CroppedVisualCard 
												visual={visual} 
												croppedSize={VisualPreviewSizes.S}
												onClick={(url) => this.setState({ previewImage: {image: url} as unknown as Visual })}		
												updated = { updatedVisuals.some( updatedVisual => updatedVisual === visual)}								
											/>
											<CroppedVisualCard 
												visual={visual} 
												croppedSize={VisualPreviewSizes.M}	
												onClick={(url) => this.setState({ previewImage: {image: url} as unknown as Visual })}		
												updated = { updatedVisuals.some( updatedVisual => updatedVisual === visual)}								
											/>
											<CroppedVisualCard 
												visual={visual} 
												croppedSize={VisualPreviewSizes.L}	
												onClick={(url) => this.setState({ previewImage: {image: url} as unknown as Visual })}		
												updated = { updatedVisuals.some( updatedVisual => updatedVisual === visual)}								
											/>

											<CroppedVisualCard 
												visual={visual} 
												croppedSize={VisualPreviewSizes.L2}	
												onClick={(url) => this.setState({ previewImage: {image: url} as unknown as Visual })}		
												updated = { updatedVisuals.some( updatedVisual => updatedVisual === visual)}								
											/>

											<CroppedVisualCard 
												visual={visual} 
												croppedSize={VisualPreviewSizes.XL}	
												onClick={(url) => this.setState({ previewImage: {image: url} as unknown as Visual })}		
												updated = { updatedVisuals.some( updatedVisual => updatedVisual === visual)}									
											/>
											</>
										}
									</section>
								</section>
							</section>  
						</section> 
					)

				})
			})
                   
              
		);
	}

	render () {
		const { t } = this.props;
		const { loading, campaign } = this.props.store_validate_crops_state;

		if (loading || !campaign) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		return (
			<>
                {this.renderViewImageModal()}
				{this.renderPOI()}
				{this.renderContentTop()}   
                {this.renderBody()} 
				{this.renderStatusChangeConfirmationPop(campaign)}
			</>
		);
	}
}

export default withTranslation()(connector(ValidateCrops));
