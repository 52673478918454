import React from 'react';
import { Spring, animated } from 'react-spring';
import * as d3 from 'd3';
import { Support } from '../interfaces/interfaces';
import { remove_decimals } from '../util/number';
import { getCenter, getIndicatorColor, labelContainerProps, labelTextProps, scaleOrigin, supportIndicatorProps } from '../util/floorplan';

interface SvgSupportProps {
    support: Support,
    draggable: boolean,
    onSupportClick?: (support: Support, center: {x: number, y: number}) => void,
    onSupportMove?: (support: Support, x: number, y: number) => void,
    disabled?: boolean
    selected?: boolean
    zoom?: number
}

const LABEL_DEFAULT_WIDTH = 30;
const LABEL_PADDING = 6;
const DEFAULT_CHAR_WIDTH = 6;

class SvgSupport extends React.Component<SvgSupportProps, { x: number, y: number, translateX: number, translateY: number, width: number, dragging: boolean }> {
    ref!: SVGGElement;

    constructor(props: SvgSupportProps) {
        super(props);

        this.state = {
            x: props.support.map_x || 0,
            y: props.support.map_y || 0,
            translateX: 0,
            translateY: 0,
            width: LABEL_DEFAULT_WIDTH,
            dragging: false
        };
    }

    measureText(text: string) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (context) {
            context.font = '10px'; 

            return context?.measureText(text).width;
        }

        return text.length * DEFAULT_CHAR_WIDTH;
    }

    componentDidMount() {
        if (this.props.draggable) {
            this.enableDrag();
        }
        
        this.setState({
            width: this.measureText(this.props.support.no_soporte_cliente) + LABEL_PADDING * 2
        });
    }

    componentDidUpdate(prevProps: SvgSupportProps) {
        if (prevProps.draggable !== this.props.draggable) {
            if (this.props.draggable) {
                this.enableDrag();
            } else {
                this.disableDrag();
            }
        }

        if (prevProps.zoom !== this.props.zoom) {
            const { x, y, width } = this.state;
            const scaleFactor = 1 / (this.props.zoom || 1);
            const { translateX, translateY } = scaleOrigin(this.props.support, x, y, width, scaleFactor); 

            this.setState({ translateX, translateY });
        }
    }

    enableDrag() {
        d3.drag<SVGGElement, unknown, unknown>()
        .on('start', () => this.setState({ dragging: true}))
        .on('end', () => this.setState({ dragging: false }))
        .on('drag', (event) => {
            const x = this.state.x + event.dx;
            const y = this.state.y + event.dy;

            this.setState({ x, y })
            this.props.onSupportMove?.(this.props.support, remove_decimals(x), remove_decimals(y));
        })(d3.select(this.ref)); 
    }

    disableDrag() {
        d3.select(this.ref).on('.drag', null); 
    }

    getIndicatorCursor() {
        if (this.props.draggable) {
            if (this.state.dragging) {
                return 'grabbing';
            }
    
            return 'grab';
        }
    
        return 'pointer';
    }

    onClick(event: React.MouseEvent) {
        event.stopPropagation();
        
        this.props.onSupportClick?.(this.props.support, getCenter(this.props.support, this.state.x, this.state.y, this.state.width));
    }


    render() {
        const { x, y, width, translateX, translateY } = this.state;
        const { support, disabled, selected, zoom } = this.props;
        const scaleFactor = 1 / (zoom || 1);

        return (
            <Spring to={{ opacity: disabled ? .75 : 1, fill: selected ? "#001114" : getIndicatorColor(support)}}>
                { styles => (
                    <animated.g ref={(ref: SVGGElement) => this.ref = ref} transform={`translate(${x + translateX}, ${y + translateY}) scale(${scaleFactor})`} style={{ opacity: styles.opacity, cursor: this.getIndicatorCursor() }} onClick={(e) => this.onClick(e)}>
                        <animated.rect rx="7" fill={ styles.fill } {...labelContainerProps(support,6,width)} width={width} />
                        <text fill="#2C2C2C" dominantBaseline="central" textAnchor="middle" fontSize={10} fontWeight={700} {...labelTextProps(support,6,width)}>{support.no_soporte_cliente}</text>
                        <rect rx="4" fill={getIndicatorColor(support)} stroke="white" strokeWidth={2} {...supportIndicatorProps(support,6,width)} />
                    </animated.g>
                )}
            </Spring>
        );
    }
}

export { SvgSupport };