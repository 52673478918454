import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from '../../../reducers';
import {approveStoreEmail} from './StoreApproveActions';
import { CheckCircle, XCircle } from 'react-feather';
import {
	Spinner,
} from '../../../components';
const mapStateToProps = (state: RootState) => {
	return {
		store_approve: state.storeApprove,
	}
}

const connector = connect(mapStateToProps, {
	approveStoreEmail
});

interface ParamTypes {
	store_id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {

}

class Approve extends React.Component<Props, State> {

	componentDidMount() {
		const store_id=this.props.match.params.store_id;
		this.props.approveStoreEmail(store_id);

	}

	renderChecks(checks:number[]){
		const { t } = this.props;
		let text_renders=[];

		if(checks.includes(1)){
			text_renders.push(t('no_tienes_los_permisos_necesarios_para_aprobar_la_tienda'))
		}
		if(checks.includes(4)){
			text_renders.push(t('kendu_is_processing_supports'))
		}
		if(checks.includes(5)){
			text_renders.push(t('soportes_sin_imagen'))
		}
		if(checks.includes(6)){
			text_renders.push(t('incompatible_estado_tienda'))
		}
		if(checks.includes(3)){
			text_renders.push(t('campana_no_activa'))
		}

		if(checks.includes(0)){
			text_renders.push(t('tienda_aprobada_correctamente'))
		}

		return(
			<ul>
				{text_renders.map((row)=>(
					<li className="text-400--regular">{row}</li>
				))}
				
			</ul>
		)
	}

	renderGlobal(checks:number[]){
		const { store } = this.props.store_approve;
		const { t } = this.props;
		if(checks.includes(0)){
			return(
				<div className="validation-header validation-header--success">
					<CheckCircle/>
					<h1 className="headline-600--regular">{t('Tienda')} {store?.customer_shop_no+' '+store?.name}</h1>
				</div>
			)
		}else{
			return(
				<div className="validation-header validation-header--error">
					<XCircle/>
					{
						store?.customer_shop_no?
							<h1 className="headline-600--regular">{t('Tienda')} {store?.customer_shop_no+' '+store?.name}</h1>:
							<h1 className="headline-600--regular">{t('Tienda no encontrada')}</h1>
					}
				</div>
			)
		}
	}
	render () {
		const { loading,checks } = this.props.store_approve;
		const { t } = this.props;
		
		if (loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}

		return (
			<>
			<div className="validation-row">
				{this.renderGlobal(checks)}
				{this.renderChecks(checks)}
			</div>
			</>
		);
	}
}

export default withTranslation()(connector(Approve));
