import {
    UPDATE_CAMPAIGNS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_TOTAL_CAMPAIGNS,
    CampaignIndexActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Campaign, Filter } from "../../../interfaces/interfaces";

export interface CampaignIndexState {
    campaigns: Campaign[],
    totalCampaigns: Campaign[],
    loading: boolean,
    showFilter: boolean,
    filter: Filter,
}

const INITIAL_STATE: CampaignIndexState = {
    campaigns: [],
    totalCampaigns: [],
    loading: false,
    showFilter: false,
    filter: new Filter(),
};

const reducer: Reducer<CampaignIndexState, CampaignIndexActions> = (state = INITIAL_STATE, action: CampaignIndexActions) => {
    switch(action.type) {
        case UPDATE_CAMPAIGNS:
            return { ...state, campaigns: action.payload };  
        case UPDATE_TOTAL_CAMPAIGNS:
            return { ...state, totalCampaigns: action.payload };  
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new Filter() }
        default:
            return state;
    }
}

export default reducer;