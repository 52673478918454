import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign, Store, Filter } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_ACTIVE_CAMPAIGN = 'update_active_campaign';
export const UPDATE_STORES = 'update_stores';
export const UPDATE_FILTER = 'update_filter';
export const CLEAR_FILTER = 'clear_filter';
export const UPDATE_STORE_STATS = 'update_store_stats';
export const UPDATE_TOTAL_STORES = 'update_total_stores';
export interface UpdateLoadingAction {
	type: typeof UPDATE_LOADING,
	payload: boolean
}

export interface UpdateActiveCampaign {
	type: typeof UPDATE_ACTIVE_CAMPAIGN,
	payload: Campaign
}

export interface UpdateStoresAction {
	type: typeof UPDATE_STORES,
	payload: Store[]
}

export interface UpdateFilterAction {
	type: typeof UPDATE_FILTER,
	payload: Filter
}

export interface ClearFilterAction {
	type: typeof CLEAR_FILTER,
	payload: null
}
export interface UpdateTotalStoresAction {
    type: typeof UPDATE_TOTAL_STORES,
    payload: Store[]
}
export interface UpdateStoreStats {
	type: typeof UPDATE_STORE_STATS,
	payload: { total_stores: number, installed_stores: number, approved_stores: number }
}

export type DashboardIndexActions = UpdateLoadingAction | UpdateActiveCampaign | UpdateStoresAction | UpdateFilterAction | ClearFilterAction | UpdateStoreStats | UpdateTotalStoresAction;

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>
