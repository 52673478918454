import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Select, { MultiValue } from 'react-select';
import { SelectOption } from '../interfaces/interfaces';

export interface InputSelectMultipleProps {
    id: string,
    selected: string[],
    options: SelectOption[],
    placeholder?: string,
    menuIsOpen?: boolean,
    create?: boolean,
    disabled?: boolean,
    helpText?: string,
    onChange: (options: SelectOption[]) => void,
}

type InputSelectMultiplePropsSum = InputSelectMultipleProps & WithTranslation;

const DropdownIndicator = () => {
    return (<></>);
};

interface State {
    searchText: string,
}

class InputSelectMultipleComponent extends React.Component<InputSelectMultiplePropsSum, State> {

    private selectMultipleRef: any;

    constructor(props: InputSelectMultiplePropsSum) {
        super(props);
        this.state = {
            searchText: '',
        };
    }

    render () {
        const { t, id, selected, options, placeholder, menuIsOpen, onChange, create, helpText, disabled } = this.props;
        
        let customStyles = {
            indicatorSeparator: () => ({
                width: 0,
            }),
            control: (provided: any, state: any) => ({
                border: !state.isFocused ? '1px solid #DEE0E3 !important' : '1px solid #0AD6FF !important',
                borderRadius: '8px !important',
                minHeight: '45px',
                maxHeight: '100px',
                overflow: 'scroll',
                padding: '0px'
            })
        }

        return (
            <>
                <Select
                    ref={this.selectMultipleRef}
                    inputId={`select-multiple-input-${id}`}
                    defaultValue={options.filter(option => selected.includes(option.value)).map(option => ({ value: option.value, label: option.name }))}
                    options={options.map(option => ({ value: option.value, label: option.name }))}
                    isMulti
                    onChange={(newValues: MultiValue<{ value: string, label: string}>) => onChange(newValues.map(value => ({ value: value.value, name: value.label }) as SelectOption))}
                    isSearchable={ true }
                    placeholder={ placeholder }
                    menuPlacement="auto"
                    className={ helpText ? 'field--error' : '' }
                    loadingMessage={() => t('loading')}
                    noOptionsMessage={() => t('nothing to show')}
                    onInputChange={(text: string) => this.setState({ searchText: text })}
                    onKeyDown={(e) => {
                        const text = this.state.searchText;
                        if (create && e.key === 'Enter' && text !== '' && !this.props.selected.includes(text)) {
                            onChange([...options, { name: text, value: text}]);
                        }
                    }}
                    isClearable={ false }
                    components={{ DropdownIndicator }}
                    menuIsOpen = { menuIsOpen }
                    styles={ customStyles }
                    isDisabled={ disabled }
                />
                {helpText && 
                <span className='text-error'>{helpText} </span>}
            </>
        );
    };

}

const InputSelectMultiple = withTranslation()(InputSelectMultipleComponent);
export { InputSelectMultiple };
