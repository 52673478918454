import { changeState, processingDetails, findVisual,get_sections,update_support_info } from '../../../api';
import { ProcessingLine } from '../../../interfaces/interfaces';
import { UPDATE_LOADING, AppThunk, UPDATE_DATA, ProcessingDetailsActions, UPDATE_VISUAL, UPDATE_SELECTED_DEPARTMENT, UPDATE_PRIORITY } from './ActionTypes';

export const getData = (campaignId: string, supportId: string): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ProcessingDetailsActions);

        const data = await processingDetails(campaignId, supportId);
        const departments = await get_sections();
        let selected_department=departments.find(d=>d.value.toString()===(data[0]?.support?.section)?.toString());
        dispatch({
            type: UPDATE_DATA,
            payload: data[0]
        } as ProcessingDetailsActions);

        dispatch({
            type: UPDATE_SELECTED_DEPARTMENT,
            payload: selected_department?.value
        } as ProcessingDetailsActions);

        dispatch({
            type: UPDATE_PRIORITY,
            payload: data[0]?.support?.priority
        } as ProcessingDetailsActions);
        

        const lineData: ProcessingLine = data[0];
        if (lineData.line.visual_id!==undefined){
            const visual = await findVisual(lineData.line.visual_id, lineData.line.campaign_id)
            if (visual) {
                dispatch({
                    type: UPDATE_VISUAL,
                    payload: visual
                } as ProcessingDetailsActions);
            }
        }

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ProcessingDetailsActions);
    }
}

export const updateDeparmentValue = (value:string|undefined): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_SELECTED_DEPARTMENT,
            payload: value
        } as ProcessingDetailsActions);
    }
}

export const updatePriority = (value:string|undefined): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_PRIORITY,
            payload: value
        } as ProcessingDetailsActions);
    }
}

export const updateSupportInfo = (): AppThunk => {
    return async (dispatch,getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ProcessingDetailsActions);
        const priority=getState().processingDetail.priority;
        const selected_department=getState().processingDetail.selected_department;
        const line_id=getState().processingDetail.data?.line.id;
        const campaign_id=getState().processingDetail.data?.line.campaign_id;
        const support_id=getState().processingDetail.data?.support.id;
        await update_support_info(line_id, priority, selected_department,campaign_id,support_id);
        const data = await processingDetails(campaign_id, support_id);
        const departments = await get_sections();
        let selected_department2=departments.find(d=>d.value.toString()===(data[0]?.support?.section)?.toString());
        dispatch({
            type: UPDATE_DATA,
            payload: data[0]
        } as ProcessingDetailsActions);

        dispatch({
            type: UPDATE_SELECTED_DEPARTMENT,
            payload: selected_department2?.value
        } as ProcessingDetailsActions);

        dispatch({
            type: UPDATE_PRIORITY,
            payload: data[0]?.support?.priority
        } as ProcessingDetailsActions);
        

        const lineData: ProcessingLine = data[0];
        if (lineData.line.visual_id!==undefined){
            const visual = await findVisual(lineData.line.visual_id, lineData.line.campaign_id)
            if (visual) {
                dispatch({
                    type: UPDATE_VISUAL,
                    payload: visual
                } as ProcessingDetailsActions);
            }
        }

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ProcessingDetailsActions);
    }
}


export const markAsSolved = (line: ProcessingLine, props:any): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ProcessingDetailsActions);
      
        await changeState(line.support.id, line.line.campaign_id, line.line.user_rol);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ProcessingDetailsActions);
        props.history.push('/kendu_processing');
        
    }
}
