import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputNumber } from '.';

export interface FormNumberProps {
    id: string,
    label?: string,
    value?: number,
    disabled?: boolean,
    helpText?: string,
    className?: string,
    unitClass?: string,
    onChange?: (text: string) => void
}

type FormNumberPropsSum = FormNumberProps & WithTranslation;

class FormNumberComponent extends React.Component<FormNumberPropsSum> {

    render () {
        const { id, label, value, disabled, helpText, className, unitClass, onChange } = this.props;
        return (
            <article className="form-item">
                <label htmlFor={ id }>{ label }</label>
                <InputNumber id={ id } className={`field field--text ${className ? className : ''}`} unitClass={ unitClass } value={value} disabled={disabled} helpText={helpText} onChange={(text) => onChange ? onChange(text) : null} />
            </article>
        );
    };
}

const FormNumber = withTranslation()(FormNumberComponent);
export { FormNumber };
