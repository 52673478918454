import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { updateUser, updateUserError, updatePassword, updatePasswordError, updateLoginError } from './LoginActions';
import { updateToken } from '../../../reducers/AuthActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { login, get_profile } from '../../../api';
import cookies, { COOKIE_USER_LANGUAGE, COOKIE_USER_NAME, COOKIE_USER_PHOTO, COOKIE_USER_TOKEN, COOKIE_ON_LOGIN} from '../../../util/cookies';
import i18n from 'i18next';
import Alert from '@material-ui/lab/Alert';
import { is_empty_string, is_email } from "../../../util/form";

const mapStateToProps = (state: RootState) => {
	return {
		security_login_state: state.securityLogin
	}
}

const connector = connect(mapStateToProps, {
	updateUser,
	updateUserError,
	updatePassword,
	updatePasswordError,
	login,
	updateToken,
	get_profile,
	updateLoginError
});

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Settings extends React.Component<Props> {

	componentDidMount() {
		this.props.updateUserError('');
		this.props.updatePasswordError('');
		this.props.updateLoginError('');
	}

	login() {
		const { user, password } = this.props.security_login_state;
		const { t } = this.props;
		if (this.validate()) {
			login(user, password).then((token) => {
				this.props.updateLoginError('');
				if (token !== null) {
					this.props.updateLoginError('');
					cookies.set(COOKIE_USER_TOKEN, token, { path: '/' });
					this.props.updateToken(token);
					get_profile().then((user) => {
						if (user && user.language) {
							cookies.set(COOKIE_USER_NAME, user.name + ' ' + user.last_name, { path: '/' });
							const language = user.language.toLowerCase();
							cookies.set(COOKIE_USER_LANGUAGE, language, { path: '/' });
							cookies.set(COOKIE_USER_PHOTO, user.photo, { path: '/' });
							i18n.changeLanguage(language.toLowerCase(), (err, t) => {
								if (err) return console.log('something went wrong loading', err);
							});
						}

						if(cookies.get(COOKIE_ON_LOGIN)){
							window.location.href = cookies.get(COOKIE_ON_LOGIN);
							cookies.remove(COOKIE_ON_LOGIN);
						}
					});
				} else {
					this.props.updateLoginError(t('invalid_username_or_password'));
				}
			});
		}else{
			this.props.updateLoginError(t('invalid_username_or_password'));
		}
	}

	validate() {
		const { t } = this.props;
		const { user, password } = this.props.security_login_state;
		let isValid = true;

		this.props.updateUserError('');
		this.props.updatePasswordError('');

		if (!is_email(user)) {
			isValid = false;
			this.props.updateUserError(t('user_should_have_email_format'));
		}
		if (is_empty_string(user)) {
			isValid = false;
			this.props.updateUserError(t('user_is_required'));
		}
		if (is_empty_string(password)) {
			isValid = false;
			this.props.updatePasswordError(t('password_is_required'));
		}
		return isValid;
	}

	renderLoginError() {
		if (this.props.security_login_state.loginError) {
			return (
				<article className="form-row">
					<Alert severity="error">{this.props.security_login_state.loginError}</Alert>
				</article>
			);
		}
	}

	renderPasswordInput() {
		if (this.props.security_login_state.passwordError) {
			return (
				<>
					<input id="password" className="field field--text field--error" type="password" value={this.props.security_login_state.password} onChange={(event) => this.props.updatePassword(event.target.value)}/>
					<p className="text-error">{this.props.security_login_state.passwordError}</p>
				</>
			);
		}else{
			return(
				<input id="password" className="field field--text" type="password" value={this.props.security_login_state.password} onChange={(event) => this.props.updatePassword(event.target.value)}/>
			);
		}
	}

	renderUserInput() {

		if (this.props.security_login_state.userError) {
			return (
				<>
					<input id="mail" className="field field--text field--error" type="text" value={this.props.security_login_state.user} onChange={(event) => this.props.updateUser(event.target.value)} />
					<p className="text-error">{this.props.security_login_state.userError}</p>
				</>
			);
		}else{
			return (
				<input id="mail" className="field field--text" type="text" value={this.props.security_login_state.user} onChange={(event) => this.props.updateUser(event.target.value)} />
			);
		}
	}

	render () {
		const { t } = this.props;
		return (
			<>
				<section id="login-box">
					<Link to="/">
						<h1 className="text-center">
							<img src="../../img/corporative/primark.svg" alt="PRIMARK" />
						</h1>
					</Link>
					<article id="content">
						<h2 className="mb-24">{t('log_in')}</h2>
						{this.renderLoginError()}
						<form>
							<div className="form-row">
								<article className="form-item">
									<label htmlFor="mail">{t('email')}</label>
									{this.renderUserInput()}
								</article>
							</div>
							<div className="form-row">
								<article className="form-item">
									<label htmlFor="password">{t('password')}</label>
										{this.renderPasswordInput()}
								</article>
							</div>
							<article className="login-actions">
								<button type="button" onClick={() => this.login()} className="btn-base fill-primary btn-xl w-100 mb-24">{t('log_in')}</button>
								<p>
									<Link to="/password-request">
										{t('request_password')}	
									</Link>
								</p>
							</article>
						</form>
					</article>
				</section>
			</>	
		);
	}
}

export default withTranslation()(connector(Settings));
