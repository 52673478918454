import {
    UPDATE_TOKEN,
    GET_USER,
    UPDATE_USER,
    AuthActions
} from "./AuthTypes";
import { Reducer } from 'redux';
import { User } from "../interfaces/interfaces";

export interface AuthState {
    token?: string|null,
    openEditpopUp: boolean,
    user: User|null
}

const INITIAL_STATE: AuthState = {
    token: null,
    openEditpopUp: false,
    user: null
};

const reducer: Reducer<AuthState, AuthActions> = (state = INITIAL_STATE, action: AuthActions) => {
    switch(action.type) {
        case UPDATE_TOKEN:
            return { ...state, token: action.payload };
        case GET_USER:
            return { ...state, user: action.payload };
        case UPDATE_USER:
            return { ...state, user: action.payload };
        default:
            return state;
    }
}

export default reducer;