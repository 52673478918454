import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { ProcessingLine,Filter,SelectOption } from '../../../interfaces/interfaces';
export const UPDATE_LOADING = 'update_loading';
export const UPDATE_ACTIVE_CAMPAIGN = 'update_active_campaign';
export const UPDATE_LINES = 'update_lines';
export const CLEAN_LINES = 'clean_lines';
export const UPDATE_TOTAL_LINES = 'update_total_lines';
export const UPDATE_FILTER = 'update_filter_lines';
export const CLEAR_FILTER = 'clear_filter_lines';
export const UPDATE_STORES = 'update_stores';


export interface UpdateLoadingAction {
	type: typeof UPDATE_LOADING,
	payload: boolean
}

export interface UpdateLinesAction {
	type: typeof UPDATE_LINES,
	payload: ProcessingLine[]
}

export interface CleanLinesAction {
	type: typeof CLEAN_LINES
}



export interface UpdateTotalLinesAction {
    type: typeof UPDATE_TOTAL_LINES,
    payload: ProcessingLine[]
}
export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: Filter
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: SelectOption[]
}


export type ProcessingIndexActions = UpdateLoadingAction | UpdateLinesAction | CleanLinesAction | UpdateTotalLinesAction | UpdateFilterAction | ClearFilterAction | UpdateStoresAction;

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>
