import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { ProcessingLine, SelectOption, Visual} from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_DATA = 'update_data';
export const UPDATE_VISUAL = 'update_visual';
export const UPDATE_STORE_DEPARTMENTS='update_store_departments';
export const UPDATE_PRIORITY='update_priority';
export const UPDATE_SELECTED_DEPARTMENT='update_selected_department';


export interface UpdateLoadingAction {
	type: typeof UPDATE_LOADING,
	payload: boolean
}

export interface UpdateData {
	type: typeof UPDATE_DATA,
	payload: ProcessingLine
}

export interface UpdateVisual {
	type: typeof UPDATE_VISUAL,
	payload: Visual
}
export interface UpdateStoreDepartments{
	type: typeof UPDATE_STORE_DEPARTMENTS,
	payload:SelectOption[]
}

export interface UpdateSelectedDepartment{
	type: typeof UPDATE_SELECTED_DEPARTMENT,
	payload:string
}

export interface UpdatePriority{
	type:typeof UPDATE_PRIORITY,
	payload:string
}

export type ProcessingDetailsActions = UpdateLoadingAction | UpdateData | UpdateVisual|UpdateStoreDepartments|UpdatePriority|UpdateSelectedDepartment;

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>
