import { Reducer } from 'redux';
import { Country, SelectOption, User } from '../../interfaces/interfaces';
import { DatatypesActions,
    UPDATE_COUNTRIES,
    UPDATE_SUPPORT_TYPES,
    UPDATE_TEXTIL_TYPES,
    UPDATE_SUPPORT_STATUSES,
    UPDATE_MAP_DISPOSITION_TYPES,
    UPDATE_DEPARTMENTS,
    UPDATE_PRODUCTION_LANGUAGES,
    UPDATE_LANGUAGES,
    UPDATE_COUNTRY_MANAGERS
} from './DatatypesTypes';

export interface DatatypesState {
    countries: Country[];
    support_types: SelectOption[];
    languages: SelectOption[];
    textile_types: SelectOption[];
    support_statuses: SelectOption[];
    map_disposition_types: SelectOption[];
    departments: SelectOption[];
    production_languages: SelectOption[];
    country_managers: User[];
}

const INITIAL_STATE: DatatypesState = {
    countries: [],
    support_types: [],
    languages: [],
    textile_types: [],
    support_statuses: [],
    map_disposition_types: [],
    departments: [],
    production_languages: [],
    country_managers: []
};

const reducer: Reducer<DatatypesState, DatatypesActions> = (state = INITIAL_STATE, action: DatatypesActions) => {
    switch(action.type) {
        case UPDATE_COUNTRIES:
            return { ...state, countries: action.payload };
        case UPDATE_SUPPORT_TYPES:
            return { ...state, support_types: action.payload };
        case UPDATE_TEXTIL_TYPES:
            return { ...state, textile_types: action.payload };
        case UPDATE_SUPPORT_STATUSES:
            return { ...state, support_statuses: action.payload };
        case UPDATE_MAP_DISPOSITION_TYPES:
            return { ...state, map_disposition_types: action.payload };
        case UPDATE_DEPARTMENTS:
            return { ...state, departments: action.payload };
        case UPDATE_PRODUCTION_LANGUAGES:
            return { ...state, production_languages: action.payload };
        case UPDATE_LANGUAGES:
            return { ...state, languages: action.payload };
        case UPDATE_COUNTRY_MANAGERS:
            return { ...state, country_managers: action.payload as User[]};
        default:
            return state;
    }
}

export default reducer;