import {
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SECTION,
    CampaignDetailActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Campaign, Section } from "../../../interfaces/interfaces";

export interface CampaignDetailState {
    campaign: Campaign | null,
    section: Section | null,
    loading: boolean,
}

const INITIAL_STATE: CampaignDetailState = {
    campaign: null,
    section: null,
    loading: false,
};

const reducer: Reducer<CampaignDetailState, CampaignDetailActions> = (state = INITIAL_STATE, action: CampaignDetailActions) => {
    switch(action.type) {
        case UPDATE_CAMPAIGN:
            return { ...state, campaign: action.payload };
        case UPDATE_SECTION:
            return { ...state, section: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
}

export default reducer;