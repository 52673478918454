import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign, Filter, Store, Support, Visual } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_STORE = 'update_store';
export const SELECT_SUPPORT = 'select_support';
export const UPDATE_ACTIVE_CAMPAIGN = 'update_active_campaign';
export const UPDATE_GALLERY_VISIBLE_CAMPAIGN_VISUALS = 'update_gallery_visible_campaign_visuals';
export const UPDATE_FILTER = 'update_filter'
export const UPDATE_CAMPAIGNS = 'update_campaigns'
export const UPDATE_KENDU_PROCESSING = 'UPDATE_KENDU_PROCESSING'
export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateStoreAction {
    type: typeof UPDATE_STORE,
    payload: Store
}

export interface UpdateActiveCampaign {
    type: typeof UPDATE_ACTIVE_CAMPAIGN,
    payload: Campaign
}

export interface SelectSupportAction {
    type: typeof SELECT_SUPPORT,
    payload: Support
}

export interface updateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: Filter
}

export interface UpdateGalleryVisibleCampaignVisuals {
    type: typeof UPDATE_GALLERY_VISIBLE_CAMPAIGN_VISUALS,
    payload: Visual []
}

export interface UpdateCampaigns {
    type: typeof UPDATE_CAMPAIGNS,
    payload: Campaign []
}

export interface UpdateKenduProcessing{
    type: typeof UPDATE_KENDU_PROCESSING,
    payload: boolean
}

export type StoreValidateImagesActions =
UpdateLoadingAction |
UpdateStoreAction |
UpdateActiveCampaign |
SelectSupportAction |
UpdateGalleryVisibleCampaignVisuals |
updateFilterAction |
UpdateCampaigns |
UpdateKenduProcessing;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
