import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseButton, FormTextArea, PrimaryButton, InputCheckBox, InputCheckBoxGroup, FormImage,FormSelect } from '.';
import { SpecialRequestRequest,SelectOption, Floor } from '../interfaces/interfaces';

import { FormText } from './FormText';
export interface SpecialRequestProps {
    onClose: () => void,
	floors_options?: Floor[],
	onSubmit: (specialRequest: SpecialRequestRequest) => void,
	production_languages?: SelectOption[]
}

type SpecialRequestPropsSum = SpecialRequestProps & WithTranslation;

interface State {
    comment: string;
    image: string;
	directory: boolean;
	departments: string;
	language: string;
	floors: string;
	highlight_floor: string;
	rrss_wifi: string;
	returns_exchanges: string;
	click_collect: string;
	click_collect_floor: string|undefined;
	language_id:string;
	comment_error:boolean;
	departments_error:boolean;
	language_error:boolean;
	floors_error:boolean;
	highlight_floor_error:boolean;
	click_collect_floor_error:boolean;


}


function searchLanguageID(value:any,languages:any){
	let language_name=''
	for(let i=0; i<languages.length;i++){
		if(languages[i].value===value){
			language_name=languages[i].name;
		}
	}
	return language_name;
}
class SpecialRequestComponent extends React.Component<SpecialRequestPropsSum, State> {

    constructor(props: SpecialRequestPropsSum) {
		super(props);
		this.state = {
            comment: '',
            image: '',
			directory: false,
			departments: '',
			language_id: '',
			language:'',
			floors: '',
			highlight_floor: '',
			rrss_wifi: 'si',
			returns_exchanges: 'si',
			click_collect: 'si',
			click_collect_floor: '',
			comment_error:false,
			departments_error:false,
			language_error:false,
			floors_error:false,
			highlight_floor_error:false,
			click_collect_floor_error:false
			
		};
	}

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

	resetError(){
		this.setState({departments_error:false});
		this.setState({language_error:false});
		this.setState({floors_error:false});
		this.setState({highlight_floor_error:false});
		this.setState({comment_error:false});
		this.setState({click_collect_floor_error:false});
	
	}
	onSubmit() {
		this.resetError();
		const { comment, image, directory, departments, language, floors, highlight_floor, rrss_wifi, returns_exchanges,click_collect,click_collect_floor } = this.state;
		let error_status=false;
	
		if(directory){
			if(departments===''){
				this.setState({departments_error:true});
				error_status=true;
			}
			if(language===''){
				this.setState({language_error:true});
				error_status=true;
			}
			
			  if(floors===''){
				this.setState({floors_error:true});
				error_status=true;
			  }
			  if(highlight_floor===''){
				error_status=true;
				this.setState({highlight_floor_error:true});
			  }

			  if(click_collect_floor==='' && click_collect==='si'){
				error_status=true;
				this.setState({click_collect_floor_error:true});
			  }
		}
		if(comment===''){
			this.setState({comment_error:true});
			error_status=true;
		  }

		if(!error_status){
			this.props.onSubmit({
				comment,
				special_request_image: image,
				...directory && { directory: departments },
				...directory && { directory2: JSON.stringify({
					language,
					floors,
					highlight_floor,
					rrss_wifi,
					returns_exchanges,
					click_collect,
					click_collect_floor
					
				})}
			});
		}
	
		
	}

	renderDirectory() {
		const { t,production_languages,floors_options } = this.props;
		
		let floor_select: SelectOption[] = [];
		const { directory, departments,  floors, highlight_floor,language_id,departments_error,language_error,floors_error,highlight_floor_error,click_collect,click_collect_floor,click_collect_floor_error } = this.state;
	
		floors_options?.forEach(floor => {
			floor_select.push({value:floor.name,name:floor.name});
		});
		if (directory) {
			return (
				<section id="directory__form" className='grid cols-2 col-gap-16'>
					<div>
						<div className="form-row">
							<FormTextArea limit={250} id="departments" label={ t('Listado de departamentos')+' *' } error={departments_error} value={departments} onChange={(text: string) => this.setState({ departments: text })} />
						</div>
					</div>
					<div className='flex flex-column gap-12'>
						<div className="form-row">
							<FormSelect id="language" label={t('idioma')+' *'} error={language_error} options={production_languages} value={language_id} onChange={(value, label) => value !== undefined && this.setState({ language: searchLanguageID(value,production_languages),language_id:value })} />
						</div>
						<div className="form-row">
							<FormText id="floors" label={ t('N. de plantas')+' *' } value={floors} error={floors_error} onChange={(text) => this.setState({ floors: text })} />
						</div>
						
						<div className="form-row">
							<FormText id="highlight_floor" label={ t('Planta a resaltar')+' *' } error={highlight_floor_error} value={highlight_floor} onChange={(text) => this.setState({ highlight_floor: text })} />
						</div>
						<InputCheckBoxGroup
							name={ t('RRSS y/o WiFi') }
							options={[{name: t('Sí'), value: 'si'}, {name: t('No'), value: 'no'}]}
							default={{name: t('Sí'), value: 'si'}}
							onChange={(option) => {
								this.setState({ rrss_wifi: option.value });
							}}
						/>
						<InputCheckBoxGroup
							name={ t('Returns & Exchanges') }
							options={[{name: t('Sí'), value: 'si'}, {name: t('No'), value: 'no'}]}
							default={{name: t('Sí'), value: 'si'}}
							onChange={(option) => {
								this.setState({ returns_exchanges: option.value });
							}}
						/>
						<InputCheckBoxGroup
							name={ t('Click&Collect') }
							options={[{name: t('Sí'), value: 'si'}, {name: t('No'), value: 'no'}]}
							default={{name: t('Sí'), value: 'si'}}
							onChange={(option) => {
								this.setState({ click_collect: option.value, click_collect_floor:option.value==='no'?'' : click_collect_floor });
							}}
						/>

						{
							click_collect==='si' &&
                            <div className="form-row">
                         <div className="form-row">
							<FormSelect id="click_collect_floor" label={t('Click&Collect Floor')+' *'} error={click_collect_floor_error} options={floor_select} value={click_collect_floor}  onChange={(label)=> this.setState({click_collect_floor:label})}/>
						</div>
                            </div>
						}
					</div>
				</section>
			);
		}
	}

    render () {
        const { t } = this.props;
        const { comment, directory,comment_error } = this.state;

        return (
            <section className="cover-blur active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('Special request') } / { t('ad hoc request') }</h2>
						
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section id="special__request__form" className="popup-content">
						<div className='flex flex-column gap-12 padding-top-24'>
							<div className="form-row">
								<h2>{ t('cambio_medidas_imagen') }</h2>
							</div>
							<div className="form-row">
								<FormTextArea id="comment" label={ t('comment') +' *'} error={comment_error} value={comment} onChange={(text: string) => this.setState({ comment: text })} />
							</div>
							<div className="form-row">
								<article className="form-item">
									<label htmlFor="image">{ t('reference_image') } ({ t('optional') })</label>
								</article>
							</div>
							<FormImage cssClass='fit__field' avatar photo={this.state.image} onChange={(photo) => this.setState({ image: photo })}/>
							<div className="form-row">
								<article className="form-item">
									<label>{ t('request type') }</label>
								</article>
							</div>
							<div className="check-group-row">
								<InputCheckBox 
									name="Directory"
									id="directory"
									checked={directory}
									checkBoxChange={checked => this.setState({ directory: checked }) }
								/>
							</div>
							{this.renderDirectory()}
						</div>
                    </section>
					<section>
				</section>
				
                    <section className="popup-actions">
					
                        <PrimaryButton onClick={() => this.onSubmit()}>{ t('send request') }</PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const SpecialRequest = withTranslation()(SpecialRequestComponent);
export { SpecialRequest };
