import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Spinner, ContentTop, PrimaryButton, Filter, EditSupport, FilterRow, SupportCard } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getSupports, updateFilter, clearFilter, getStores, getFloors } from './SupportIndexActions';
import { Support, FilterFields, ViewSupportStyle, SupportStatusPrimark } from '../../../interfaces/interfaces';
import { getSupportTypes, getTextileTypes, getDepartments,getProductionLanguages } from '../../../reducers/datatypes/DatatypesActions';
import { create_support } from '../../../api';
import { RoleGuard } from '../../../components/RoleGuard';
import { Role, get_role } from '../../../util/jwt';
import { get_user_token } from '../../../util/cookies';
const mapStateToProps = (state: RootState) => {
	return {
		support_index_state: state.supportIndex,
		data_types_state: state.datatypes
	}
}
const connector = connect(mapStateToProps, {
	getSupports,
	getStores,
	updateFilter,
	clearFilter,
	getTextileTypes,
	getSupportTypes,
	getDepartments,
	getFloors,
	getProductionLanguages
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showFilter: boolean,
	showCreate: boolean,
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showFilter: false,
			showCreate: false,
		};
	}

	componentDidMount() {
		this.props.getSupports();
		this.props.getStores();
		this.props.getFloors();
		this.props.getTextileTypes();
		this.props.getSupportTypes();
		this.props.getDepartments();
		this.props.getProductionLanguages();
	}

	loadMoreSupports() {
		this.props.getSupports(this.props.support_index_state.supports.slice(-1).pop()?.key);
	}

	renderLoadMore() {
		const { t } = this.props;
		if (this.props.support_index_state.loading) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		return (
			<div className="load-more-supports">
				<button className={`text-link link-primary link-m`} onClick={() => this.loadMoreSupports()}>
					{t('load_more_supports') }
				</button>
			</div>
		)
	}

	renderFilter() {
		const { t } = this.props;
		const { showFilter } = this.state;
		const { filter, stores, floors } = this.props.support_index_state;
		const { textile_types, support_types, departments } = this.props.data_types_state;

		if (showFilter) {
			return (
				<Filter
					filterFields={FilterFields.SUPPORT}
					filter={filter}
					textile_types={textile_types}
					support_types={support_types}
					support_statuses={Object.values(SupportStatusPrimark).map(status => {
						if(status==='N_A'){
							return {name: 'Not in Campagin (N/A)', value: status}
						}
						return {name: t(status.toLowerCase()), value: status}
					})}
					stores={stores}
					floors={floors}
					sections={departments}
					onClose={() => this.setState({ showFilter: !showFilter })}
					onClear={() => {
						this.props.clearFilter();
						this.setState({ showFilter: !showFilter })
						this.props.getSupports();
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.setState({ showFilter: !showFilter })
						this.props.getSupports();
					}}
				/>
			);
		}
	}

	renderCreate() {
		const { showCreate } = this.state;
		const { textile_types, support_types,map_disposition_types, departments, production_languages } = this.props.data_types_state;
		const { stores, floors } = this.props.support_index_state;

		if (showCreate) {
			return (
				<EditSupport		
					editing={ false }			
					textile_types={textile_types}
					support_types={support_types}
					sections={departments}
					stores={stores}
					floors={floors}
					production_languages={production_languages}
					map_disposition_types={map_disposition_types}
					onDelete={(support: Support) => {}}
					onClose={() => this.setState({ showCreate: false })}
					onSubmit={(support: Support) => {
						create_support(support).then(() => {
							this.setState({ showCreate: false })
							this.props.getSupports();
						}).catch(err => console.error);
						}
					}
				/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;
		const { showFilter, showCreate } = this.state;
		const { filter } = this.props.support_index_state;
		return (
			<ContentTop>
				<article>
					<div>
						<h2>{ t('supports') }</h2>
					</div>
					<div>
						<RoleGuard not={true} roles={[Role.MARKETING, Role.COUNTRY_MANAGER, Role.STORE_MANAGER, Role.HEAD_VM]}>
							<PrimaryButton onClick={() => this.setState({ showCreate: !showCreate })}>
								{t('create_support') }
							</PrimaryButton>
						</RoleGuard>
					</div>
				</article>
				<FilterRow
					filter={filter}
					pluralizeStrings={[t('result_found'), t('results_found')]}
					onFilterClicked={() => this.setState({ showFilter: !showFilter })}
				/>
			</ContentTop>)
	}

	renderSupports() {
		const { supports } = this.props.support_index_state;
		const { departments } = this.props.data_types_state;
		const token = get_user_token();
		const role = get_role(token);
		return (
			<ul>
				{supports.map((support: Support) => (
					<SupportCard key={support.id} support={support} userRole={ role } viewStyle={ ViewSupportStyle.INDEX } departments={departments}/>
				))}
			</ul>
		)
	}

	render () {
		return (
			<>
				{this.renderFilter()}
				<RoleGuard not={true} roles={[Role.MARKETING, Role.COUNTRY_MANAGER, Role.STORE_MANAGER, Role.HEAD_VM]}>
					<>{this.renderCreate()}</>					
				</RoleGuard>
				{this.renderContentTop()}
				<section id="listPills">
					{this.renderSupports()}
					{this.renderLoadMore()}
				</section>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
