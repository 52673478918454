import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Floor, Store, Filter as FilterInterface, Campaign, User,Support } from "../../../interfaces/interfaces";

export const UPDATE_LOADING = 'store_detail:update_loading';
export const UPDATE_STORE = 'store_detail:update_store';
export const UPDATE_SELECTED_FLOOR = 'store_detail:update_selected_floor';
export const UPDATE_FILTER = 'store_detail:update_supports_filter';
export const UPDATE_ACTIVE_CAMPAIGN = 'store_detail:update_active_campaign'
export const UPDATE_STORE_USERS = 'store_detail:update_store_users'
export const CLEAN_STORE = 'store_detail:clean_sotre';
export const UPDATE_SUPPORT='store_detail:UPDATE_SUPPORT';
export const UPDATE_TOTAL_SUPPORT='store_detail:UPDATE_TOTAL_SUPPORT';
export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateActiveCampaign {
    type: typeof UPDATE_ACTIVE_CAMPAIGN,
    payload: Campaign
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORE,
    payload: Store
}

export interface UpdateSelectedFloorAction {
    type: typeof UPDATE_SELECTED_FLOOR,
    payload: Floor|null
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: FilterInterface
}

export interface UpdateStoreUsersAction {
    type: typeof UPDATE_STORE_USERS,
    payload: User[]
}
export interface CleanStore {
    type: typeof CLEAN_STORE,
}

export interface UpdateSupportAction {
    type:typeof UPDATE_SUPPORT,
    payload: Support | null
}

export interface UpdateTotalSupport {
    type: typeof UPDATE_TOTAL_SUPPORT,
    payload: Number |null
}
export type StoreDetailActions =
UpdateLoadingAction |
UpdateStoresAction |
UpdateSelectedFloorAction |
UpdateFilterAction |
UpdateActiveCampaign |
UpdateStoreUsersAction |
CleanStore |
UpdateSupportAction|
UpdateTotalSupport;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
