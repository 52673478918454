import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CloseButton } from ".";
import { ExternalLink } from "react-feather";
import { SpinnerInline } from "./SpinnerInline";
import * as path from "path";
import { useVisualPacksPopup } from "./VisualPacksPopup.hook";

type VisualPacksPopupProps = {
  storeId: string;
  onClose: () => void;
};

export function VisualPacksPopup(props: VisualPacksPopupProps) {
  const { t } = useTranslation();
  const { loading, visualPacks } = useVisualPacksPopup(props.storeId);

  useEffect(() => {
    document.body.classList.add("stopScroll");

    return () => {
      document.body.classList.remove("stopScroll");
    };
  }, []);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const renderVisualPacks = () => {
    if (loading) {
      return <SpinnerInline message={t("loading")} />;
    }

    if (visualPacks.length === 0) {
      return <p>{t("no_visual_packs")}</p>;
    }

    return visualPacks.map((visualPack) => (
      <article
        onClick={() => openInNewTab(visualPack.url)}
        key={visualPack.url}
        className={"img-inside"}
      >
        <div className="campaign-image">
          <img src={visualPack.img} alt="" />
        </div>
        <div className="campaign-info">
          <div>
            <h4>{visualPack.campaign}</h4>
            <p style={{ color: "#888", overflowWrap: "anywhere" }}>
              <ExternalLink width={12} style={{ verticalAlign: "middle" }} />{" "}
              {path.basename(visualPack.url)}
            </p>
          </div>
        </div>
      </article>
    ));
  };

  return (
    <section className="cover-blur active">
      <section className="popup">
        <section className="popup-top">
          <h2>{t("visual_pack_history")}</h2>
          <CloseButton onClick={props.onClose} />
        </section>
        <section className="popup-content pb-50">
          <div className="text-center mt-16"></div>
          <ul className="accordion">
            <li>
              <section className="campaign-content">
                {renderVisualPacks()}
              </section>
            </li>
          </ul>
        </section>
      </section>
    </section>
  );
}
