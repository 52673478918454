import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { ContentTop, RadialChart, Spinner, Icon, Filter, FilterRow,TrackingCard } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getActiveCampaign, getStores, updateFilter, clearFilter, setInstalled } from './DashboardIndexActions';
import { start_end_date, estimated_end_date } from '../../../util/campaign';
import { Role } from '../../../util/jwt';
import { StoreStatusPrimark, FilterFields } from '../../../interfaces/interfaces';
import { Link } from 'react-router-dom';
import { RoleGuard } from '../../../components/RoleGuard';
import { country_map } from '../../../util/select_maps';
import { getAllLanguages, getCountries, getProductionLanguages, getCountryManagers } from '../../../reducers/datatypes/DatatypesActions';
const mapStateToProps = (state: RootState) => {
	return {
		dashboard_index_state: state.dashboardIndex,
		data_types_state: state.datatypes
	}
}
const connector = connect(mapStateToProps, {
	getActiveCampaign,
	getStores,
	updateFilter,
	clearFilter,
	setInstalled,
	getCountries,
	getProductionLanguages,
	getAllLanguages,
	getCountryManagers
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showFilter: boolean,
	showTracking:boolean,
	trackingNumber:string|undefined
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			showFilter: false,
			showTracking:false,
			trackingNumber:''
		};
	}

	componentDidMount() {
		this.props.getActiveCampaign();
		this.props.getStores();
		this.props.getCountries();
		this.props.getProductionLanguages();
		this.props.getAllLanguages();
		this.props.getCountryManagers();
	}

	renderFilter() {
		const { showFilter } = this.state;
		const { filter } = this.props.dashboard_index_state;
		const { countries, country_managers } = this.props.data_types_state;

		if (showFilter) {
			return (
				<Filter
					filterFields={ FilterFields.DASHBOARD_STORE }
					filter={ filter }
					countryManagers = { country_managers }
					countries={ countries.map(country_map) }
					onClose={() => this.setState({ showFilter: !showFilter })}
					onClear={() => {
						this.props.clearFilter();
						this.props.getStores();
						this.setState({ showFilter: !showFilter })
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.getStores();
						this.setState({ showFilter: !showFilter });
					}}
				/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;	
		
		return (
			<ContentTop>
				<article className="detail-top">
					<div>
						<h2>{ t('dashboards') }</h2>
					</div>
					<div className="detail-top--actions">
						<button onClick={() => window.open('https://kendub2b.blob.core.windows.net/primark/User_Manual.pdf', '_blank')} className="btn-outline outline-primary btn-m">
							{ t('user_manual') }
						</button>
						<button onClick={() => window.open('https://kendub2b.blob.core.windows.net/primark/Campaign_Info.pdf', '_blank')} className="btn-outline outline-primary btn-m">
							{ t('campaign_info') }
						</button>
					</div>
				</article>
				
			</ContentTop>
		)
	}

	renderDashboardBanner() {
		const { t } = this.props;
		const { active_campaign } = this.props.dashboard_index_state;

		if (active_campaign) {
			let sectionStyle = {
				backgroundPosition: 'right center',
				backgroundSize: '60%',
				backgroundRepeat: 'no-repeat',
				backgroundImage: `url(${active_campaign.image})`
			};

			return (
				<>
					<section id="dashboard-banner" className='role--marketing' style={ sectionStyle }>
						<div className='flex flex--column col-space-between'>
							<article className='flex flex--column gap-8'>
								<h2 className='headline-700--semibold'>{active_campaign.title}</h2>
								<ul className='flex gap-16'>
									<li><span className='dashboard-label'>{t(active_campaign.status_primark.toLowerCase())}</span></li>
									<li>{start_end_date(active_campaign)}</li>
								</ul>
							</article>

							<article>
								<ul className='flex gap-4'>
									<li>{ t('entrega_estimada') }:</li>
									<li>{estimated_end_date(active_campaign)}</li>
								</ul>
							</article>
						</div>
					</section>
				</>
			)
		}else {
			return (
				<>
					<article className='noResult'>
						<p>{ t('There are no campaigns') }</p>
					</article>
				</>
			)
		}
	}

	renderIndicators() {
        const { t } = this.props;
        const { active_campaign, total_stores, installed_stores, approved_stores } = this.props.dashboard_index_state;


		if (active_campaign) {
			return (
				<section id="dashboard-grid" className='flex flex-column gap-8'>
					<div className='grid cols-2 col-gap-8'>
						<article className='flex gap-16 col-center'>
							<div>
								<RadialChart progress={ isNaN(installed_stores/total_stores * 100) ? 0 : installed_stores/total_stores * 100} />
							</div>
							<div>
								<h3>{installed_stores}<span>{total_stores}</span></h3>
								<p>{ t('campañas_implementadas') }</p>
							</div>
						</article>

						<article className='flex gap-16 col-center'>
							<div>
								<RadialChart progress={isNaN(approved_stores/total_stores * 100) ? 0 : approved_stores/total_stores * 100} />
							</div>
							<div>
								<h3>{approved_stores}<span>{total_stores}</span></h3>
								<p>{ t('tiendas_aprobadas') }</p>
							</div>
						</article>
					</div>
					<div className='grid cols-3 col-gap-8'>
						<article>
							<div></div>
							<div>
								<h3>{total_stores}</h3>
								<p>{ t('tiendas_totales') }</p>
							</div>
						</article>

						<article>
							<div></div>
							<div>
								
							<h3>{Number(active_campaign.m2 || 0).toFixed(2)} m²</h3>
								
								<p>{ t('totales_de_lona') }</p>
							</div>
						</article>

						<article>
							<div></div>
							<div>
								<h3>{active_campaign.num_supports || 0}</h3>
								<p>{ t('soportes_en_campaña') }</p>
							</div>
						</article>
					</div>
				</section>
			)
		}
		return null;
    }

	getStoreStatusClass(status: StoreStatusPrimark) {
		const classes = ['status'];

		switch (status) {
			case StoreStatusPrimark.PENDING_APPROVAL_SM:
				classes.push('status--pending-approval');
				break;
			case StoreStatusPrimark.PENDING_SIGN_OFF_CM:
				classes.push('status--pending-sign-off');
				break;
			case StoreStatusPrimark.SIGNED_OFF:
				classes.push('status--signed-off');
				break;
			case StoreStatusPrimark.IN_PRODUCTION:
				classes.push('status--in-production');
				break;
			case StoreStatusPrimark.SHIPPED:
				classes.push('status--shipped');
				break;
			case StoreStatusPrimark.INSTALLED:
				classes.push('status--installed');
				break;
		}

		return classes.join(' ');
	}

	renderShopsManaged() {
        const { t } = this.props;
		const { showFilter,showTracking } = this.state;
		const { stores, filter,totalStores } = this.props.dashboard_index_state;
		return (
			<>
				<section id="shop-managed-container" className='flex flex-column gap-16'>
					<h2 className='headline-500--semibold'>{ t('tiendas_gestionadas') }</h2>
					<div>
						

					<FilterRow
						cssClass={'flex row-space-between col-center'}
						filter={filter}
						results={stores.length || 0}
						totalResults={totalStores.length || 0}
						pluralizeStrings={[t('result_found'), t('results_found')]}
						onFilterClicked={() => this.setState({ showFilter: !showFilter })}
						/> 
						
						<table className='table table--shops-managed'>
							<thead>
								<tr>
									<th className='w-50 text-left'>{ t('Salepoint') }</th>
									<th className='w-40 text-left'>{ t('Status') }</th>
									<th className='w-10 text-center'></th>
								</tr>
							</thead>
							<tbody>
							
								{ stores.sort((a, b) => Number(a.customer_shop_no) > Number(b.customer_shop_no) ? 1 : -1).map(store =>
								<>{store?.status_primark!==undefined &&
								<tr>
									
									<td>[{store.customer_shop_no}] {store.name}</td>
									
									<td>
										<ul className='status-grid'>
											<li className={this.getStoreStatusClass(store?.status_primark)}><a href="#a">{t(store?.status_primark.toLowerCase())}</a></li>
											{store.kp_support && <li className="status"><a href="#a">{ t('kendu_processing')+': '+store.kp_support_n }</a></li>}
										</ul>
									</td>
									<td className='flex gap-16 row-flex-end'>
										
										{/* <a href={store.tracking_number}></a> */}
										{store.tracking_number && store.status_primark !== StoreStatusPrimark.INSTALLED && <button onClick={()=> {
											this.props.setInstalled(store.id)
										}
										}>
											{t("install")}</button>
										}

										{store.tracking_number && store.tracking_number !== "-1" &&<button onClick={()=>this.setState({showTracking:!showTracking,trackingNumber:store.tracking_number})}><Icon type="package" /></button>}
										<Link to={`/stores/${store.id}`}><Icon type="chevron-right" /></Link>
									</td>
								</tr>
								}</>)}
							</tbody>
						</table>
					</div>
				</section>
			</>
		)
	}
	
	renderTrackingCard(){
		const{showTracking,trackingNumber}=this.state;
		if(showTracking){return <TrackingCard trackingNumber={trackingNumber} onClose={()=> this.setState({showTracking:false})}/>}
		
	}

	render () {
		const { t } = this.props;
		const { loading } = this.props.dashboard_index_state;

		if (loading) {
			return <Spinner message={ t('loading') } />;
		}
		return (
			<>
				{this.renderFilter()}
				{this.renderTrackingCard()}
				{this.renderContentTop()}
				{this.renderDashboardBanner()}
				<RoleGuard roles={[Role.MARKETING, Role.ADMIN, Role.BACKDOOR, Role.HEAD_VM, Role.KENDU_DESIGN]}>
					{this.renderIndicators()}
				</RoleGuard>
				<RoleGuard roles={[Role.COUNTRY_MANAGER, Role.STORE_MANAGER, Role.ADMIN, Role.BACKDOOR, Role.HEAD_VM]}>
					{this.renderShopsManaged()}
				</RoleGuard>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
