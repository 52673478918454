import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface InputCheckBoxStatusProps {
    cssClass?: string,
    name: string,
    id: string,
    checked: boolean,
    disabled:boolean,
    checkBoxChange: (value: boolean) => void,
}

type InputCheckBoxStatusPropsSum = InputCheckBoxStatusProps & WithTranslation;

class InputCheckBoxStatusComponent extends React.Component<InputCheckBoxStatusPropsSum> {

    render () {
        const { name, id, cssClass } = this.props;
        return (
            <article className={`check-group ${cssClass}`}>
                <input id={ id } type="checkbox" onChange={(event) => this.props.checkBoxChange(event.target.checked)} checked={ this.props.checked } disabled={this.props.disabled}/>
                <label htmlFor={ id }><span>{ name.toLocaleLowerCase() }</span></label>
            </article>
        );
    };
}

const InputCheckBoxStatus = withTranslation()(InputCheckBoxStatusComponent);
export { InputCheckBoxStatus };