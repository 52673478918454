import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { StoreStatusPrimark } from '../interfaces/interfaces';
export interface NotificationStoreProps {
    title: string,
    subtitle?: string,
    actions?: React.ReactNode[],
    status_class:StoreStatusPrimark | undefined
}

type NotificationStorePropsSum = NotificationStoreProps & WithTranslation;

class NotificationStoreComponent extends React.Component<NotificationStorePropsSum> {

    getStoreStatusClass(status: any){
        let color_class='';
        switch (status) {
            
			case StoreStatusPrimark.PENDING_APPROVAL_SM:
            color_class="pending-notification--red";
				break;
			case StoreStatusPrimark.PENDING_SIGN_OFF_CM:
				color_class="pending-notification--yellow";
				break;
			case StoreStatusPrimark.SIGNED_OFF:
            color_class="pending-notification--green";
				break;
			case StoreStatusPrimark.IN_PRODUCTION:
            color_class="pending-notification--blue";
				break;
			case StoreStatusPrimark.SHIPPED:
            color_class="pending-notification--blue";
				break;
			case StoreStatusPrimark.INSTALLED:
            color_class="pending-notification--black";
				break;
		}

		return color_class;
    }
    render () {
        const { title, subtitle, status_class } = this.props;
        return (
            <section className={`pending-notification ${this.getStoreStatusClass(status_class)}`}>
                <article className="pending-info">
                    <h2>{ title }</h2>
                    <h2>{ subtitle }</h2>
                </article>
                <article className="pending-action">
                    <ul>
                        {this.props.actions?.map((action, key) => <li key={key}>{action}</li>)}
                    </ul>
                </article>
            </section>
        );
    };
}

const NotificationStore = withTranslation()(NotificationStoreComponent);
export { NotificationStore };
