import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseButton } from '.';
import { EstimatedPriceChange } from '../interfaces/interfaces';
import { renderPrice } from '../util/price';

export interface EstimatedPricesChangeProps {
    estimatedPricesChange: EstimatedPriceChange [],
    onClose: () => void,
}

type EstimatedPricesChangePropsSum = EstimatedPricesChangeProps & WithTranslation;

class EstimatedPricesChangeComponent extends React.Component<EstimatedPricesChangePropsSum> {

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    render () {
        const { t, estimatedPricesChange } = this.props

        return(
            <section id="store-estimated-budget" className="cover-blur /*active*/ active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('estimated')}</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content pb-40">
                        <fieldset>
                        <table className="estimated">
                            <tbody>
                                { estimatedPricesChange.map((estimatedPriceChange, index) => 
                                    {
                                        let unit = index !== 0 && index !== 1 ? 'eur' : 'uds';
                                        if(index!==5){
                                            return (
                                                <tr key={index}>
                                                    <th scope="row" className="text-left">{ estimatedPriceChange.name }</th>
                                                    <td className="text-right">{ renderPrice(estimatedPriceChange.price, unit) }</td>
                                                </tr>
                                                )
                                        }else{
                                            return(<div key={index}></div>)
                                        }
                                        
                                    }
                                    
                                )}
                            </tbody>
                             <tfoot>
                                <tr key={20}>
                                    <th scope="row" className="text-left">{ t('Total CAMPAIGN')}</th>
                                    <td className="text-right">{ renderPrice( estimatedPricesChange[5].price,' eur')}</td>
                                </tr>
                            </tfoot>
                            </table>
                        </fieldset>
                    </section>
                </section>
            </section>
        )
    };
}

const EstimatedPricesChange = withTranslation()(EstimatedPricesChangeComponent);


export { EstimatedPricesChange };
