import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign } from '../../../interfaces/interfaces';

export const UPDATE_CAMPAIGN = 'update_campaign';
export const UPDATE_LOADING = 'update_loading';


export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateCampaignAction {
    type: typeof UPDATE_CAMPAIGN,
    payload: Campaign
}

export type StoreValidateCropsActions =
UpdateLoadingAction |
UpdateCampaignAction ;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
