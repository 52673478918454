import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';


export interface ClickHandlerProps {
    onClickedOutside?: () => void,
}

type ClickHandlerPropsSum = ClickHandlerProps & WithTranslation;

class ClickHandlerComponent extends React.Component<ClickHandlerPropsSum> {

    private clickHandlerRef: any;

    constructor(props: ClickHandlerPropsSum) {
        super(props);

        this.clickHandlerRef = React.createRef();

        this.state = {
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (e: { target: any; }) => {
        const { onClickedOutside } = this.props;
        if (this.clickHandlerRef.current.contains(e.target)) {
            return;
        }
        if (onClickedOutside) {
            onClickedOutside();
        }
    }

    render () {
        return (
            <div ref={ this.clickHandlerRef }>
                {this.props.children}
            </div>
        );
    }
}

const ClickHandler = withTranslation()(ClickHandlerComponent);
export { ClickHandler };
