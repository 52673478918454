import {
    UPDATE_STORES,
    UPDATE_TOTAL_STORES,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    StoreIndexActions,
    AppThunk,
} from './ActionTypes';
import { Filter } from '../../../interfaces/interfaces';
import { get_stores } from '../../../api';
import { FilterStoreRequest } from '../../../api/interfaces';

export const getStores = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as StoreIndexActions);

        const stores = await get_stores({
            ...getState().storeIndex.filter,
            name: getState().storeIndex.filter.search,
            page_size: 0,
            key: lastKey
        } as FilterStoreRequest);

        const totalStores = await get_stores({
            page_size: 0
        } as FilterStoreRequest);

        dispatch({
            type: UPDATE_STORES,
            payload: stores
        } as StoreIndexActions);

        dispatch({
            type: UPDATE_TOTAL_STORES,
            payload: totalStores
        } as StoreIndexActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as StoreIndexActions);
    }
}

export const updateFilter = (value: Filter): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as StoreIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as StoreIndexActions);
    }
}