import {
    UPDATE_CAMPAIGNS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    GalleryIndexActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Campaign, Filter } from "../../../interfaces/interfaces";

export interface GalleryIndexState {
    campaigns: Campaign[],
    loading: boolean,
    filter: Filter,
}

const INITIAL_STATE: GalleryIndexState = {
    campaigns: [],
    loading: false,
    filter: new Filter(),
};

const reducer: Reducer<GalleryIndexState, GalleryIndexActions> = (state = INITIAL_STATE, action: GalleryIndexActions) => {
    switch(action.type) {
        case UPDATE_CAMPAIGNS:
            return { ...state, campaigns: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new Filter() }
        default:
            return state;
    }
}

export default reducer;