import { Support, Store, SupportStatusPrimark } from "../interfaces/interfaces";

// FLOORPLAN
const LABEL_HEIGHT = 14;
const INDICATOR_WIDTH = 8;
const INDICATOR_HEIGHT = 30;
const DEFAULT_INDICATOR_COLOR = '#00B4D9';
const MARGIN = 4;

export const getSupports = (floor_id: number, store: Store | undefined) => {
    if(store){
        return store.sections.reduce<Support[]>((supports, section) => [...supports, ...section.supports?.filter(support => support.floor_id === floor_id) || []], []) || [];
    }else{
        const supports: Support[] = [];
        return supports;
    }
}

export const labelContainerProps = (support: Support, defaultCharWidth:number, widthValue?: number, labelHeight?:number, indicatorWidth?:number, indicatorHeight?:number, margin?:number) => {
    var labelHeightValue = LABEL_HEIGHT;
    if(labelHeight) labelHeightValue = labelHeight;

    var indicatorWidthValue = INDICATOR_WIDTH;
    if(indicatorWidth) indicatorWidthValue = indicatorWidth;

    var indicatorHeightValue = INDICATOR_HEIGHT;
    if(indicatorHeight) indicatorHeightValue = indicatorHeight;

    var marginValue = MARGIN;
    if(margin) marginValue = margin;

    const text = support.no_soporte_cliente;
    var width = text.length * defaultCharWidth;
    if(widthValue && widthValue !== 0 ) width = widthValue;
    
    switch(support.map_disposition) {
        case 'right':
          return  {
                x: 0,
                y: indicatorHeightValue / 2 - labelHeightValue / 2,
                height: labelHeightValue,
                width: width
            };
        case 'bottom':
          return {
                x: width < indicatorHeightValue ? indicatorHeightValue / 2 - width  / 2 : 0,
                y: 0,
                height: labelHeightValue,
                width: width
            };
        case 'left':
          return {
                x: indicatorWidthValue + marginValue,
                y: indicatorHeightValue / 2 - labelHeightValue / 2,
                height: labelHeightValue,
                width: width
            };
        case 'top':
          return {
                x: width < indicatorHeightValue ? indicatorHeightValue / 2 - width / 2 : 0,
                y: indicatorWidthValue + marginValue,
                height: labelHeightValue,
                width: width
            };
    }
}

export const labelTextProps = (support: Support, defaultCharWidth:number, widthValue?: number, labelHeight?:number, indicatorWidth?:number, indicatorHeight?:number, margin?:number) => {
    var labelHeightValue = LABEL_HEIGHT;
    if(labelHeight) labelHeightValue = labelHeight;

    var indicatorWidthValue = INDICATOR_WIDTH;
    if(indicatorWidth) indicatorWidthValue = indicatorWidth;

    var indicatorHeightValue = INDICATOR_HEIGHT;
    if(indicatorHeight) indicatorHeightValue = indicatorHeight;

    var marginValue = MARGIN;
    if(margin) marginValue = margin;

    const text = support.no_soporte_cliente;
    var width = text.length * defaultCharWidth;
    if(widthValue && widthValue !== 0 ) width = widthValue;

    switch(support.map_disposition) {
        case 'right':
            return {
                x: width / 2,
                y: indicatorHeightValue / 2,
            }
        case 'bottom':
            return {
                x: width < indicatorHeightValue ? indicatorHeightValue / 2 : width / 2,
                y: labelHeightValue / 2,
            }
        case 'left':
            return {
                x: indicatorWidthValue + marginValue + width / 2,
                y: indicatorHeightValue / 2,
            }
        case 'top':
            return {
                x: width < indicatorHeightValue ? indicatorHeightValue / 2 : width / 2,
                y: indicatorWidthValue + marginValue + labelHeightValue / 2,
            }
    }
}

export const supportIndicatorProps =(support: Support, defaultCharWidth:number, widthValue?: number, labelHeight?:number, indicatorWidth?:number, indicatorHeight?:number, margin?:number) => {
    var labelHeightValue = LABEL_HEIGHT;
    if(labelHeight) labelHeightValue = labelHeight;

    var indicatorWidthValue = INDICATOR_WIDTH;
    if(indicatorWidth) indicatorWidthValue = indicatorWidth;

    var indicatorHeightValue = INDICATOR_HEIGHT;
    if(indicatorHeight) indicatorHeightValue = indicatorHeight;

    var marginValue = MARGIN;
    if(margin) marginValue = margin;

    const text = support.no_soporte_cliente;
    var width = text.length * defaultCharWidth;
    if(widthValue && widthValue !== 0 ) width = widthValue;

    switch(support.map_disposition) {
        case 'right':
            return {
                x: width + marginValue,
                y: 0,
                width: indicatorWidthValue,
                height: indicatorHeightValue
            }
        case 'bottom':
            return {
                x: width < indicatorHeightValue ? 0 : width / 2 - indicatorHeightValue / 2,
                y: labelHeightValue + marginValue,
                width: indicatorHeightValue,
                height: indicatorWidthValue
            }
        case 'left':
            return {
                x: 0,
                y: 0,
                width: indicatorWidthValue,
                height: indicatorHeightValue
            }
        case 'top':
            return {
                x: width < indicatorHeightValue ? 0 : width / 2 - indicatorHeightValue / 2,
                y: 0,
                width: indicatorHeightValue,
                height: indicatorWidthValue
            }
    } 
}

export const getIndicatorColor = (support: Support) => {
    switch (support.status_primark) {
        case (SupportStatusPrimark.APPROVED_BY_CM): return '#34D058';
        case (SupportStatusPrimark.APPROVED_BY_SM): return '#FFDF5D';
        case (SupportStatusPrimark.NOT_IN_CAMPAIGN): return '#959DA5';
        case (SupportStatusPrimark.PENDING_APPROVAL): return '#EA4A5A';
        case (SupportStatusPrimark.PENDING_APPROVAL_CM): return '#FFDF5D';
        case (SupportStatusPrimark.KENDU_PROCESSING): return '#2188FF';
        default: return DEFAULT_INDICATOR_COLOR;
    }
}

export const getCenter = (support: Support, x: number, y: number, width: number, labelHeight?:number) => {
    var labelHeightValue = LABEL_HEIGHT;
    if(labelHeight){
        labelHeightValue = labelHeight;
    }
    const centerOffset = () => {
        switch(support.map_disposition) {
        case 'right':
        case 'left':
            return {
                dx: (width + MARGIN + INDICATOR_WIDTH) / 2,
                dy: INDICATOR_HEIGHT / 2
            }
        case 'bottom':
        case 'top':
            return {
                dx: width < INDICATOR_HEIGHT ? INDICATOR_HEIGHT / 2 : width / 2,
                dy: (labelHeightValue + MARGIN + INDICATOR_WIDTH) / 2
            }
        }
    }

    const { dx, dy } = centerOffset();

    return {
        x: x + dx,
        y: y + dy
    }
}

export const scaleOrigin = (support: Support, x: number, y: number, width: number, scaleFactor: number) => {
    var labelHeightValue = LABEL_HEIGHT;
    
    const measure = () => {
        switch(support.map_disposition) {
        case 'right':
        case 'left':
            return {
                w: (width + MARGIN + INDICATOR_WIDTH),
                h: INDICATOR_HEIGHT
            }
        case 'bottom':
        case 'top':
            return {
                w: width < INDICATOR_HEIGHT ? INDICATOR_HEIGHT : width ,
                h: labelHeightValue + MARGIN + INDICATOR_WIDTH
            }
        }
    }

    const { w, h } = measure();

    return {
        translateX: (w - w * scaleFactor) / 2,
        translateY: (h - h * scaleFactor) / 2
    }
}
