import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

export interface InputTextAreaProps {
    id: string,
    type?: string,
    label?: string,
    value?: string,
    disabled?: boolean,
    hasError?: boolean,
    helpText?: string,
    className?: string,
    placeholder?: string,
	maxLength?: number,
    onChange?: (text: string) => void
}

type InputTextAreaPropsSum = InputTextAreaProps & WithTranslation;

class InputTextAreaComponent extends React.Component<InputTextAreaPropsSum> {

    render () {
        const { id, type, label, value, disabled, helpText, className, placeholder, maxLength, onChange } = this.props;
        return (
            <TextField
                id={ id }
                type={ type || 'text' }
                label={ label }
                value={ value }
                onChange={(event) => onChange ? onChange(event.target.value) : null}
                variant="outlined"
                inputProps={{ className: className, maxLength }}
                fullWidth
                disabled={ disabled }
                error={ !!helpText }
                helperText={ helpText }
                placeholder={ placeholder }
                multiline
                rows={4}
            />
        );
    };
}

const InputTextArea = withTranslation()(InputTextAreaComponent);
export { InputTextArea };
