import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Store, Filter } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_STORES = 'update_stores';
export const UPDATE_FILTER = 'update_filter_store';
export const CLEAR_FILTER = 'clear_filter_store';
export const UPDATE_TOTAL_STORES = 'update_total_stores';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface UpdateTotalStoresAction {
    type: typeof UPDATE_TOTAL_STORES,
    payload: Store[]
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: Filter
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export type StoreIndexActions =
UpdateLoadingAction |
UpdateStoresAction |
UpdateFilterAction |
ClearFilterAction |
UpdateTotalStoresAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
