import jwt_decode from 'jwt-decode';

export enum Role {
    MARKETING = 'marketing',
    HEAD_VM = 'head-vm',
    COUNTRY_MANAGER = 'country-manager',
    STORE_MANAGER = 'store-manager',
    ADMIN = 'admin',
    BACKDOOR = 'backdoor',
	KENDU_DESIGN = 'kendu-design'
}

export const get_roles = () => [Role.MARKETING, Role.HEAD_VM, Role.COUNTRY_MANAGER, Role.STORE_MANAGER, Role.ADMIN, Role.BACKDOOR, Role.KENDU_DESIGN]

interface MyToken {
    customer: string
    exp: number
    iat: number
    role: Role
    sub: number
    username: string
}

export const get_role = (token: string) => {
    return jwt_decode<MyToken>(token).role;
}
