import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActivityFilter as FilterInterface, SelectOption, PrimaryButtonStyle, } from "../interfaces/interfaces";
import {InputSelect,CloseButton,PrimaryButton,} from '../components';

import TextField from '@material-ui/core/TextField';

export interface ActivityFilterProps {
    filter: FilterInterface,
    activities_types_options?: SelectOption[],
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterInterface) => void
    
}

interface State {
    filter: FilterInterface,
    date_from_error:boolean,
    date_to_error:boolean,
}

type FilterPropsSum = ActivityFilterProps & WithTranslation;

class FilterComponent extends React.Component<FilterPropsSum, State> {

    constructor(props: FilterPropsSum) {
        super(props);

        this.state = {
            filter: props.filter,
            date_from_error:false,
            date_to_error:false
        };
    }

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    onSubmit() {
        const { filter } = this.state;
        

        if(filter.to!==null &&filter.to!==undefined && filter.from!==null && filter.from!==undefined && filter?.from>filter.to){
            this.setState({ date_to_error:true});

        }else{
            this.props.onSubmit(filter);
        }
        
    }

   

    


    renderActivityTypes() {
        const { t, activities_types_options } = this.props
        const { filter,  } = this.state;
     
        return (
            
            <div className="form-row">
                <div className="form-row check-group-title">
                    <legend>{ t('Activity Types') }</legend>
                </div>
               
                <InputSelect 
                    id="activity_types" 
                   
                    options={ activities_types_options }
                    onChange={(value,label) => this.setState({ filter: { ...filter, activity_type: value } })}
                    value={filter.activity_type}
                    helpText={ "" }
                    disabled={ false }/>
                </div> 
        )
        
    }

    

    renderDateFilter(){
        const { filter,date_from_error,date_to_error } = this.state;
        const { t } = this.props;
        return(
            <div className='grid cols-2 col-gap-8'>
                <div>
                    <div className="form-row check-group-title">
                        <legend>{ t('From') }</legend>
                    </div>
                    <TextField
                    id={ 'from' }
                    type={ 'datetime-local' }

                    value={ filter.from }
                    onChange={(event) => this.setState({filter:{...filter,from:event.target.value}})}
                    variant="outlined"
                    inputProps={{ className: 'field' }}
                    fullWidth

                    error={date_from_error}
                    />
                </div>
                <div>
                    <div className="form-row check-group-title">
                        <legend>{ t('To') }</legend>
                    </div>
                    <TextField
                    id={ 'to' }
                    type={ 'datetime-local' }
                    
                    value={ filter.to }
                    onChange={(event) => this.setState({filter:{...filter,to:event.target.value}})}
                    variant="outlined"
                    inputProps={{ className: 'field' }}
                    fullWidth
                 
                    error={date_to_error}
                    />
                </div>
                
                
            </div>
            

        )
    }


    render () {
        const { t } = this.props;
        return (
             <section id="popUp-advanced-filter" className="cover-blur advanced-filter-popup active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('advanced_filter') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content formlines-container">
                
                    <fieldset className="advanced-filter-datapicker">
                    {this.renderActivityTypes()}
                       {this.renderDateFilter()}
                    </fieldset>
        
                    </section>
                    <section className="popup-actions">
                        <PrimaryButton onClick={this.props.onClear} style={PrimaryButtonStyle.OUTLINED}>
                            { t('clear_filters') }
                        </PrimaryButton>
                        <PrimaryButton onClick={() => this.onSubmit()} style={PrimaryButtonStyle.FILLED}>
                            { t('apply_filter') }
                        </PrimaryButton>
                    </section>
                </section>
            </section> 
        );
    };
}

const ActivityFilter = withTranslation()(FilterComponent);
export { ActivityFilter };
