import React from 'react';
import { Link } from "react-router-dom";
import { Campaign, CampaignStatusPrimark } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { start_end_date } from '../util/campaign';

export interface CampaignCardProps {
    campaign: Campaign,
    crops?: boolean
}

type CampaignCardPropsSum = CampaignCardProps & WithTranslation;

class CampaignCardComponent extends React.Component<CampaignCardPropsSum> {

    cardLink(id: string|undefined) {
        if (id === undefined) {
            return '#';
        }
        if(this.props.crops){
            return `/crops_validation/${id}`;
        }
        return `/campaigns/${id}`;
    }

    checkDisabled(status: CampaignStatusPrimark) {
        if (status === CampaignStatusPrimark.LIVE) {//TODO determinar cuando no se puede acceder a una campaña
            return 'disabled'
        }
        return ''
    }

    render () {
        const { t } = this.props
        const { id, image, title, status_primark } = this.props.campaign;

        var statusString = ""
        if (status_primark) {
            statusString= status_primark.toLowerCase()
            if (statusString === "kendu_processing") { // KENDU_PROCESSING => RATIO_PROCESSING
                statusString = t("ratio_processing")
            } else {
                statusString = t(statusString)
            }
        }
       
        
        return (
            <Link to={this.cardLink(id)}>
                <article className={`card ${this.checkDisabled(status_primark)} card-campaign cursor-hand`}>
                    <div className="card-state">
                        <em>{statusString}</em>
                    </div>
                    <div className="card-content">
                        <h3>{title}</h3>
                        <p>{ start_end_date(this.props.campaign) }</p>
                    </div>
                    <div className="card-image">
                        <img src={image} alt="" />
                    </div>
                </article>
            </Link>
        );
    };
}

const CampaignCard = withTranslation()(CampaignCardComponent);
export { CampaignCard };
